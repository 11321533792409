import { connection } from '@/api/connection'
import { pathBuilder } from '@/helpers'

const { get, put, post, delete: remove } = connection

const controllerPath = ({
  applicationId,
  action,
  thirdPartyId
}) => pathBuilder`bo/factoring/applications${applicationId}third-parties${action}${thirdPartyId}`

export default {
  getFactoringApplicationThirdParties: ({ applicationId }) => get(controllerPath({ applicationId })),
  putFactoringApplicationThirdParty: ({
    applicationId,
    thirdPartyId,
    thirdPartyData
  }) => put(controllerPath({ applicationId, thirdPartyId }), thirdPartyData),
  postFactoringApplicationThirdParty: ({
    applicationId,
    thirdPartyData
  }) => post(controllerPath({ applicationId }), thirdPartyData),
  deleteFactoringApplicationThirdParty: ({
    applicationId,
    thirdPartyId
  }) => remove(controllerPath({ applicationId, thirdPartyId }))
}
