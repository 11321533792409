import pickBy from 'lodash/pickBy'
import { connection } from './connection'

const CONTROLLER_ROOT = 'bo/payments'

export default {
  getPayments ({ filters }) {
    return connection.get(CONTROLLER_ROOT, {
      params: pickBy(filters)
    })
  },
  getPayment ({ id }) {
    return connection.get(`${CONTROLLER_ROOT}/${id}`)
  },
  putPayment ({ id, data }) {
    return connection.put(`${CONTROLLER_ROOT}/${id}`, data)
  },
  getMatchingLoans ({ id }) {
    return connection.get(`${CONTROLLER_ROOT}/${id}/matchingLoans`)
  },
  postPayment ({ files }) {
    const formData = new FormData()
    Array.from(files).forEach(file => {
      formData.append('files', file)
    })
    return connection.post(CONTROLLER_ROOT, formData)
  },
  postSplitPayment ({ id, data }) {
    return connection.post(`${CONTROLLER_ROOT}/${id}/split`, data)
  },
  getHandlers () {
    return connection.get(`${CONTROLLER_ROOT}/handler`)
  },
  getHandlersManual () {
    return connection.get(`${CONTROLLER_ROOT}/handler/manual`)
  },
  getPaymentFiles: () => connection.get('bo/payment/file'),
  deletePaymentFile: ({ id }) => connection.delete(`bo/payment/file/${id}`),
  getSplitInfo: (params) => connection.get(`${CONTROLLER_ROOT}/split-info`, { params })
}
