<template lang="pug">
tr.dashboard-application-list-item
  td: router-link(:to = '{ name: "Application", params: { id: application.id }}') {{ application.id }}
  td
    router-link(v-if = 'application.customerId'
                :to  = '{ name: "Customer", params: { id: application.customerId }}') {{ customerName(customerFromApplication(application)) }} ({{ application.idCode }})
    template(v-else) {{ customerName(customerFromApplication(application)) }} ({{ application.idCode }})
  td {{ product.name }}
  td.money {{ application.amount | money }}
  td {{ $t('common:formatDate', { value: application.createdAt }) }}
  td
    template(v-if = '$vueLoading.isLoading("classifiers:managers:fetch")')
      i.fa.fa-spinner.fa-pulse
    template(v-else-if = 'application.employeeId') {{ managerById(application.employeeId).name }}
    template(v-else-if = '$vueLoading.isLoading(`dashboard:applicationAssignee:${application.id}:save`)')
      | {{ $t('assigning') }}
      |
      i.fa.fa-spinner.fa-pulse
    template(v-else)
      a(href           = '#'
        @click.prevent = 'assignToMe') {{ $t('assignToMe') }}
  td: .badge(:class = '`badge-${applicationStatusColor(application.statusId)}`')  {{ applicationStatus.human }}
</template>


<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'dashboard-application-list-item',

  props: {
    application: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('classifiers', ['classifierById', 'managerById']),
    ...mapGetters('applications', ['applicationStatusColor', 'customerFromApplication']),
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('products', ['productById']),
    applicationStatus () {
      return this.classifierById('creditApplicationStatuses', this.application.statusId)
    },
    product () {
      return this.productById(this.application.productId)
    }
  },

  methods: {
    ...mapActions('dashboard', ['setApplicationAssignee', 'loadDashboardApplicationsNew']),
    async assignToMe () {
      await this.setApplicationAssignee({ id: this.application.id, customerId: this.user.id })
      this.loadDashboardApplicationsNew()
    }
  }
}
</script>


<i18n>
en:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."

et:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."


ru:
  assignToMe: "Assign to me"
  assigning:  "Assigning..."

</i18n>
