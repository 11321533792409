<template lang="pug">
select.fi-select.custom-select.form-control(
  :class  = 'sizeClasses'
  v-model = 'localValue'
  v-bind  = '$attrs'
)
  option(
    v-if      = '!$attrs.required'
    value     = ''
    :disabled = '$attrs.required'
  ) {{ placeholder || $t('common:selectBlank') }}
  option(
    v-for     = 'option in localOptions'
    :disabled = 'option.disabled'
    :value    = 'option[valueBy]'
  ) {{ option[labelBy] }}
</template>


<script>
export default {
  name: 'fi-select',

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, Object],
      default: ''
    },
    options: {
      type: [Array, Object],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    lg: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    },
    labelBy: {
      type: String,
      default: 'text'
    },
    valueBy: {
      type: String,
      default: 'value'
    }
  },

  computed: {
    localOptions () {
      if (Array.isArray(this.options)) {
        return this.options.map((option) => {
          const { [this.valueBy]: value, [this.labelBy]: text } = option
          return { ...option, [this.labelBy]: text ?? value ?? option, [this.valueBy]: value ?? option.value ?? option }
        })
      }
      return Object.entries(this.options || {}).map(([value, text, ...option]) => ({ ...option, [this.labelBy]: text, [this.valueBy]: value }))
    },
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    sizeClasses () {
      return [
        ...(this.sm ? ['custom-select-sm', 'form-control-sm'] : []),
        ...(this.lg ? ['custom-select-lg', 'form-control-lg'] : [])
      ]
    }
  },

  created () {
    if (this.$attrs.autoSelect) {
      this.value = this.options[0].value
      this.$emit('input', this.value)
    }
  }
}
</script>


<style lang='scss' scoped>
  .fi-select {
    .input-group .input-group-prepend:not(:last-child) & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .input-group .input-group-prepend:not(:first-child) & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
</style>
