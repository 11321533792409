<template lang="pug">
.row.payment-split-item
  .col-lg-2
    fi-form-field
      fi-select(
        :options = 'splitTypes'
        v-model  = 'split.splitType'
        required
      )
  .col-lg
    fi-form-field(
      :label     = 'split.splitType === "FACTORING_INVOICE_NUMBER" ? $t("invoiceNumber") : $t("reference")'
      label-cols = 4
    )
      fi-multiselect(
        v-model   = 'splitModel'
        track-by  = 'number'
        label     = 'number'
        :options  = 'splitOptions'
        @search   = 'searchSplitInfo'
      )
        template(#option="{ option }")
          .row
            .col  {{ option.invoiceNumber ? option.invoiceNumber : option.number }}
            .col.text-right {{ option.invoiceAmount || option.prepaymentBalance | money }}
        template(#singleLabel="{ option }")
          .row
            .col {{ option.invoiceNumber ? option.invoiceNumber : option.number }}
            .pull-right {{ option.invoiceAmount || option.prepaymentBalance | money }}
  .col-lg(v-if = 'split.splitType !== "REFERENCE_NUMBER"')
    fi-form-field(
      :label     = '$t("referenceNumber")'
      label-cols = 4
    )
      input.form-control(v-model = 'split.referenceNumber')
  .col-lg-1(v-if = 'splitStatus.lenght')
    fi-data-field(
      v-if        = 'splitStatus'
      :label-cols = '0'
    )
      .badge(:class = '`badge-${factoringInvoiceStatusColor(splitStatus.id)}`') {{ splitStatus.human }}
  .col-lg
    fi-form-field(
      :label     = '$t("amount")'
      label-cols = 4
    )
      fi-money-input.form-control(
        v-model       = 'split.amount'
        :decimalLimit = 4
      )
  .col-lg
    fi-form-field(
      :label     = '$t("description")'
      label-cols = 4
    )
      input.form-control(v-model = 'split.description')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'
import FiMoneyInput from '@/components/FiMoneyInput'
import api from '@/api'
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'

export default {
  name: 'payment-split-item',

  components: { FiDataField, FiMoneyInput, FiMultiselect, FiSelect, FiFormField },

  props: {
    split: {
      type: Object,
      required: true
    },
    splitTypes: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    splitOptions: [],
    splitOptionsInv: [],
    selectedSplit: null,
    selectedSplitInv: null
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierByCode']),
    ...mapGetters('factoring', ['factoringInvoiceStatusColor']),
    splitModel: {
      get () {
        return this.selectedSplit ?? {}
      },
      set (split) {
        if (split.invoiceNumber) {
          this.split.invoiceNumber = split.invoiceNumber
        }
        this.split.referenceNumber = split.number
        this.selectedSplit = split
      }
    },
    splitModelInv: {
      get () {
        return this.selectedSplitInv ?? {}
      },
      set (split) {
        this.split.invoiceNumber = split.number
        this.selectedSplitInv = split
      }
    },
    splitStatus () {
      const { classifier, code } = this.selectedSplit?.invoiceStatus ?? {}
      return this.classifierByCode(classifier, code)
    }
  },

  methods: {
    async searchSplitInfo ({ query }) {
      const { data } = await api.getSplitInfo({
        splitType: this.split.splitType === 'FACTORING_INVOICE_NUMBER' ? 'FACTORING_INVOICE_NUMBER' : 'REFERENCE_NUMBER',
        number: query
      })

      this.splitOptions = data
    },
    async searchSplitInfoInv ({ query }) {
      const { data } = await api.getSplitInfo({
        splitType: 'FACTORING_INVOICE_NUMBER',
        number: query
      })

      this.splitOptionsInv = data
    }
  }
}
</script>

<i18n>
  en:
    description:      "Description"
    amount:           "Amount"
    referenceNumber:  "Reference number"
    reference:        "Reference"
    invoiceNumber:     "Invoice number"
  et:
    description:      "Description"
    amount:           "Amount"
    referenceNumber:  "Reference number"
    reference:        "Reference"
    invoiceNumber:     "Invoice number"
  ru:
    description:      "Description"
    amount:           "Amount"
    referenceNumber:  "Reference number"
    reference:        "Reference"
    invoiceNumber:     "Invoice number"
</i18n>
