<template lang="pug">
.template
  v-loading(loader = 'template:fetch')
    template(#spinner)
      .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
    .animated.fadeIn
      .card
        .card-header {{ header }}
        .card-body
          button.btn.btn-primary(
            :disabled = 'saving'
            @click    = 'onSubmit'
          ) {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
          | &nbsp;
          button.btn.btn-secondary(
            :disabled = 'saving'
            @click    = 'onCancel'
          ) {{ $t('common:cancel') }}
      .card
        .card-body
          form.form-horizontal(@submit.prevent = 'onSubmit')
            fi-form-field(
              :label      = '$t("status")'
              :label-cols = '3'
            )
              .switch-status-wrap
                fi-switch(
                  v-model  = 'templateData.active'
                  on-text  = 'Enabled'
                  off-text = 'Disabled'
                )
            fi-form-field(
              :label      = '$t("name")'
              :label-cols = '3'
              :state      = '!$v.templateData.name.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.templateData.name.required') {{ $t('common:required') }}
              input.form-control.form-control-sm(
                v-model.trim = 'templateData.name'
                type         = 'text'
                :placeholder = '$t("nameFieldPh")'
                :class       = '{ "is-invalid": $v.templateData.name.$error }'
              )

            fi-form-field(
              :label      = '$t("type")'
              :label-cols = '3'
              :state      = '!$v.templateData.typeId.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.templateData.typeId.required') {{ $t('common:required') }}
              fi-select(
                v-model.number = 'templateData.typeId'
                :options       = 'optionsFromClassifier("templateType")'
                :class         = '{ "is-invalid": $v.templateData.typeId.$error }'
                sm
              )

            fi-form-field(
              :label      = '$t("defaultLang")'
              :label-cols = '3'
              :state      = '!$v.templateData.defaultLanguageId.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.templateData.defaultLanguageId.required') {{ $t('common:required') }}
              fi-select(
                v-model.number = 'templateData.defaultLanguageId'
                :options       = 'languages'
                :class         = '{ "is-invalid": $v.templateData.defaultLanguageId.$error }'
                sm
              )

      template-content(
        v-for     = 'language in languages'
        :key      = 'language.value'
        :language = 'language.text'
        :content  = 'templateData.content[language.value]'
        :is-pdf   = 'isPdfTemplate'
      )
      .card
        .card-body
          button.btn.btn-primary(
            :disabled = 'saving'
            @click    = 'onSubmit'
          ) {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
          | &nbsp;
          button.btn.btn-secondary(
            :disabled = 'saving '
            @click    = 'onCancel'
          ) {{ $t('common:cancel') }}
</template>


<script>
import TemplateContent from '@/views/admin/templates/TemplateContent'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import { rules, validation } from '@/validation'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import FiCodemirror from '@/components/FiCodemirror'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'

export default {
  name: 'template',

  components: {
    TemplateContent,
    FiSwitch,
    FiCodemirror,
    FiFormField,
    FiSelect
  },

  mixins: [validation],

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },

  i18nOptions: {},

  data () {
    return {
      templateData: {
        active: false,
        name: '',
        typeId: '',
        description: '',
        defaultLanguageId: '',
        content: {}
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName', 'classifierById', 'classifierList']),
    ...mapState('templates', { template: 'current' }),
    ...mapGetters('settings', ['settingByKey']),
    isNewTemplate () {
      return !this.id
    },
    header () {
      return this.isNewTemplate ? this.$t('newTemplate') : this.$t('editTplTitle', { id: this.id })
    },
    isPdfTemplate () {
      const { typeId } = this.template || this.templateData
      return /pdf/i.test(this.classifierById('templateType', typeId).code)
    },
    saving () {
      return this.$vueLoading.isLoading('template:save')
    },
    languages () {
      return Object.values(this.classifierList('languages'))
        .filter(lang => this.settingByKey('supportedTemplateLanguages').includes(lang.code))
        .map(({ human, id }) => ({ text: human, value: id }))
    }
  },

  validations () {
    return {
      templateData: pick(rules.templateRules, Object.keys(this.templateData))
    }
  },

  async created () {
    if (!this.isNewTemplate) {
      await this.loadTemplate({ id: this.id })
    }
    this.resetData()
  },

  methods: {
    ...mapActions('templates', ['loadTemplate', 'updateTemplate', 'createTemplate']),
    resetData () {
      let content = this.languages.reduce((res, { value }) => ({ ...res, [value]: { subject: '', content: '' } }), {})
      if (!this.isNewTemplate) {
        content = merge({}, content, pickBy(this.template.content))
        this.templateData = pick(this.template, Object.keys(this.templateData))
      }
      this.templateData.content = content
    },
    onCancel () {
      this.$router.push({ name: 'TemplateList' })
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.isNewTemplate) {
          await this.createTemplate({ templateData: this.templateData })
          this.$router.push({ name: 'Template', params: { id: this.template.id } })
        } else {
          await this.updateTemplate({ templateData: this.templateData, id: this.id })
          this.$toasted.success(this.$t('success'))
          this.$router.push({ name: 'TemplateList' })
        }
      }
    }
  }
}
</script>


<style scoped lang="scss">
.card:not(:last-child) {
  margin-bottom: 0;
  border-bottom: 0;
}
.card:last-of-type, .card:nth-of-type(2) {
  border-top: 0;
}
</style>


<i18n>
en:
  defaultLang:       "Default language"
  editTplTitle:      "Edit template #{{id}}"
  language:          "Language"
  name:              "Template name"
  nameFieldPh:       "Enter template name"
  newTemplate:       "New template"
  status:            "Status"
  subject:           "Subject"
  type:              "Template type"
  success:           "Template changes successfully saved"
et:
  defaultLang:       "Default language"
  editTplTitle:      "Edit template #{{id}}"
  language:          "Language"
  name:              "Template name"
  nameFieldPh:       "Enter template name"
  newTemplate:       "New template"
  status:            "Status"
  subject:           "Subject"
  type:              "Template type"
  success:           "Template changes successfully saved"
ru:
  defaultLang:       "Default language"
  editTplTitle:      "Edit template #{{id}}"
  language:          "Language"
  name:              "Template name"
  nameFieldPh:       "Enter template name"
  newTemplate:       "New template"
  status:            "Status"
  subject:           "Subject"
  type:              "Template type"
  success:           "Template changes successfully saved"
</i18n>
