<template lang="pug">
.app.full-view
  header-view
  .app-body
    sidebar-view
    main.main
      breadcrumb-view(:list = 'list')
      .container-fluid.flex-grow-1.flex-column.d-flex
        .text-center(v-if = 'isPending'): i.fa.fa-spinner.fa-pulse.fa-lg
        router-view(v-else)
  //- footer-view
  session-timeout-modal
  fi-confirm-modal(
    v-if             = 'showCalendarWarning && !notificationShown'
    :title           = '$t("calendarTitle")'
    :message         = '$t("calendarNotSet")'
    :ok-callback     = 'openSettings'
    :hidden-callback = 'hideNotification'
  )
</template>

<script>
import FiConfirmModal from '@/components/FiConfirmModal'
import { isDepositEnabled } from '@/mixins'
import { mapActions, mapState } from 'vuex'

import HeaderView from './HeaderView'
import SidebarView from './SidebarView'
import FooterView from './FooterView'
import BreadcrumbView from './BreadcrumbView'
import SessionTimeoutModal from './SessionTimeoutModal'

export default {
  name: 'full-view',

  components: {
    FiConfirmModal,
    HeaderView,
    SidebarView,
    FooterView,
    BreadcrumbView,
    SessionTimeoutModal
  },

  mixins: [isDepositEnabled],

  data () {
    return {
      isPending: true,
      notificationShown: false
    }
  },

  computed: {
    ...mapState('settings', ['settings']),
    ...mapState('auth', ['permissions']),
    name () {
      return this.$route.name
    },

    list () {
      return this.$route.matched
    },
    showCalendarWarning () {
      const day = this.$moment({ month: 11, date: 1 })

      if (this.$moment().isBefore(day, 'day') || !this.settings || this.$route.name === 'Settings') return false

      const nextYear = this.$moment().add(1, 'years').year()
      const penaltyDates = this.settings?.noPenaltyDates.split(',').map(date => date.trim()) || []

      return !penaltyDates.some(date => this.$moment(date).year() === nextYear) && this.permissions.includes('SETTINGS_EDIT')
    }
  },

  async created () {
    await this.loadSettings()

    await Promise.all([
      this.loadClassifiers(),
      this.loadLoanClassifiers(),
      this.loadFactoringInvoiceClassifiers(),
      this.loadTemplates(),
      this.loadProductTypes(),
      this.loadTaxationClassifiers(),
      this.loadCreditIssuers(),
      this.loanLoanSystemData(),
      this.loadCustomerAssignedComments(),
      this.isDepositEnabled && this.loadDepositContractClassifiers(),
      this.isDepositEnabled && this.loadDepositProductClassifiers(),
      this.loadManagers()
    ])
    this.loadSystemNotifications()
    if (this.settings && this.settings.creditIssuerLogo) {
      this.loadLogo()
    }
    this.isPending = false
  },

  methods: {
    ...mapActions('classifiers', ['loadClassifiers', 'loadLoanClassifiers', 'loadFactoringInvoiceClassifiers', 'loadProductTypes', 'loadCreditIssuers', 'loadDepositProductClassifiers', 'loadDepositContractClassifiers', 'loadManagers', 'loadTaxationClassifiers']),
    ...mapActions('templates', ['loadTemplates']),
    ...mapActions('settings', ['loadSettings', 'loadLogo']),
    ...mapActions('notifications', ['loadSystemNotifications']),
    ...mapActions('loans', ['loanLoanSystemData']),
    ...mapActions('customers', ['loadCustomerAssignedComments']),
    openSettings () {
      this.$router.push({ name: 'Settings' })
      this.notificationShown = true
    },
    hideNotification () {
      this.notificationShown = true
    }
  }
}
</script>


<style lang="scss">
.app-body {
  overflow: visible;

  & main {
    display: flex;
    flex-direction: column;
  }
}
</style>


<i18n>
en:
  calendarTitle: "Calendar for holidays not set for the next year"
  calendarNotSet: "Do you want to set it now?"
et:
  calendarTitle: "Calendar for holidays not set for the next year"
  calendarNotSet: "Do you want to set it now?"
ru:
  calendarTitle: "Calendar for holidays not set for the next year"
  calendarNotSet: "Do you want to set it now?"
</i18n>
