import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import sortBy from 'lodash/sortBy'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

const MUTATION_TYPES = {
  setCustomerField: 'SET_CUSTOMER_FIELD',
  setCustomerFields: 'SET_CUSTOMER_FIELDS',
  addCustomerField: 'ADD_CUSTOMER_FIELD'
}

const state = {
  customerFields: [],
  customerField: null
}

const mutations = {
  [MUTATION_TYPES.setCustomerFields] (state, { customerFields }) {
    state.customerFields = sortBy(customerFields, 'fieldOrder')
  },
  [MUTATION_TYPES.addCustomerField] (state, { customerField }) {
    state.customerFields.push(customerField)
  },
  [MUTATION_TYPES.setCustomerField] (state, { customerField }) {
    state.customerField = customerField
  }
}

const actions = {
  async loadCustomerFields ({ commit, dispatch }) {
    const { data: customerFields } = await startLoading(dispatch, 'customer:fields:fetch', () => api.getCustomerFields())

    commit(MUTATION_TYPES.setCustomerFields, { customerFields })
  },
  async saveCustomerField ({ commit, dispatch }, { customerFieldData }) {
    const { data: customerField } = await startLoading(dispatch, 'customer:field:save', () => api.postCustomerField({ customerFieldData }))

    commit(MUTATION_TYPES.setCustomerField, { customerField })
  },
  async loadCustomerField ({ commit, dispatch }, { id }) {
    commit(MUTATION_TYPES.setCustomerField, { customerField: null })
    if (!id) return

    const { data: customerField } = await startLoading(dispatch, `customer:field:${id}:fetch`, () => api.getCustomerField({ id }))

    commit(MUTATION_TYPES.setCustomerField, { customerField })
  },
  async updateCustomerField ({ commit, dispatch }, { customerFieldData, id }) {
    const { data: customerField } = await startLoading(dispatch, `customer:field:${id}:save`, () => api.putCustomerField({ customerFieldData, id }))

    commit(MUTATION_TYPES.setCustomerField, { customerField })
  },
  async updateCustomerFields ({ commit, dispatch }, { customerFieldsData }) {
    const { data: customerFields } = await startLoading(dispatch, 'customer:fields:save', () => api.putCustomerFields({ customerFieldsData }))

    commit(MUTATION_TYPES.setCustomerFields, { customerFields })
  }
}

export default {
  state,
  mutations,
  actions
}
