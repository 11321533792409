import { validators } from '@/validation'

export default (notificationData = {}) => ({
  data: {
    templateId: notificationData.templateId,
    triggerDays: notificationData.triggerDays ?? 0,
    typeId: notificationData.typeId,
    triggerId: notificationData.triggerId,
    productIds: notificationData.productIds || [],
    feeType: notificationData.feeType?.code,
    actions: notificationData.actions || [],
    roleTypeIds: notificationData.roleTypeIds || [],
    segmentIds: notificationData.segmentIds || [],
    commentCategoryId: notificationData.commentCategoryId,
    active: Boolean(notificationData.active),
    penaltyGraceDays: notificationData.penaltyGraceDays,
    applicationClosingReasonIds: notificationData.applicationClosingReasonIds,
    attachableTemplateId: notificationData.attachableTemplateId
  },
  get validation () {
    return {
      templateId: {
        required: validators.required
      },
      triggerDays: {
        required: validators.required
      },
      triggerId: {
        required: validators.required
      },
      typeId: {
        required: validators.required
      },
      penaltyGraceDays: {
        required: validators.requiredIf(function () {
          return this.isInDebt
        })
      },
      applicationClosingReasonIds: {
        required: validators.requiredIf(function () {
          return this.isApplicationRejected
        })
      }
    }
  }
})
