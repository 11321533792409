import { connection, downloadFile } from './connection'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import qs from 'qs'
import { createBlobFromObject } from '@/helpers'

const controllerRootv1 = 'bo/loans'
export const controllerRootv2 = 'bo/v2/loans'

export default {
  getLoans ({ filters }) {
    return connection.get(controllerRootv2, {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },

  getDebts ({ filters }) {
    return connection.get(`${controllerRootv2}/debts`, {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },

  getLoanOverview ({ id }) {
    return connection.get(`${controllerRootv2}/${id}/overview`)
  },

  getLoanFull ({ id }) {
    return connection.get(`${controllerRootv2}/${id}`)
  },

  getLoanFinancial ({ id }) {
    return connection.get(`${controllerRootv2}/${id}/financial`)
  },

  getLoanIfrs ({ id }) {
    return connection.get(`${controllerRootv2}/${id}/ifrs`)
  },

  putLoan ({ id, loanData }) {
    return connection.put(`${controllerRootv2}/${id}`, omitBy(loanData, isNil))
  },

  patchLoan ({ id, loanData }) {
    return connection.patch(`${controllerRootv2}/${id}`, omitBy(loanData, isNil), {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },

  postLoan ({ loanData }) {
    return connection.post(`${controllerRootv2}`, loanData)
  },

  getLoanAssets ({ id }) {
    return connection.get(`${controllerRootv1}/${id}/assets`)
  },

  getLoanMortgages ({ id }) {
    return connection.get(`${controllerRootv1}/${id}/mortgages`)
  },

  postLoanAsset ({ id, assetData }) {
    return connection.post(`${controllerRootv1}/${id}/assets`, assetData)
  },

  postLoanMortgage ({ id, mortgageData }) {
    return connection.post(`${controllerRootv1}/${id}/mortgages`, mortgageData)
  },

  getLoanClassifiers () {
    return connection.get(`${controllerRootv2}/classifiers`)
  },

  putLoanStatus ({ id, statusId, periodStart }) {
    return connection.put(`${controllerRootv2}/${id}/status/${statusId}`, { periodStart })
  },

  postLoanSchedule ({ id, data }) {
    return connection.post(`${controllerRootv2}/${id}/schedules`, omitBy(data, isNil))
  },

  terminateLoan ({ id, data }) {
    return connection.post(`${controllerRootv2}/${id}/terminate`, data)
  },

  putPendingSchedule ({ id }) {
    return connection.put(`${controllerRootv2}/${id}/schedules`)
  },

  putLoanScheduleAccept ({ id, changeReasonComment, loanRelatedPayments }) {
    return connection.put(`${controllerRootv2}/${id}/schedules/accept`, {
      changeReasonComment,
      loanRelatedPayments
    })
  },

  deleteLoanSchedule ({ id }) {
    return connection.delete(`${controllerRootv2}/${id}/schedules`)
  },

  postLoanScheduleInterest ({ id, interestData }) {
    return connection.post(`${controllerRootv2}/${id}/interests`, interestData)
  },

  putLoanScheduleInterest ({ id, interestId, interestData }) {
    return connection.put(`${controllerRootv2}/${id}/interests/${interestId}`, interestData)
  },

  deleteLoanScheduleInterest ({ id, interestId }) {
    return connection.delete(`${controllerRootv2}/${id}/interests/${interestId}`)
  },

  getLoanNotifications () {
    return connection.get(`${controllerRootv2}/notifications`)
  },

  getLoanTemplate ({ templateId, loanId, languageId, scheduleId, invoiceDate }) {
    const queryString = qs.stringify(pickBy({
      loanId,
      languageId,
      scheduleId,
      invoiceDate
    }))
    return downloadFile(`${controllerRootv2}/preview-template/${templateId}?${queryString}`)
  },

  getLoanMessageTemplate ({ templateId, loanId, scheduleId }) {
    return connection.get(`${controllerRootv2}/template/${templateId}`, {
      params: pickBy({
        loanId,
        scheduleId
      })
    })
  },

  getLoanTransactions ({ id, filters }) {
    return connection.get(`${controllerRootv2}/${id}/transactions`, {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  putMessage ({ id, data }) {
    const formData = new FormData()
    const messageData = pick(data, ['text', 'subject', 'customerEmail', 'customerPhoneNumber'])
    formData.append('template', JSON.stringify([data.invoiceTemplateId].filter(Number)))
    formData.append('channel', data.channel)
    formData.append('message', createBlobFromObject(messageData))
    formData.append('languageId', data.languageId)
    formData.append('invoiceDate', data.invoiceDate)
    if (data.files.length) {
      formData.append('documentCategoryId', data.documentCategoryId)
    }
    formData.append('scheduleId', data.scheduleId)
    formData.append('recipients', JSON.stringify(data.recipients))
    Array.from(data.files).forEach(file => {
      formData.append('file', file)
    })
    return connection.put(`${controllerRootv2}/${id}/message`, formData)
  },
  putContract ({ id, data }) {
    const formData = new FormData()
    const messageData = pick(data, ['text', 'subject', 'customerEmail', 'customerPhoneNumber'])
    formData.append('template', JSON.stringify([data.infoSheetTemplateId, data.agreementTemplateId, data.scheduleTemplateId].filter(Number)))
    formData.append('channel', data.channel)
    formData.append('message', createBlobFromObject(messageData))
    formData.append('languageId', data.languageId)
    formData.append('invoiceDate', data.invoiceDate)
    if (data.files.length) {
      formData.append('documentCategoryId', data.documentCategoryId)
    }
    formData.append('scheduleId', data.scheduleId)
    formData.append('recipients', JSON.stringify(data.recipients))
    Array.from(data.files).forEach(file => {
      formData.append('file', file)
    })
    return connection.put(`${controllerRootv2}/${id}/message`, formData)
  },
  postLoanProvision ({ provisionData, loanId }) {
    return connection.post(`${controllerRootv2}/${loanId}/provision`, provisionData)
  },
  putLoanCorrection ({ correctionData, loanId }) {
    return connection.put(`${controllerRootv2}/${loanId}/correction`, correctionData)
  },
  patchLoanSchedulePrincipals ({ loanId, principalsData }) {
    return connection.patch(`${controllerRootv2}/${loanId}/schedules/invoices`, principalsData)
  },
  getLoanSystemData () {
    return connection.get(`${controllerRootv2}/system-data`)
  },
  postLoanPayback ({ id, data }) {
    return connection.post(`${controllerRootv2}/${id}/early-payback`, data)
  },
  putLoanStatusesBulk ({ loanIds, statusId }) {
    return connection.put(`${controllerRootv2}/status/${statusId}`, loanIds)
  },
  putLoanScheduleReason ({ loanId, changeReasonComment }) {
    return connection.put(`${controllerRootv2}/${loanId}/schedules/reason`, {
      changeReasonComment
    })
  },
  postLoanExtension ({ id, data }) {
    return connection.post(`${controllerRootv2}/${id}/extension`, data)
  },
  getLoanSchedules ({ loanId, filters }) {
    return connection.get(`${controllerRootv2}/${loanId}/schedules`, {
      params: { sort: 'validFrom,desc', ...pickBy(filters) },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  getLoanActiveSchedule ({ loanId }) {
    return connection.get(`${controllerRootv2}/${loanId}/schedules`, {
      params: { sort: 'validFrom,desc', size: '1' },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  getLoanScheduleById: ({ loanId, scheduleId }) => connection.get(`${controllerRootv2}/${loanId}/schedule/`, {
    params: {
      scheduleId
    }
  }),
  getLoanExtraInterests: ({ loanId }) => connection.get(`${controllerRootv2}/${loanId}/extra-interests`),
  getLoanImmovablesRegNumbers: ({ criteria }) => connection.get(`${controllerRootv1}/assets/immovable?criteria=${criteria}`)
}
