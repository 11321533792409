import { connection } from './connection'
import pickBy from 'lodash/pickBy'

const controllerRoot = 'bo/audit'

export default {
  getAuditHistory ({ filters }) {
    return connection.get(`${controllerRoot}/gdpr`, {
      params: pickBy(filters)
    })
  },
  getLoginHistory ({ filters }) {
    return connection.get(`${controllerRoot}/login`, {
      params: pickBy(filters)
    })
  }
}
