import { required } from 'vuelidate/lib/validators'

export default {
  name: {
    required
  },
  typeId: {
    required
  },
  defaultLanguageId: {
    required
  }
}
