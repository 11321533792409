import { toUpperCase, sortClassifiers } from '@/helpers'
import map from 'lodash/map'
import filter from 'lodash/filter'
import find from 'lodash/find'
import defaults from 'lodash/defaults'
import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import unionBy from 'lodash/unionBy'
import sortBy from 'lodash/sortBy'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

const ACTIONS_ICONS = {
  CALL: 'font-xl cil-phone',
  EMAIL: 'font-xl cil-envelope-closed',
  SMS: 'font-xl cil-speech',
  LETTER: 'font-xl cil-envelope-letter'
}

export default {
  state: {
    classifiers: {},
    editableClassifiers: {},
    managers: {},
    creditIssuers: {},
    handlers: [],
    productTypes: {}
  },

  getters: {
    optionsFromHandlers: state => sortClassifiers(state.handlers.map(({ paymentHandlerName }) => ({ value: paymentHandlerName, text: paymentHandlerName }))),
    classifierList: state => classifier => state.classifiers?.[toUpperCase(classifier)] || {},
    classifierById: state => (classifier, id) => state.classifiers?.[toUpperCase(classifier)]?.[id] || {},
    classifierByName: state => (classifier = '', nameQuery = '') => find(state.classifiers?.[toUpperCase(classifier)], ({ name }) => toUpperCase(name) === toUpperCase(nameQuery)),
    classifierByCode: state => (classifier = '', codeQuery = '') => find(state.classifiers?.[toUpperCase(classifier)], ({ code }) => toUpperCase(code) === toUpperCase(codeQuery)) || {},
    optionsFromClassifier: state => (classifier, byCode = false) => sortClassifiers(map(
      state.classifiers[toUpperCase(classifier)],
      ({ name, id, human, code, countryDto, rate }) => ({
        value: byCode ? code : id || countryDto,
        text: human || name || rate
      })
    )),
    managerById: state => id => state.managers[id] || {},
    optionsFromManagers: state => sortClassifiers(map(
      state.managers,
      ({ customerId, name }) => ({
        value: customerId,
        text: name
      })
    )),
    creditIssuerById: state => id => state.creditIssuers[id] || {},
    optionsFromCreditIssuers: state => sortClassifiers(map(
      state.creditIssuers,
      ({ customerId, name }) => ({
        value: customerId,
        text: name
      })
    )),
    optionsFromCountiesByCountry: (state, getters) => countryId => {
      const country = getters.classifierById('countries', countryId)
      return sortClassifiers(filter(getters.classifierList('counties'), ({ name }) => RegExp(country.name, 'gi').test(name))
        .map(({ id, human }) => ({
          value: id,
          text: human
        })))
    },
    commentActionIcon: () => actionId => ACTIONS_ICONS[actionId],
    productTypesByGroup: state => group => filter(state.productTypes, { group: toUpperCase(group) }),
    productTypeById: state => productType => find(state.productTypes, { id: Number.parseInt(productType) }),
    productTypeByCode: state => productType => find(state.productTypes, { code: productType })
  },
  actions: {
    async loadCountryClassifier ({ commit, dispatch }) {
      const { data: countries } = await startLoading(dispatch, 'classifiers:countries:fetch', () => api.getClassifiersCountry())

      commit('SET_CLASSIFIER', { name: 'COUNTRIES', classifiers: countries })
    },
    async loadClassifiers ({ commit, dispatch, rootState }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:fetch', () => api.getClassifiers({ lang: rootState.i18N.currentLocale }))

      Object.entries(classifiers).forEach(([name, value]) => commit('SET_CLASSIFIER', { name: toUpperCase(name), classifiers: value }))
    },
    async loadManagers ({ commit, dispatch }) {
      const { data: managers } = await startLoading(dispatch, 'classifiers:managers:fetch', () => api.getManagers())
      commit('SET_MANAGERS', { managers })
    },
    async loadCreditIssuers ({ commit, dispatch }) {
      const { data: creditIssuers } = await startLoading(dispatch, 'classifiers:creditIssuers:fetch', () => api.getCreditIssuers())

      commit('SET_CREDIT_ISSUERS', { creditIssuers })
    },
    async loadLoanClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:loans:fetch', () => api.getLoanClassifiers())
      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadProductTypes ({ commit, dispatch }) {
      const { data: productTypes } = await startLoading(dispatch, 'classifiers:productTypes:fetch', () => api.getProductTypes())
      commit('SET_PRODUCT_TYPES', { productTypes })
    },
    async createClassifier ({ commit, dispatch }, { type, human }) {
      const { data: classifiers } = await startLoading(
        dispatch,
        `classifiers:${type}:save`,
        () => api.postClassifier({ type: toUpperCase(type), human }))

      commit('SET_CLASSIFIER', { name: type, classifiers })
    },
    async updateClassifier ({ commit, dispatch }, { id, type, ...rest }) {
      const { data: classifiers } = await startLoading(
        dispatch,
        `classifiers:${type}:${id}:save`,
        () => api.putClassifier({ id, type, ...rest })
      )

      commit('SET_CLASSIFIER', { name: type, classifiers })
    },
    async loadDepositProductClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:deposit:fetch', () => api.getDepositClassifiers())

      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadTaxationClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:taxationTypes:fetch', () => api.getTaxationClassifiers())

      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadDepositContractClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:deposit:fetch', () => api.getDepositContractClassifiers())

      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadDepositTermsRevisionsClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:deposit:revisions:fetch', () => api.getDepositTermsRevisionsClassifiers())

      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadClassifiersEMTAK ({ commit, dispatch }, { query, id } = {}) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:emtak:fetch', () => api.getClassifiersEMTAK({ filter: query, id }))

      Object.entries(classifiers).forEach(([name, value]) => commit('SET_CLASSIFIER', { name: toUpperCase(name), classifiers: value }))
    },
    async loadClassifiersNACE ({ commit, dispatch }, { query, id } = {}) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:nace:fetch', () => api.getClassifiersNACE({ filter: query, id }))

      Object.entries(classifiers).forEach(([name, value]) => commit('SET_CLASSIFIER', { name: toUpperCase(name), classifiers: value }))
    },
    async loadTaxationCountries ({ commit, dispatch }, { taxType = 'WITHHOLD_TAX' } = {}) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:taxationCountries:fetch', () => api.getTaxationCountries({ taxType }))

      commit('SET_CLASSIFIER_TAX', { name: 'taxationCountries', classifiers })
    },
    async loadTaxationCountriesVat ({ commit, dispatch }, { taxType = 'VAT' } = {}) {
      const { data } = await startLoading(dispatch, 'classifiers:taxationCountries:fetch', () => api.getTaxationCountries({ taxType }))

      return data
    },
    async loadPaymentHandlers ({ commit, dispatch }) {
      const { data: handlers } = await startLoading(dispatch, 'classifiers:handlers:fetch', () => api.getHandlers())

      commit('SET_HANDLERS', { handlers })
    },
    async loadPaymentHandlersManual ({ commit, dispatch }) {
      const { data: handlers } = await startLoading(dispatch, 'classifiers:handlers:fetch', () => api.getHandlersManual())

      commit('SET_HANDLERS', { handlers })
    },
    async loadFactoringProductClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:factoring:product:fetch', () => api.getFactoringProductClassifiers())

      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadFactoringClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:factoring:contract:fetch', () => api.getFactoringContractClassifiers())

      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadFactoringInvoiceClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:factoring:invoices:fetch', () => api.getFactoringInvoiceClassifiers())

      commit('SET_LOAN_CLASSIFIERS', { classifiers })
    },
    async loadProductAttributeClassifier ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:attribute:type:fetch', () => api.getProductAttributeClassifier())
      commit('SET_CLASSIFIER', { name: 'attributeTypes', classifiers })
    },
    async loadTransactionMethods ({ commit, dispatch }, { group }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:transactionMethods:fetch', () => api.getTransactionMethodByGroup({ group }))

      commit('SET_CLASSIFIER', { name: 'transactionMethods', classifiers })
    },
    async loadEditableClassifiers ({ commit, dispatch }) {
      const { data: classifiers } = await startLoading(dispatch, 'classifiers:editable:fetch', () => api.getClassifiersEditable())

      commit('SET_EDITABLE_CLASSIFIERS', { classifiers })
    },
    async createEditableClassifier ({ commit, dispatch }, { classifierData }) {
      const { data: classifier } = await startLoading(dispatch, 'classifiers:editable:save', () => api.postClassifiersEditable({ classifierData }))

      commit('UPDATE_EDITABLE_CLASSIFIERS', { classifier, key: classifierData.classifierKey })
    },
    async updateEditableClassifier ({ commit, dispatch }, { id, ...classifierData }) {
      const { data: classifier } = await startLoading(dispatch, `classifiers:editable:${id}:save`, () => api.putClassifierEditable({ id, classifierData }))

      commit('UPDATE_EDITABLE_CLASSIFIERS', { classifier, key: classifierData.classifierKey })
    },
    async removeEditableClassifier ({ commit, dispatch }, { id, classifierKey }) {
      await startLoading(dispatch, `classifiers:editable:${id}:remove`, () => api.deleteClassifierEditable({ id }))

      commit('REMOVE_EDITABLE_CLASSIFIER', { id, key: classifierKey })
    }
  },

  mutations: {
    SET_CLASSIFIER (state, { name, classifiers }) {
      state.classifiers = {
        ...state.classifiers,
        [toUpperCase(name)]: classifiers.reduce((acc, current) => {
          return { ...acc, [current.id]: current }
        }, {})
      }
    },
    SET_CLASSIFIER_TAX (state, { name, classifiers }) {
      state.classifiers = {
        ...state.classifiers,
        [toUpperCase(name)]: classifiers
      }
    },
    SET_MANAGERS (state, { managers }) {
      state.managers = managers.reduce((acc, current) => {
        return { ...acc, [current.customerId]: current }
      }, {})
    },
    SET_CREDIT_ISSUERS (state, { creditIssuers }) {
      state.creditIssuers = creditIssuers.reduce((acc, current) => {
        return { ...acc, [current.customerId]: current }
      }, {})
    },
    SET_PRODUCT_TYPES (state, { productTypes }) {
      state.productTypes = productTypes.reduce((acc, current) => {
        return { ...acc, [current.code]: current }
      }, {})
    },
    SET_LOAN_CLASSIFIERS (state, { classifiers }) {
      state.classifiers = defaults(
        state.classifiers,
        classifiers.reduce((acc, current) => ({
          ...acc,
          [toUpperCase(current.name)]: Object.entries(current.values)
            .reduce(
              (res, [key, value]) => ({ ...res, [key]: { name: key, id: key, human: value, code: key } }),
              {}
            )
        }), {})
      )
    },
    SET_HANDLERS (state, { handlers }) {
      state.handlers = handlers
    },
    SET_EDITABLE_CLASSIFIERS (state, { classifiers }) {
      state.editableClassifiers = classifiers

      state.classifiers = {
        ...defaults(
          state.classifiers,
          Object.entries(classifiers)
            .reduce(
              (res, [key, values]) => ({
                ...res,
                [key]: values.reduce((res, value) => ({
                  ...res,
                  [value.classifierCode]: {
                    code: value.classifierCode,
                    human: value.classifierValue,
                    name: value.classifierCode,
                    id: value.id
                  }
                }), {})
              }),
              {}
            )
        )
      }
    },

    UPDATE_EDITABLE_CLASSIFIERS (state, { classifier, key }) {
      state.editableClassifiers = {
        ...state.editableClassifiers,
        [key]: sortBy(unionBy([classifier], state.editableClassifiers[key] ?? [], 'id'), 'id')
      }
    },

    REMOVE_EDITABLE_CLASSIFIER (state, { id, key }) {
      state.editableClassifiers[key] = state.editableClassifiers[key].filter((classifier) => classifier.id !== id)

      if (!state.editableClassifiers[key].length) {
        this._vm.$delete(state.editableClassifiers, key)
      }
    }
  }
}
