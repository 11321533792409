import { mapState } from 'vuex'

export const permissions = {
  computed: {
    ...mapState('auth', ['permissions']),
    isVisible () {
      return this.permissions.includes(this.$route.meta.visible)
    },
    isEditable () {
      return this.permissions.includes(this.$route.meta.editable)
    }
  }
}
