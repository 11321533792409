import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import orderBy from 'lodash/orderBy'
import unionBy from 'lodash/unionBy'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    dashboardApplicationsNew: [],
    dashboardApplicationsRecent: [],
    dashboardLoanNotifications: [],
    dashboardApplicationNotifications: [],
    dashboardMessagesNew: [],
    dashboardPaymentsPending: [],
    dashboardApplicationsNewCount: 0,
    dashboardPaymentsPendingCount: 0,
    dashboardCustomerTasks: [],
    dashboardCustomerTasksPages: 0,
    dashboardDepositsNotifications: [],
    dashboardDepositsNotificationsPages: 0
  },

  actions: {
    async loadDashboardApplicationsNew ({ commit, dispatch, rootGetters }) {
      commit('CLEAR_DASHBOARD_APPLICATIONS_NEW')
      const statusInsertedId = rootGetters['classifiers/classifierByName']('creditApplicationStatuses', 'inserted').id
      const response = await startLoading(dispatch, 'dashboard:applicationsNew:fetch', () => {
        return api.getApplications({ filters: { sort: 'createdAt,desc', size: 3, statusId: statusInsertedId } })
      })
      commit('SET_DASHBOARD_APPLICATIONS_NEW', { applications: response.data })
    },
    async loadDashboardApplicationsRecent ({ commit, dispatch }) {
      commit('CLEAR_DASHBOARD_APPLICATIONS_RECENT')
      const response = await startLoading(dispatch, 'dashboard:applicationsRecent:fetch', () => {
        return api.getApplications({ filters: { size: 10, sort: 'createdAt,desc' } })
      })
      commit('SET_DASHBOARD_APPLICATIONS_RECENT', { applications: response.data.content })
    },
    async loadDashboardPaymentsPending ({ commit, dispatch, rootGetters }) {
      commit('CLEAR_DASHBOARD_PAYMENTS_PENDING')
      const statusPendingId = rootGetters['classifiers/classifierByName']('incomingPaymentStatuses', 'pending').id
      const response = await startLoading(dispatch, 'dashboard:paymentsPending:fetch', () => {
        return api.getPayments({ filters: { sort: 'createdAt,desc', size: 3, statusId: statusPendingId } })
      })
      commit('SET_DASHBOARD_PAYMENTS_PENDING', { payments: response.data })
    },
    async setApplicationAssignee ({ commit, dispatch }, { id, customerId }) {
      const response = await startLoading(dispatch, `dashboard:applicationAssignee:${id}:save`, () => {
        return api.putApplicationAssignee({ id, customerId })
      })
      commit('SET_APPLICATION_ASSIGNEE', { application: response.data })
    },
    async loadDashboardLoanNotifications ({ commit, dispatch }) {
      commit('CLEAR_DASHBOARD_LOAN_NOTIFICATIONS')
      const response = await startLoading(dispatch, 'dashboard:loanNotifications:fetch', () => {
        return api.getLoanNotifications()
      })
      commit('SET_DASHBOARD_LOAN_NOTIFICATIONS', { notifications: response.data })
    },
    async loadDashboardApplicationNotifications ({ commit, dispatch }) {
      commit('CLEAR_DASHBOARD_APPLICATION_NOTIFICATIONS')
      const response = await startLoading(dispatch, 'dashboard:applicationNotifications:fetch', () => {
        return api.getApplicationNotifications()
      })
      commit('SET_DASHBOARD_APPLICATION_NOTIFICATIONS', { notifications: response.data })
    },
    async loadDashboardMessagesNew ({ commit, dispatch }) {
      commit('CLEAR_DASHBOARD_MESSAGES_NEW')
      const response = await startLoading(dispatch, 'dashboard:messagesNew:fetch', () => {
        return api.getMessagesNew()
      })
      commit('SET_DASHBOARD_MESSAGES_NEW', { messages: response.data })
    },
    async loadDashboardTasks ({ commit, dispatch }, { filters }) {
      const { data: { content: tasks, totalPages } } = await startLoading(dispatch, 'dashboard:tasks:fetch', () => api.getComments({ filters }))

      commit('SET_DASHBOARD_TASKS', { tasks, totalPages })
    },
    async updateComment ({ commit, dispatch }, { commentId, commentData }) {
      const { data: task } = await startLoading(dispatch, `dashboard:customer:task:${commentId}:save`, () => api.putComment({ id: commentId, data: commentData }))

      commit('UPDATE_DASHBOARD_TASK', { task })
    },
    async updateCommentAction ({ commit, dispatch }, { id, actionId, actionData }) {
      const { data: task } = await startLoading(dispatch, `dashboard:customer:task:${id}:save`, () => api.putCommentAction({ id, actionData, actionId }))

      commit('UPDATE_DASHBOARD_TASK', { task })
    },
    async removeDashboardCustomerTask ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `dashboard:customer:task:${id}:remove`, () => {
        return api.deleteComment({ id: id })
      })
      commit('REMOVE_DASHBOARD_TASK', { id })
    },
    async loadDashboardDepositNotifications ({ commit, dispatch }) {
      const { data: { content: notifications, totalPages } } = await startLoading(dispatch, 'dashboard:depositsNotifications:fetch', () => api.getDepositContractNotifications())

      commit('SET_DASHBOARD_DEPOSITS_NOTIFICATIONS', { notifications, totalPages })
    }
  },

  mutations: {
    CLEAR_DASHBOARD_APPLICATIONS_NEW (state) {
      state.dashboardApplicationsNew = []
      state.dashboardApplicationsNewCount = 0
    },
    CLEAR_DASHBOARD_APPLICATIONS_RECENT (state) {
      state.dashboardApplicationsRecent = []
    },
    CLEAR_DASHBOARD_PAYMENTS_PENDING (state) {
      state.dashboardPaymentsPending = []
      state.dashboardPaymentsPendingCount = 0
    },
    CLEAR_DASHBOARD_LOAN_NOTIFICATIONS (state) {
      state.dashboardLoanNotifications = []
    },
    CLEAR_DASHBOARD_APPLICATION_NOTIFICATIONS (state) {
      state.dashboardApplicationNotifications = []
    },
    CLEAR_DASHBOARD_MESSAGES_NEW (state) {
      state.dashboardMessagesNew = []
    },
    SET_DASHBOARD_APPLICATIONS_NEW (state, { applications }) {
      state.dashboardApplicationsNew = applications.content
      state.dashboardApplicationsNewCount = applications.totalElements
    },
    SET_DASHBOARD_APPLICATIONS_RECENT (state, { applications }) {
      state.dashboardApplicationsRecent = applications
    },
    SET_DASHBOARD_LOAN_NOTIFICATIONS (state, { notifications }) {
      state.dashboardLoanNotifications = notifications
    },
    SET_DASHBOARD_APPLICATION_NOTIFICATIONS (state, { notifications }) {
      state.dashboardApplicationNotifications = notifications
    },
    SET_DASHBOARD_MESSAGES_NEW (state, { messages }) {
      state.dashboardMessagesNew = orderBy(messages, 'createdAt', 'desc')
    },
    SET_DASHBOARD_PAYMENTS_PENDING (state, { payments }) {
      state.dashboardPaymentsPending = payments.content
      state.dashboardPaymentsPendingCount = payments.totalElements
    },
    SET_APPLICATION_ASSIGNEE (state, { application }) {
      state.dashboardApplicationsRecent = unionBy([application], state.dashboardApplicationsRecent, 'id')
    },
    SET_DASHBOARD_TASKS (state, { tasks, totalPages }) {
      state.dashboardCustomerTasks = tasks
      state.dashboardCustomerTasksPages = totalPages
    },
    UPDATE_DASHBOARD_TASK (state, { task }) {
      state.dashboardCustomerTasks = unionBy([task], state.dashboardCustomerTasks, 'id')
    },
    REMOVE_DASHBOARD_TASK (state, { id }) {
      state.dashboardCustomerTasks = state.dashboardCustomerTasks.filter(task => task.id !== id)
    },
    SET_DASHBOARD_DEPOSITS_NOTIFICATIONS (state, { notifications, totalPages }) {
      state.dashboardDepositsNotifications = notifications
      state.dashboardDepositsNotificationsPages = totalPages
    }
  }
}
