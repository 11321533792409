<template lang="pug">
li.dashboard-widget-applications-item
  .row
    .col
      router-link.text-body.d-block(:to = '{ name: "Application", params: { id: application.id } }')
        strong {{ customerName(customerFromApplication(application)) }}
        .small {{ product.name }}
    .col-auto.small {{ $t('common:formatDate', { value: application.createdAt }) }}
    .col-auto
      application-assign-dropdown.colored(:loader      = 'loader'
        :application = 'application'
        no-caret
        @assign      = 'onAssign')
  hr.mt-2.mb-2
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import ApplicationAssignDropdown from '@/views/applications/ApplicationAssignDropdown'

export default {
  name: 'dashboard-widget-applications-inserted-item',

  components: {
    ApplicationAssignDropdown
  },

  props: {
    application: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('applications', ['customerFromApplication']),
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('products', ['productById']),
    product () {
      return this.productById(this.application.productId)
    },
    loader () {
      return `dashboard:applicationAssignee:${this.application.id}:save`
    }
  },

  methods: {
    ...mapActions('dashboard', ['setApplicationAssignee', 'loadDashboardApplicationsNew']),
    async onAssign ({ id, customerId }) {
      await this.setApplicationAssignee({ id, customerId })
      this.loadDashboardApplicationsNew()
    }
  }
}
</script>


<style scoped lang="scss">
@import 'scss/variables';

.colored /deep/ button { color: $body-color; }
</style>
