<template lang="pug">
.debt-list.card.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    form.form-horizontal
      .row
        .form-group.col-lg-6
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-search
            input.form-control(
              v-model.trim = 'searchParams.criteria'
              :placeholder = '$t("searchPlaceholder")'
              type         = 'text'
            )
        .form-group.col-lg-6
          range-input-date(
            :options     = 'dateFilterOptions'
            :start.sync  = 'searchParams.dateMin'
            :end.sync    = 'searchParams.dateMax'
            :column.sync = 'searchParams.dateColumn'
          )
      .row
        .form-group.col-lg-6
          range-input-text(
            :options     = 'rangeInputTextOptions'
            :start.sync  = 'searchParams.amountMin'
            :end.sync    = 'searchParams.amountMax'
            :column.sync = 'searchParams.amountColumn'
          )
        .form-group.col-lg-3
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-tag
            fi-select(
              v-model.number = 'searchParams.productId'
              :options       = 'optionsFromProducts'
              :placeholder   = '$t("productsPlaceholder")'
            )
        .form-group.col-lg-3
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-flag
            fi-multiselect(
              v-model      = 'statusSearchModel'
              :options     = 'statusOptions'
              :placeholder = '$t("statusesPlaceholder")'
              multiple
            )
    fi-table(
      :fields      = 'fields'
      :items       = 'debts'
      loader       = 'debts:fetch'
      :empty-label = '$t("notFound")'
      :sort.sync   = 'searchParams.sort'
      responsive
    )
      template(slot = 'status') {{ $t('status') }}
        loan-status-dropdown(
          v-if      = 'false'
          variant   = 'p-0'
          :statuses = 'statuses'
          @submit   = 'changeStatus'
        )
      template(#default = '{ items }')
        debt-list-item.animated.fadeIn(
          v-for = 'debt in items'
          :key  = 'debt.id'
          :debt = 'debt'
        )

    fi-pagination(
      v-if    = 'debtsPages > 1'
      :pages  = 'debtsPages'
      v-model = 'pagination.page'
    )
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import LoanStatusDropdown from '@/views/loans/LoanStatusDropdown'
import DebtListItem from './DebtListItem'
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import FiTable from '@/components/FiTable'
import pick from 'lodash/pick'
import { pagination } from '@/const'
import FiMultiselect from '@/components/FiMultiselect'
import { getStatuesQuery } from '@/helpers'

const searchParams = {
  criteria: '',
  dateMin: '',
  dateMax: '',
  dateColumn: 'inDebtSince',
  amountColumn: 'amount',
  amountMin: null,
  amountMax: null,
  productId: '',
  statuses: [],
  sort: 'inDebtSince,asc'
}

export default {
  name: 'debt-list',

  components: {
    FiMultiselect,
    LoanStatusDropdown,
    FiTable,
    DebtListItem,
    FiPagination,
    FiSelect,
    RangeInputText,
    RangeInputDate
  },

  i18nOptions: {},

  data: (vm) => ({
    editStatus: false,
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)), statuses: getStatuesQuery(vm.$route.query.statuses) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('debts', ['debts', 'debtsPages']),
    ...mapGetters('products', ['optionsFromProducts']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapState('loans', ['loan', 'systemData']),
    statusOptions () {
      return this.optionsFromClassifier('LoanStatuses', true)
    },
    statusSearchModel: {
      get () {
        return this.searchParams.statuses.map(searchValue => this.statusOptions.find(({ value }) => value === searchValue))
      },
      set (values) {
        if (values.length === this.statusOptions.length) {
          this.searchParams.statuses = []
        } else {
          this.searchParams.statuses = values.map(({ value }) => value)
        }
      }
    },
    statuses () {
      return this.optionsFromClassifier('loanStatuses').map(({ value }) => ({ classifier: 'loanStatuses', id: value }))
    },
    rangeInputTextOptions () {
      return {
        amount: this.$t('amount'),
        balance: this.$t('balance'),
        debts: this.$t('outstanding'),
        debtDays: this.$t('debtSinceDays')
      }
    },
    dateFilterOptions () {
      return {
        inDebtSince: this.$t('debtSinceDates')
      }
    },
    searchFilter () {
      let filter = { ...this.searchParams }
      if (!(filter.amountMin || filter.amountMax)) {
        filter.amountColumn = null
      }
      if (!(filter.dateMin || filter.dateMax)) {
        filter.dateColumn = null
      }
      if (filter.amountColumn === 'debtDays') {
        filter = {
          ...filter,
          dateColumn: 'inDebtSince',
          dateMin: filter.amountMax && this.$moment(this.systemData.nextDayChange).subtract(filter.amountMax, 'days').format('YYYY-MM-DD'),
          dateMax: filter.amountMin && this.$moment(this.systemData.nextDayChange).subtract(filter.amountMin, 'days').format('YYYY-MM-DD'),
          amountMin: '',
          amountMax: '',
          amountColumn: ''
        }
      }
      return filter
    },
    fields () {
      return [
        {
          key: 'customer',
          label: this.$t('customer'),
          sort: 'customerFullName'
        },
        {
          key: 'contract',
          label: this.$t('contract'),
          sort: 'agreementNumber'
        },
        {
          key: 'product',
          label: this.$t('product')
        },
        {
          key: 'balance',
          label: this.$t('balance'),
          class: 'money'
        },
        {
          key: 'debt',
          label: this.$t('debt'),
          class: 'money',
          sort: 'debts'
        },
        {
          key: 'interest',
          label: this.$t('interest'),
          class: 'money'
        },
        {
          key: 'outstanding',
          label: this.$t('outstanding'),
          class: 'money'
        },
        {
          key: 'inDebtSince',
          label: this.$t('debtSince'),
          sort: 'inDebtSince'
        },
        {
          key: 'nextPaymentDate',
          label: this.$t('nextPaymentDate')
        },
        {
          key: 'status',
          label: this.$t('status'),
          sort: 'statusId'
        },
        {
          key: 'activeActionsCount',
          label: this.$t('tasks')
        }
      ]
    },
    searchQuery () {
      return pickBy({ ...this.searchFilter, ...this.pagination })
    }
  },

  watch: {
    '$route.query': {
      handler (query) {
        this.loadDebts({ filters: query })
      },
      deep: true
    },
    searchFilter: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page': 'search'
  },

  created () {
    this.loadProducts({ group: ['LOAN', 'OVERDRAFT', 'LEASING', 'GUARANTEE', 'FACTORING'] })
    this.loadDebts({ filters: this.searchQuery })
  },

  methods: {
    ...mapActions('debts', ['loadDebts', 'changeDebtsStatus']),
    ...mapActions('products', ['loadProducts']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 250
    ),
    changeStatus ({ id: statusId }) {
      this.changeDebtsStatus({ statusId, loanIds: this.debts.map(({ loanId }) => loanId) })
    }
  }
}
</script>


<i18n>
en:
  title:                "Loans in debt"
  searchPlaceholder:    "Search by name, id code, agreement reference"
  debtSinceDates:       "Debt since (dates)"
  debtSinceDays:        "Debt since (days)"
  debtSince:            "Debt since"
  nextPaymentDate:      "Next payment date"
  debt:                 "Loan debt"
  outstanding:          "Outstanding"
  amount:               "Amount"
  balance:              "Balance"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All loan statuses"
  contract:             "Contract"
  customer:             "Customer"
  product:              "Product"
  interest:             "Interest accrued"
  status:               "Status"
  tasks:                "Active tasks"
  notFound:             "Debts not found"
et:
  title:                "Loans in debt"
  searchPlaceholder:    "Search by name, id code, agreement reference"
  debtSinceDates:       "Debt since (dates)"
  debtSinceDays:        "Debt since (days)"
  debtSince:            "Debt since"
  nextPaymentDate:      "Next payment date"
  debt:                 "Loan debt"
  outstanding:          "Outstanding"
  amount:               "Amount"
  balance:              "Balance"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All loan statuses"
  contract:             "Contract"
  customer:             "Customer"
  product:              "Product"
  interest:             "Interest accrued"
  status:               "Status"
  tasks:                "Active tasks"
  notFound:             "Debts not found"
ru:
  title:                "Loans in debt"
  searchPlaceholder:    "Search by name, id code, agreement reference"
  debtSinceDates:       "Debt since (dates)"
  debtSinceDays:        "Debt since (days)"
  debtSince:            "Debt since"
  nextPaymentDate:      "Next payment date"
  debt:                 "Loan debt"
  outstanding:          "Outstanding"
  amount:               "Amount"
  balance:              "Balance"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All loan statuses"
  contract:             "Contract"
  customer:             "Customer"
  product:              "Product"
  interest:             "Interest accrued"
  status:               "Status"
  tasks:                "Active tasks"
  notFound:             "Debts not found"
</i18n>


<style lang='scss' scoped>
  .debt-list /deep/ .loan-status-dropdown .dropdown-menu {
    max-height: 350px;
    overflow-y: scroll;
  }
</style>
