export * from './overdraft'
export * from './feeUtils'
export * from './deposits'
export * from './permissions'
export * from './loanLeasing'
export * from './applicationLeasing'
export * from './factoring'
export * from './scoring'

export const excludeLastMonthDays = {
  computed: {
    lastMonthDays: () => ({
      days: -1
    })
  }
}

export const formatRangeError = {
  methods: {
    formatRangeError (string, value, isPercentage) {
      const { money, percent } = this.$options.filters
      return this.$t(string, { value: isPercentage ? percent(value) : money(value) })
    }
  }
}

export const codeWithHuman = {
  methods: {
    codeWithHuman ({ code, human }) {
      if (!(code && human)) {
        return
      }
      return `${code} - ${human}`
    }
  }
}
