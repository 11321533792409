import { connection } from '@/api/connection'
import { pathBuilder } from '@/helpers'

const controllerPathV1 = ({
  type,
  id
} = {}) => pathBuilder`bo/classifiers${type}${id}`

const controllerPathV2 = ({
  id
} = {}) => pathBuilder`bo/v2/classifier${id}`


export default {
  postClassifier ({ type, human }) {
    return connection.post(controllerPathV1({ type }), { human })
  },
  putClassifier ({ type, id, ...rest }) {
    return connection.put(controllerPathV1({ type, id }), { type, ...rest })
  },
  getClassifiers ({ lang }) {
    return connection.get(controllerPathV1(), { params: { lang: lang } })
  },
  getClassifiersEMTAK: ({ filter, id }) => connection.get(controllerPathV1({ type: 'EMTAK', id }), {
    params: {
      filter
    }
  }),
  getClassifiersNACE: ({ filter, id }) => connection.get(controllerPathV1({ type: 'NACE', id }), {
    params: {
      filter
    }
  }),
  getClassifiersCountry: () => connection.get(controllerPathV1({ type: 'country' })),
  getProductAttributeClassifier: () => connection.get(controllerPathV1({ type: 'attribute', id: 'type' })),

  getClassifiersEditable: () => connection.get(controllerPathV2()),
  postClassifiersEditable: ({ classifierData }) => connection.post(controllerPathV2(), classifierData),
  putClassifierEditable: ({ id, classifierData }) => connection.put(controllerPathV2({ id }), classifierData),
  deleteClassifierEditable: ({ id }) => connection.delete(controllerPathV2({ id }))
}
