<template lang="pug">
codemirror.border(v-model      = 'text'
                  :placeholder = 'placeholder'
                  :options     = 'options'
                  disabled)
</template>


<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/edit/closetag.js'
import 'codemirror/addon/display/placeholder.js'

export default {
  name: 'fi-codemirror',

  components: { codemirror },

  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      options: {
        mode: 'text/html',
        tabSize: 2,
        styleActiveLine: true,
        lineNumbers: true,
        lineWrapping: true,
        line: true,
        autoCloseTags: true
      }
    }
  },

  computed: {
    text: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>


<style scoped lang="scss">
@import '~codemirror/lib/codemirror.css';
</style>
