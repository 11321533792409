<template lang="pug">
masked-input.form-control(v-model.string = 'computedValue'
             :mask          = 'maskOptions'
             type           = 'text'
             show-mask
             guide)
</template>

<script>
import MaskedInput from 'vue-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import numeral from 'numeral'
import isNil from 'lodash/isNil'

export default {
  name: 'fi-money-input',

  components: { MaskedInput },

  props: {
    value: {
      type: [Number, String],
      default: null
    },
    placeholder: {
      type: String,
      default: function () {
        return `0.00 ${this.$store.state.settings.settings.currencySymbol}`
      }
    },
    decimalLimit: {
      type: Number,
      default: 2
    }
  },

  data () {
    numeral.localeData().currency.symbol = this.$store.state.settings.settings.currencySymbol
    numeral.localeData().delimiters.thousands = ' '
    return {
      maskOptions: createNumberMask({
        prefix: '',
        suffix: ` ${this.$store.state.settings.settings.currencySymbol}`,
        allowDecimal: true,
        decimalLimit: this.decimalLimit,
        thousandsSeparatorSymbol: ' '
      })
    }
  },

  computed: {
    computedValue: {
      get () {
        if (isNil(this.value)) return
        return numeral(this.value).format(`0,0[.][${Array(this.decimalLimit).fill(0).join('')}] $`)
      },
      set (value) {
        this.$emit('input', numeral(value).value())
      }
    }
  }
}
</script>

