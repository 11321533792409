<template lang="pug">
fi-table.payment-list(:fields      = 'fields'
                      :items       = 'payments'
                      :empty-label = '$t("notFound")'
                      responsive)
  template(v-slot:selectAll
           v-if = 'isSelectable')
    b-check(:checked       = 'allSelected'
            :indeterminate = 'indeterminate'
            @change        = 'toggleSelectAll')
  template(v-slot:custom-row)
    tr.animated.fadeIn(v-if = 'selectedItems.length')
      td(:colspan = 'fields.length')
        .btn-toolbar
          .btn-group
            button.btn.btn-outline-primary(:title = '$t("confirm")') {{ $t("confirm") }}
            button.btn.btn-outline-primary(:title = '$t("cancel")') {{ $t("cancel") }}
  template(v-slot='{ items }')
    payment-list-item(v-for         = 'payment in items'
                      v-model       = 'selectedItems'
                      :key          = 'payment.id'
                      :payment      = 'payment'
                      :isSelectable = 'isSelectable')
</template>


<script>
import FiTable from '@/components/FiTable'
import PaymentListItem from './PaymentIncomingListItem'

export default {
  name: 'payment-list',

  components: {
    FiTable,
    PaymentListItem
  },

  props: {
    payments: {
      type: Array,
      required: true
    },
    loader: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    selectedItems: []
  }),

  computed: {
    fields () {
      return [
        ...(this.isSelectable ? [{ key: 'selectAll' }] : []),
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'paymentDate',
          label: this.$t('paymentDate')
        },
        {
          key: 'payerName',
          label: this.$t('payerName')
        },
        {
          key: 'account',
          label: this.$t('account')
        },
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'reference',
          label: this.$t('reference')
        },
        {
          key: 'customer',
          label: this.$t('customer')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    },
    allSelected () {
      return this.selectedItems?.length && this.selectedItems?.length === this.itemIds?.length
    },
    indeterminate () {
      const { length } = this.selectedItems
      return Boolean(length) && length < this.itemIds?.length
    },
    itemIds () {
      return this.payments.map(({ id }) => id) || []
    },
    isSelectable () {
      return /outgoing/i.test(this.$route.params.type)
    }
  },
  methods: {
    toggleSelectAll (state) {
      if (state) {
        this.selectedItems = this.itemIds
      } else {
        this.selectedItems = []
      }
    }
  }
}
</script>


<i18n>
en:
  paymentDate: "Payment Date"
  payerName:   "Payer Name"
  account:     "Account"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  customer:    "Customer"
  status:      "Status"
  notFound:    "Payments not found"
et:
  paymentDate: "Payment Date"
  payerName:   "Payer Name"
  account:     "Account"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  customer:    "Customer"
  status:      "Status"
  notFound:    "Payments not found"
ru:
  paymentDate: "Payment Date"
  payerName:   "Payer Name"
  account:     "Account"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  customer:    "Customer"
  status:      "Status"
  notFound:    "Payments not found"
</i18n>
