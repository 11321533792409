export default [
  {
    path: 'customers',
    name: 'Customers',
    component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerList'),
    meta: { label: 'menu:customers' }
  },
  {
    path: 'customers',
    component: { render (c) { return c('router-view') } },
    meta: { label: 'menu:customers' },
    children: [
      {
        path: 'new',
        name: 'CustomerNew',
        component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerNew'),
        meta: { label: 'menu:customerNew' }
      },
      {
        path: ':id',
        name: 'Customer',
        component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerView'),
        meta: { label: 'menu:customer' },
        props: true,
        redirect: { name: 'CustomerOverview' },
        children: [
          {
            path: 'overview',
            name: 'CustomerOverview',
            component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerOverview'),
            meta: { skip: true }
          },
          {
            path: 'general',
            name: 'CustomerGeneral',
            component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerGeneral'),
            meta: { skip: true }
          },
          {
            path: 'roles',
            name: 'CustomerRoles',
            component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerRoles'),
            meta: { skip: true }
          },
          {
            path: 'communication',
            name: 'CustomerCommunication',
            component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerCommunicationView'),
            meta: { skip: true }
          },
          {
            path: 'loans',
            name: 'CustomerLoans',
            component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerContracts.vue'),
            meta: { skip: true }
          },
          {
            path: 'payments',
            name: 'CustomerPaymentInfo',
            component: () => import(/* webpackChunkName: "Customers" */'@/views/customers/CustomerPaymentInfo.vue'),
            props: true,
            meta: { skip: true }
          },
          {
            path: 'reports',
            name: 'CustomerReports',
            component: () => import(/* webpackChunkName: "Customers" */ '@/views/customers/CustomerReports'),
            meta: { skip: true }
          }
        ]
      }
    ]
  }
]
