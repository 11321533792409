import { mapState } from 'vuex'

export const isApplicationLeasing = {
  computed: {
    ...mapState('applications', ['applicationProduct']),
    isLeasingGroup () {
      return /leasing/i.test(this.applicationProduct?.group)
    }
  }
}
