import api from '@/api'
import { toUpperCase, sortClassifiers } from '@/helpers'
import { createActionHelpers } from 'vuex-loading'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    allTemplates: [],
    allInvoices: [],
    templatesList: [],
    templatesPages: null,
    current: null
  },

  getters: {
    optionsFromTemplates: (state) => codes => sortClassifiers(state.allTemplates
      .filter(({ active, templateType }) => codes.some(code => toUpperCase(code) === toUpperCase(templateType.code)) && active)
      .map(({ id, templateName, active }) => ({ value: id, text: templateName, active }))),
    getTemplateById: state => id => {
      return state.allTemplates.find(template => template.id === id)
    },
    getAttachableTemplates: state => state.allTemplates
      .filter(({ active, templateType }) => templateType.attachable && active)
  },

  actions: {
    async loadTemplatesList ({ commit, dispatch }, { query } = {}) {
      commit('CLEAR_TEMPLATES')
      try {
        const { data: { content: templates, totalPages } } = await startLoading(dispatch, 'templates:fetch', () => {
          return api.getTemplatesList({ query })
        })
        commit('SET_TEMPLATES_LIST', { templates, totalPages })
      } catch (err) {
      }
    },
    async loadTemplates ({ commit, dispatch }) {
      try {
        const { data: templates } = await startLoading(dispatch, 'templates:fetch', () => {
          return api.getTemplates()
        })
        commit('SET_TEMPLATES', { templates })
      } catch (err) {
      }
    },
    async loadInvoices ({ commit, dispatch }, { scheduleId }) {
      try {
        const { data: invoices } = await startLoading(dispatch, 'templates:fetch', () => {
          return api.getInvoices({ scheduleId })
        })
        commit('SET_INVOICES', { invoices })
      } catch (err) {
      }
    },
    async loadTemplate ({ commit, dispatch }, { id }) {
      commit('CLEAR_CURRENT')
      const response = await startLoading(dispatch, 'template:fetch', () => {
        return api.getTemplate({ id })
      })

      commit('SET_CURRENT', { template: response.data })
    },
    async updateTemplate ({ commit, dispatch }, { id, templateData }) {
      const response = await startLoading(dispatch, 'template:save', () => {
        return api.putTemplate({ id, templateData })
      })

      commit('SET_CURRENT', { template: response.data })
    },
    async createTemplate ({ commit, dispatch }, { templateData }) {
      const response = await startLoading(dispatch, 'template:save', () => {
        return api.postTemplate({ templateData })
      })

      commit('SET_CURRENT', { template: response.data })
    },
    async loadTemplatePreview ({ commit, dispatch }, { id, applicationId, languageId }) {
      await startLoading(dispatch, `template:${id}:preview:fetch`, () => {
        return api.getTemplatePreview({ id, applicationId, languageId })
      })
    }
  },

  mutations: {
    CLEAR_TEMPLATES (state) {
      state.templatesList = []
      state.templatesPages = 0
    },
    SET_TEMPLATES_LIST (state, { templates, totalPages }) {
      state.templatesList = templates
      state.templatesPages = totalPages
    },
    SET_TEMPLATES (state, { templates }) {
      state.allTemplates = templates
    },
    SET_INVOICES (state, { invoices }) {
      state.allInvoices = invoices
    },
    CLEAR_CURRENT (state) {
      state.current = null
    },
    SET_CURRENT (state, { template }) {
      state.current = template
    }
  }
}
