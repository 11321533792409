<template lang="pug">
li.dashboard-widget-payments-pending-item
  router-link.text-body.d-block(:to = '{ name: "incomingPayment", params: { id: payment.id } }')
    strong {{ payment.fromName }}
    .small {{ payment.description }}
  hr.mt-2.mb-2
</template>


<script>
export default {
  name: 'dashboard-widget-payments-pending-item',

  props: {
    payment: {
      type: Object,
      required: true
    }
  }
}
</script>
