import { connection } from './connection'

export default {
  deleteRole ({ id }) {
    return connection.delete(`bo/roles/${id}`)
  },

  putRole ({ id, roleData }) {
    return connection.put(`bo/roles/${id}`, roleData)
  },
  getRolesInfo () {
    return connection.get('bo/roles/info')
  }
}
