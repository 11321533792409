import { connection } from './connection'
import pickBy from 'lodash/pickBy'
import isNil from 'lodash/isNil'

export default {
  postComment (data) {
    return connection.post('bo/comments', data)
  },
  putComment ({ id, data }) {
    return connection.put(`bo/comments/${id}`, data)
  },

  deleteComment ({ id }) {
    return connection.delete(`bo/comments/${id}`)
  },
  putCommentAction ({ id, actionId, actionData }) {
    return connection.put(`bo/comments/${id}/actions/${actionId}`, actionData)
  },
  getComments ({ filters }) {
    return connection.get('bo/comments/dashboard', {
      params: pickBy(filters, filter => !isNil(filter))
    })
  },
  getUserAssignedComments () {
    return connection.get('bo/comments/assigned-to-me')
  }
}
