import pickBy from 'lodash/pickBy'
import omit from 'lodash/omit'
import { connection } from './connection'
import qs from 'qs'
import isNil from 'lodash/isNil'
import { createBlobFromObject } from '@/helpers'

const CONTROLLER_ROOT = 'bo/customers'

export default {
  getCustomers ({ filters }) {
    return connection.get(CONTROLLER_ROOT, {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },

  getCustomer ({ id }) {
    return connection.get(`${CONTROLLER_ROOT}/${id}`)
  },

  getCustomerByIdCode ({ idCode }) {
    return connection.get(`${CONTROLLER_ROOT}/by/id-code/${idCode}`)
  },

  putCustomer ({ id, data }) {
    return connection.put(`${CONTROLLER_ROOT}/${id}`, data)
  },

  postCustomer ({ customerData }) {
    return connection.post(CONTROLLER_ROOT, customerData)
  },

  putCustomerPrivate ({ customerId, data }) {
    return connection.put(`${CONTROLLER_ROOT}/${customerId}/private`, data)
  },

  putCustomerCorporate ({ customerId, data }) {
    return connection.put(`${CONTROLLER_ROOT}/${customerId}/corporate`, data)
  },

  getCustomerApplications ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/applications`)
  },

  getCustomerHistory ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/history`)
  },

  postCustomerRole ({ customerId, data }) {
    return connection.post(`${CONTROLLER_ROOT}/${customerId}/roles`, data)
  },

  getCustomerRole ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/roles`)
  },

  getCustomerRelations ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/relations`)
  },

  postCustomerRelation ({ customerId, data }) {
    return connection.post(`${CONTROLLER_ROOT}/${customerId}/relations`, data)
  },

  getCustomerAddress ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/addresses`)
  },

  postCustomerAddress ({ customerId, data }) {
    return connection.post(`${CONTROLLER_ROOT}/${customerId}/addresses`, data)
  },

  getCustomerMessages ({ customerId, filters }) {
    let { filters: searchFilters, ...pagination } = filters
    searchFilters = Object.entries(pickBy(searchFilters)).map(entry => entry.join(','))
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/messages`, {
      params: {
        filters: searchFilters,
        ...pagination
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },

  postCustomerMessage ({ customerId, data }) {
    const formData = new FormData()
    const dataWithoutFiles = omit(data, 'files')
    formData.append('message', createBlobFromObject(dataWithoutFiles))
    Array.from(data.files).forEach(file => {
      formData.append('file', file)
    })
    return connection.post(`${CONTROLLER_ROOT}/${customerId}/messages`, formData)
  },

  getCustomerResources ({ customerId, filters }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/resources`, {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },

  postCustomerResource ({ customerId, data }) {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('documentCategoryId', data.documentCategoryId)
    formData.append('resource', createBlobFromObject({
      comment: data.comment,
      visibleForPortal: data.visibleForPortal
    }))
    return connection.post(`${CONTROLLER_ROOT}/${customerId}/resources`, formData)
  },

  getCustomerPayments ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/payments`)
  },

  getCustomerOutgoingPayments ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/outgoing`)
  },

  getCustomerAccounts ({ customerId, productGroup }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/accounts`, {
      params: {
        productGroup
      }
    })
  },

  postCustomerAccount ({ customerId, accountData }) {
    return connection.post(`${CONTROLLER_ROOT}/${customerId}/accounts`, accountData)
  },

  getCustomerDebts ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/debt-history`)
  },

  postCustomerExtraIncome ({ customerId, accountId, data }) {
    return connection.post(`${CONTROLLER_ROOT}/${customerId}/extra_income/${accountId}`, data)
  },

  getCustomerRelatedLoans ({ customerId }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/relatedloans`)
  },

  async getCustomerComments ({ customerId, filters }) {
    return connection.get(`${CONTROLLER_ROOT}/${customerId}/related-comments`, {
      params: pickBy(filters, filter => !isNil(filter)),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },

  deleteCustomer ({ customerId }) {
    return connection.delete(`${CONTROLLER_ROOT}/${customerId}`)
  },

  deleteCustomerBankAccount: ({ customerId, accountId }) =>
    connection.put(`${CONTROLLER_ROOT}/${customerId}/external-bank-account/${accountId}`),

  getCustomerLoans: ({ customerId }) => connection.get(`${CONTROLLER_ROOT}/${customerId}/loans`),

  getCustomerFactoringContracts: ({ customerId }) =>
    connection.get(`${CONTROLLER_ROOT}/search/${customerId}/factoring`),

  getCustomerThirdPartyAgreements: ({ customerId }) =>
    connection.get(`${CONTROLLER_ROOT}/search/${customerId}/third-party`),

  getCustomerAnnualReports: ({ customerId }) =>
    connection.get(`bo/annualreports/${customerId}`),

  postCustomerAnnualReport ({ customerId, data }) {
    return connection.post(`bo/annualreports/customer/${customerId}`, data)
  },
  putCustomerAnnualReport ({ reportId, data }) {
    return connection.put(`bo/annualreports/${reportId}`, data)
  },

  getCustomerFinancialData: ({ customerId, applicationId }) =>
    connection.get(`${CONTROLLER_ROOT}/${customerId}/financial-data`, {
      params: {
        applicationId
      }
    }),

  putCustomerFinancialData: ({ customerId, financialData, financialDataId }) => {
    return connection.put(`${CONTROLLER_ROOT}/${customerId}/financial-data/${financialDataId}`, financialData)
  },

  postCustomerFinancialData: ({ customerId, financialData }) =>
    connection.post(`${CONTROLLER_ROOT}/${customerId}/financial-data`, financialData),

  deleteCustomerFinancialData: ({ customerId, applicationId }) =>
    connection.delete(`${CONTROLLER_ROOT}/${customerId}/financial-data/${applicationId}`),
  getCustomerFieldsById: ({ customerId }) => connection.get(`${CONTROLLER_ROOT}/${customerId}/custom-fields/values`),
  postCustomerFieldsById: ({ customerId, customerFieldData }) => connection.post(`${CONTROLLER_ROOT}/${customerId}/custom-fields/values`, customerFieldData),
  getCustomerPrepaymentAccounts: ({ customerId }) => connection.get(`${CONTROLLER_ROOT}/${customerId}/prepayment-accounts`)
}
