<template lang="pug">
label.switch.switch-label.switch-pill.form-check-label(
  ref = 'switch'
  :class = 'switchClass'
)
  input.switch-input.form-check-input(
    :checked  = 'value'
    ref       = 'input'
    :disabled = 'disabled || readOnly'
    type      = 'checkbox'
    @change   = '$emit("input", $event.target.checked)'
  )
  span.switch-slider(
    ref = 'label'
    :data-checked  = 'onText || "on"'
    :data-unchecked = 'offText || "off"'
  )
</template>


<script>
const px = x => `${x}px`

export default {
  name: 'fi-switch',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onText: {
      type: String,
      default: 'on'
    },
    offText: {
      type: String,
      default: 'off'
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    switchClass () {
      return [this.disabled ? 'switch-secondary' : 'switch-primary']
    },
    maxWidth () {
      const width = Number.parseFloat(this.$refs.label && window.getComputedStyle(this.$refs.label, '::after').width)
      return Number.isNaN(width) ? 0 : width
    }
  },

  watch: {
    onText () {
      this.$nextTick(() => {
        this.$refs.switch && this.$refs.switch.style.setProperty('--switch-width', this.maxWidth ? px(this.maxWidth) : '')
      })
    }
  },

  mounted () {
    this.$refs.input.checked = false
    this.$nextTick(() => {
      this.$refs.switch && this.$refs.switch.style.setProperty('--switch-width', this.maxWidth ? px(this.maxWidth) : '')
      if (this.$refs.input) this.$refs.input.checked = this.value
    })
  }
}
</script>


<style lang="scss" scoped>
@import "../../scss/variables";

.switch {
  --switch-width: 60px;
  --switch-height: #{$switch-height};
  width: calc(var(--switch-width) + 2 * #{$switch-handle-margin} + var(--switch-height));
  &.switch-label {
    .switch-slider {
      transition: transform .15s ease-out;
    }
    .switch-input:checked ~ .switch-slider{
      &::before {
        transform: translateX(calc(var(--switch-width) + 2 * #{$switch-handle-margin}))
      }
      &::after {
        left: 2 * $switch-handle-margin;
      }
    }
    .switch-slider {
      transition: opacity, background .15s ease-out;
      &::after {
        right: 2 * $switch-handle-margin;
        white-space: nowrap;
        width: fit-content;
      }
    }
  }
}
</style>
