<template lang="pug">
li.dashboard-widget-messages-new-item
  router-link.text-body.d-block(:to = '{ name: "CustomerCommunication", params: { id: message.customerId } }')
    .row
      .col
        strong {{ message.customerName }}
        .small {{ message.subject }}
      .col-auto.small {{ $t('common:formatDate', { value: message.createdAt }) }}
  hr.mt-2.mb-2
</template>


<script>
export default {
  name: 'dashboard-widget-messages-new-item',

  props: {
    message: {
      type: Object,
      required: true
    }
  }
}
</script>

