import { connection } from './connection'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

const controllerRoot = 'bo/notifications/automatic'

const checkIfEmpty = (value) => isNil(value) || value === ''

export default {
  getAutomaticNotifications () {
    return connection.get(`${controllerRoot}`)
  },
  getAutomaticNotification ({ notificationId }) {
    return connection.get(`${controllerRoot}/${notificationId}`)
  },
  postAutomaticNotification ({ notificationData }) {
    return connection.post(`${controllerRoot}`, omitBy(notificationData, checkIfEmpty))
  },
  putAutomaticNotification ({ notificationId, notificationData }) {
    return connection.put(`${controllerRoot}/${notificationId}`, omitBy(notificationData, checkIfEmpty))
  },
  deleteAutomaticNotification ({ notificationId }) {
    return connection.delete(`${controllerRoot}/${notificationId}`)
  }
}
