import api from '@/api'
import { createActionHelpers } from 'vuex-loading'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

const state = {
  taxationList: [],
  taxationListPages: 0,
  taxRate: null,
  systemTransactions: [],
  systemTransactionsPages: 0
}

const mutations = {
  SET_TAXATION_LIST (state, { taxationList, totalPages }) {
    state.taxationList = taxationList
    state.taxationListPages = totalPages
  },
  SET_TAX_RATE (state, { taxRate }) {
    state.taxRate = taxRate
  },
  SET_TAXATION_SYSTEM_TRANSACTIONS (state, { transactions, totalPages }) {
    state.systemTransactions = transactions
    state.systemTransactionsPages = totalPages
  }
}

const actions = {
  async loadTaxationList ({ commit, dispatch }, { filters } = {}) {
    const { data: { content: taxationList, totalPages } } = await startLoading(dispatch, 'taxation:list:fetch', () => api.getDepositTaxationList({ filters }))
    commit('SET_TAXATION_LIST', { taxationList, totalPages })
  },
  async loadTaxRate ({ commit, dispatch }, { taxRateId }) {
    const { data: taxRate } = await startLoading(dispatch, 'taxation:rate:fetch', () => api.getTaxRate({ taxRateId }))

    commit('SET_TAX_RATE', { taxRate })
  },
  async createTaxRate ({ commit, dispatch }, { taxRateData }) {
    await startLoading(dispatch, 'taxation:rate:save', () => api.postTaxRate({ taxRateData }))
  },
  async updateTaxRate ({ commit, dispatch }, { taxRateData, taxRateId }) {
    await startLoading(dispatch, 'taxation:rate:save', () => api.putTaxRate({ taxRateData, taxRateId }))
  },
  async removeTaxRate ({ commit, dispatch }, { taxRateId }) {
    await startLoading(dispatch, `taxation:rate:${taxRateId}:delete`, () => api.deleteTaxRate({ taxRateId }))

    dispatch('loadTaxationList')
  },
  async getTaxRateVat ({ commit, dispatch }, { countryId }) {
    const { data } = await startLoading(dispatch, 'taxation:vat:save', () => api.getTaxRateVat({ countryId }))
    return data
  },
  async loadTaxationSystemTransactions ({ commit, dispatch }, { filters }) {
    const { data: { content: transactions, totalPages } } = await startLoading(dispatch, 'taxation:systemTransactions:fetch', () => api.getTaxationTransactions(filters))
    commit('SET_TAXATION_SYSTEM_TRANSACTIONS', { transactions, totalPages })
  },
  async convertAmount ({ commit, dispatch }, { amountData }) {
    const { data } = await startLoading(dispatch, 'taxation:convert:save', () => api.convertAmount({ amountData }))
    return data
  }
}

export default {
  state,
  mutations,
  actions
}
