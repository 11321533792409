import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    payments: [],
    paymentsPages: 0,
    payment: null,
    accountPayments: [],
    matchingLoans: [],
    exportSheets: [],
    exportSheetsPages: 0,
    paymentFiles: []
  },

  actions: {
    async loadIncomingPayments ({ commit, dispatch }, { filters } = {}) {
      commit('SET_PAYMENTS', { payments: [], totalPages: 0 })
      const { data: { content: payments, totalPages } } = await startLoading(dispatch, 'payments:fetch', () => api.getPayments({ filters }))
      commit('SET_PAYMENTS', { payments, totalPages })
    },
    async loadOutgoingPayments ({ commit, dispatch }, { filters } = {}) {
      commit('SET_PAYMENTS', { payments: [], totalPages: 0 })
      const { data: { content: payments, totalPages } } = await startLoading(dispatch, 'payments:fetch', () => api.getOutgoingPayments({ filters }))
      commit('SET_PAYMENTS', { payments, totalPages })
    },
    async loadIncomingPayment ({ commit, dispatch }, { id }) {
      commit('CLEAR_PAYMENT')
      const response = await startLoading(dispatch, 'payment:fetch', () => api.getPayment({ id }))
      commit('SET_PAYMENT', { payment: response.data })
    },
    async loadOutgoingPayment ({ commit, dispatch }, { id }) {
      commit('CLEAR_PAYMENT')
      const response = await startLoading(dispatch, 'payment:fetch', () => api.getOutgoingPayment({ id }))
      commit('SET_PAYMENT', { payment: response.data })
    },
    async updatePayment ({ commit, dispatch }, { id, data }) {
      const response = await startLoading(dispatch, `payment:${id}:save`, function () {
        return api.putPayment({ id, data })
      })
      commit('UPDATE_PAYMENT', { payment: response.data })
    },
    async loadAccountPayments ({ commit, dispatch, state }) {
      const response = await startLoading(dispatch, 'payment:accountPayments:fetch', function () {
        return api.getPayments({ filters: { criteria: state.payment.fromAccount, sort: 'createdAt,desc', size: 5 } })
      })

      commit('SET_CUSTOMER_PAYMENTS', { payments: response.data.content })
    },
    async loadMatchingLoans ({ commit, dispatch, state }) {
      const response = await startLoading(dispatch, 'payment:loans:fetch', function () {
        return api.getMatchingLoans({ id: state.payment.id })
      })
      commit('SET_CUSTOMER_LOANS', { loans: response.data })
    },
    async createPayment ({ commit, dispatch }, { files }) {
      const { data: payment } = await startLoading(dispatch, 'payment:save', () => api.postPayment({ files }))

      commit('INSERT_PAYMENT', { payment })
    },
    async doDayChange ({ dispatch }) {
      const response = await startLoading(dispatch, 'payment:daychange:save', function () {
        return api.postDayChange()
      })
      return response
      // await startLoading(dispatch, 'payment:daychange:save', () => api.postDayChange())
    },
    async splitPayment ({ commit, dispatch }, { id, data }) {
      await startLoading(dispatch, `payment:${id}:split:save`, () => api.postSplitPayment({ id, data }))
    },
    async updateOutgoingPayment ({ commit, dispatch }, { paymentId, paymentData }) {
      const { data: payment } = await startLoading(dispatch, 'payments:outgoing:save', () => api.putOutgoingPayment({ id: paymentId, paymentData }))

      commit('UPDATE_PAYMENT', { payment })
    },
    async updateOutgoingPaymentsStatus ({ commit, dispatch }, { paymentIds, status, metadata } = {}) {
      await startLoading(dispatch, 'payments:outgoing:status:save', () => api.putPaymentsBulk({ paymentIds, status, metadata }))
    },
    async loadExportSheets ({ dispatch, commit }, { filters }) {
      const { data: { content: sheets, totalPages } } = await startLoading(dispatch, 'payments:outgoing:sheets:fetch', () => api.getSheets({ filters }))

      commit('SET_SHEETS', { sheets, totalPages })
    },
    async createExportSheet ({ dispatch, commit }, { handler, searchQuery }) {
      await startLoading(dispatch, 'payments:outgoing:sheet:save', () => api.postCreateSheet({ handler }))
      dispatch('loadExportSheets', { filters: searchQuery })
    },
    async loadExportSheetXML ({ dispatch }, { sheetId }) {
      await startLoading(dispatch, `payments:outgoing:sheet:${sheetId}:xml:fetch`, () => api.getExportSheetXML({ sheetId }))
    },
    async loadManualUpdate ({ dispatch }, { searchQuery }) {
      await startLoading(dispatch, 'payments:outgoing:sync', () => api.getManualUpdate())
      dispatch('loadOutgoingPayments', { filters: searchQuery })
    },
    async loadPaymentFiles ({ dispatch, commit }) {
      const { data: files } = await startLoading(dispatch, 'payments:incoming:files:fetch', () => api.getPaymentFiles())

      commit('SET_PAYMENT_FILES', { files })
    },
    async removePaymentFile ({ dispatch, commit }, { id }) {
      await startLoading(dispatch, `payments:incoming:file:${id}:remove`, () => api.deletePaymentFile({ id }))

      commit('REMOVE_PAYMENT_FILE', { id })
    }
  },

  mutations: {
    SET_PAYMENTS (state, { payments, totalPages }) {
      state.payments = payments
      state.paymentsPages = totalPages
    },
    CLEAR_PAYMENT (state) {
      state.payment = null
      state.accountPayments = []
      state.matchingLoans = []
    },
    SET_PAYMENT (state, { payment }) {
      state.payment = payment
    },
    UPDATE_PAYMENT (state, { payment }) {
      state.payment = { ...state.payment, ...payment }
    },
    SET_CUSTOMER_PAYMENTS (state, { payments }) {
      state.accountPayments = payments
    },
    SET_CUSTOMER_LOANS (state, { loans }) {
      state.matchingLoans = loans
    },
    INSERT_PAYMENT (state, { payments }) {
      payments && state.payments.push(...payments)
    },
    SET_SHEETS (state, { sheets, totalPages }) {
      state.exportSheets = sheets
      state.exportSheetsPages = totalPages
    },
    SET_PAYMENT_FILES (state, { files }) {
      state.paymentFiles = files
    },
    REMOVE_PAYMENT_FILE (state, { id }) {
      state.paymentFiles = state.paymentFiles.filter(file => file.id !== id)
    }
  }
}
