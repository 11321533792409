import Asset from './Asset'
import AssetImmovable from './AssetImmovable'
import AssetProduct from './AssetProduct'
import AssetVehicle from './AssetVehicle'
import AssetGuarantees from './AssetGuarantees'
import AssetTradeReceivables from './AssetTradeReceivables'
import AssetSecurities from './AssetSecurities'
import AssetOther from './AssetOther'

export default {
  common: Asset,
  product: AssetProduct,
  immovable: AssetImmovable,
  vehicle: AssetVehicle,
  trade_receivables: AssetTradeReceivables,
  guarantees: AssetGuarantees,
  securities: AssetSecurities,
  other: AssetOther
}
