<template lang="pug">
b-form-group.fi-form-field(label-class = 'fi-form-field-label'
                           :label-cols = 'label && labelCols || 0'
                           label-size  = 'sm'
                           :state      = 'state'
                           :label-sr-only = '!label')
  template(#label)
    div.header-table-flexing
      slot(name = 'label') {{ !required ? label : `${ label } *` }}
      div(v-if="info")
        span(:id = '`toggle_${_uid}`').fa-stack.fa-sm.text-info
          i.fas.fa-exclamation.fa-fw.fa-stack-1x
          i.far.fa-circle.fa-fw.fa-stack-2x
        b-popover(
          :target   = '`toggle_${_uid}`'
          triggers  = 'hover'
          placement = 'bottom'
        ) {{ info }}
  template(
    #invalid-feedback
    v-if = '!state'
 )
    slot(name = 'error')
  .fi-form-field-value: slot
</template>

<script>
export default {
  name: 'fi-form-field',

  props: {
    label: {
      type: [String, Boolean],
      default: null
    },
    info: {
      type: [String, Boolean],
      default: null
    },
    labelCols: {
      type: [Number, String],
      default: 6
    },
    state: {
      type: [Boolean, String],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~bootstrap/scss/mixins';
  @import 'scss/variables';

  .fi-form-field /deep/ .fi-form-field-label {
  font-weight: 500;
  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
    margin-bottom: 0;
  }

}

.fi-form-field-value {
  color: #3e515b;
  @include media-breakpoint-down(xs) {
    padding-top: 0;
  }
}
</style>
