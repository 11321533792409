import { validators } from '@/validation'

export default (data) => ({
  attributesData: {
    principalPaybackAmountCalculationType: data.principalPaybackAmountCalculationType ?? {
      code: '',
      classifier: 'FeeCalculationType'
    },
    principalPaybackAmount: data.principalPaybackAmount
  },
  validations: {
    attributesData: {
      invoicePaymentTerms: {
        required: validators.requiredIf(function () {
          return this.isOverdraft
        }),
        /*
        issue #766: Overdraft should not allow to put payment term
        under product configuration more than 28 days (due to February).
        */
        maxValue: validators.maxValue('28')
      },
      invoicingDay: {
        required: validators.requiredIf(function () {
          return this.isOverdraft
        }),
        // By design, 28 - maximum invoicing day due to February
        maxValue: validators.maxValue('28')
      },
      currencyId: {
        required: validators.required
      },
      dayCountConventionId: {
        required: validators.required
      },
      penaltyDayCountConventionId: {
        required: validators.required
      },
      interestPaymentPeriodId: {
        required: validators.required
      },
      principalPaybackAmountCalculationType: {
        code: {}
      },
      principalPaybackAmount: {},
      fees: {
        required: true,
        minLength: validators.minLength(1)
      }
    }
  },
  dump () {
    const { fees, principalPaybackAmountCalculationType } = this.attributesData
    return {
      ...this.generalData,
      ...this.attributesData,
      ...this.scoringData,
      ...this.templatesData,
      ...this.paymentsData,
      principalPaybackAmountCalculationType: principalPaybackAmountCalculationType.code,
      fees: [
        ...fees.filter(({ feeType }) => feeType.code)
          .map(fee => ({
            ...fee,
            feeType: fee.feeType.code,
            feeCalculationType: fee.feeCalculationType.code
          }))
      ],
      financialData: this.financialData,
      customFields: this.customFields,
      provision: this.provision
    }
  }
})
