import { validators } from '@/validation'
import { fee } from '@/helpers/feeUtils'

export default (data) => ({
  generalData: {
    hirePurchaseInfo: Boolean(data.hirePurchaseInfo),
    warrantyInfo: Boolean(data.warrantyInfo),
    assetTypes: data.assetTypes,
    checkApr: Boolean(data.checkApr),
    dayChangeSettlementOrder: data.dayChangeSettlementOrder,
    dayChangeSettlementMethod: data.dayChangeSettlementMethod?.code ?? data.dayChangeSettlementMethod
  },
  financialData: data.financialData ?? [],
  attributesData: {
    amount: data.amount,
    amountMin: data.amountMin,
    amountMax: data.amountMax,
    amountSliderStep: data.amountSliderStep,
    automaticExtensionDaysMin: data.automaticExtensionDaysMin,
    automaticExtensionDaysMax: data.automaticExtensionDaysMax,
    automaticExtensionDaysSliderStep: data.automaticExtensionDaysSliderStep,
    automaticExtensionNumberMax: data.automaticExtensionNumberMax,
    automaticPenaltyRateChangeDays: data.automaticPenaltyRateChangeDays,
    automaticPenaltyRateChangeRate: data.automaticPenaltyRateChangeRate,
    currencyId: data.currencyId,
    currencyIdOverride: Boolean(data.currencyIdOverride),
    dayCountConventionId: data.dayCountConventionId,
    dayCountConventionIdOverride: Boolean(data.dayCountConventionIdOverride),
    firstInterestPayment: data.firstInterestPayment,
    firstInterestPaymentOverride: Boolean(data.firstInterestPaymentOverride),
    firstPrincipalPayment: data.firstPrincipalPayment,
    firstPrincipalPaymentOverride: Boolean(data.firstPrincipalPaymentOverride),
    includeAllInvoicesToPenalty: Boolean(data.includeAllInvoicesToPenalty),
    interestPaymentPeriodId: data.interestPaymentPeriodId,
    interestPaymentPeriodIdOverride: Boolean(data.interestPaymentPeriodIdOverride),
    interestRate: data.interestRate,
    interestRateMin: data.interestRateMin,
    interestRateMax: data.interestRateMax,
    invoicePaymentTerms: data.invoicePaymentTerms,
    invoicingDay: data.invoicingDay,
    loanLimitRate: data.loanLimitRate,
    penaltyDayCountConventionId: data.penaltyDayCountConventionId,
    penaltyDayCountConventionIdOverride: Boolean(data.penaltyDayCountConventionIdOverride),
    penaltyInterestRate: data.penaltyInterestRate,
    penaltyInterestRateMin: data.penaltyInterestRateMin,
    penaltyInterestRateMax: data.penaltyInterestRateMax,
    period: data.period,
    periodMin: data.periodMin,
    periodMax: data.periodMax,
    periodOverride: Boolean(data.periodOverride),
    periodUnitTypeId: data.periodUnitTypeId,
    principalPaymentPeriodId: data.principalPaymentPeriodId,
    principalPaymentPeriodIdOverride: Boolean(data.principalPaymentPeriodIdOverride),
    scheduleTypeId: data.scheduleTypeId,
    scheduleTypeIdOverride: Boolean(data.scheduleTypeIdOverride),
    fees: data.fees?.map(feeItem => ({ ...fee(), ...feeItem }))
  },
  templatesData: {
    agreementTemplateId: data.agreementTemplateId,
    agreementTemplateIdOverride: Boolean(data.agreementTemplateIdOverride),
    informationSheetTemplateId: data.informationSheetTemplateId,
    informationSheetTemplateIdOverride: Boolean(data.informationSheetTemplateIdOverride),
    offerTemplateId: data.offerTemplateId,
    offerTemplateIdOverride: Boolean(data.offerTemplateIdOverride),
    scheduleTemplateId: data.scheduleTemplateId,
    scheduleTemplateIdOverride: Boolean(data.scheduleTemplateIdOverride),
    acceptTermsTemplateId: data.acceptTermsTemplateId
  },
  scoringData: {
    applicantScoringModelId: data.applicantScoringModelId,
    applicantScoringModelIdOverride: Boolean(data.applicantScoringModelIdOverride),
    applicantScoringModelAutoRun: Boolean(data.applicantScoringModelAutoRun),
    creditScoringModelId: data.creditScoringModelId,
    creditScoringModelIdOverride: Boolean(data.creditScoringModelIdOverride),
    creditScoringModelAutoRun: Boolean(data.creditScoringModelAutoRun),
    preliminaryScoringModelId: data.preliminaryScoringModelId,
    preliminaryScoringModelIdOverride: Boolean(data.preliminaryScoringModelIdOverride),
    preliminaryScoringModelAutoRun: Boolean(data.preliminaryScoringModelAutoRun)
  },
  validations: {
    generalData: {
      dayChangeSettlementOrder: {
        required: validators.required
      },
      dayChangeSettlementMethod: {
        required: validators.required
      }
    },
    financialData: {},
    attributesData: {
      automaticPenaltyRateChangeDays: {
        required: validators.requiredIf(function () {
          return this.isFinland
        })
      },
      automaticPenaltyRateChangeRate: {
        required: validators.requiredIf(function () {
          return this.isFinland
        })
      },
      automaticExtensionGroup: ['attributesData.automaticExtensionDaysSliderStep', 'attributesData.automaticExtensionDaysMin', 'attributesData.automaticExtensionDaysMax'],
      automaticExtensionDaysSliderStep: {
        required: validators.requiredUnless(function () {
          return this.attributesData.automaticExtensionDaysMin === this.attributesData.automaticExtensionDaysMax
        })
      },
      automaticExtensionDaysMin: {
        max: validators.maxValueRef('automaticExtensionDaysMax')
      },
      automaticExtensionDaysMax: {
        min: validators.minValueRef('automaticExtensionDaysMin')
      },
      amountGroup: ['attributesData.amount', 'attributesData.amountMin', 'attributesData.amountMax', 'attributesData.currencyId', 'attributesData.amountSliderStep'],
      amount: {
        required: validators.required,
        min: validators.minValueRef('amountMin'),
        max: validators.maxValueRef('amountMax')
      },
      amountMin: {
        required: validators.required,
        max: validators.maxValueRef('amountMax')
      },
      amountMax: {
        required: validators.required,
        min: validators.minValueRef('amountMin')
      },
      amountSliderStep: {
        min: validators.minValue(1),
        max: validators.maxValueRef((vm) => {
          return vm.amountMax - vm.amountMin
        }),
        steps: (value, parentVm) => {
          const { amountMin, amountMax } = parentVm
          return !validators.helpers.req(value) || Number.isInteger((amountMax - amountMin) / value)
        },
        required: validators.requiredIf(function () {
          return this.productData.availableInPortal
        })
      },
      periodGroup: ['attributesData.period', 'attributesData.periodMin', 'attributesData.periodMax', 'attributesData.periodUnitTypeId'],
      period: {
        required: validators.required,
        min: validators.minValueRef('periodMin'),
        max: validators.maxValueRef('periodMax')
      },
      periodMin: {
        required: validators.required,
        min: validators.minValue(1),
        max: validators.maxValueRef('periodMax')
      },
      periodMax: {
        required: validators.required,
        min: validators.minValueRef('periodMin')
      },
      periodUnitTypeId: {
        required: validators.required
      },
      currencyId: {
        required: validators.required
      },
      scheduleTypeId: {
        required: validators.requiredUnless(function () {
          return this.isOverdraft || this.isGuarantee
        })
      },
      dayCountConventionId: {
        required: validators.required
      },
      penaltyDayCountConventionId: {
        required: validators.required
      },
      firstPrincipalPayment: {
        required: validators.requiredUnless(function () {
          return this.isOverdraft || this.isGuarantee
        })
      },
      principalPaymentPeriodId: {
        required: validators.requiredUnless(function () {
          return this.isOverdraft || this.isGuarantee
        }),
        tenor: function (value) {
          return !/DAY/i.test(this.classifierById('periodUnitType', this.attributesData.periodUnitTypeId).name) ||
            /TENOR/i.test(this.classifierById('paymentPeriodTypes', value).name)
        }
      },
      firstInterestPayment: {
        required: validators.requiredUnless(function () {
          return this.isOverdraft || this.isGuarantee
        })
      },
      interestPaymentPeriodId: {
        required: validators.required,
        tenor: function (value) {
          return !/DAY/i.test(this.classifierById('periodUnitType', this.attributesData.periodUnitTypeId).name) ||
            /TENOR/i.test(this.classifierById('paymentPeriodTypes', value).name)
        }
      },
      interestRateGroup: ['attributesData.interestRate', 'attributesData.interestRateMin', 'attributesData.interestRateMax', 'attributesData.dayCountConventionId'],
      penaltyInterestRateGroup: ['attributesData.penaltyInterestRate', 'attributesData.penaltyInterestRateMin', 'attributesData.penaltyInterestRateMax', 'attributesData.penaltyDayCountConventionId'],
      interestRate: {
        required: validators.required,
        min: validators.minValueRef(function () {
          return this.attributesData.interestRateMin
        }),
        max: validators.maxValueRef(function () {
          return this.attributesData.interestRateMax
        })
      },
      interestRateMin: {
        required: validators.required,
        max: validators.maxValueRef(function () {
          return this.attributesData.interestRateMax
        })
      },
      interestRateMax: {
        required: validators.required,
        min: validators.minValueRef(function () {
          return this.attributesData.interestRateMin
        })
      },
      penaltyInterestRate: {
        min: validators.minValueRef(function () {
          return this.attributesData.penaltyInterestRateMin
        }),
        max: validators.maxValueRef(function () {
          return this.attributesData.penaltyInterestRateMax
        })
      },
      penaltyInterestRateMin: {
        max: validators.maxValueRef(function () {
          return this.attributesData.penaltyInterestRateMax
        })
      },
      penaltyInterestRateMax: {
        min: validators.minValueRef(function () {
          return this.attributesData.penaltyInterestRateMin
        })
      },
      fees: {
        required: validators.required,
        minLength: validators.minLength(1),
        $each: {
          feeType: {
            classifier: {},
            code: {
              required: validators.required
            }
          },
          feeAmount: {
            required: validators.required
          },
          feeMax: {
            required: validators.required
          },
          feeMin: {
            required: validators.required
          },
          feeCalculationType: {
            classifier: {},
            code: {
              required: validators.required
            }
          },
          feeOverride: {}
        }
      }
    }
  }
})
