import { validators } from '@/validation'
import pick from 'lodash/pick'

export const newContractTermsFields = [
  'amount',
  'periodQuantity',
  'startDate',
  'interestPaymentMethod',
  'interestPaymentAccount',
  'originOfMoney',
  'beneficiary',
  'withholdTax',
  'autoExtendableEnabled',
  'payoutBankAccount',
  'productId',
  'compoundInterest',
  'fee'
]

export const contractDetailsTermsFields = [
  'amount',
  'periodQuantity',
  'startDate',
  'interestPaymentMethod',
  'dayCountConventionType',
  'periodUnitType',
  'fees',
  'maxWithdrawalFromCurrentAmount'
]

export const contractTermsFields = [
  'autoExtendableEnabled',
  'withholdTax',
  'additionalInterest',
  'originOfMoney',
  'beneficiary',
  'interests',
  'compoundInterest'
]

export default ({ terms = {}, customer: { document = {}, address = {}, ...customer } = {}, ...depositData } = {}) => ({
  depositData: {
    paymentReferenceId: depositData.paymentReferenceId
  },
  termsData: {
    interests: (terms.amountRangePeriodInterests || []).reduce((res, { periodRangeInterests }, index) => ({ ...res, [index]: periodRangeInterests.map(({ interest }) => interest) }), {}) || terms.interests || {},
    amount: terms.amount ?? terms.amountRangePeriodInterests?.[0].amountRange?.from,
    autoExtendableEnabled: Boolean(terms.autoExtendable?.extendable || terms.autoExtendableEnabled),
    additionalInterest: terms.autoExtendable?.additionalInterest || terms.additionalInterest,
    beneficiary: terms.beneficiary,
    dayCountConventionType: terms.dayCountConventionType,
    interestPaymentAccount: terms.interestPaymentAccount?.id || terms.interestPaymentAccount,
    interestPaymentMethod: terms.interestPaymentMethod?.type?.code || terms.interestPaymentMethod,
    maxWithdrawalFromCurrentAmount: terms.maxWithdrawalFromCurrentAmount,
    originOfMoney: terms.originOfMoney,
    originOfMoneyLimit: terms.originOfMoneyLimit,
    payoutBankAccount: terms.payoutBankAccount,
    periodQuantity: terms.period?.quantity || terms.periodQuantity,
    periodUnitType: terms.period?.unitType?.code || terms.periodUnitType,
    productId: terms.productId,
    startDate: terms.startDate || '',
    fees: terms.fees || [],
    withholdTax: {
      enabled: false,
      residenceCountryId: '',
      rate: '',
      ...terms.withholdTax
    },
    compoundInterest: Boolean(terms.compoundInterest),
    fee: terms.fee
  },
  customerData: {
    id: customer.id,
    idCode: customer.idCode,
    email: customer.email,
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: customer.phoneNumber,
    deskPhoneNumber: customer.deskPhoneNumber,
    customerName: customer.customerName
  },
  documentData: {
    documentNumber: document.documentNumber,
    documentType: document.documentType
  },
  addressData: {
    address: address.address,
    city: address.city,
    countryId: address.countryId,
    countyId: address.countyId,
    postalCode: address.postalCode
  },
  get newContractModel () {
    return {
      depositData: this.depositData,
      customerData: this.customerData,
      addressData: this.addressData,
      documentData: this.documentData,
      termsData: {
        ...pick(this.termsData, newContractTermsFields),
        withholdTax: pick(this.termsData.withholdTax, ['enabled', 'residenceCountryId', 'rate'])
      },
      validations: {
        depositData: this.validations.depositData,
        customerData: this.validations.customerData,
        addressData: this.validations.addressData,
        documentData: this.validations.documentData,
        termsData: pick(this.validations.termsData, newContractTermsFields)
      }
    }
  },
  get contractDetailsModel () {
    return {
      termsData: pick(this.termsData, contractDetailsTermsFields),
      validations: {
        termsData: pick(this.validations.termsData, contractDetailsTermsFields)
      }
    }
  },
  get contractTermsModel () {
    return {
      termsData: {
        ...pick(this.termsData, contractTermsFields),
        withholdTax: pick(this.termsData.withholdTax, ['enabled', 'residenceCountryId', 'rate'])
      },
      validations: {
        termsData: pick(this.validations.termsData, contractTermsFields)
      }
    }
  },
  validations: {
    depositData: {
      paymentReferenceId: {}
    },
    customerData: {
      firstName: {
        required: validators.requiredUnless(function () {
          return this.isCorporateCustomer(this.selectedCustomerModel)
        })
      },
      lastName: {
        required: validators.requiredUnless(function () {
          return this.isCorporateCustomer(this.selectedCustomerModel)
        })
      },
      idCode: {
        required: validators.required
      },
      phoneNumber: {
        required: validators.required
      },
      deskPhoneNumber: {},
      email: {
        required: validators.required,
        isEmail: validators.email
      },
      customerName: {
        required: validators.requiredIf(function () {
          return this.isCorporateCustomer(this.selectedCustomerModel)
        })
      }
    },
    documentData: {
      documentType: {
        required: validators.requiredUnless(function () {
          return this.isCorporateCustomer(this.selectedCustomerModel)
        })
      },
      documentNumber: {
        required: validators.requiredUnless(function () {
          return this.isCorporateCustomer(this.selectedCustomerModel)
        })
      }
    },
    addressData: {
      countryId: {
        required: validators.required
      },
      countyId: {
        required: validators.required
      },
      city: {
        required: validators.required
      },
      address: {
        required: validators.required
      },
      postalCode: {
        required: validators.required
      }
    },
    termsData: {
      interests: {
        $each: {}
      },
      additionalInterest: {
        required: validators.requiredIf(function () {
          return this.termsData.autoExtendableEnabled
        })
      },
      maxWithdrawalFromCurrentAmount: {
        required: validators.required
      },
      dayCountConventionType: {
        required: validators.required
      },
      autoExtendableEnabled: {},
      productId: {
        required: validators.required
      },
      amount: {
        required: validators.required
      },
      periodQuantity: {
        required: validators.required
      },
      periodUnitType: {
        required: validators.required
      },
      startDate: {
        required: validators.required
      },
      interestPaymentMethod: {
        required: validators.required
      },
      originOfMoney: {
        // required: validators.requiredIf(function () {
        //   const { originOfMoneyLimit, amount } = this.termsData
        //   return amount > originOfMoneyLimit || !this.isCorporateCustomer(this.selectedCustomerModel)
        // })
      },
      beneficiary: {
        required: validators.requiredIf(function () {
          return this.revisionId && !this.isCorporateCustomer(this.selectedCustomerModel)
        })
      },
      withholdTax: {
        enabled: {},
        residenceCountryId: {
          required: validators.requiredIf(function () {
            return this.termsData.withholdTax.enabled
          })
        },
        rate: {
          required: validators.requiredIf(function () {
            return this.termsData.withholdTax.enabled
          })
        }
      },
      interestPaymentAccount: {
        required: validators.required
      },
      payoutBankAccount: {
        required: validators.required
      },
      fees: {
        $each: {
          feeType: {
            classifier: {},
            code: {
              required: validators.required
            }
          },
          feeAmount: {
            required: validators.required
          },
          feeCalculationType: {
            classifier: {},
            code: {
              required: validators.required
            }
          }
        }
      },
      compoundInterest: {}
    }
  },
  dump () {
    const { feeAmount, feeType, feeCalculationType } = this.termsData.fee ?? {}
    const { originOfMoney } = this.termsData

    if (Array.isArray(originOfMoney) && originOfMoney.length) {
      this.termsData.originOfMoney = originOfMoney.map((el) => el.text.toUpperCase()).join(', ')
    }

    return {
      terms: {
        ...pick(this.termsData, newContractTermsFields),
        withholdTax: pick(this.termsData.withholdTax, ['enabled', 'residenceCountryId', 'rate']),
        ...this.termsData.fee ? {
          fee: {
            feeAmount,
            feeType: feeType.code,
            feeCalculationType: feeCalculationType.code
          }
        } : {}
      },
      customer: {
        ...this.customerData,
        address: this.addressData,
        document: this.documentData
      },
      ...this.depositData
    }
  }
})
