<template lang="pug">
.input-group.range-input-date
  .input-group-prepend: span.input-group-text: i.fa.fa-calendar
  .input-group-prepend(v-if = 'title'): span.input-group-text {{ title }}
  .input-group-prepend(v-else-if = 'options')
    fi-select.bg-light(
      v-model  = 'computedColumn'
      :options = 'options'
      required
    )
  fi-datepicker(v-model        = 'computedStart'
                :placeholder   = '$t("startPlaceholder")'
                :enabled-dates  = 'enabledDates'
                :disabled-dates = 'disabledDates')
  fi-datepicker(v-model        = 'computedEnd'
                :placeholder   = '$t("endPlaceholder")'
                :enabled-dates  = 'enabledDates'
                :disabled-dates = 'disabledDates')
</template>

<script>
import FiSelect from '@/components/FiSelect'
import RangeInputBase from './RangeInputBase'
import FiDatepicker from './FiDatepicker'

export default {
  name: 'range-input-date',

  components: {
    FiSelect,
    FiDatepicker
  },

  extends: RangeInputBase,

  props: {
    disabledDates: {
      type: Array,
      default: () => []
    },
    enabledDates: {
      type: Array,
      default: () => []
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  startPlaceholder: "from"
  endPlaceholder: "until"

et:
  startPlaceholder: "from"
  endPlaceholder: "until"

ru:
  startPlaceholder: "from"
  endPlaceholder: "until"
</i18n>


<style scoped lang="scss">
.range-input-date /deep/ .flatpickr-input:last-child { margin-left: -1px; }
</style>
