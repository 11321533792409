<template lang='pug'>
tr.payment-outgoing-sheet-list-item
  td {{ $t('common:formatDate', { value: sheet.createdAt }) }}
  td {{ sheet.numberOfPayments }}
  td.money {{ sheet.totalAmount | money }}
  td {{ sheet.handlerName }}
  td
    button.btn.btn-outline-primary.btn-sm.my-0(@click = 'loadExportSheetXML({ sheetId: sheet.id })') {{ $t('xml') }}
      i.fa.fa-spinner.fa-pulse.fa-lg(v-if = 'loading')
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'payment-outgoing-sheet-list-item',

  props: {
    sheet: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    loading () {
      return this.$vueLoading.isLoading(`payments:outgoing:sheet:${this.sheet.id}:xml:fetch`)
    }
  },

  methods: {
    ...mapActions('payments', ['loadExportSheetXML'])
  }
}
</script>


<i18n>
en:
  xml: "XML"
et:
  xml: "XML"
ru:
  xml: "XML"
</i18n>
