<template lang="pug">
ol.breadcrumb.breadcrumb-view
  li.breadcrumb-item(v-for = '(item, index) in filteredList')
    span.active(v-if = "isLast(index)") {{ $t(showName(item)) }}
    router-link(v-else
                :to  = "path(item) || '/'" ) {{ $t(showName(item)) }}
</template>


<script>
import filter from 'lodash/filter'
import pathToRegexp from 'path-to-regexp'

export default {
  name: 'breadcrumb-view',

  props: {
    list: {
      type: Array,
      required: true
    },
    separator: {
      type: String,
      default: ' '
    }
  },

  computed: {
    filteredList () {
      return filter(this.list, (item) => !(item.meta?.skip))
    }
  },

  methods: {
    path (item) {
      return pathToRegexp.compile(item.path)({ ...(item?.instances?.default?.$route?.params || {}) })
    },
    isLast (index) {
      return index === this.filteredList.length - 1
    },
    showName (item) {
      if (item.meta?.label) {
        return item.meta.label
      }
      if (item.name) {
        return item.name
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.breadcrumb-view {
  position: sticky;
  top: 55px;
  z-index: 100;
}
</style>
