export default {
  registerNumber: null,
  warrantyTypeId: '',
  warrantyCountryId: '',
  warrantyCountyId: '',
  warrantyCity: null,
  warrantyAddress: null,
  warrantyCadastralUnitIdentifier: null,
  warrantyArea: null,
  warrantyOwnerName: null,
  areaSizeTypeId: null,
  immovableName: '',
  assetsOwners: [],
  assetReportingType: '',
  assetSoldValue: null,
  assetStatus: '',
  collateralLocationCode: '',
  soldDate: null,
  valuationType: '',
  valueType: '',
  warrantyPostalCode: '',
  appraisalPartnerId: ''
  // appraisalPartnerName: ''
}
