export const loanStatuses = {
  inserted: 'INSERTED',
  pending: 'PENDING',
  signed: 'SIGNED',
  deleted: 'DELETED',
  matured: 'MATURED',
  complete: 'COMPLETE',
  active: 'ACTIVE',
  delayed: 'DELAYED',
  collector: 'COLLECTOR',
  bankrupt: 'BANKRUPT',
  bailiff: 'BAILIFF',
  conkedOut: 'CONKED_OUT',
  terminated: 'TERMINATED',
  breached: 'BREACHED',
  closed: 'CLOSED',
  agreed: 'AGREED',
  writtenOff: 'WRITTEN_OFF'
}

export const pagination = {
  page: 0,
  size: 25
}

export const schedulePagination = {
  page: 0,
  size: 5
}

export const paymentRelationData = {
  amount: null,
  beneficiaryExternalAccountNumber: '',
  beneficiaryIdCode: '',
  beneficiaryName: null,
  customerId: '',
  subtractFeeAccount: false,
  description: '',
  referenceNumber: '',
  paymentWayId: ''
}

export const periodUnit = {
  MONTH: 'months',
  DAY: 'days'
}

export const factoringInvoiceStatuses = {
  approved: 'APPROVED',
  unapproved: 'UNAPPROVED',
  pending: 'PENDING',
  cancel: 'CANCEL',
  advancePaid: 'ADVANCE_PAID',
  partlyPaid: 'PARTLY_PAID'
}
