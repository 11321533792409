import { connection } from '@/api/connection'

export default {
  getCustomFields: () => connection.get('/custom-fields'),
  getKYCCustomFields: () => connection.get('/kyc/custom-fields/all'),
  getCustomField: ({ id }) => connection.get(`/custom-fields/${id}`),
  getKYCCustomField: ({ id }) => connection.get(`/kyc/custom-fields/${id}`),
  postCustomField: ({ customFieldData }) => connection.post('/custom-fields', customFieldData),
  postKYCCustomField: ({ customFieldData }) => connection.post('/kyc/custom-fields', customFieldData),
  putCustomField: ({ id, customFieldData }) => connection.put(`/custom-fields/${id}`, customFieldData),
  putKYCCustomField: ({ id, customFieldData }) => connection.put(`/kyc/custom-fields/update/${id}`, customFieldData)
}
