<template lang="pug">
b-dd.loan-status-dropdown(right
                          :variant  = 'computedVariant'
                          :disabled = 'saving')
  //- div {{ statuses }}
  template(#button-content)
    i.fa.fa-cog(:class = '{ "fa-spin": saving }')
  b-dd-header {{ header || $t('changeTo') }}:
  template(v-for = 'status in sortClassifiers')
    b-dd-item(:key   = 'status.id'
              @click = "changeStatus(status)") {{ classifierById(status.classifier, status.id).human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'loan-status-dropdown',

  props: {
    statuses: {
      type: Array,
      required: true
    },
    variant: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.statuses))
      const sortList = list.sort(function (a, b) {
        const nameA = a.human
        const nameB = b.human
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    },
    saving () {
      return this.$vueLoading.isLoading('loan:status:save')
    },
    computedVariant () {
      return this.variant || 'transparent p-0'
    }
  },

  methods: {
    changeStatus (status) {
      this.$emit('submit', status)
    }
  }
}
</script>


<i18n>
en:
  changeTo: "Select new loan status"
  changeStatusConfirm: "Are you sure to change status?"
et:
  changeTo: "Select new loan status"
  changeStatusConfirm: "Are you sure to change status?"
ru:
  changeTo: "Select new loan status"
  changeStatusConfirm: "Are you sure to change status?"
</i18n>
