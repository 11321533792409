import { connection } from '@/api/connection'

const controllerRoot = 'bo/pledgee'
export default {
  getPledgees () {
    return connection.get(controllerRoot)
  },
  postPledgee ({ pledgeeData }) {
    return connection.post(controllerRoot, pledgeeData)
  }
}
