<template lang="pug">
tr.payment-incoming-list-item
  td: router-link(:to = '{ name: "incomingPayment", params: { id: payment.id.toString() } }') {{ payment.id }}
  td {{ $t('common:formatDate', { value: payment.paymentDate }) }}
  td {{ payment.fromName }}
  td {{ payment.fromAccount }}
  td {{ payment.description }}
  td.money {{ payment.amount | money }}
  td
    router-link(
      v-if = 'payment.customerId'
      :to  = '{ name: "CustomerPaymentInfo", params: { id: payment.customerId.toString(), reference: payment.referenceNumber.toString() }}'
    ) {{ payment.referenceNumber }}
    template(v-else) {{ payment.referenceNumber }}
  td: router-link(
    v-if = 'payment.customerId'
    :to  = '{ name: "Customer", params: { id: payment.customerId.toString() }}'
  ) {{ payment.customerName }}
  td: .badge(:class = 'statusClass') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

const STATUS_COLOR_MAP = {
  PROCESSED: 'badge-primary',
  CANCELLED: 'badge-secondary',
  FACTORING: 'badge-secondary',
  PENDING: 'badge-danger'
}

export default {
  name: 'payment-incoming-list-item',

  props: {
    payment: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('customers', ['customerName']),
    status () {
      return this.classifierById('incomingPaymentStatuses', this.payment.statusId)
    },
    statusClass () {
      return STATUS_COLOR_MAP[this.status.code] || 'badge-dark'
    }
  }
}
</script>
