<template lang='pug'>
.card.dashboard-widget-deposit-notifications
  .card-header.text-white.bg-secondary.border-bottom-0
    i.far.fa-2x.fa-star.text-muted.fa-pull-right.m-0
    h4.mb-0 {{dashboardDepositsNotificationsInsertedAndSigned.length}}
    small.text-muted.font-weight-bold
      router-link.text-muted.font-weight-bold(:to = '{ name: "Deposits", query: { statuses: ["INSERTED", "SIGNED"], sort: "periodStart,desc", size: 25 } }') {{ $t('title') | upperCase}}
  .card-body
    ul.list-unstyled.m-0
      dashboard-widget-deposit-notifications-item(v-for         = '(notification, index) in dashboardDepositsNotificationsInsertedAndSigned.slice(0, 3)'
                                                  :key          = 'index'
                                                  :notification = 'notification')

      li.text-center.nav-link(@click.prevent = 'showDepositNotificationsModal = true'): i.fa.fa-ellipsis-h.fa-lg

      deposit-notifications-modal(
        v-if    = 'showDepositNotificationsModal'
        v-model = 'showDepositNotificationsModal'
      )
</template>


<script>
import DashboardWidgetDepositNotificationsItem from '@/views/dashboard/DashboardWidgetDepositNotificationsItem'
import DepositNotificationsModal from '@/views/deposits/DepositNotificationsModal'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'dashboard-widget-deposit-notifications',

  components: { DepositNotificationsModal, DashboardWidgetDepositNotificationsItem },

  data: () => ({
    showDepositNotificationsModal: false
  }),

  computed: {
    ...mapState('dashboard', ['dashboardDepositsNotifications']),
    dashboardDepositsNotificationsInsertedAndSigned () {
      const dashboardDepositsNotificationsInsertedAndSigned = []
      this.dashboardDepositsNotifications.forEach((element) => {
        if (element.type === 'INSERTED' || element.type === 'SIGNED') {
          dashboardDepositsNotificationsInsertedAndSigned.push(element)
        }
      })
      return dashboardDepositsNotificationsInsertedAndSigned
    }
  },

  mounted () {
    this.loadDashboardDepositNotifications()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardDepositNotifications'])
  }
}
</script>


<style scoped lang="scss">
  .nav-link { cursor:pointer; }
</style>


<i18n>
en:
  title: "Deposit notices"
et:
  title: "Deposit notices"
ru:
  title: "Deposit notices"
</i18n>
