<template lang="pug">
.card.dashboard-widget-payments-pending(v-if = 'dashboardPaymentsPendingCount')
  .card-header.text-white.bg-danger.border-bottom-0
    i.fa.fa-2x.fa-arrow-circle-o-right.text-muted.fa-pull-right.m-0
    h4.mb-0 {{ dashboardPaymentsPendingCount }}
    small.text-muted {{ $t('title') }}
  .card-body
    ul.list-unstyled.m-0
      dashboard-widget-payments-pending-item(v-for    = 'payment in dashboardPaymentsPending'
                                             :payment = 'payment'
                                             :key     = 'payment.id')
      li.text-center(v-if = 'dashboardPaymentsPendingCount > dashboardPaymentsPending.length')
        router-link.text-body.d-block(:to = '{ name: "Payments", query: { statusId: statusPendingId } }'): i.fa.fa-ellipsis-h.fa-lg
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DashboardWidgetPaymentsPendingItem from './DashboardWidgetPaymentsPendingItem'

export default {
  name: 'dashboard-widget-payments-pending',

  components: {
    DashboardWidgetPaymentsPendingItem
  },

  i18nOptions: {},

  computed: {
    ...mapState('dashboard', ['dashboardPaymentsPending', 'dashboardPaymentsPendingCount']),
    ...mapGetters('classifiers', ['classifierByName']),
    statusPendingId () {
      return this.classifierByName('incomingPaymentStatuses', 'pending')?.id
    }
  },

  mounted () {
    this.loadDashboardPaymentsPending()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardPaymentsPending'])
  }
}
</script>


<i18n>
en:
  title: "PENDING PAYMENTS"

et:
  title: "PENDING PAYMENTS"

ru:
  title: "PENDING PAYMENTS"

</i18n>
