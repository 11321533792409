import { connection, downloadFile } from './connection'

export default {
  getResourceFile ({ id }) {
    return downloadFile(`bo/resources/${id}/download`)
  },
  deleteResource ({ id }) {
    return connection.delete(`bo/resources/${id}`)
  },
  putResource ({ id, resourceData }) {
    return connection.put(`bo/resources/${id}`, resourceData)
  }
}
