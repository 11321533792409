import { required } from 'vuelidate/lib/validators'

export default {
  accountExternalId: {
    required
  },
  guaranteeFeeMethodId: {
    required
  },
  guaranteeFeePaymentDate: {
    required
  },
  amortizationPeriod: {
    required
  },
  amount: {
    required
  },
  amountLimit: {
    required
  },
  customerBorrowerId: {
    required
  },
  dayCountConvention: {
    required
  },
  interestPaymentPeriod: {
    required
  },
  interestRate: {
    required
  },
  interestFirst: {
    required
  },
  penaltyDayCountConvention: {
    required
  },
  penaltyInterestRate: {
    required
  },
  periodEnd: {
    required
  },
  periodStart: {
    required
  },
  principalFirst: {
    required
  },
  principalPaymentPeriod: {
    required
  },
  productId: {
    required
  },
  scheduleType: {
    required
  },
  residualAmount: {
    required
  },
  residualAmountCalculationType: {
    required
  },
  upfrontAmount: {
    required
  },
  upfrontAmountCalculationType: {
    required
  }
}
