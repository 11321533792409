<template lang="pug">
footer.app-footer.footer-view
  //- a(href='https://genesisui.com') Alba
  //- |
  //- | &copy; 2017 creativeLabs.
  //- span.float-right
  //-   | Powered by
  //-   |
  //-   a(href='https://genesisui.com') GenesisUI
</template>


<script>
export default {
  name: 'footer-view'
}
</script>
