<template lang="pug">
b-dd(
  right
  :disabled = '$vueLoading.isLoading(loader)'
  variant   = 'transparent p-0'
  :no-caret = 'noCaret'
)
  template(#button-content)
    i.fa.fa-cog.fa-pulse(v-if = '$vueLoading.isLoading(loader)')
    i.cil-user-follow(v-else)

  b-dd-header {{ $t('assignTo') }}:

  b-dd-item(
    :disabled      = 'user.id === application.employeeId'
    @click.prevent = 'onAssign(user.id)'
  ) {{ $t('me') }}

  b-dd-item(
    v-for          = 'manager in managers'
    v-if           = 'manager.customerId !== user.id'
    :key           = 'manager.id'
    :disabled      = 'manager.customerId === application.employeeId'
    @click.prevent = 'onAssign(manager.customerId)'
  ) {{ manager.name }}
</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'application-assign-dropdown',

  props: {
    loader: {
      type: String,
      required: true
    },
    application: {
      type: Object,
      required: true
    },
    noCaret: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('classifiers', ['managers'])
  },

  methods: {
    onAssign (customerId) {
      this.$emit('assign', { id: this.application.id, customerId })
    }
  }
}
</script>


<i18n>
en:
  assignTo: "Assign to"
  me:       "Me"
et:
  assignTo: "Assign to"
  me:       "Me"
ru:
  assignTo: "Assign to"
  me:       "Me"
</i18n>


<style lang='scss' scoped>
  @import '~bootstrap/scss/mixins';
  @import 'scss/variables';
  .colored /deep/ button { color: $body-color; }
</style>
