<template lang="pug">
.payment-incoming.animated.card.fadeIn
  v-loading(loader = 'payment:fetch')
    template(#spinner)
      .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
    template(v-if = 'payment')
      .card-header {{ $t('title', { id: payment.id }) }}
      .card-body
        form.form(@submit.prevent='savePayment')
          payment-outgoing-fields(
            :statuses         = 'payment.availableStatuses'
            :validation-state = '$v.paymentData'
            :editable         = 'editable'
          )
          button.btn.btn-primary.mb-3(
            type      = 'submit'
            :disabled = 'saving'
          )
            | {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
          | &nbsp;
          button.btn.btn-secondary.mb-3(
            :disabled      = 'saving'
            @click.prevent = 'navigateToList'
          ) {{ $t('common:cancel') }}
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import { validation, validators } from '@/validation'
import PaymentOutgoingFields from '@/views/payments/PaymentOutgoingFields'
import { mapActions, mapGetters, mapState } from 'vuex'
import merge from 'lodash/merge'
import pick from 'lodash/pick'

export default {
  name: 'payment-outgoing',

  components: { PaymentOutgoingFields, FiMoneyInput, FiDatepicker, FiSelect, FiFormField },

  mixins: [validation],

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data: (vm) => ({
    paymentEditFields: [
      'payableAfterTime',
      'description',
      'status',
      ...vm.isProcessed ? [] : ['paymentHandler']
    ],
    paymentData: {
      payableAfterTime: '',
      description: '',
      status: '',
      customerFullName: '',
      externalAccount: '',
      amount: '',
      paymentHandler: ''
    }
  }),

  computed: {
    ...mapState('payments', ['payment']),
    ...mapGetters('classifiers', ['classifierById']),
    isProcessed () {
      return /processed|completed|cancelled|failed/i.test(this.status.name)
    },
    status () {
      return this.classifierById('outgoingPaymentStatuses', this.payment.statusId)
    },
    saving () {
      return this.$vueLoading.isLoading('payments:outgoing:save')
    },
    editable () {
      return /WAITING_APPROVAL|PENDING/i.test(this.paymentData.status)
    }
  },

  validations () {
    return {
      paymentData: {
        payableAfterTime: {
          positive: validators.minValue(0)
        },
        description: {},
        status: {},
        customerFullName: {},
        externalAccount: {},
        amount: {},
        paymentHandler: {}
      }
    }
  },

  async created () {
    this.loadPaymentHandlers()
    await this.loadOutgoingPayment({ id: this.id })
    this.resetData()
  },

  methods: {
    ...mapActions('payments', ['loadOutgoingPayment', 'updateOutgoingPayment']),
    ...mapActions('classifiers', ['loadPaymentHandlers', 'loadPaymentHandlersManual']),
    resetData () {
      this.paymentData = merge(
        {},
        this.paymentData,
        pick(this.payment, Object.keys(this.paymentData)),
        {
          payableAfterTime: this.payment.payableAfterHours < 0 ? 0 : this.payment.payableAfterHours,
          customerFullName: this.payment.metadata.customerFullName,
          externalAccount: this.payment.toAccount
        }
      )
    },
    navigateToList () {
      this.$router.push({ name: 'outgoingPaymentsList' })
    },
    async savePayment () {
      let paymentData
      if (this.validate()) {
        if (this.editable) {
          paymentData = { ...pick(this.paymentData, this.paymentEditFields), id: this.id }
        } else {
          paymentData = { ...pick(this.paymentData, 'status'), id: this.id }
        }
        await this.updateOutgoingPayment({ paymentData })
        this.$toasted.success(this.$t('success'))
        this.navigateToList()
      }
    }
  }
}
</script>


<i18n>
en:
  title:   "Payment #{{id}}"
  success: "Payment updated successfully"
et:
  title:   "Payment #{{id}}"
  success: "Payment updated successfully"
ru:
  title:   "Payment #{{id}}"
  success: "Payment updated successfully"
</i18n>
