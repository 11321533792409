import map from 'lodash/map'
import api from '@/api'
import { createActionHelpers } from 'vuex-loading'
import { WebsocketListener } from '@/classes/WebsocketListener'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

const scoringProgressParams = {
  brokerUrl: '/ws/scoring/progress',
  subscribeTo: '/topic/progress'
}

// TODO content type should be given in backend
const pattern1 = /<div class="message msg-info"[^>]*>((.|[\n\r])*)<div id="footer"/im
const pattern2 = /<div class="content[^>]*>[\s]*<section[^>]*>((.|[\n\r])*)<\/section>/im
const pattern3 = /<div id="content1left[^>]*>((.|[\n\r])*)<!-- end #mainContent -->/im
const pattern4 = /<div class="clear" id="content[^>]*>((.|[\n\r])*)<\/table>/im

export default {
  state: {
    scoringModels: {},
    model: null,
    requestList: [],
    requestsPages: 0,
    currentRequest: null,
    checkParams: []
  },

  getters: {
    scoringModelById: (state) => (id) => {
      return state.scoringModels[id] || {}
    },
    optionsFromScoringModels: state => map(state.scoringModels, ({ id, description }) => ({ value: id, text: description })),
    requestResultContent: (state) => (key) => {
      const parseJson = (json) => {
        try {
          return JSON.parse(json)
        } catch (e) {
          console.debug('Not JSON', json)
        }
      }
      const resultData = state.currentRequest.scoringResponseModel[key]
      const rawData = resultData.rawData
      const objectRawData = parseJson(rawData)
      if (objectRawData !== undefined) {
        resultData.content = { content: objectRawData, html: false, json: true }
      } else {
        const arrayMatches = pattern1.exec(rawData) || pattern2.exec(rawData) ||
          pattern3.exec(rawData) || pattern4.exec(rawData)
        if (arrayMatches && arrayMatches.length > 1) {
          resultData.content = { content: arrayMatches[1], html: true, json: false }
        } else {
          resultData.content = { content: rawData, html: false, json: false }
        }
      }
      const objectDecisionData = parseJson(resultData.decisionData)
      resultData.decision = objectDecisionData || null
      resultData.scoreCheckName = key
      return resultData
    }
  },

  actions: {
    async loadScoringModels ({ commit, dispatch }) {
      const { data: scoringModels } = await startLoading(dispatch, 'scoring:models:fetch', () => api.getScoringModels())
      commit('SET_SCORING_MODELS', { scoringModels })
    },
    async loadRequestList ({ commit, dispatch }, { filters } = {}) {
      commit('CLEAR_REQUEST_LIST')
      const { data: { content, totalPages } } = await startLoading(dispatch, 'scoring:requests:fetch', () => api.getRequests({ filters }))
      commit('SET_REQUEST_LIST', { list: content, requestsPages: totalPages })
    },
    async createRequest ({ commit, dispatch }, { modelId, requestData, type }) {
      commit('CLEAR_CURRENT')
      const { data: request } = await startLoading(dispatch, 'scoring:request:save', () => api.postRequest({ modelId, requestData, type }))
      commit('SET_CURRENT', { request })
    },
    async continueRequest ({ commit, dispatch }, { modelId, requestId, type }) {
      const scoringProgressListener = new WebsocketListener(scoringProgressParams)

      scoringProgressListener.listen((message) => {
        commit('SET_SCORING_CHECK_RESULT', { check: JSON.parse(message.body) })
      })

      const { data: request } = await startLoading(dispatch, 'scoring:request:continue:save', () => api.postRequestContinue({ modelId, requestId, type })).then((result) => {
        return result
      })
      commit('SET_CURRENT', { request })
    },
    async loadRequest ({ commit, dispatch }, { requestId, type }) {
      commit('CLEAR_CURRENT')
      const response = await startLoading(dispatch, 'scoring:request:fetch', () => api.getScoreRequest({ requestId, type }))
      commit('SET_CURRENT', { request: response.data })
    },
    async loadExternalResponseReport ({ dispatch }, { data, filename }) {
      await startLoading(dispatch, 'scoring:external:response:fetch', () => api.getExternalResponseReport({ data, filename }))
    },
    async loadScoringCheckParams ({ dispatch, commit }, { ids }) {
      const { data: checkParams } = await startLoading(dispatch, 'scoring:check:params:fetch', () => api.getScoringCheckParams({ ids }))

      commit('SET_SCORING_CHECK_PARAMS', { checkParams })
    },
    async createScoringModel ({ dispatch, commit }, { modelData }) {
      const { data: model } = await startLoading(dispatch, 'scoring:model:save', () => api.postScoringModel({ modelData }))

      commit('SET_SCORING_MODEL', { model })
    },
    async updateScoringModel ({ dispatch, commit }, { modelData, modelId }) {
      const { data: model } = await startLoading(dispatch, 'scoring:model:save', () => api.putScoringModel({ modelData, modelId }))

      commit('SET_SCORING_MODEL', { model })
    },
    async loadScoringModel ({ dispatch, commit, state }, { modelId }) {
      if (modelId === state.model?.id) return
      const { data: model } = await startLoading(dispatch, 'scoring:model:fetch', () => api.getScoringModel({ modelId }))

      commit('SET_SCORING_MODEL', { model })
    },
    async updateScoringModelChecks ({ dispatch, commit }, { checkParams, checkId }) {
      await startLoading(dispatch, `scoring:model:check:${checkId}:save`, () => api.putScoringModelChecks({ checkParams }))
    }
  },

  mutations: {
    SET_SCORING_MODELS (state, { scoringModels }) {
      state.scoringModels = scoringModels.reduce((res, model) => ({ ...res, [model.id]: model }), {})
    },
    CLEAR_REQUEST_LIST (state) {
      state.requestList = []
      state.requestsPages = 0
    },
    SET_REQUEST_LIST (state, { list, requestsPages }) {
      state.requestList = list
      state.requestsPages = requestsPages
    },
    CLEAR_CURRENT (state) {
      state.currentRequest = null
    },
    SET_CURRENT (state, { request }) {
      state.currentRequest = request
    },
    SET_SCORING_CHECK_PARAMS (state, { checkParams }) {
      state.checkParams = checkParams
    },
    SET_SCORING_MODEL (state, { model }) {
      state.model = model
    },
    SET_SCORING_CHECK_RESULT (state, { check }) {
      state.currentRequest.scoringResponseModel = {
        ...state.currentRequest.scoringResponseModel,
        [check.key]: check
      }
    }
  }
}
