import * as SockJS from 'sockjs-client'
import { Client } from '@stomp/stompjs'

export class WebsocketListener {
  constructor ({ brokerUrl, subscribeTo, headers = {} } = {}) {
    const token = sessionStorage.getItem('token')
    this.subscribeTo = subscribeTo
    this.stompClient = new Client({
      webSocketFactory: () => new SockJS(brokerUrl),
      onWebSocketError (event) {
        this.stompClient.deactivate()
      },
      onStompError (event) {
        this.stompClient.deactivate()
      },
      connectHeaders: {
        'Content-type': 'application/json',
        'X-Authorization': 'token',
        Authorization: `Bearer ${token}`,
        ...headers
      }
    })
  }

  listen (cb) {
    this.stompClient.onConnect = () => {
      this.stompClient.subscribe(this.subscribeTo, cb)
    }

    this.stompClient.activate()
  }

  close () {
    return this.stompClient.deactivate()
  }
}
