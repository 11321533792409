import { connection } from '@/api/connection'

export default {
  postDataFile ({ file }) {
    const formData = new FormData()
    formData.append('file', file)
    return connection.post('bo/migration', formData)
  },
  getMigrationStatus () {
    return connection.get('bo/migration')
  },
  postDocumentMigration: () => connection.post('bo/migration/documents')
}
