<template lang='pug'>
.reports-quicksight(ref = 'container')
</template>


<script>
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'
import { mapActions } from 'vuex'

export default {
  name: 'reports-quicksight',

  data: () => ({
    dashboard: null
  }),

  async mounted () {
    this.dashboard = embedDashboard({
      container: this.$refs.container,
      url: await this.loadUrl(),
      height: 'AutoFit',
      loadingHeight: '700px',
      scrolling: 'auto'
    })
  },

  methods: mapActions('quickSight', ['loadUrl'])
}
</script>
