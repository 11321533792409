import { connection } from '@/api/connection'

const controllerRoot = '/bo/password-reset'

export default {
  postResetRequest (login) {
    return connection.post(`${controllerRoot}/request`, { login })
  },
  postReset ({ token, newPassword }) {
    return connection.post(`${controllerRoot}/reset`, { token, newPassword })
  }
}
