import { connection } from './connection'
import { pathBuilder } from '@/helpers'

const controllerPath = ({
  productId
} = {}) => pathBuilder`bo/products/credit${productId}`

export default {
  getProducts () {
    return connection.get(controllerPath())
  },

  getProduct ({ id }) {
    return connection.get(controllerPath({ productId: id }))
  },

  postProduct ({ productData }) {
    if (productData.productInterestDefinitionDtos) {
      if (!productData.productInterestDefinitionDtos.length) {
        delete productData.productInterestDefinitionDtos
      }
    }
    return connection.post(controllerPath(), productData)
  },

  putProduct ({ id, productData }) {
    return connection.put(controllerPath({ productId: id }), productData)
  }
}
