import { browserDownload, connection } from './connection'
import pickBy from 'lodash/pickBy'
import qs from 'qs'

export default {
  getScoreRequest: ({ requestId, type }) => connection.get(`scoring/${type}/requests/${requestId}`),
  getRequests: ({ filters }) => connection.get('scoring/requests', {
    params: pickBy(filters)
  }),
  postRequest: ({ modelId, requestData, type }) => connection.post(`scoring/${type}/requests`, requestData, { params: { modelId } }),
  postRequestContinue: ({ requestId, modelId, type }) => connection.post(`scoring/${type}/requests/${requestId}/continue`, null, { params: { modelId } }),
  getExternalResponseReport ({ data, filename }) {
    browserDownload(data, filename)
  },
  getScoringChecks: ({ modelType, countryId }) => connection.get('scoring/checks', {
    params: {
      modelType,
      countryId
    }
  }),
  getScoringCheckParams: ({ ids }) => connection.get('scoring/checks/params', {
    params: {
      ids
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
  }),
  getScoringModels: () => connection.get('scoring/models'),
  postScoringModel: ({ modelData }) => connection.post('scoring/models', modelData),
  putScoringModel: ({ modelData, modelId }) => connection.put(`scoring/models/${modelId}`, modelData),
  getScoringModel: ({ modelId }) => connection.get(`scoring/models/${modelId}`),
  putScoringModelChecks: ({ checkParams }) => connection.put('scoring/checks/params', checkParams)
}
