<template lang='pug'>
b-tabs(pills
        lazy)
  b-tab(v-for  = 'report in reportCategory.reports'
        v-if   = 'report.component'
        :key   = 'report.name'
        :title = '$t(report.name)')
    component(:is         = 'report.component'
              :report     = 'report'
              @run-report = 'executeReport')
</template>

<script>
export default {
  name: 'report-tab',

  props: {
    reportCategory: {
      type: Object,
      required: true
    }
  },

  methods: {
    executeReport ({ reportAction, reportName, data }) {
      if (reportName) {
        this.$store.dispatch(`reports/${reportAction}`, { reportName, data })
      } else {
        this.$store.dispatch(`reports/${reportAction}`, data)
      }
    }
  }
}
</script>
