<template lang="pug">
.card.payment-outgoing-view.animated.fadeIn
  nav.card-header
    b-nav(card-header tabs)
      b-nav-item(:to = '{ name: "incomingPaymentsList" }') {{ $t('incoming') }}
      b-nav-item(:to = '{ name: "outgoingPaymentsList" }') {{ $t('outgoing') }}
      b-nav-text.ml-auto
        span.toolbar
          button.btn.btn-link.py-0(
            :title         = '$t("download")'
            @click.prevent = 'downloadPaymentsXml'
          )
            i.fa-download.fa
          button.btn.btn-link.py-0(
            :title         = '$t("paymentUpdate")'
            @click.prevent = 'loadManualUpdate({ searchQuery })'
          )
            i.fa-sync-alt.fas(:class='{ "fa-spin":isUpdating }')
  .card-body
    form.form-horizontal
      .row
        .form-group.col-lg-4
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-search
            input.form-control(
              v-model.trim = 'searchParams.searchQuery'
              type         = 'text'
              :placeholder = '$t("searchPh")'
            )
        .form-group.col-lg-4
          range-input-text(
            :title       = '$t("amount")'
            :start.sync  = 'searchParams.amountMin'
            :end.sync    = 'searchParams.amountMax'
          )
        .form-group.col-lg-4
          range-input-date(
            :title      = '$t("paymentDate")'
            :start.sync = 'searchParams.paymentDateAfter'
            :end.sync   = 'searchParams.paymentDateBefore'
          )
      .row
        .form-group.col-lg-4
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-bank
            .input-group-prepend
              fi-select.bg-light(
                v-model.number = 'managerColumn'
                :options       = 'managerColumnOptions'
                required
              )
            fi-select(
              v-model.number = 'searchParams[managerColumn]'
              :options       = 'optionsFromManagers'
              :placeholder   = '$t("managerPh")'
            )
        .form-group.col-lg-4
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-bank
            fi-select(
              v-model.number = 'searchParams.handlerName'
              :options       = 'optionsFromHandlers'
              :placeholder   = '$t("handlerPh")'
            )
        .form-group.col-lg-4
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-flag
            fi-select(
              v-model.number = 'searchParams.outgoingPaymentStatus'
              :options       = 'statusOptions'
              :placeholder   = '$t("statusPh")'
            )

    fi-table.payment-list(
      :fields      = 'fields'
      :items       = 'payments'
      :empty-label = '$t("notFound")'
      :sort.sync   = 'searchParams.sort'
      loader       = 'payments:fetch'
      responsive
    )
      template(#selectAll)
        b-check.animated.fadeIn(
          :checked       = 'allSelected'
          :indeterminate = 'indeterminate'
          @change        = 'toggleSelectAll'
        )
      template(#custom-row)
        tr.animated.fadeIn(v-if = 'selectedItems.length')
          td(:colspan = 'fields.length')
            .btn-toolbar
              .btn-group
                .input-group.input-group-sm
                  fi-select(
                    v-model      = 'newStatus'
                    :placeholder = '$t("selectStatusPh")'
                    :options     = 'availableStatusesOptions'
                    required
                    sm
                  )
                  .input-group-append
                    button.btn.btn-primary(
                      :disabled = '!newStatus || setStatusLoader'
                      @click    = 'setPaymentsStatus'
                    ) {{ $t('change') }}
                      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'setStatusLoader')
      template(#default='{ items }')
        payment-outgoing-list-item(
          v-for    = 'payment in items'
          v-model  = 'selectedItems'
          :key     = 'payment.id'
          :payment = 'payment'
          is-selectable
        )

    fi-pagination(
      v-if    = 'paymentsPages > 1'
      :pages  = 'paymentsPages'
      v-model = 'pagination.page'
    )
  payment-outgoing-sheet-list-modal(v-model = 'showSheetModal')
</template>


<script>
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import PaymentOutgoingListItem from '@/views/payments/PaymentOutgoingListItem'
import PaymentOutgoingSheetListModal from '@/views/payments/PaymentOutgoingSheetListModal'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiPagination from '@/components/FiPagination'
import PaymentNewModal from './PaymentNewModal'
import intersection from 'lodash/intersection'
import filter from 'lodash/filter'
import pick from 'lodash/pick'
import { pagination } from '@/const'
import pickBy from 'lodash/pickBy'

const searchParams = {
  createdBy: '',
  confirmedBy: '',
  payableAfterTimeBefore: null,
  payableAfterTimeAfter: null,
  searchQuery: null,
  paymentDateBefore: null,
  paymentDateAfter: null,
  amountMin: null,
  amountMax: null,
  outgoingPaymentStatus: '',
  handlerName: '',
  sort: 'id,desc'
}

export default {
  name: 'payment-outgoing-list',

  components: {
    PaymentOutgoingSheetListModal,
    FiSelect,
    RangeInputText,
    RangeInputDate,
    FiTable,
    PaymentOutgoingListItem,
    PaymentNewModal,
    FiPagination
  },

  props: {
    criteria: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    selectedColumn: 'amount',
    managerColumn: 'createdBy',
    newStatus: '',
    selectedItems: [],
    showSheetModal: false,
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)), searchQuery: vm.criteria },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('payments', ['payments', 'paymentsPages']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromHandlers', 'optionsFromManagers']),
    fields () {
      return [
        {
          key: 'selectAll'
        },
        {
          key: 'id',
          label: 'ID',
          sort: 'id'
        },
        {
          key: 'paymentDate',
          label: this.$t('paymentDate'),
          sort: 'paymentDate'
        },
        {
          key: 'createdBy',
          label: this.$t('createdBy')
        },
        {
          key: 'triggeredBy',
          label: this.$t('triggeredBy')
        },
        {
          key: 'confirmedBy',
          label: this.$t('confirmedBy')
        },
        {
          key: 'account',
          label: this.$t('account'),
          sort: 'toAccount'
        },
        {
          key: 'description',
          label: this.$t('description'),
          sort: 'description'
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money',
          sort: 'amount'
        },
        {
          key: 'handler',
          label: this.$t('handler'),
          sort: 'handlerName'
        },
        {
          key: 'payableAfter',
          label: this.$t('payableAfter'),
          sort: 'payableAfterTime'
        },
        {
          key: 'customer',
          label: this.$t('customer')
        },
        {
          key: 'status',
          label: this.$t('status'),
          sort: 'status'
        }
      ]
    },
    allSelected () {
      return this.selectedItems?.length && this.selectedItems?.length === this.itemIds?.length
    },
    indeterminate () {
      const { length } = this.selectedItems
      return Boolean(length) && length < this.itemIds?.length
    },
    itemIds () {
      return this.payments.map(({ id }) => id) || []
    },
    setStatusLoader () {
      return this.$vueLoading.isLoading('payments:outgoing:status:save')
    },
    statusOptions () {
      return this.optionsFromClassifier('outgoingPaymentStatuses', true)
    },
    availableStatuses () {
      return intersection(...filter(this.payments, ({ id }) => this.selectedItems.includes(id)).map(({ availableStatuses }) => availableStatuses || []))
    },
    availableStatusesOptions () {
      return this.statusOptions.filter(({ value }) => this.availableStatuses.includes(value))
    },
    managerColumnOptions () {
      return [
        {
          value: 'createdBy',
          text: this.$t('createdBy')
        },
        {
          value: 'confirmedBy',
          text: this.$t('confirmedBy')
        }
      ]
    },
    isUpdating () {
      return this.$vueLoading.isLoading('payments:outgoing:sync')
    },
    searchQuery () {
      return pickBy({ ...this.searchParams, ...this.pagination })
    }
  },

  watch: {
    type: 'search',
    'pagination.page': 'search',
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    managerColumn () {
      const fields = this.managerColumnOptions.map(({ value }) => value)
      fields.forEach(field => {
        this.searchParams[field] = ''
      })
    },
    showSheetModal (value) {
      if (value) {
        this.loadPaymentHandlersManual()
      } else {
        this.loadPaymentHandlers()
      }
    },
    '$route.query': {
      handler (query) {
        this.loadOutgoingPayments({ filters: query })
      },
      deep: true
    }
  },

  created () {
    this.loadPaymentHandlers()
    this.loadOutgoingPayments({ filters: this.searchQuery })
  },

  methods: {
    ...mapActions('payments', ['loadOutgoingPayments', 'updateOutgoingPaymentsStatus', 'loadManualUpdate']),
    ...mapActions('classifiers', ['loadPaymentHandlers', 'loadPaymentHandlersManual']),
    search: debounce(
      function () {
        this.selectedItems = []
        this.newStatus = ''
        this.$router.push({ query: this.searchQuery })
      }, 250, {
        leading: true
      }
    ),
    toggleSelectAll (state) {
      if (state) {
        this.selectedItems = this.itemIds
      } else {
        this.selectedItems = []
      }
    },
    downloadPaymentsXml () {
      this.showSheetModal = !this.showSheetModal
    },
    async setPaymentsStatus () {
      await this.updateOutgoingPaymentsStatus({ paymentIds: this.selectedItems, status: this.newStatus })
      this.search()
    }
  }
}
</script>


<i18n>
en:
  incoming:       "Incoming"
  outgoing:       "Outgoing"
  confirm:        "Confirm"
  approve:        "Approve..."
  approveTooltip: "Start approving process"
  paymentDate:    "Payment Date"
  createdBy:      "Created by"
  triggeredBy:    "Triggered by"
  confirmedBy:    "Confirmed by"
  account:        "Account"
  description:    "Description"
  amount:         "Amount"
  handler:        "Handler"
  payableAfter:   "Payable after"
  customer:       "Customer"
  status:         "Status"
  notFound:       "Payments not found"
  searchPh:       "Search by customer name, customer account, description"
  statusPh:       "All statuses"
  handlerPh:      "All handlers"
  managerPh:      "All managers"
  selectStatusPh: "Set status to..."
  download:       "Export sheets"
  paymentUpdate:  "Payment update"
  change:         "Change"
et:
  incoming:       "Incoming"
  outgoing:       "Outgoing"
  confirm:        "Confirm"
  approve:        "Approve..."
  approveTooltip: "Start approving process"
  paymentDate:    "Payment Date"
  createdBy:      "Created by"
  triggeredBy:    "Triggered by"
  confirmedBy:    "Confirmed by"
  account:        "Account"
  description:    "Description"
  amount:         "Amount"
  handler:        "Handler"
  payableAfter:   "Payable after"
  customer:       "Customer"
  status:         "Status"
  notFound:       "Payments not found"
  searchPh:       "Search by customer name, customer account, description"
  statusPh:       "All statuses"
  handlerPh:      "All handlers"
  managerPh:      "All managers"
  selectStatusPh: "Set status to..."
  download:       "Export sheets"
  paymentUpdate:  "Payment update"
  change:         "Change"
ru:
  incoming:       "Incoming"
  outgoing:       "Outgoing"
  confirm:        "Confirm"
  approve:        "Approve..."
  approveTooltip: "Start approving process"
  paymentDate:    "Payment Date"
  createdBy:      "Created by"
  triggeredBy:    "Triggered by"
  confirmedBy:    "Confirmed by"
  account:        "Account"
  description:    "Description"
  amount:         "Amount"
  handler:        "Handler"
  payableAfter:   "Payable after"
  customer:       "Customer"
  status:         "Status"
  notFound:       "Payments not found"
  searchPh:       "Search by customer name, customer account, description"
  statusPh:       "All statuses"
  handlerPh:      "All handlers"
  managerPh:      "All managers"
  selectStatusPh: "Set status to..."
  download:       "Export sheets"
  paymentUpdate:  "Payment update"
  change:         "Change"
</i18n>
