import { connection } from '@/api/connection'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export default {
  getFactoringProductClassifiers: () => connection.get('bo/products/factoring/classifiers'),

  getFactoringProduct: ({ id }) => connection.get(`bo/products/factoring/${id}`),

  postFactoringProduct: ({ productData }) => connection.post('bo/products/factoring', omitBy(productData, isNil)),

  putFactoringProduct: ({ id, productData }) => connection.put(`bo/products/factoring/${id}`, omitBy(productData, isNil))
}
