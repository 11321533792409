import { required } from 'vuelidate/lib/validators'

export default {
  limit: {
    required
  },
  interestRate: {
    required
  },
  penaltyRate: {
    required
  },
  periodStart: {
    required
  },
  periodEnd: {
    required
  },
  fees: {
    $each: {
      date: {},
      feeType: {
        classifier: {},
        code: {
          required
        }
      },
      feeAmount: {
        required
      },
      feeCalculationType: {
        classifier: {},
        code: {
          required
        }
      }
    }
  }
}
