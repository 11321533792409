<template lang='pug'>
.notification-list.card
  .card-header {{ $t('title') }}
    span.fa-pull-right
      button.btn.btn-link.ml-auto.py-0(@click.prevent = 'onDayChange') {{ $t('dayChange') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("payment:daychange:fetch")')
  .card-body
    .table-responsive
      table.table
        thead: tr
          th {{ $t('day') }}
          th {{ $t('status') }}
          th {{ $t('message') }}
          th {{ $t('started') }}
          th {{ $t('finished') }}
          th {{ $t('duration') }}
          th {{ $t('severity') }}
        tbody
          tr(v-if = '$vueLoading.isLoading("notifications:fetch")'): td.text-center(colspan = '2'): i.fa.fa-spinner.fa-pulse.fa-lg
          tr(v-else-if = 'systemNotifications.length === 0'): td(colspan = '2').text-center {{ $t('notFound') }}
          template(v-else)
            notification-list-item(v-for         = '(notification, index) in systemNotifications'
                                   :notification = 'notification'
                                   :key          = 'index')
  fi-modal.fi-confirm-modal(
    v-model           = 'showConfirmModal'
    ref               = 'modal'
    header-bg-variant = 'warning'
    :title            = '$t("modalTitle")'
    centered
    @hidden           = 'showConfirmModal = false'
    @close            = 'showConfirmModal = false'
  )
    p.text-dark.mb-0 {{$t('failedDayChange')}}
    template(#modal-footer)
      button.btn.btn-warning(
        @click.prevent.stop = 'showConfirmModal = false'
      ) {{ $t('common:Ok') }}
</template>


<script>
import { mapActions, mapState } from 'vuex'
import NotificationListItem from '@/views/notifications/NotificationListItem'
import FiModal from '@/components/FiModal'

export default {
  name: 'notification-list',

  components: { NotificationListItem, FiModal },
  data: () => ({
    showConfirmModal: false
  }),

  computed: mapState('notifications', ['systemNotifications']),

  created () {
    this.loadSystemNotifications()
  },

  methods: {
    ...mapActions('notifications', ['loadSystemNotifications']),
    ...mapActions('payments', ['doDayChange']),
    async onDayChange () {
      const response = await this.doDayChange()
      if (response?.data?.body) {
        this.$toasted.error(response.data.body)
      } else {
        this.$toasted.success(this.$t('successDayChange'))
      }
    }
  }
}
</script>

<i18n>
en:
  title:            "System notifications"
  message:          "Message"
  severity:         "Severity"
  notFound:         "Notifications not found"
  dayChange:        "Execute daychange"
  successDayChange: "Daychange execution started"
  failedDayChange:  "Daychange not allowed to execute at this time"
  day:              "Day"
  started:          "Started"
  finished:         "Finished"
  duration:         "Duration"
  status:           "Status"
  modalTitle:       "Daychange cannot be executed"
et:
  title:            "System notifications"
  message:          "Message"
  severity:         "Severity"
  notFound:         "Notifications not found"
  dayChange:        "Execute daychange"
  successDayChange: "Daychange execution started"
  failedDayChange:  "Daychange not allowed to execute at this time"
  day:              "Day"
  started:          "Started"
  finished:         "Finished"
  duration:         "Duration"
  status:           "Status"
  modalTitle:       "Daychange cannot be executed"
ru:
  title:            "System notifications"
  message:          "Message"
  severity:         "Severity"
  notFound:         "Notifications not found"
  dayChange:        "Execute daychange"
  successDayChange: "Daychange execution started"
  failedDayChange:  "Daychange not allowed to execute at this time"
  day:              "Day"
  started:          "Started"
  finished:         "Finished"
  duration:         "Duration"
  status:           "Status"
  modalTitle:       "Daychange cannot be executed"
</i18n>
