<template lang='pug'>
.fi-table(:class = '{ "table-responsive": responsive }')
  table.table.table-hover(:class = '{ "table-fixed": fixed }')
    thead: tr
      th(
        v-for  = 'field in fields'
        :key   = 'field.key'
        :class = 'fieldClasses(field)'
        @click = 'onClick(field)'
      )
        slot(:name = 'field.key' :items="items" ) {{ field.label }}
    draggable(
      v-model    = 'draggedItems'
      tag        = 'tbody'
      :disabled  = '!draggable'
      :class     = 'tbodyClass'
      :draggable = 'draggableClass'
    )
      template(#header)
        slot(name = 'custom-row')
      tr(v-if = '$vueLoading.isLoading(loader)'): td.text-center(:colspan = 'fields.length'): i.fa.fa-spinner.fa-pulse.fa-lg
      tr(v-else-if = 'items.length === 0'): td.text-center(:colspan = 'fields.length') {{ emptyLabel }}
      template(v-else-if = 'groupedItems')
        template(v-for = '(values, key) in groupedItems')
          tr.table-active: td(:colspan = 'fields.length') {{ key }}
          slot(:items = 'values')
      slot(
        v-else
        :items = 'items'
      )
        tr(
          v-for = 'row in rows'
          :key  = 'row.id'
        )
          td(
            v-for = '(field, index) in row'
            :key  = 'index'
          ) {{ field }}
    tfoot
      slot(name = 'custom-footer')
</template>


<script>
import groupBy from 'lodash/groupBy'
import pick from 'lodash/pick'
import draggable from 'vuedraggable'

const SORT_ORDER = {
  DESC: 'desc',
  ASC: 'asc'
}

export default {
  name: 'fi-table',

  components: { draggable },

  props: {
    fields: {
      type: Array,
      required: true
    },
    items: {
      type: [Array, Object],
      default: null
    },
    emptyLabel: {
      type: String,
      default: ''
    },
    loader: {
      type: String,
      default: null
    },
    tbodyClass: {
      type: [Object, String, Array],
      default: undefined
    },
    responsive: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: [Function, String],
      default: null
    },
    sort: {
      type: [String, Array],
      default: ''
    },
    fixed: {
      type: Boolean,
      default: false
    },
    draggable: Boolean,
    draggableClass: {
      type: String,
      default: ''
    }
  },

  data () {
    if (Array.isArray(this.sort)) {
      return {
        sortBy: '',
        sortOrder: SORT_ORDER.DESC
      }
    }

    const [sortBy, sortOrder] = this.sort.split(',')
    return {
      sortBy,
      sortOrder
    }
  },

  computed: {
    rows () {
      const items = this.items
      const fieldKeys = this.fields.map(({ key }) => key)
      return items.map(item => pick(item, fieldKeys))
    },
    draggedItems: {
      get () {
        return this.items
      },
      set (items) {
        this.$emit('drag', items)
      }
    },
    groupedItems () {
      return this.groupBy && groupBy(this.items, this.groupBy)
    }
  },

  methods: {
    fieldClasses (field) {
      return [
        ...(field.class || '').split(','),
        {
          sortable: !!field.sort,
          sorting_asc: this.sortBy === field.key && this.sortOrder === SORT_ORDER.ASC,
          sorting_desc: this.sortBy === field.key && this.sortOrder === SORT_ORDER.DESC
        }
      ]
    },
    onClick (field) {
      if (!field.sort) return
      if (field.key === this.sortBy) {
        if (this.sortOrder === SORT_ORDER.ASC) {
          this.sortOrder = SORT_ORDER.DESC
        } else {
          this.sortOrder = SORT_ORDER.ASC
        }
      } else {
        this.sortBy = field.key
        this.sortOrder = SORT_ORDER.DESC
      }
      if (Array.isArray(field.sort)) {
        this.$emit('update:sort', field.sort.map(sort => `${sort},${this.sortOrder}`))
      } else {
        this.$emit('update:sort', `${field.sort},${this.sortOrder}`)
      }
    }
  }
}
</script>


<style lang='scss' scoped>
  /* Sort styling */
  .table {
    margin-bottom: unset;
  }
  table > thead > tr > th {
    vertical-align: middle;
    position: relative;
    white-space: nowrap;
  }
  table > thead > tr > th.sortable {
    padding-right: 1.5em;
    cursor: pointer;
  }

  table > thead > tr > th.sortable::before,
  table > thead > tr > th.sortable::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    opacity: 0.2;
    font-size: inherit;
    line-height: 180%;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
  }
  table > thead > tr > th.sortable::after {
    left: 0;
    content: '\f0de';
  }
  table > thead > tr > th.sortable::before {
    left: 0;
    content: '\f0dd';
  }
  table > thead > tr > th.sorting_asc::after,
  table > thead > tr > th.sorting_desc::before {
    opacity: 1;
  }

  .table-fixed {
    table-layout: fixed;
  }
  .white-space {
    white-space: break-spaces;
  }
</style>
