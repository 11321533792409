import { connection } from './connection'

export default {
  getMessagesNew () {
    return connection.get('bo/messages/notifications')
  },
  markAsRead ({ messageIds }) {
    return connection.patch('bo/messages/read', messageIds)
  },
  markAsUnread ({ messageIds }) {
    return connection.patch('bo/messages/unread', messageIds)
  },
  postMessageNewOwner ({ messageId, customerId }) {
    return connection.post(`bo/messages/${messageId}/newowner`, {
      customerId
    })
  }
}
