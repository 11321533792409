import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    auditHistory: [],
    auditHistoryPages: 0,
    loginHistory: [],
    loginHistoryPages: 0
  },

  actions: {
    async loadAuditHistory ({ commit, dispatch }, { filters } = {}) {
      commit('SET_AUDIT_HISTORY', { auditHistory: [], auditHistoryPages: 0 })

      const response = await startLoading(dispatch, 'audit:history:fetch', () => api.getAuditHistory({ filters }))

      commit('SET_AUDIT_HISTORY', { auditHistory: response.data.content, auditHistoryPages: response.data.totalPages })
    },
    async loadLoginHistory ({ commit, dispatch }, { filters } = {}) {
      commit('SET_LOGIN_HISTORY', { loginHistory: [], loginHistoryPages: 0 })

      const { data } = await startLoading(dispatch, 'login:history:fetch', () => api.getLoginHistory({ filters }))

      commit('SET_LOGIN_HISTORY', { loginHistory: data.content, loginHistoryPages: data.totalPages })
    }
  },

  mutations: {
    SET_AUDIT_HISTORY (state, { auditHistory, auditHistoryPages }) {
      state.auditHistory = auditHistory
      state.auditHistoryPages = auditHistoryPages
    },
    SET_LOGIN_HISTORY (state, { loginHistory, loginHistoryPages }) {
      state.loginHistory = loginHistory
      state.loginHistoryPages = loginHistoryPages
    }
  }

}
