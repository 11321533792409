<template lang='pug'>
b-modal.payment-new-modal(ref     = 'modal'
                           v-model = 'showModal'
                           size    = 'xl'
                           lazy
                           @shown  = 'resetData'
                           @hide   = 'onHide')
  template(#modal-header = '{ close }')
    .row.w-100
      .col
        h5 {{ $t('title') }}
      .col.ml-auto
        form.form-horizontal(@submit.prevent = 'onSubmit')
          fi-form-field.mb-0(
            :label       = '$t("uploadPayments")'
            :label-cols = 4
          )
            fi-file-input(
              v-model = 'paymentData.files'
              multiple
            )
      .col-auto
        button.close(@click = 'close') ×
  fi-table(
    :fields      = 'fields'
    :items       = 'paymentFiles'
    :empty-label = '$t("notFound")'
    loader       = 'payments:incoming:files:fetch'
  )
    template(#default = '{ items }')
      tr(v-for = 'file in items')
        td.text-nowrap {{ $t('common:formatDate', { value: file.paymentDate }) }}
        td {{ file.payments }}
        td {{ file.document.fileName || file.document.s3File }}
        td
          button.btn.btn-link.text-danger.btn-sm(
            @click = 'removePaymentFile({ id: file.id })'
          )
            i.fa.fa-spinner.fa-pulse.fa-lg.fa-fw(v-if = '$vueLoading.isLoading(`payments:incoming:file:${file.id}:remove`)')
            i.far.fa-trash-alt.fa-fw.fa-lg(v-else)
  template(#modal-footer)
    button.btn.btn-primary(:disabled      = 'saving'
                           @click.prevent = 'onSubmit')
      | {{ $t("upload") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(:disabled      = 'saving'
                             @click.prevent = '$refs.modal.hide()') {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiFileInput from '@/components/FiFileInput'
import FiModal from '@/components/FiModal'
import { mapActions, mapState } from 'vuex'
import FiTable from '@/components/FiTable'

const FIELDS = ['paymentDate', 'payments', 'file', '']

export default {
  name: 'payment-new-modal',

  components: {
    FiTable,
    FiFileInput,
    FiFormField,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data: () => ({
    paymentData: {
      files: null
    }
  }),

  computed: {
    ...mapState('payments', ['paymentFiles']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('payment:save')
    },
    fields () {
      return FIELDS.map(field => (field ? {
        key: field,
        label: this.$t(field)
      } : {}))
    }
  },

  methods: {
    ...mapActions('payments', ['createPayment', 'loadPaymentFiles', 'removePaymentFile']),
    async onSubmit () {
      await this.createPayment(this.paymentData)
      this.resetData()
    },
    onHide (e) {
      if (this.saving) {
        e.preventDefault()
      }
    },
    resetData () {
      this.loadPaymentFiles()
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>


<i18n>
en:
  title:          "Import sheets"
  uploadPayments: "Upload payments"
  paymentDate:    "Payment date"
  payments:       "Payments"
  file:           "File"
  notFound:       "Payment sheets not found"
  upload:         "Upload"
et:
  title:          "Import sheets"
  uploadPayments: "Upload payments"
  paymentDate:    "Payment date"
  payments:       "Payments"
  file:           "File"
  notFound:       "Payment sheets not found"
  upload:         "Upload"
ru:
  title:          "Импорт платежей"
  uploadPayments: "Загрузить платежи"
  paymentDate:    "Payment date"
  payments:       "Payments"
  file:           "File"
  notFound:       "Payment sheets not found"
  upload:         "Upload"
</i18n>
