import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  accountExternalId: {},
  idCode: {
    required
  },
  phoneNumber: {
    required
  },
  deskPhoneNumber: {},
  emails: {
    required,
    minLength: minLength(1),
    $each: {
      value: {
        required,
        email
      }
    }
  },
  email: {
    required,
    email
  },
  phoneNumbers: {
    required,
    minLength: minLength(1)
  },
  deskPhoneNumbers: {},
  firstName: {
    required
  },
  lastName: {
    required
  },
  idDocId: {
    required
  },
  idDocNumber: {
    required
  },
  countyId: {
    required
  },
  countryId: {
    required
  },
  city: {
    required
  },
  fullAddress: {
    required
  },
  address: {
    required
  },
  postalCode: {
    required
  },
  customerName: {
    required
  },
  monthlyIncome: {
    required
  },
  monthlyObligations: {
    required
  },
  employmentLength: {
    required
  },
  sectorId: {
    required
  },
  segmentId: {
    required
  },
  customerId: {}
}
