import { connection } from './connection'

const controllerRoot = 'bo/settings'

export default {
  getSettings () {
    return connection.get(controllerRoot)
  },

  putSettings ({ data }) {
    return connection.put(controllerRoot, data)
  },

  postSettingFile ({ key, file }) {
    const formData = new FormData()
    formData.append('file', file)
    return connection.post(`${controllerRoot}/${key}/file`, formData)
  }
}
