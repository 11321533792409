import ProductDeposit from '@/models/products/ProductDeposit'
import ProductLoanBase from '@/models/products/ProductLoanBase'
import ProductLeasing from '@/models/products/ProductLeasing'
import ProductOverdraft from '@/models/products/ProductOverdraft'
import merge from 'lodash/merge'
import { validators } from '@/validation'
import ProductFactoring from '@/models/products/ProductFactoring'

export const MAX_CODE_LENGTH = 30

const BaseProduct = (data = {}) => ({
  generalData: {
    active: Boolean(data?.active),
    availableInPortal: Boolean(data?.availableInPortal),
    showContractsInPortal: Boolean(data?.showContractsInPortal),
    code: data?.code,
    group: data?.group,
    name: data?.name,
    productType: data?.productType || '',
    creditIssuerId: data?.creditIssuerId,
    segments: data?.segments,
    sectorId: data.sectorId,
    externalBankAccounts: data?.externalBankAccounts ?? []
  },
  customFields: data?.customFields ?? [],
  attributesData: {
    productInterestDefinitionDtos: data?.productInterestDefinitionDtos
  },
  templatesData: {},
  scoringData: {},
  paymentsData: {},
  provision: data?.provision ?? {},
  dump () {
    const { generalData, attributesData, templatesData, scoringData, paymentsData, provision, customFields, financialData } = this
    const { fees } = this.attributesData
    return {
      ...generalData,
      ...attributesData,
      ...scoringData,
      ...templatesData,
      ...paymentsData,
      provision,
      customFields,
      financialData,
      fees: [
        ...fees.filter(({ feeType }) => feeType.code)
          .map(fee => ({
            ...fee,
            feeType: fee.feeType.code,
            feeCalculationType: fee.feeCalculationType.code
          }))
      ]
    }
  },
  id: data?.id,
  validations: {
    generalData: {
      code: {
        required: validators.required,
        maxLength: validators.maxLength(MAX_CODE_LENGTH)
      },
      group: {
        required: validators.required
      },
      name: {
        required: validators.required
      },
      creditIssuerId: {
        required: validators.required
      },
      productType: {
        required: validators.required
      },
      sectorId: {
        required: validators.required
      },
      segments: {
        required: validators.required
      },
      externalBankAccounts: {
        required: validators.required
      }
    },
    attributesData: {},
    templatesData: {},
    provision: {},
    scoringData: {},
    customFields: {
      $each: {}
    }
  }
})

const withPayments = (data) => (
  {
    paymentsData: {
      portalOutgoingPaymentsEnabled: Boolean(data.portalOutgoingPaymentsEnabled),
      outgoingPaymentsEnabled: Boolean(data.outgoingPaymentsEnabled),
      outgoingPaymentConformationNeededFromSum: data.outgoingPaymentConformationNeededFromSum,
      outgoingPaymentDelayHours: data.outgoingPaymentDelayHours
    }
  }
)

export default (data, attributeTypes) => {
  const { group } = data || {}
  switch (group) {
    case 'LOAN':
      return merge({}, BaseProduct(data), ProductLoanBase(data), withPayments(data))
    case 'DEPOSIT':
      return merge({}, BaseProduct(data), ProductDeposit(data), withPayments(data))
    case 'LEASING':
      return merge({}, BaseProduct(data), ProductLoanBase(data), ProductLeasing(data), withPayments(data))
    case 'GUARANTEE':
    case 'OVERDRAFT':
      return merge({}, BaseProduct(data), ProductLoanBase(data), ProductOverdraft(data), withPayments(data))
    case 'FACTORING':
      return merge({}, BaseProduct(data), ProductFactoring(data, attributeTypes), withPayments(data))
    default:
      return BaseProduct(data)
  }
}
