<template lang="pug">
fi-modal.messages-new-modal(ref     = 'modal'
                            v-model = 'showModal'
                            :title  = '$t("title")'
                            size    = 'sm'
                            hide-footer
                            lazy)
    ul.list-unstyled.m-0
      dashboard-widget-messages-new-item(v-for    = 'message in dashboardMessagesNew'
                                         :key     = 'message.id'
                                         :message = 'message')
</template>


<script>
import { mapActions, mapState } from 'vuex'
import DashboardWidgetMessagesNewItem from '../dashboard/DashboardWidgetMessagesNewItem'
import FiModal from '@/components/FiModal'

export default {
  name: 'messages-new-modal',

  components: {
    DashboardWidgetMessagesNewItem,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('dashboard', ['dashboardMessagesNew']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  mounted () {
    this.loadDashboardMessagesNew()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardMessagesNew'])
  }
}
</script>


<i18n>
en:
  title:    "All new messages"
et:
  title:    "All new messages"
ru:
  title:    "All new messages"
</i18n>
