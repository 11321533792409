import { connection } from './connection'

export default {
  deleteRelation ({ id }) {
    return connection.delete(`bo/relations/${id}`)
  },

  putRelation ({ id, relationData }) {
    return connection.put(`bo/relations/${id}`, relationData)
  }
}
