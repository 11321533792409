import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    debts: [],
    debtsPages: 0
  },

  actions: {
    async loadDebts ({ dispatch, commit }, { filters }) {
      const response = await startLoading(dispatch, 'debts:fetch', () => api.getDebts({ filters }))

      commit('SET_DEBTS', { debts: response.data.content, debtsPages: response.data.totalPages })
    },
    async changeDebtsStatus ({ dispatch, commit }, { statusId, loanIds }) {
      await startLoading(dispatch, 'debts:status:save', () => api.putLoanStatusesBulk({ loanIds, statusId }))
      dispatch('loadDebts')
    }
  },

  mutations: {
    SET_DEBTS (state, { debts, debtsPages }) {
      state.debts = debts
      state.debtsPages = debtsPages
    }
  }
}
