import { connection, downloadFile } from '@/api/connection'
import qs from 'querystring'

const reportController = (controller, type, method, query) => `reports/${controller}/${method}/${type}?${qs.stringify(query)}`
const financialInspectionReportController = (method, type, query) => `reports/financial-inspection/?reportType=${type}&fileType=${method}&${qs.stringify(query)}`
const reportControllerMMR = (type, method, query) => `reports/${method}/${type}?${qs.stringify(query)}`
const accountingControllerCSV = reportController.bind(null, 'accounting', 'csv')
const accountingControllerJSON = reportController.bind(null, 'accounting', 'json')
const behaviourControllerCSV = reportController.bind(null, 'behaviour', 'csv')
const behaviourControllerJSON = reportController.bind(null, 'behaviour', 'json')
const periodisingControllerCSV = reportController.bind(null, 'periodising', 'csv')
const financeInspectionControllerCSV = financialInspectionReportController.bind(null, 'CSV')
const financeInspectionControllerXML = financialInspectionReportController.bind(null, 'XML')
const MMRControllerXML = reportControllerMMR.bind(null, 'xml')

export default {
  getBalanceSheet (params) {
    return downloadFile(accountingControllerCSV('balances', params))
  },
  getAccountsSheetJSON (params) {
    return connection.get(accountingControllerJSON('accounts', params))
  },
  getLoansSheet (params) {
    return downloadFile(accountingControllerCSV('loans', params))
  },
  getLoansSheetJSON (params) {
    return connection.get(accountingControllerJSON('loans', params))
  },
  getIncomeSheet (params) {
    return downloadFile(accountingControllerCSV('income', params))
  },
  getAccountsSheet (params) {
    return downloadFile(accountingControllerCSV('accounts', params))
  },
  getCurrentSheet (params) {
    return downloadFile(accountingControllerCSV('current', params))
  },
  getPrepaymentTransactionsSheet (params) {
    return downloadFile(accountingControllerCSV('prepayments/all', params))
  },
  getPrepaymentIncomingTransactionsSheet (params) {
    return downloadFile(accountingControllerCSV('prepayments/incoming', params))
  },
  getPledgeeStatement (params) {
    return downloadFile(behaviourControllerCSV('pledgee', params))
  },
  getMortgageStatement (params) {
    return downloadFile(behaviourControllerCSV('mortgage', params))
  },
  getMortgageStatementJSON (params) {
    return connection.get(behaviourControllerJSON('mortgage', params))
  },
  getInDebtStatement (params) {
    return downloadFile(behaviourControllerCSV('debts', params))
  },
  getPeriodisingReport (params) {
    return downloadFile(periodisingControllerCSV('', params))
  },
  getReport ({ reportName, data }) {
    return downloadFile(financeInspectionControllerCSV(reportName, data))
  },
  getReportXML ({ reportName, data }) {
    return downloadFile(financeInspectionControllerXML(reportName, data))
  },
  async getMMRReportXML (params) {
    delete params.creditIssuerId
    return downloadFile(MMRControllerXML('mmr', params))
  },
  getSuccessMMRRanDates () {
    let month = String(new Date().getMonth() + 1)
    let day = String(new Date().getDate())
    if (month.length === 1) {
      month = `0${month}`
    }
    if (day.length === 1) {
      day = `0${day}`
    }
    const startDate = `${new Date().getFullYear() - 1}-${month}-${day}`
    const endDate = `${new Date().getFullYear()}-${month}-${day}`
    return connection.get('reports/mmr/history/dates/success', {
      params: {
        startDate,
        endDate
      }
    })
  },

  postCalculateHistory (params) {
    return connection.post('reports/history', {}, {
      params
    })
  },
  getSuccessfullyRanDates () {
    return connection.get('reports/history/dates/success')
  },
  getCalculateHistory () {
    return connection.get('reports/history')
  }
}
