import { connection } from '@/api/connection'
import { pathBuilder } from '@/helpers'

const { get, put, post } = connection

const controllerPath = ({
  contractId,
  action,
  thirdPartyId
}) => pathBuilder`bo/factoring/contracts${contractId}third-parties${action}${thirdPartyId}`

export default {
  getFactoringThirdParties: ({ contractId }) => get(controllerPath({ contractId })),
  putFactoringThirdParty: ({
    contractId,
    thirdPartyId,
    thirdPartyData
  }) => put(controllerPath({ contractId, thirdPartyId }), thirdPartyData),
  postFactoringThirdParty: ({
    contractId,
    thirdPartyData
  }) => post(controllerPath({ contractId }), thirdPartyData),
  getFactoringThirdPartyClassifiers: ({ contractId }) => get(controllerPath({ contractId, action: 'classifiers' }))
}
