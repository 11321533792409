<template lang='pug'>
b-modal.application-notifications-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'xl'
  lazy
  @shown  = 'initData'
)
  template(#modal-header = '{ close }')
    .col
      h5.m-0 {{ $t('title') }}
    .col-auto.font-weight-bold
      h5.m-0: router-link(:to = '{ name: "Loan", params: { id: debt.loanId } }') {{ debt.agreementNumber }}
    button.close(@click = 'close') ×

  .row
    .col-6.border-right
      fi-data-field(:label = '$t("name")') {{ debt.customer.fullName }}
      fi-data-field(:label = '$t("idCode")') {{ debt.customer.idCode }}
      fi-data-field(:label = '$t("sector")') {{ classifierById('sectors',  debt.customer.sectorId).human }}
      fi-data-field(:label = '$t("phone")') {{ phoneNumber }}
      fi-data-field(:label = '$t("email")') {{ email }}
      fi-data-field(:label = '$t("inDebt")') {{ $t('common:formatDate', { value: debt.inDebtSince }) }}
    .col-6
      fi-data-field(:label = '$t("balance")') {{ debt.balance | money }}
      fi-data-field(:label = '$t("debts")') {{ debt.debts | money }}
      fi-data-field(:label = '$t("principalDebt")') {{ debt.principalDebt | money }}
      fi-data-field(:label = '$t("interestDebt")') {{ debt.interestDebt | money }}
      fi-data-field(:label = '$t("feeDebt")') {{ debt.feeDebt | money }}
      fi-data-field(:label = '$t("penaltyDebt")') {{ debt.penaltyDebt | money }}
      fi-data-field(
        v-if   = 'debt.vatDebt'
        :label = '$t("vatDebt")'
      ) {{ debt.vatDebt | money }}

  hr

  customer-tasks-list(
    :load-tasks     = 'loadDebtComments'
    :tasks          = 'sortedComments'
    :tasks-pages    = 'commentsPages'
    :loader         = 'loader'
    :commentable-id = 'debt.loanId'
    no-filter
  )

  template(#modal-footer)
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiModal from '@/components/FiModal'
import FiTable from '@/components/FiTable'
import CustomerTasksList from '@/views/customers/CustomerTasksList'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'debts-list-item-task-modal',

  components: { CustomerTasksList, FiTable, FiDataField, FiModal },

  provide () {
    return {
      storeModule: 'loans',
      commentableType: 'LOAN_CONTRACT'
    }
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    debt: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('loans', ['loanCustomer', 'commentsPages']),
    ...mapGetters('loans', ['sortedComments']),
    ...mapGetters('classifiers', ['classifierById']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    loader () {
      return 'loans:comments:fetch'
    },
    email () {
      return this.loanCustomer?.emails?.find(({ main }) => main)?.value
    },
    phoneNumber () {
      return this.loanCustomer?.phoneNumbers?.find(({ main }) => main)?.value
    }
  },

  methods: {
    ...mapActions('loans', ['loadLoanCustomer', 'loadComments']),
    loadDebtComments (payload) {
      this.loadComments({ ...payload, id: this.debt.loanId })
    },
    initData () {
      this.loadLoanCustomer({ id: this.debt.customer.id })
    }
  }
}
</script>


<i18n>
en:
  title:         "Active tasks"
  name:          "Name"
  idCode:        "ID Code"
  sector:        "Sector"
  phone:         "Phone"
  email:         "Email"
  inDebt:        "In debt since"
  balance:       "Loan balance"
  debts:         "Total debt"
  principalDebt: "Principal debt"
  interestDebt:  "Interest debt"
  feeDebt:       "Fee debt"
  penaltyDebt:   "Penalty debt"
  vatDebt:       "VAT debt"
et:
  title:         "Active tasks"
  name:          "Name"
  idCode:        "ID Code"
  sector:        "Sector"
  phone:         "Phone"
  email:         "Email"
  inDebt:        "In debt since"
  balance:       "Loan balance"
  debts:         "Total debt"
  principalDebt: "Principal debt"
  interestDebt:  "Interest debt"
  feeDebt:       "Fee debt"
  penaltyDebt:   "Penalty debt"
  vatDebt:       "VAT debt"
ru:
  title:         "Active tasks"
  name:          "Name"
  idCode:        "ID Code"
  sector:        "Sector"
  phone:         "Phone"
  email:         "Email"
  inDebt:        "In debt since"
  balance:       "Loan balance"
  debts:         "Total debt"
  principalDebt: "Principal debt"
  interestDebt:  "Interest debt"
  feeDebt:       "Fee debt"
  penaltyDebt:   "Penalty debt"
  vatDebt:       "VAT debt"
</i18n>
