import { validators } from '@/validation'

const FactoringAttribute = (data = {}) => {
  const attribute = {
    defaultValue: data.defaultValue ?? null,
    maxValue: data.maxValue ?? null,
    minValue: data.minValue ?? null,
    type: data.type.code ?? data.type
  }
  switch (attribute.type) {
    case 'INVOICING_DAY':
      return {
        invoicingDay: data.invoicingDay,
        type: attribute.type
      }
    case 'PAYMENT_TERMS':
      return {
        paymentTerm: data.paymentTerm,
        type: attribute.type
      }
    case 'AGREEMENT_LENGTH':
      return {
        ...attribute,
        periodUnitType: data.periodUnitType?.code ?? data.periodUnitType ?? '',
        changesAllowed: Boolean(data.changesAllowed)
      }
    case 'INTEREST_RATE':
    case 'PENALTY_RATE':
      return {
        ...attribute,
        dayCountConvention: data.dayCountConvention?.code ?? data.dayCountConvention ?? '',
        changesAllowed: Boolean(data.changesAllowed)
      }
    case 'AMOUNT_LIMIT':
      return {
        ...attribute,
        step: data.step,
        changesAllowed: Boolean(data.changesAllowed)
      }
    case 'LOAN_LIMIT_RATE':
      return {
        ...attribute,
        changesAllowed: Boolean(data.changesAllowed)
      }
    case 'INTEREST_INVOICE_AMOUNT_RANGE':
      return {
        minValue: attribute.minValue,
        maxValue: attribute.maxValue,
        type: attribute.type,
        optional: true
      }
    default:
      return attribute
  }
}

export default (data, attributeTypes = []) => ({
  generalData: {
    dayChangeSettlementOrder: data.dayChangeSettlementOrder,
    dayChangeSettlementMethod: data.dayChangeSettlementMethod?.code ?? data.dayChangeSettlementMethod
  },
  attributesData: {
    attributes: attributeTypes.map(attributeType => FactoringAttribute(data.attributes?.find(({ type }) => (type.code ?? type) === attributeType) ?? { type: attributeType })),
    currencyId: data.currencyId,
    fees: data.fees
  },
  financialData: data.financialData ?? [],
  templatesData: {
    agreementTemplateId: data.agreementTemplateId,
    agreementTemplateIdOverride: Boolean(data.agreementTemplateIdOverride),
    informationSheetTemplateId: data.informationSheetTemplateId,
    informationSheetTemplateIdOverride: Boolean(data.informationSheetTemplateIdOverride),
    offerTemplateId: data.offerTemplateId,
    offerTemplateIdOverride: Boolean(data.offerTemplateIdOverride)
  },
  validations: {
    generalData: {
      sectorId: {
        required: validators.required
      },
      dayChangeSettlementOrder: {
        required: validators.required
      },
      dayChangeSettlementMethod: {
        required: validators.required
      }
    },
    financialData: {},
    attributesData: {
      attributes: {
        $each: {
          defaultValue: {
            required: (value, vm) => !('defaultValue' in vm) || Boolean(value),
            min: validators.minValueRef('minValue'),
            max: validators.maxValueRef('maxValue')
          },
          maxValue: {
            required: (value, vm) => !('maxValue' in vm) || vm.optional || Boolean(value),
            min: validators.minValueRef('minValue')
          },
          minValue: {
            required: (value, vm) => !('minValue' in vm) || vm.optional || Boolean(value),
            max: validators.maxValueRef('maxValue')
          },
          type: {
            required: validators.required
          },
          invoicingDay: {
            required: (value, vm) => !('invoicingDay' in vm) || Boolean(value)
          },
          paymentTerm: {
            required: (value, vm) => !('paymentTerm' in vm) || Boolean(value)
          },
          periodUnitType: {
            required: (value, vm) => !('periodUnitType' in vm) || Boolean(value)
          },
          changesAllowed: {},
          dayCountConvention: {
            required: (value, vm) => !('dayCountConvention' in vm) || Boolean(value)
          },
          step: {
            required: (value, vm) => !('step' in vm) || Boolean(value),
            steps: (value, parentVm) => {
              const { minValue, maxValue } = parentVm
              return !validators.helpers.req(value) || Number.isInteger((maxValue - minValue) / value)
            }
          }
        }
      },
      currencyId: {
        required: validators.required
      },
      fees: {
        required: validators.required,
        minLength: validators.minLength(4),
        $each: {
          feeType: {
            classifier: {},
            code: {
              required: validators.required
            }
          },
          feeAmount: {
            required: validators.required
          },
          feeMax: {
            required: validators.requiredUnless(function ({ feeType }) {
              return /AUTO_EXTENSION/i.test(feeType.code)
            }),
            min: validators.minValueRef('feeMin')
          },
          feeMin: {
            required: validators.requiredUnless(function ({ feeType }) {
              return /AUTO_EXTENSION/i.test(feeType.code)
            }),
            max: validators.maxValueRef('feeMax')
          },
          feeCalculationType: {
            classifier: {},
            code: {
              required: validators.required
            }
          },
          feeOverride: {}
        }
      }
    }
  },
  dump () {
    const { fees, attributes } = this.attributesData
    return {
      ...this.generalData,
      ...this.attributesData,
      ...this.scoringData,
      ...this.templatesData,
      ...this.paymentsData,
      fees: [
        ...fees.filter(({ feeType }) => feeType.code)
          .map(fee => ({
            ...fee,
            feeType: fee.feeType.code,
            feeCalculationType: fee.feeCalculationType.code
          }))
      ],
      attributes: attributes?.map(({ optional, ...attribute }) => attribute),
      customFields: this.customFields,
      financialData: this.financialData,
      provision: this.provision
    }
  }
})
