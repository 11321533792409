import { connection } from '@/api/connection'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

const controllerRoot = id => ['bo/overdraft', id].join('/')

export default {
  postOverdraft: ({ overdraftData }) => connection.post(controllerRoot(), overdraftData),
  patchOverdraft: ({ id, overdraftData }) => connection.patch(
    controllerRoot(id), omitBy(overdraftData, isNil),
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }),
  getOverdraftTerms: ({ id }) => connection.get(`${controllerRoot(id)}/terms`),
  getOverdraftTerm: ({ id, termId }) => connection.get(`${controllerRoot(id)}/term`, {
    params: {
      termId
    }
  }),
  putOverdraftTerm: ({ overdraftId, termsData, termId }) => connection.put(`${controllerRoot(overdraftId)}/terms/${termId}`, termsData),
  deleteOverdraftTerm: ({ overdraftId, termId }) => connection.delete(`${controllerRoot(overdraftId)}/terms/${termId}`),
  postOverdraftTermCopy: ({ overdraftId, termId }) => connection.post(`${controllerRoot(overdraftId)}/terms/${termId}/copy`),
  putOverdraftTermActivate: ({ overdraftId, termId, data }) => connection.put(`${controllerRoot(overdraftId)}/terms/${termId}/activate`, data),
  putOverdraftStatus: ({ overdraftId, statusId }) => connection.put(`${controllerRoot(overdraftId)}/status/${statusId}`),
  postOverdraftSign: ({ overdraftId, overdraftData }) => connection.post(`${controllerRoot(overdraftId)}/sign`, overdraftData),
  postOverdraftTerminate: ({ id, data }) => connection.post(`${controllerRoot(id)}/terminate`, data),
  portOverdraftPayout: ({ id, data }) => connection.post(`${controllerRoot(id)}/payout`, data),
  putOverdraftInvoices: ({ id, paymentDate, ...invoiceData }) => connection.put(`${controllerRoot(id)}/invoices/${paymentDate}`, invoiceData)
}
