import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    pledgees: []
  },

  getters: {
    optionsFromPledgees: state => state.pledgees.filter(({ active }) => active)
      .map(({ id, name }) => ({ value: id, text: name })),
    pledgeeById: state => id => state.pledgees.find(item => item.id === id) || {}
  },

  actions: {
    async loadPledgees ({ commit, dispatch }) {
      const { data: pledgees } = await startLoading(dispatch, 'pledgees:fetch', () => api.getPledgees())
      commit('SET_PLEDGEES_LIST', { pledgees })
    },
    async createPledgee ({ commit, dispatch }, { pledgeeData }) {
      const { data: pledgee } = await startLoading(dispatch, 'pledgee:save', () => api.postPledgee({ pledgeeData }))
      commit('ADD_PLEDGEE', { pledgee })
      return pledgee
    }
  },

  mutations: {
    SET_PLEDGEES_LIST (state, { pledgees }) {
      state.pledgees = pledgees
    },
    ADD_PLEDGEE (state, { pledgee }) {
      state.pledgees.push(pledgee)
    }
  }
}
