import { connection, downloadFile } from './connection'

export default {
  getTemplatesList ({ query }) {
    return connection.get('bo/templates', {
      params: query
    })
  },
  getTemplates () {
    return connection.get('bo/templates/all')
  },
  getInvoices ({ scheduleId }) {
    return connection.get(`bo/v2/loans/invoicedates/${scheduleId}`)
  },
  getTemplate ({ id, objectId = null }) {
    return connection.get(`bo/template/${id}`, {
      params: { objectId }
    })
  },
  putTemplate ({ id, templateData }) {
    return connection.put(`bo/template/${id}`, templateData)
  },
  postTemplate ({ templateData }) {
    return connection.post('bo/template', templateData)
  },
  getTemplatePreview ({ id, applicationId, languageId }) {
    return downloadFile(`bo/preview-template/${id}?caId=${applicationId}&languageId=${languageId}`)
  }
}
