import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    calculateHistory: null,
    successDates: [],
    successDatesMMR: [],
    reportsData: {}
  },

  actions: {
    async loadLoansSheetReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getLoansSheet(payload))
    },
    async loadLoansSheetReportJSON ({ dispatch, commit }, payload) {
      const { data } = await startLoading(dispatch, 'reports:json:fetch', () => api.getLoansSheetJSON(payload))
      const serviceFields = [
        'id',
        'loanId',
        'loanAgreementNumber',
        'customerId',
        'customerName',
        'productId',
        'productName',
        'entryDate',
        'periodStart',
        'periodEnd',
        'loanClientType',
        'loanClientLocation',
        'inDebtSince'
      ]

      commit('SET_REPORTS_DATA',
        {
          name: 'loansSheet',
          data: data.map(productData => ({
            ...pick(productData, serviceFields),
            data: omit(productData, serviceFields)
          }))
        })
    },
    async loadBalanceSheetReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getBalanceSheet(payload))
    },
    async loadAccountsSheetReportJSON ({ dispatch, commit }, payload) {
      const { data } = await startLoading(dispatch, 'reports:json:fetch', () => api.getAccountsSheetJSON(payload))
      const serviceFields = [
        'productId',
        'productName',
        'statementEnd',
        'statementStart'
      ]

      commit('SET_REPORTS_DATA',
        {
          name: 'accountsSheet',
          data: data.map(productData => ({
            ...pick(productData, serviceFields),
            data: omit(productData, serviceFields)
          }))
        })
    },
    async loadIncomeSheetReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getIncomeSheet(payload))
    },
    async loadAccountsSheetReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getAccountsSheet(payload))
    },
    async loadCurrentSheetReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getCurrentSheet(payload))
    },
    async loadPrepaymentTransactionsSheet ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getPrepaymentTransactionsSheet(payload))
    },
    async loadPrepaymentIncomingTransactionsSheet ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getPrepaymentIncomingTransactionsSheet(payload))
    },
    async loadPledgeeStatementReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getPledgeeStatement(payload))
    },
    async loadMortgageStatementReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getMortgageStatement(payload))
    },
    async loadMortgageStatementReportJSON ({ dispatch, commit }, payload) {
      const { data } = await startLoading(dispatch, 'reports:json:fetch', () => api.getMortgageStatementJSON(payload))
      const serviceFields = ['productId', 'productName', 'statementEnd', 'statementStart']

      commit('SET_REPORTS_DATA',
        {
          name: 'behaviorMortgage',
          data: data.map(productData => ({
            ...pick(productData, serviceFields),
            data: omit(productData, serviceFields)
          }))
        })
    },
    async loadInDebtStatementReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getInDebtStatement(payload))
    },
    async loadReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getReport(payload))
    },
    async loadPeriodisingReport ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:csv:fetch', () => api.getPeriodisingReport(payload))
    },
    async postCalculateHistory ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:calculate:save', () => api.postCalculateHistory(payload))
    },
    async loadSuccessDates ({ dispatch, commit }) {
      const { data: dates } = await startLoading(dispatch, 'reports:success:dates:fetch', () => api.getSuccessfullyRanDates())

      commit('SET_SUCCESS_DATES', { dates })
    },
    async loadSuccessDatesMMR ({ dispatch, commit }) {
      const { data: dates } = await startLoading(dispatch, 'reports:success:dates:fetch', () => api.getSuccessMMRRanDates())

      commit('SET_SUCCESS_DATES_MMR', { dates })
    },
    async loadCalculateHistory ({ dispatch, commit }) {
      const { data: history } = await startLoading(dispatch, 'reports:calculate:history:fetch', () => api.getCalculateHistory())

      commit('SET_CALCULATE_HISTORY', { history })
    },
    async loadMMRReportXML ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:xml:fetch', () => api.getMMRReportXML(payload))
    },
    async loadReportXML ({ dispatch }, payload) {
      await startLoading(dispatch, 'reports:xml:fetch', () => api.getReportXML(payload))
    }
  },

  mutations: {
    SET_SUCCESS_DATES (state, { dates }) {
      if (dates.length) {
        state.successDates = dates
      } else {
        state.successDates = [new Date('1970-1-1')]
      }
    },
    SET_SUCCESS_DATES_MMR (state, { dates }) {
      if (dates.length) {
        state.successDatesMMR = dates
      } else {
        state.successDatesMMR = [new Date('1970-1-1')]
      }
    },
    SET_CALCULATE_HISTORY (state, { history }) {
      state.calculateHistory = history
    },
    SET_REPORTS_DATA (state, { name, data }) {
      state.reportsData = {
        ...state.reportsData,
        [name]: data
      }
    }
  }
}
