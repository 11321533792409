import { connection } from '@/api/connection'
import { pathBuilder } from '@/helpers'

const { get, post, put, delete: remove } = connection

const controllerPath = ({
  contractId,
  action,
  relationId
}) => pathBuilder`bo/factoring${contractId}payments/relation${action}${relationId}`

export default {
  getFactoringPaymentRelations: ({ contractId }) => get(controllerPath({ contractId })),
  postFactoringPaymentRelation: ({ contractId, relationData }) => post(controllerPath({ contractId }), relationData),
  putFactoringPaymentRelation: ({ contractId, relationId, relationData }) => put(controllerPath({
    contractId,
    relationId
  }), relationData),
  deleteFactoringPaymentRelation: ({ contractId, relationId }) => remove(controllerPath({
    contractId,
    relationId
  })),
  postFactoringPayout: ({ contractId, payoutData }) => post(controllerPath({
    contractId,
    action: 'payout'
  }), payoutData)
}
