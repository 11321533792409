import { connection } from '@/api/connection'

const controllerRoot = 'bo/v2/deposits'

export default {
  getDepositPaymentRelations: ({ contractId }) => connection.get(`${controllerRoot}/${contractId}/payments/relation`),
  postDepositPaymentRelation: ({ contractId, paymentRelationData }) =>
    connection.post(`${controllerRoot}/${contractId}/payments/relation`, paymentRelationData),
  putDepositPaymentRelation: ({ contractId, paymentRelationData, paymentRelationId }) =>
    connection.put(`${controllerRoot}/${contractId}/payments/relation/${paymentRelationId}`, paymentRelationData),
  deleteDepositPaymentRelation: ({ contractId, paymentRelationId }) =>
    connection.delete(`${controllerRoot}/${contractId}/payments/relation/${paymentRelationId}`)
}
