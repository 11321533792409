<template lang="pug">
li.dashboard-widget-application-notifications-item
  router-link.text-body.d-block(:to = '{ name: "Application", params: { id: notification.applicationId } }')
   .row
      .col
        strong {{ notification.customerName }}
        .small {{ $t(notification.type) }}
      .col-auto.small {{ $t('common:formatDate', { value: notification.createdAt }) }}
  hr.mt-2.mb-2
</template>


<script>
export default {
  name: 'dashboard-widget-application-notifications-item',

  props: {
    notification: {
      type: Object,
      required: true
    }
  }
}
</script>

<i18n>
en:
  PENDING:              "Pending applications"
  EXPIRING_OFFER:       "Aging proposals"

et:
  PENDING:              "Pending applications"
  EXPIRING_OFFER:       "Aging proposals"

ru:
  PENDING:              "Pending applications"
  EXPIRING_OFFER:       "Aging proposals"
</i18n>
