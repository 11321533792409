const ProductListView = () => import(/* webpackChunkName: "Products" */'@/views/admin/products/ProductListView')
const ProductList = () => import(/* webpackChunkName: "Products" */'@/views/admin/products/ProductList')
const Product = () => import(/* webpackChunkName: "Products" */'@/views/admin/products/Product')
const ClassifierList = () => import(/* webpackChunkName: "Classifiers" */'@/views/admin/classifiers/ClassifierList')
const Settings = () => import(/* webpackChunkName: "Settings" */'@/views/admin/settings/Settings')
const TemplateList = () => import(/* webpackChunkName: "Templates" */'@/views/admin/templates/TemplateList')
const Template = () => import(/* webpackChunkName: "Templates" */'@/views/admin/templates/Template')
const ScoringModelList = () => import(/* webpackChunkName: "Scoring" */'@/views/admin/scoringModels/ScoringModelList')
const ScoringModel = () => import(/* webpackChunkName: "Scoring" */'@/views/admin/scoringModels/ScoringModel')
const ScoringModelEdit = () => import(/* webpackChunkName: "Scoring" */'@/views/admin/scoringModels/ScoringModelEdit')
const LogsView = () => import(/* webpackChunkName: "Logs" */'@/views/admin/logs/LogsView')
const DataAccessLog = () => import(/* webpackChunkName: "Logs" */'@/views/admin/logs/DataAccessLogList')
const LoginHistoryLog = () => import(/* webpackChunkName: "Logs" */'@/views/admin/logs/LoginHistoryLogList')
const RoleList = () => import(/* webpackChunkName: "Roles" */'@/views/admin/RoleList')
const AutomaticNotificationList = () => import(/* webpackChunkName: "Notifications" */'@/views/admin/automaticNotifications/AutomaticNotificationList')
const AutomaticNotification = () => import(/* webpackChunkName: "Notifications" */'@/views/admin/automaticNotifications/AutomaticNotification')
const DataMigration = () => import(/* webpackChunkName: "DataMigration" */'@/views/admin/dataMigration/DataMigration')
const TaxationList = () => import(/* webpackChunkName: "Taxation" */'@/views/admin/taxation/TaxationList')
const TaxationDetails = () => import(/* webpackChunkName: "Taxation" */'@/views/admin/taxation/TaxationDetails')
const CustomFieldList = () => import(/* webpackChunkName: "CustomFields" */ '@/views/admin/customFields/CustomFieldList')
const KYCCustomFieldList = () => import(/* webpackChunkName: "CustomFields" */ '@/views/admin/customFields/KYCCustomFieldList')
const CustomField = () => import(/* webpackChunkName: "CustomFields" */ '@/views/admin/customFields/CustomField')
const KYCCustomField = () => import(/* webpackChunkName: "KYCCustomField" */ '@/views/admin/customFields/KYCCustomField')
const CustomerFieldList = () => import(/* webpackChunkName: "CustomerFields" */ '@/views/admin/customer/CustomerFieldList')
const CustomerField = () => import(/* webpackChunkName: "CustomerFields" */ '@/views/admin/customer/CustomerField')
const VariableInterests = () => import(/* webpackChunkName: "Logs" */'@/views/admin/variableInterests/VariableInterestView')
const VariableInterestsList = () => import(/* webpackChunkName: "Logs" */'@/views/admin/variableInterests/VariableInterestsList')
const InterestRatesList = () => import(/* webpackChunkName: "Logs" */'@/views/admin/variableInterests/InterestRatesList')

export default {
  path: 'admin',
  redirect: '/admin/products',
  name: 'Admin',
  meta: { label: 'menu:admin' },
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'products',
      name: 'ProductList',
      component: ProductListView,
      redirect: { name: 'ProductGroup' },
      props: true,
      meta: { label: 'menu:products' },
      children: [
        {
          path: ':group?',
          name: 'ProductGroup',
          component: ProductList,
          meta: { skip: true },
          props: true
        }
      ]
    },
    {
      path: 'products',
      name: 'Products',
      meta: { label: 'menu:products' },
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: ':group/new',
          name: 'NewProduct',
          component: Product,
          props: true,
          meta: { label: 'menu:newProduct' }
        },
        {
          path: ':group/:id',
          name: 'Product',
          component: Product,
          props: true,
          meta: { label: 'menu:editProduct' }
        }
      ]
    },
    {
      path: 'classifiers',
      name: 'Classifiers',
      component: ClassifierList,
      meta: { label: 'menu:classifiers' }
    },
    {
      path: 'templates',
      name: 'TemplateList',
      component: TemplateList,
      meta: { label: 'menu:templates' }
    },
    {
      path: 'templates',
      name: 'Templates',
      meta: { label: 'menu:templates' },
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: 'new',
          name: 'NewTemplate',
          component: Template,
          meta: { label: 'menu:newTemplate' }
        },
        {
          path: ':id',
          name: 'Template',
          component: Template,
          props: true,
          meta: { label: 'menu:editTemplate' }
        }
      ]
    },
    {
      path: 'settings',
      name: 'Settings',
      component: Settings,
      meta: {
        label: 'menu:settings',
        visible: 'SETTINGS_VIEW',
        editable: 'SETTINGS_EDIT'
      }
    },
    {
      path: 'scoring-models',
      name: 'ScoringModelList',
      component: ScoringModelList,
      meta: { label: 'menu:scoringModels' }
    },
    {
      path: 'scoring-models',
      name: 'ScoringModels',
      meta: { label: 'menu:scoringModels' },
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: 'new',
          name: 'NewScoringModel',
          component: ScoringModelEdit,
          meta: { label: 'menu:scoringModelConfigs' }
        },
        {
          path: ':id',
          name: 'ScoringModel',
          component: ScoringModel,
          props: true,
          meta: { label: 'menu:scoringModelConfigs' }
        },
        {
          path: ':id/edit',
          name: 'EditScoringModel',
          component: ScoringModelEdit,
          props: true,
          meta: { label: 'menu:scoringModelConfigs' }
        }
      ]
    },
    {
      path: 'logs',
      name: 'LogsView',
      component: LogsView,
      redirect: 'logs/data-access-log',
      meta: { label: 'menu:logs' },
      children: [
        {
          path: 'data-access-log',
          name: 'DataAccessLog',
          component: DataAccessLog,
          meta: { label: 'menu:dataAccessLog' }
        },
        {
          path: 'login-history-log',
          name: 'LoginHistoryLog',
          component: LoginHistoryLog,
          meta: { label: 'menu:loginHistoryLog' }
        }
      ]
    },
    {
      path: 'roles',
      name: 'RoleList',
      component: RoleList,
      meta: { label: 'menu:roleList' }
    },
    {
      path: 'automatic-notifications',
      name: 'AutomaticNotificationList',
      component: AutomaticNotificationList,
      meta: { label: 'menu:automaticNotifications' }
    },
    {
      path: 'automatic-notifications',
      name: 'AutomaticNotifications',
      meta: { label: 'menu:automaticNotifications' },
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: 'new',
          name: 'NewAutomaticNotification',
          component: AutomaticNotification,
          meta: { label: 'menu:newAutomaticNotification' }
        },
        {
          path: ':id',
          name: 'AutomaticNotification',
          component: AutomaticNotification,
          props: true,
          meta: { label: 'menu:editAutomaticNotification' }
        }
      ]
    },
    {
      path: 'data-migration',
      name: 'DataMigration',
      component: DataMigration,
      meta: {
        label: 'menu:migration',
        auth: ''
      }
    },
    {
      path: 'taxation',
      name: 'TaxationList',
      component: TaxationList,
      meta: {
        label: 'menu:taxation',
        auth: ''
      }
    },
    {
      path: 'taxation',
      name: 'Taxation',
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: 'new',
          name: 'TaxationNew',
          component: TaxationDetails,
          meta: { label: 'menu:newTaxation' }
        },
        {
          path: ':id',
          name: 'TaxationEdit',
          component: TaxationDetails,
          props: true,
          meta: { label: 'menu:editTaxation' }
        }
      ]
    },
    {
      path: 'custom-fields',
      name: 'CustomFieldList',
      component: CustomFieldList,
      meta: { label: 'menu:customFields' }
    },
    {
      path: 'custom-fields',
      name: 'CustomField',
      meta: { label: 'menu:customFields' },
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: 'new',
          name: 'CustomFieldNew',
          component: CustomField,
          meta: { label: 'menu:newCustomField' }
        },
        {
          path: ':id',
          name: 'CustomFieldEdit',
          component: CustomField,
          props: true,
          meta: { label: 'menu:editCustomField' }
        }
      ]
    },
    {
      path: 'customer-fields',
      name: 'CustomerFieldList',
      component: CustomerFieldList,
      meta: { label: 'menu:customerFields' }
    },
    {
      path: 'customer-fields',
      name: 'CustomerField',
      meta: { label: 'menu:customerFields' },
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: 'new',
          name: 'CustomerFieldNew',
          component: CustomerField,
          meta: { label: 'menu:newCustomerField' }
        },
        {
          path: ':id',
          name: 'CustomerFieldEdit',
          component: CustomerField,
          props: true,
          meta: { label: 'menu:editCustomerField' }
        }
      ]
    },
    {
      path: 'kyc-custom-fields',
      name: 'KYCCustomFieldList',
      component: KYCCustomFieldList,
      meta: { label: 'menu:KYCCustomFields' }
    },
    {
      path: 'kyc-custom-fields',
      name: 'KYCCustomField',
      meta: { label: 'menu:KYCCustomFields' },
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: 'new',
          name: 'KYCCustomFieldNew',
          component: KYCCustomField,
          meta: { label: 'menu:newKYCCustomField' }
        },
        {
          path: ':id',
          name: 'KYCCustomFieldEdit',
          component: KYCCustomField,
          props: true,
          meta: { label: 'menu:editKYCCustomField' }
        }
      ]
    },
    {
      path: 'variable-interests',
      name: 'VariableInterests',
      component: VariableInterests,
      redirect: 'variable-interests/variable-interests-list',
      meta: { label: 'menu:variableInterest' },
      children: [
        {
          path: 'variable-interests-list',
          name: 'VariableInterestsList',
          component: VariableInterestsList,
          meta: { label: 'menu:variableInterestsList' }
        },
        {
          path: 'interest-rates-list',
          name: 'InterestRatesList',
          component: InterestRatesList,
          meta: { label: 'menu:interestRatesList' }
        }
      ]
    }
  ]
}
