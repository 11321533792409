<script>
export default {
  name: 'range-input-base',

  props: {
    icon: {
      type: String,
      required: false,
      default: ''
    },
    column: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: null
    },
    start: {
      type: [Number, String, Date],
      default: ''
    },
    end: {
      type: [Number, String, Date],
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },

  computed: {
    computedStart: {
      get () {
        return this.start
      },
      set (val) {
        this.$emit('update:start', val)
      }
    },
    computedEnd: {
      get () {
        return this.end
      },
      set (val) {
        this.$emit('update:end', val)
      }
    },
    computedColumn: {
      get () {
        return this.column
      },
      set (val) {
        this.$emit('update:column', val)
      }
    }
  }
}
</script>
