import { connection } from '@/api/connection'
import pickBy from 'lodash/pickBy'

export default {
  getAccount: ({ accountId }) => connection.get(`bo/accounts/${accountId}`),
  getAccountTransactions ({ accountId, filters }) {
    return connection.get(`bo/accounts/${accountId}/transactions`, {
      params: pickBy(filters)
    })
  }
}
