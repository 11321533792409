import { Product } from '@/models'
import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import { sortClassifiers } from '@/helpers'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    productsList: [],
    productsPages: 0,
    current: null,
    feeConfigs: {},
    settlementOrders: []
  },

  getters: {
    optionsFromProducts: ({ productsList }) => sortClassifiers(productsList.filter(({ active }) => active).map(({ id, name }) => ({ value: id, text: name }))),
    optionsFromAllProducts: ({ productsList }) => productsList.map(({ id, name }) => ({ value: id, text: name })),
    optionsFromProductsBySectorAndSegment: ({ productsList }) => ({ sectorId, segmentId } = {}) => productsList
      .filter(product => product.active && (!sectorId || product.sectorId === sectorId) && (!segmentId || product.segments.some(({ id }) => id === segmentId)))
      .map(({ id, name }) => ({ value: id, text: name })),
    productById: ({ productsList }) => id => productsList.find(item => item.id === id) || {},
    productType: (state, getters, rootState, rootGetters) => ({ productType }) =>
      (isNaN(productType) ? productType : rootGetters['classifiers/productTypeById'](productType).code) || '',
    optionsFromSettlementOrders: ({ settlementOrders }) => settlementOrders.map(order => ({
      value: order,
      text: order
    }))
  },

  actions: {
    async loadProducts ({ commit, dispatch }, { group } = {}) {
      const productsGetter = group ? api.getProductsByGroup.bind(this, { groups: group }) : api.getProducts

      commit('CLEAR_LIST')

      const { data: products } = await startLoading(dispatch, 'products:fetch', productsGetter)

      commit('SET_PRODUCTS', { products })
    },
    async loadLoanProduct ({ commit, dispatch, getters, rootGetters }, { id }) {
      commit('CLEAR_CURRENT')

      const { data: product } = await startLoading(dispatch, 'product:fetch', () => api.getProduct({ id }))

      commit('SET_CURRENT', { product })
    },
    async loadDepositProduct ({ commit, dispatch, getters, rootGetters }, { id }) {
      commit('CLEAR_CURRENT')

      const { data: product } = await startLoading(dispatch, 'product:fetch', () => api.getDepositProduct({ id }))

      commit('SET_CURRENT', { product })
    },
    async createLoanProduct ({ commit, dispatch, getters, rootGetters }, { productData }) {
      const { data: product } = await startLoading(dispatch, 'product:save', () => api.postProduct({ productData }))

      commit('SET_CURRENT', { product })
    },
    async updateLoanProduct ({ commit, dispatch, getters, rootGetters }, { productData, id }) {
      const { data: product } = await startLoading(dispatch, `product:${id}:save`, () => api.putProduct({ id, productData }))

      commit('SET_CURRENT', { product })
    },
    async createDepositProduct ({ commit, dispatch, getters, rootGetters }, { productData }) {
      const { data: product } = await startLoading(dispatch, 'product:save', () => api.postDepositProduct({ productData }))

      commit('SET_CURRENT', { product })
    },
    async updateDepositProduct ({ commit, dispatch, getters, rootGetters }, { productData, id }) {
      const { data: product } = await startLoading(dispatch, `product:${id}:save`, () => api.putDepositProduct({ id, productData }))

      commit('SET_CURRENT', { product })
    },
    async loadFactoringProduct ({ commit, dispatch, getters, rootGetters }, { id }) {
      commit('CLEAR_CURRENT')

      const { data: product } = await startLoading(dispatch, 'product:fetch', () => api.getFactoringProduct({ id }))

      commit('SET_CURRENT', { product, attributeTypes: rootGetters['classifiers/optionsFromClassifier']('attributeTypes', true).map(({ value }) => value) })
    },
    async createFactoringProduct ({ commit, dispatch, getters, rootGetters }, { productData }) {
      const { data: product } = await startLoading(dispatch, 'product:save', () => api.postFactoringProduct({ productData }))

      commit('SET_CURRENT', { product })
    },
    async updateFactoringProduct ({ commit, dispatch, getters, rootGetters }, { productData, id }) {
      const { data: product } = await startLoading(dispatch, `product:${id}:save`, () => api.putFactoringProduct({ id, productData }))

      commit('SET_CURRENT', { product })
    },
    async loadFeeConfig ({ commit, dispatch }, { group }) {
      const { data: feeConfigs } = await startLoading(dispatch, 'product:feeConfig:fetch', () => api.getFeeConfigByGroup({ group }))

      commit('SET_FEE_CONFIGS', { feeConfigs })
    },
    async loadProductsSettlementOrders ({ commit, dispatch }) {
      const { data: orders } = await startLoading(dispatch, 'product:settlementOrders:fetch', () => api.getProductsSettlementOrders())

      commit('SET_SETTLEMENT_ORDERS', { orders })
    }
  },

  mutations: {
    CLEAR_LIST (state) {
      state.productsList = []
    },
    SET_PRODUCTS (state, { products, totalPages }) {
      state.productsList = products
      state.productsPages = totalPages
    },
    CLEAR_CURRENT (state) {
      state.current = Product()
    },
    SET_CURRENT (state, { product, attributeTypes = [] }) {
      state.current = Product(product, attributeTypes)
    },
    SET_FEE_CONFIGS (state, { feeConfigs }) {
      state.feeConfigs = feeConfigs
    },
    SET_SETTLEMENT_ORDERS (state, { orders }) {
      state.settlementOrders = orders
    }
  }
}
