<template lang="pug">
b-file.fi-file-input.form-control-sm(
  v-model   = 'file'
  :multiple = 'multiple'
  v-bind    = '$attrs'
)
  template(
    slot = 'file-name'
    slot-scope= '{ names }'
  )
    b-badge(variant = 'light') {{ names[0] }}
    b-badge.ml-1(
      v-if    = 'names.length > 1'
      variant = 'light'
    ) + {{ names.length - 1 }} {{ $t('files', { count: names.length - 1 }) }}
</template>


<script>
export default {
  name: 'fi-file-input',

  inheritAttrs: false,

  props: {
    value: {
      type: [Array, File, FileList, String],
      default: () => null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  computed: {
    file: {
      get () {
        return this.value
      },
      set (data) {
        this.$emit('input', data)
      }
    }
  },

  methods: {
    onFilesChange (e) {
      if (this.multiple) {
        this.$emit('input', e.target.files)
      } else {
        this.$emit('input', e.target.files[0])
      }
    }
  }
}
</script>


<i18n>
en:
  files: "file"
  files_plural: "files"
et:
  files: "files"
  files_plural: "files"
ru:
  files_0: "файл"
  files_1: "файла"
  files_2: "файлов"
</i18n>


<style lang='scss' scoped>
.fi-file-input /deep/ .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.fi-file-input /deep/ .custom-file-input:lang(ru) ~ .custom-file-label::after {
  content: "Выбрать";
}
</style>
