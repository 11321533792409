import { connection } from '@/api/connection'
import { controllerRootv2 } from '@/api/loans'

export default {
  getPaymentRelations: ({ loanId }) => connection.get(`${controllerRootv2}/${loanId}/payments/relation`),
  postPaymentRelation: ({ loanId, paymentRelationData }) =>
    connection.post(`${controllerRootv2}/${loanId}/payments/relation`, paymentRelationData),
  putPaymentRelation: ({ loanId, paymentRelationData, paymentRelationId }) =>
    connection.put(`${controllerRootv2}/${loanId}/payments/relation/${paymentRelationId}`, paymentRelationData),
  deletePaymentRelation: ({ loanId, paymentRelationId }) =>
    connection.delete(`${controllerRootv2}/${loanId}/payments/relation/${paymentRelationId}`)
}
