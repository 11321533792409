import { connection } from '@/api/connection'

export default {
  getDeltaRatingReportsByUser ({ customerId }) {
    return connection.get('bbf/delta-rating/reports', {
      params: {
        customerId
      }
    })
  },
  getDeltaRatingReportNewByUser ({ customerId }) {
    return connection.get('bbf/delta-rating/reports/new', {
      params: {
        customerId
      }
    })
  }
}
