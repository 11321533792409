import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import isNil from 'lodash/isNil'
import { connection } from './connection'
import qs from 'qs'
import { createBlobFromObject } from '@/helpers'

const controllerRoot = (id, method) => ['bo/ca', id, method].filter(item => item).join('/')

export default {
  getApplications ({ filters }) {
    return connection.get('bo/dashboard/ca', {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  getApplication ({ id }) {
    return connection.get(controllerRoot(id))
  },
  postApplication ({ applicationData }) {
    return connection.post(controllerRoot(), applicationData)
  },

  putApplication ({ id, applicationData, type }) {
    return connection.put(controllerRoot(id), {
      ...applicationData,
      type
    })
  },
  deleteApplicationAnnualReport ({ reportId, applicationId }) {
    return connection.delete(controllerRoot(`${applicationId}/annualreport/${reportId}`))
  },
  putApplicationApproveNegativeScore ({ id, data }) {
    return connection.put(controllerRoot(id, 'approveNegativeScoring'), data)
  },
  putApplicationAssignee ({ id, customerId }) {
    return connection.put(controllerRoot(id, 'employee'), {
      customerId
    })
  },
  putOffer ({ id, data }) {
    const formData = new FormData()
    const messageData = pick(data, ['text', 'subject', 'infoSheetId', 'customerEmail'])
    formData.append('message', createBlobFromObject(messageData))
    formData.append('recipients', JSON.stringify(data.recipients))
    formData.append('template', JSON.stringify([data.template].filter(Number)))
    formData.append('languageId', data.languageId)
    if (data.files.length) {
      formData.append('documentCategoryId', data.documentCategoryId)
    }
    Array.from(data.files).forEach(file => {
      formData.append('file', file)
    })
    return connection.put(controllerRoot(id, 'send'), formData)
  },
  postApplicationScoreRequest ({ id, scoreType }) {
    return connection.post(`${controllerRoot(id, 'score')}/${scoreType}`)
  },
  putApplicationClose ({ id, closeReasonId, comment }) {
    return connection.put(controllerRoot(id, 'close'), {
      closeReasonId,
      comment
    })
  },
  putApplicationSystemCustomerLink ({ id, customerId }) {
    return connection.put(controllerRoot(id, 'customer'), {
      customerId
    })
  },
  putPrepareContract ({ id }) {
    return connection.put(controllerRoot(id, 'prepareContract'))
  },
  getApplicationNotifications () {
    return connection.get(controllerRoot('notifications'))
  },
  getApplicationResources ({ id, filters }) {
    return connection.get(controllerRoot(id, 'documents'), {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  postApplicationResource ({ customerId, applicationId, data }) {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('documentCategoryId', data.documentCategoryId)
    formData.append('resource', createBlobFromObject({
      comment: data.comment,
      visibleForPortal: data.visibleForPortal
    }))
    return connection.post(controllerRoot(applicationId, 'resources'), formData, {
      params: {
        customerId
      }
    })
  },
  putApplicationInAnalysis ({ id }) {
    return connection.put(controllerRoot(id, 'analysis'))
  },
  postInternalComment ({ id, commentData }) {
    return connection.post(controllerRoot(id, 'internalComment'), commentData)
  },
  postApplicationRelatedPersons ({ id, relatedPersons }) {
    return connection.post(controllerRoot(`relatedparty/${id}`), relatedPersons)
  },
  putApplicationRelatedPersons ({ id, relatedPersons }) {
    return connection.put(controllerRoot(`relatedparty/${id}`), relatedPersons)
  },
  deleteInternalComment ({ id, commentId }) {
    return connection.delete(controllerRoot(id, `internalComment/${commentId}`))
  },
  getApplicationAssets ({ id }) {
    return connection.get(`${controllerRoot(id, 'asset')}`)
  },
  postApplicationAsset ({ id, assetData }) {
    return connection.post(`${controllerRoot(id, 'asset')}`, assetData)
  },
  putApplicationAsset ({ id, assetData }) {
    return connection.put(`${controllerRoot('asset', id)}`, assetData)
  },
  deleteApplicationAsset ({ id }) {
    return connection.delete(`${controllerRoot('asset', id)}`)
  },
  deleteApplicationRelatedPerson ({ relatedPersonId }) {
    return connection.delete(`${controllerRoot(`relatedparty/${relatedPersonId}`)}`)
  },
  putApplicationOfferTerms ({ id, termsData }) {
    return connection.put(`${controllerRoot(id, 'offerTerms')}`, termsData)
  },
  getApplicationComments ({ id, filters }) {
    return connection.get(`${controllerRoot(id, 'related-comments')}`, {
      params: pickBy(filters, filter => !isNil(filter)),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  postScoreMember: ({ id, memberData }) => connection.post(controllerRoot(id, 'members/score'), memberData),
  postCorporateScoreRequest ({ id, scoreType }) {
    return connection.post(`${controllerRoot(id, 'corporate-score')}/${scoreType}`)
  },
  getApplicationCustomFieldValues: ({ applicationId }) => connection.get(`bo/ca/${applicationId}/custom-fields/values`),
  getApplicationRelatedPersons: ({ applicationId }) => {
    return connection.get(`bo/ca/relatedparty/${applicationId}`)
  },
  getApplicationPaidTaxes: ({ idCode, idCodeCountry }) => {
    return connection.get('bo/integrations/companytaxdata', {
      params: pickBy({ idCode, idCodeCountry }),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  postApplicationCustomFieldValues: ({ applicationId, customFieldValues }) => connection.post(
    `bo/ca/${applicationId}/custom-fields/values`,
    customFieldValues
  )
}
