import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  actions: {
    async loadUrl ({ dispatch }) {
      const { data: url } = await startLoading(dispatch, 'quickSight:url:fetch', () => api.getUrl())
      return url
    }
  }
}
