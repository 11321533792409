<template lang="pug">
.card.payment-incoming-view.animated.fadeIn
  nav.card-header
    b-nav(card-header tabs)
      b-nav-item(:to = '{ name: "incomingPaymentsList" }') {{ $t('incoming') }}
      b-nav-item(:to = '{ name: "outgoingPaymentsList" }') {{ $t('outgoing') }}
      b-nav-text.ml-auto
        span.float-right.toolbar
          button.btn.btn-link.py-0(@click.prevent = 'newPaymentModal=true')
            i.fa-upload.fas(:title = '$t("newLink")')
  .card-body
    form.form-horizontal
      .row
        .form-group.col-lg-6
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-search
            input.form-control(
              v-model.trim = 'searchParams.criteria'
              :placeholder = '$t("searchPlaceholder")'
              type         = 'text'
            )
        .form-group.col-lg-6
          range-input-date(
            :title       = '$t("paymentDate")'
            :start.sync  = 'searchParams.startDate'
            :end.sync    = 'searchParams.endDate'
          )
      .row
        .form-group.col-lg-6
          range-input-text(
            :title       = '$t("amount")'
            :start.sync  = 'searchParams.amountMin'
            :end.sync    = 'searchParams.amountMax'
          )
        .form-group.col-lg-6
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-flag
            fi-select(
              v-model.number = 'searchParams.statusId'
              :options       = 'optionsFromClassifier("incomingPaymentStatuses")'
              :placeholder   = '$t("statusesPlaceholder")'
            )

    fi-table.payment-list(
      #default     = '{ items }'
      :fields      = 'fields'
      loader       = 'payments:fetch'
      :items       = 'payments'
      :empty-label = '$t("notFound")'
      :sort.sync   = 'searchParams.sort'
      responsive
    )
      payment-incoming-list-item(
        v-for         = 'payment in items'
        :key          = 'payment.id'
        :payment      = 'payment'
      )

    fi-pagination(
      v-if    = 'paymentsPages > 1'
      :pages  = 'paymentsPages'
      v-model = 'pagination.page'
    )
  payment-new-modal(v-model = 'newPaymentModal')
</template>


<script>
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import PaymentIncomingListItem from '@/views/payments/PaymentIncomingListItem'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiPagination from '@/components/FiPagination'
import PaymentNewModal from './PaymentNewModal'
import pick from 'lodash/pick'
import { pagination } from '@/const'
import pickBy from 'lodash/pickBy'

const searchParams = {
  criteria: null,
  startDate: null,
  endDate: null,
  amountMin: null,
  amountMax: null,
  statusId: '',
  sort: 'id,desc'
}

export default {
  name: 'payment-incoming-list',

  components: {
    FiTable,
    PaymentIncomingListItem,
    PaymentNewModal,
    FiPagination,
    FiSelect,
    RangeInputDate,
    RangeInputText
  },

  props: {
    criteria: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    newPaymentModal: false,
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('payments', ['payments', 'paymentsPages']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    fields () {
      return [
        {
          key: 'id',
          label: 'ID',
          sort: 'id'
        },
        {
          key: 'paymentDate',
          label: this.$t('paymentDate'),
          sort: 'paymentDate'
        },
        {
          key: 'payerName',
          label: this.$t('payerName'),
          sort: 'fromName'
        },
        {
          key: 'account',
          label: this.$t('account'),
          sort: 'fromAccount'
        },
        {
          key: 'description',
          label: this.$t('description'),
          sort: 'description'
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money',
          sort: 'amount'
        },
        {
          key: 'reference',
          label: this.$t('reference'),
          sort: 'referenceNumber'
        },
        {
          key: 'customer',
          label: this.$t('customer'),
          sort: 'customerId'
        },
        {
          key: 'status',
          label: this.$t('status'),
          sort: 'statusId'
        }
      ]
    },
    searchQuery () {
      return pickBy({ ...this.searchParams, ...this.pagination })
    }
  },

  watch: {
    'pagination.page': 'search',
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    '$route.query': {
      handler (query) {
        this.loadIncomingPayments({ filters: query })
      },
      deep: true
    }
  },

  created () {
    this.loadIncomingPayments({ filters: this.searchQuery })
  },

  methods: {
    ...mapActions('payments', ['loadIncomingPayments']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 250
    ),
    toggleSelectAll (state) {
      if (state) {
        this.selectedItems = this.itemIds
      } else {
        this.selectedItems = []
      }
    }
  }
}
</script>


<i18n>
en:
  title:               "Payments"
  newLink:             "Import payments"
  incoming:            "Incoming"
  outgoing:            "Outgoing"
  searchPlaceholder:   "Search by payer name, customer name, account, reference, description"
  paymentDate:         "Payment Date"
  statusesPlaceholder: "All statuses"
  date:                "Date"
  payerName:           "Payer Name"
  account:             "Account"
  description:         "Description"
  amount:              "Amount"
  reference:           "Reference"
  customer:            "Customer"
  status:              "Status"
  notFound:            "Payments not found"
et:
  title:               "Payments"
  newLink:             "Import payments"
  incoming:            "Incoming"
  outgoing:            "Outgoing"
  searchPlaceholder:   "Search by payer name, customer name, account, reference, description"
  paymentDate:         "Payment Date"
  statusesPlaceholder: "All statuses"
  date:                "Date"
  payerName:           "Payer Name"
  account:             "Account"
  description:         "Description"
  amount:              "Amount"
  reference:           "Reference"
  customer:            "Customer"
  status:              "Status"
  notFound:            "Payments not found"
ru:
  title:               "Payments"
  newLink:             "Import payments"
  incoming:            "Incoming"
  outgoing:            "Outgoing"
  searchPlaceholder:   "Search by payer name, customer name, account, reference, description"
  paymentDate:         "Payment Date"
  statusesPlaceholder: "All statuses"
  date:                "Date"
  payerName:           "Payer Name"
  account:             "Account"
  description:         "Description"
  amount:              "Amount"
  reference:           "Reference"
  customer:            "Customer"
  status:              "Status"
  notFound:            "Payments not found"
</i18n>
