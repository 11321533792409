import orderBy from 'lodash/orderBy'
import unionBy from 'lodash/unionBy'
import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import { customerDefaultAddress, customerName } from '@/helpers'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    customers: [],
    customersPages: 0,
    customer: null,
    customerApplications: [],
    customerHistory: null,
    customerLoans: [],
    customerDeposits: [],
    customerMessages: [],
    customerMessagesPages: 0,
    customerResources: [],
    customerResourcesPages: 0,
    systemCustomers: [],
    customerPayments: [],
    customerOutgoingPayments: [],
    customerAccountTransactions: [],
    customerAccountTransactionsPages: 0,
    customerRoles: [],
    customerRelatedLoans: [],
    customerComments: [],
    customerCommentsPages: 0,
    customerAssignedComments: [],
    customerRelations: {
      referredRelations: [],
      relations: []
    },
    customerBankStatements: [],
    customerReports: [],
    customerFactoring: [],
    customerThirdParties: [],
    customerAnnualReports: [],
    customFieldsValues: [],
    customerPrepaymentAccounts: [],
    customerAddress: []
  },

  getters: {
    sortedComments: state => orderBy(state.customerComments, ['commentableType.id', 'commentableId', 'id'], ['desc', 'asc', 'desc']),
    systemCustomersForSelect: (state, getters, rootState, rootGetters) => state.systemCustomers.map((customer) => ({
      value: customer.id,
      text: `${rootGetters['customers/customerName'](customer)} (${customer.idCode})`
    })),
    isCorporateCustomer: (state, getters, rootState, rootGetters) => (customer) => {
      return customer?.sectorId && !((/PRIVATE_CUSTOMER/i.test(rootGetters['classifiers/classifierById']('sectors', customer.sectorId)?.code)) || (/EMPLOYEES/i.test(rootGetters['classifiers/classifierById']('sectors', customer.sectorId)?.code)))
    },
    customerName: () => customerName,
    customerAddress: () => customerDefaultAddress,
    isCustomerManager: (state, getters, rootState, rootGetters) => customer => {
      return !!rootGetters['classifiers/managerById'](customer.id)
    },
    roleByName: state => roleName => state.customerRoles.find(({ name }) => name === roleName)
  },

  actions: {
    async loadCustomers ({ commit, dispatch }, { filters } = {}) {
      commit('SET_CUSTOMERS', { customers: [], customersPages: 0 })
      const response = await startLoading(dispatch, 'customers:fetch', () => {
        return api.getCustomers({ filters: filters })
      })
      commit('SET_CUSTOMERS', { customers: response.data.content, customersPages: response.data.totalPages })
    },

    async loadCustomer ({ commit, dispatch }, { id }) {
      commit('CLEAR_CUSTOMER')
      const response = await startLoading(dispatch, 'customer:fetch', () => {
        return api.getCustomer({ id: id })
      })
      commit('SET_CUSTOMER', { customer: response.data })
    },

    async createCustomer ({ dispatch, commit, getters }, { customerData, privateCustomerData, addressData }) {
      const response = await startLoading(dispatch, 'customer:save', async () => {
        const customerResponse = await api.postCustomer({ customerData })
        const customer = customerResponse.data

        const parallelResponses = await (async () => {
          let customerProfileResponse

          if (!getters.isCorporateCustomer(customer)) {
            customerProfileResponse = api.putCustomerPrivate({ customerId: customer.id, data: privateCustomerData })
          }

          const addressResponse = api.postCustomerAddress({ customerId: customer.id, data: addressData })

          return {
            customerProfileResponse: await customerProfileResponse,
            addressResponse: await addressResponse
          }
        })()

        if (!getters.isCorporateCustomer(customer)) {
          customer.privateCustomer = parallelResponses.customerProfileResponse.data
        }

        customer.addresses = parallelResponses.addressResponse.data
        return customer
      })

      commit('SET_CUSTOMER', { customer: response })
    },


    async updateCustomer ({ commit, dispatch }, { id, customerData }) {
      const response = await startLoading(dispatch, 'customer:save', () => {
        return api.putCustomer({ id: id, data: customerData })
      })
      commit('UPDATE_CUSTOMER', { customer: response.data })
    },

    async updateCustomerPrivate ({ state, commit, dispatch }, { privateCustomerData }) {
      const response = await startLoading(dispatch, 'customer:private:save', () => {
        return api.putCustomerPrivate({ customerId: state.customer.id, data: privateCustomerData })
      })
      commit('UPDATE_CUSTOMER', { customer: { privateCustomer: response.data } })
    },

    async updateCustomerCorporate ({ state, commit, dispatch }, { corporateCustomerData, id }) {
      const response = await startLoading(dispatch, 'customer:corporate:save', () => {
        return api.putCustomerCorporate({ customerId: id || state.customer.id, data: corporateCustomerData })
      })
      commit('UPDATE_CUSTOMER', { customer: { corporateCustomer: response.data } })
    },

    async loadCustomerApplications ({ state, commit, dispatch }) {
      const response = await startLoading(dispatch, 'customer:applications:fetch', () => {
        return api.getCustomerApplications({ customerId: state.customer.id })
      })
      commit('SET_CUSTOMER_APPLICATIONS', { applications: response.data })
    },

    async loadCustomerHistory ({ state, commit, dispatch }, { customerId = state.customer.id } = {}) {
      const response = await startLoading(dispatch, 'customer:history:fetch', () => {
        return api.getCustomerHistory({ customerId })
      })
      commit('SET_CUSTOMER_HISTORY', { history: response.data })
    },

    async loadCustomerLoans ({ state, commit, dispatch }, { id = state.customer.id }) {
      const { data: loans } = await startLoading(dispatch, 'customer:loans:fetch', () => {
        return api.getCustomerLoans({ customerId: id })
      })
      commit('SET_CUSTOMER_LOANS', { loans })
    },

    async loadCustomerDeposits ({ state, commit, dispatch }, { id = state.customer.id }) {
      const { data: { content: deposits } } = await startLoading(dispatch, 'customer:deposits:fetch', () => {
        return api.getDepositContracts({ filters: { customerId: id, size: 0 } })
      })
      commit('SET_CUSTOMER_DEPOSITS', { deposits })
    },

    async loadMessages ({ state, commit, dispatch }, filters) {
      commit('SET_CUSTOMER_MESSAGES', { messages: [], totalPages: 0 })
      const { data: { content: messages, totalPages } } = await startLoading(dispatch, 'customer:messages:fetch', () => {
        return api.getCustomerMessages({ customerId: state.customer.id, filters })
      })
      commit('SET_CUSTOMER_MESSAGES', { messages, totalPages })
    },

    async createMessage ({ state, commit, dispatch }, { messageData, direction, customerId }) {
      const response = await startLoading(
        dispatch,
        'customer:message:save',
        () => api.postCustomerMessage({ customerId: state.customer.id, data: messageData })
      )
      commit('ADD_CUSTOMER_MESSAGE', { message: response.data, direction })
    },

    async loadCustomerAddress ({ state, commit, dispatch }, { customerId = state.customer.id } = {}) {
      const { data: addresses } = await startLoading(dispatch, 'customer:address:fetch', () => {
        return api.getCustomerAddress({ customerId })
      })
      commit('SET_CUSTOMER_ADDRESS', { addresses })
      commit('UPDATE_CUSTOMER', { customer: { addresses } })
    },

    async createCustomerAddress ({ state, commit, dispatch }, { addressData }) {
      const { data: addresses } = await startLoading(dispatch, 'customer:address:save', () => {
        return api.postCustomerAddress({ customerId: state.customer.id, data: addressData })
      })
      commit('UPDATE_CUSTOMER', { customer: { addresses } })
      commit('SET_CUSTOMER_ADDRESS', { addresses })
    },

    async updateCustomerAddress ({ state, commit, dispatch }, { id, addressData }) {
      const { data: addresses } = await startLoading(dispatch, `customer:address:${id}:save`, () => {
        return api.putAddress({ id, addressData })
      })
      commit('UPDATE_CUSTOMER', { customer: { addresses } })
      commit('SET_CUSTOMER_ADDRESS', { addresses })
    },

    async removeCustomerAddress ({ state, commit, dispatch }, { id }) {
      const { data: addresses } = await startLoading(dispatch, `customer:address:${id}:remove`, () => {
        return api.deleteAddress({ id: id })
      })
      commit('SET_CUSTOMER_ADDRESS', { addresses })
    },

    async createCustomerRole ({ state, commit, dispatch }, { roleData }) {
      const response = await startLoading(dispatch, 'customer:role:save', () => {
        return api.postCustomerRole({ customerId: state.customer.id, data: roleData })
      })
      commit('ADD_CUSTOMER_ROLE', { role: response.data })
    },

    async removeCustomerRole ({ state, commit, dispatch }, { id }) {
      await startLoading(dispatch, `customer:role:${id}:remove`, () => {
        return api.deleteRole({ id: id })
      })
      commit('REMOVE_CUSTOMER_ROLE', { id: id })
    },

    async updateCustomerRole ({ state, commit, dispatch }, { id, roleData }) {
      const response = await startLoading(dispatch, `customer:role:${id}:save`, () => {
        return api.putRole({ id, roleData })
      })
      commit('UPDATE_CUSTOMER_ROLE', { role: response.data })
    },

    async loadCustomerRelations ({ commit, dispatch, state }, { customerId = state.customer.id } = {}) {
      const { data: relations } = await startLoading(dispatch, 'customer:relations:fetch', () => api.getCustomerRelations({ customerId }))

      commit('SET_CUSTOMER_RELATIONS', { relations })
    },

    async createCustomerRelation ({ state, commit, dispatch }, { relationData, customerId = state.customer.id, referredCustomerId }) {
      const { data: relations } = await startLoading(dispatch, 'customer:relation:save', () => {
        return api.postCustomerRelation({ customerId, data: { ...relationData, referredCustomerId } })
      })
      commit('SET_CUSTOMER_RELATIONS', { relations })
    },

    async createCustomerRelationInApp ({ state, commit, dispatch }, { relationData, customerId, referredCustomerId }) {
      await startLoading(dispatch, 'customer:relation:save', () => {
        return api.postCustomerRelation({ customerId, data: { ...relationData, referredCustomerId } })
      })
      // commit('SET_CUSTOMER_RELATIONS', { relations })
    },

    async updateCustomerRelation ({ state, commit, dispatch }, { id, relationData } = {}) {
      await startLoading(dispatch, `customer:relation:${id}:save`, () => {
        return api.putRelation({ id, relationData })
      })

      dispatch('loadCustomerRelations', { id: state.customer.id })
    },

    async removeCustomerRelation ({ state, commit, dispatch }, { id }) {
      await startLoading(dispatch, `customer:relation:${id}:remove`, () => {
        return api.deleteRelation({ id: id })
      })
      commit('REMOVE_CUSTOMER_RELATION', { id: id })
    },

    async findSystemCustomers ({ commit, dispatch }, { query }) {
      if (!query) {
        commit('SET_SYSTEM_CUSTOMERS', { customers: [] })
        return Promise.resolve()
      }
      const response = await startLoading(dispatch, 'customers:systemCustomers:fetch', () => api.getCustomers({ filters: { criteria: query } }))

      commit('SET_SYSTEM_CUSTOMERS', { customers: response.data.content })
    },

    async loadComments ({ commit, dispatch, state }, { id = state.customer.id, filters } = {}) {
      commit('CLEAR_COMMENTS')

      const { data: { content: comments, totalPages } } = await startLoading(dispatch, 'customers:comments:fetch', () => api.getCustomerComments({ customerId: id, filters }))

      commit('SET_CUSTOMER_COMMENTS', { comments, totalPages })
    },

    async createComment ({ state, commit, dispatch }, { commentData, commentableId = state.customer.id } = {}) {
      const { data: comment } = await startLoading(dispatch, 'customers:comment:save', () => {
        return api.postComment({ ...commentData, commentableId })
      })
      commit('ADD_CUSTOMER_COMMENT', { comment })
    },

    async updateComment ({ commit, dispatch }, { commentId, commentData }) {
      const response = await startLoading(dispatch, `customers:comment:${commentId}:save`, () => {
        return api.putComment({ id: commentId, data: commentData })
      })
      commit('UPDATE_CUSTOMER_COMMENT', { comment: response.data })
    },

    async removeComment ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `customers:comment:${id}:remove`, () => {
        return api.deleteComment({ id: id })
      })
      commit('REMOVE_CUSTOMER_COMMENT', { id })
    },

    async loadResources ({ state, commit, dispatch }, { filters }) {
      const { data: { content: resources, totalPages } } = await startLoading(dispatch, 'resources:fetch', () => {
        return api.getCustomerResources({ customerId: state.customer.id, filters })
      })
      commit('SET_CUSTOMER_RESOURCES', { resources, totalPages })
    },

    async createCustomerResource ({ state, commit, dispatch }, { resourceData }) {
      const response = await startLoading(dispatch, 'resource:save', () => {
        return api.postCustomerResource({ customerId: state.customer.id, data: resourceData })
      })
      commit('ADD_CUSTOMER_RESOURCE', { resource: response.data })
    },

    async loadCustomerResourceFile ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `resource:${id}:fetch`, () => {
        return api.getResourceFile({ id })
      })
    },

    async removeResource ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `resource:${id}:remove`, () => {
        return api.deleteResource({ id: id })
      })
      commit('REMOVE_CUSTOMER_RESOURCE', { id })
    },

    async createCustomerAccount ({ state, commit, dispatch }, { accountData }) {
      const { data: account } = await startLoading(dispatch, 'customer:account:save', () => api.postCustomerAccount({ customerId: state.customer.id, accountData }))

      commit('SET_CUSTOMER_ACCOUNT', { account })
    },

    async loadCustomerPayments ({ state, commit, dispatch }) {
      const { data: payments } = await startLoading(dispatch, 'customer:payments:fetch', () => api.getCustomerPayments({ customerId: state.customer.id }))

      commit('SET_CUSTOMER_PAYMENTS', { payments })
    },

    async loadCustomerOutgoingPayments ({ state, commit, dispatch }, { customerId = state.customer.id } = {}) {
      const { data: outgoing } = await startLoading(dispatch, 'customer:outgoing:fetch', () => api.getCustomerOutgoingPayments({ customerId }))

      commit('SET_CUSTOMER_OUTGOING_PAYMENTS', { outgoing })
    },

    async loadCustomerAccountTransactions ({ commit, dispatch }, { accountId, filters }) {
      commit('CLEAR_CUSTOMER_ACCOUNT_TRANSACTIONS')
      const { data } = await startLoading(dispatch, 'account:transactions:fetch', () => api.getAccountTransactions({ accountId, filters }))

      commit('SET_CUSTOMER_ACCOUNT_TRANSACTIONS', { transactions: data.content, transactionsPages: data.totalPages })
    },
    async loadCustomerDebts ({ commit, dispatch, state }) {
      const { data = [] } = await startLoading(dispatch, 'customer:debts:fetch', () => api.getCustomerDebts({ customerId: state.customer.id }))

      commit('SET_CUSTOMER_DEBTS', { debts: data })
    },
    async loadCustomerRoles ({ commit, dispatch }) {
      const { data: { userRoles: roles = [] } } = await startLoading(dispatch, 'customer:roles:fetch', () => api.getRolesInfo())

      commit('SET_CUSTOMER_ROLES', { roles })
    },
    async createSystemCustomer ({ commit, dispatch }, { customerData }) {
      try {
        const { data: customer } = await startLoading(dispatch, 'customer:system:save', () => api.postCustomer({ customerData }))
        commit('SET_SYSTEM_CUSTOMERS', { customers: [customer] })
        return customer
      } catch (e) {
        throw e.response.data.message
      }
    },
    async createCustomerExtraIncome ({ commit, dispatch }, { customerId, accountId, ...data }) {
      try {
        const { data: account } = await startLoading(dispatch, `customer:${customerId}:extra-income:save`, () => api.postCustomerExtraIncome({ customerId, accountId, data }))
        commit('SET_CUSTOMER_ACCOUNT', { account })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async loadCustomerRelatedLoans ({ commit, dispatch }, { customerId }) {
      const { data: loans } = await startLoading(dispatch, 'customer:relatedLoans:fetch', () => api.getCustomerRelatedLoans({ customerId }))

      commit('SET_CUSTOMER_RELATED_LOANS', { loans })
    },
    async updateResource ({ commit, dispatch }, { resourceId, resourceData }) {
      const { data: resource } = await startLoading(
        dispatch,
        `customers:resource:${resourceId}:save`,
        () => api.putResource({ id: resourceId, resourceData })
      )

      commit('SET_CUSTOMER_RESOURCE', { resource })
    },
    async updateCommentAction ({ commit, dispatch }, { id, actionId, actionData }) {
      const { data: comment } = await startLoading(dispatch, 'customers:comment:action:save', () => api.putCommentAction({ id, actionData, actionId }))

      commit('UPDATE_CUSTOMER_COMMENT', { comment })
    },
    async loadCustomerAssignedComments ({ commit, dispatch }) {
      const { data: comments } = await startLoading(dispatch, 'customer:assigned:comments:fetch', () => api.getUserAssignedComments())

      commit('SET_CUSTOMER_ASSIGNED_COMMENTS', { comments })
    },
    async loadCustomerBankStatement ({ dispatch, commit }, { customerId }) {
      const { data: statements } = await startLoading(dispatch, 'customer:bankStatement:fetch', () => api.getUserAccountStatement({ customerId }))

      commit('SET_CUSTOMER_BANK_STATEMENT', { statements })
    },
    async createCustomerPayout ({ dispatch, state }, paymentData) {
      await startLoading(dispatch, 'customer:payout:save', () => api.postFromPrepayment({ paymentData }))

      dispatch('loadCustomer', { id: state.customer.id })
    },
    async loadCustomerReports ({ dispatch, commit }, { customerId }) {
      const { data: reports } = await startLoading(dispatch, 'customer:reports:fetch', () => api.getDeltaRatingReportsByUser({ customerId }))

      commit('SET_CUSTOMER_REPORTS', { reports })
    },
    async loadCustomerReportNew ({ dispatch, commit }, { customerId }) {
      const { data: report } = await startLoading(dispatch, 'customer:report:new:fetch', () => api.getDeltaRatingReportNewByUser({ customerId }))

      commit('ADD_CUSTOMER_REPORT', { report })
    },
    async deleteCustomer ({ dispatch }, { customerId }) {
      await startLoading(dispatch, 'customer:delete', () => api.deleteCustomer({ customerId }))
    },
    async anonymizeCustomerData ({ dispatch }, { customerId }) {
      await startLoading(dispatch, 'customer:anonymize:save', () => api.postAnonymizeCustomer({ customerId }))
      dispatch('loadCustomer', { id: customerId })
    },
    async saveDeleteBankAccount ({ dispatch, commit }, { customerId, accountId }) {
      const { data: customer } = await startLoading(
        dispatch,
        'customer:bankAccount:delete',
        () => api.deleteCustomerBankAccount({ customerId, accountId })
      )
      commit('UPDATE_CUSTOMER', { customer })
    },
    async loadCustomerFactoringContracts ({ dispatch, commit }, { customerId }) {
      const { data: contracts } = await startLoading(dispatch, 'customer:factoring:contracts:fetch', () => api.getCustomerFactoringContracts({ customerId }))

      commit('SET_CUSTOMER_FACTORING_CONTRACTS', { contracts })
    },
    async loadCustomerFactoringThirdParties ({ dispatch, commit }, { customerId }) {
      const { data: thirdParties } = await startLoading(dispatch, 'customer:factoring:third:parties:fetch', () => api.getCustomerThirdPartyAgreements({ customerId }))

      commit('SET_CUSTOMER_FACTORING_THIRD_PARTIES', { thirdParties })
    },
    async loadCustomerAnnualReports ({ dispatch, commit }, { customerId }) {
      const { data: annualReports } = await startLoading(dispatch, 'customer: annual:reports:fetch', () => api.getCustomerAnnualReports({ customerId }))

      commit('SET_CUSTOMER_ANNUAL_REPORTS', { annualReports })
    },
    async createCustomerAnnualReport ({ commit, dispatch }, { customerId, ...data }) {
      try {
        const { data: report } = await startLoading(dispatch, `customer:${customerId}:annual-report:save`, () => api.postCustomerAnnualReport({ customerId, data }))
        commit('SET_CUSTOMER_ANNUAL_REPORT', { report })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async editCustomerAnnualReport ({ commit, dispatch }, { reportId, ...data }) {
      try {
        const { data: report } = await startLoading(dispatch, `customer:${reportId}:annual-report:save`, () => api.putCustomerAnnualReport({ reportId, data }))
        commit('SET_CUSTOMER_ANNUAL_REPORT', { report })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async loadCustomerCustomFields ({ dispatch, commit }, { customerId }) {
      const { data: customFields } = await startLoading(dispatch, 'customer:custom-fields:fetch', () => api.getCustomerFieldsById({ customerId }))

      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async saveCustomerCustomFields ({ dispatch, commit }, { customerId, customerFieldData }) {
      const { data: customFields } = await startLoading(dispatch, 'customer:custom-fields:save', () => api.postCustomerFieldsById({ customerId, customerFieldData }))

      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async loadCustomerPrepaymentAccounts ({ dispatch, commit }, { customerId }) {
      const { data: accounts } = await startLoading(dispatch, 'customer:prepaymentAccounts:fetch', () => api.getCustomerPrepaymentAccounts({ customerId }))

      commit('SET_PREPAYMENT_ACCOUNTS', { accounts })
    }
  },

  mutations: {
    SET_CUSTOMERS (state, { customers, customersPages }) {
      state.customers = customers
      state.customersPages = customersPages
    },

    CLEAR_CUSTOMER (state) {
      state.customer = null
      state.customerApplications = []
      state.customerHistory = null
      state.customerLoans = []
      state.customerMessages = []
      state.customerResources = []
      state.customerPayments = []
      state.customerReports = []
      state.customerPrepaymentAccounts = []
    },

    CLEAR_COMMENTS (state) {
      state.customerComments = []
      state.customerCommentsPages = 0
    },

    SET_CUSTOMER (state, { customer }) {
      state.customer = customer
    },

    UPDATE_CUSTOMER (state, { customer }) {
      state.customer = { ...state.customer, ...customer }
    },

    SET_CUSTOMER_APPLICATIONS (state, { applications }) {
      state.customerApplications = applications
    },

    SET_CUSTOMER_HISTORY (state, { history }) {
      state.customerHistory = {
        ...state.customerHistory,
        contracts: orderBy([
          ...(history.loans ?? []),
          ...(history.deposits || []),
          ...(history.factorings ?? [])
        ],
        ['createdAt', 'id'],
        ['desc', 'desc']),
        creditApplications: orderBy(history.creditApplications, ['createdAt', 'id'], ['desc', 'desc'])
      }
    },

    SET_CUSTOMER_LOANS (state, { loans }) {
      state.customerLoans = loans
    },

    SET_CUSTOMER_DEPOSITS (state, { deposits }) {
      state.customerDeposits = deposits
    },

    SET_CUSTOMER_MESSAGES (state, { messages, totalPages }) {
      state.customerMessages = messages
      state.customerMessagesPages = totalPages
    },

    ADD_CUSTOMER_MESSAGE (state, { message, direction }) {
      if (message.direction !== direction) {
        return
      }
      state.customerMessages.push(message)
    },

    REMOVE_CUSTOMER_ADDRESS (state, { id }) {
      state.customer.addresses = state.customer.addresses.filter(address => address.id !== id)
    },

    ADD_CUSTOMER_ROLE (state, { role }) {
      state.customer.roles.push(role)
    },

    UPDATE_CUSTOMER_ROLE (state, { role }) {
      state.customer.roles = unionBy([role], state.customer.roles, 'id')
    },

    REMOVE_CUSTOMER_ROLE (state, { id }) {
      state.customer.roles = state.customer.roles.filter(role => role.id !== id)
    },

    SET_CUSTOMER_RELATIONS (state, { relations }) {
      state.customerRelations = relations
    },

    ADD_CUSTOMER_RELATION (state, { relations }) {
      state.customerRelations.relations = relations
    },

    UPDATE_CUSTOMER_RELATION (state, { relation }) {
      state.customerRelations.relations = unionBy([relation], state.customerRelations.relations, 'id')
    },

    UPDATE_CUSTOMER_REFERRED_RELATION (state, { relation }) {
      state.customerRelations.referredRelations = unionBy([relation], state.customerRelations.referredRelations, 'id')
    },

    REMOVE_CUSTOMER_RELATION (state, { id }) {
      state.customerRelations.relations = state.customerRelations.relations.filter(relation => relation.id !== id)
    },

    SET_SYSTEM_CUSTOMERS (state, { customers }) {
      state.systemCustomers = customers
    },

    UPDATE_CUSTOMER_COMMENT (state, { comment }) {
      state.customerComments = unionBy([comment], state.customerComments, 'id')
    },

    REMOVE_CUSTOMER_COMMENT (state, { id }) {
      state.customerComments = state.customerComments.filter(comment => comment.id !== id)
    },

    SET_CUSTOMER_RESOURCES (state, { resources, totalPages }) {
      state.customerResources = resources
      state.customerResourcesPages = totalPages
    },

    ADD_CUSTOMER_RESOURCE (state, { resource }) {
      state.customerResources.push(resource)
    },

    REMOVE_CUSTOMER_RESOURCE (state, { id }) {
      state.customerResources = state.customerResources.filter(comment => comment.id !== id)
    },

    SET_CUSTOMER_ACCOUNT (state, { account }) {
      state.customerPrepaymentAccounts = unionBy([account], state.customerPrepaymentAccounts, 'id')
    },

    SET_CUSTOMER_ANNUAL_REPORT (state, { report }) {
      state.customerAnnualReports = unionBy([report], state.customerAnnualReports, 'id')
    },

    SET_CUSTOMER_PAYMENTS (state, { payments }) {
      state.customerPayments = orderBy(payments, 'createdAt', 'desc')
    },

    SET_CUSTOMER_ACCOUNT_TRANSACTIONS (state, { transactions, transactionsPages }) {
      state.customerAccountTransactions = transactions
      state.customerAccountTransactionsPages = transactionsPages
    },
    CLEAR_CUSTOMER_ACCOUNT_TRANSACTIONS (state) {
      state.customerAccountTransactionsPages = 0
    },
    SET_CUSTOMER_DEBTS (state, { debts }) {
      state.customerHistory = {
        ...state.customerHistory,
        debts: orderBy(debts, ['paymentDate', 'id'], ['desc', 'desc'])
      }
    },
    SET_CUSTOMER_ROLES (state, { roles }) {
      state.customerRoles = roles
    },
    SET_CUSTOMER_RELATED_LOANS (state, { loans }) {
      state.customerRelatedLoans = loans
    },
    SET_CUSTOMER_COMMENTS (state, { comments, totalPages }) {
      state.customerComments = comments
      state.customerCommentsPages = totalPages
    },
    ADD_CUSTOMER_COMMENT (state, { comment }) {
      state.customerComments.push(comment)
    },
    SET_CUSTOMER_ASSIGNED_COMMENTS (state, { comments }) {
      state.customerAssignedComments = comments
    },
    SET_CUSTOMER_RESOURCE (state, { resource }) {
      state.customerResources = unionBy([resource], state.customerResources, 'id')
    },
    SET_CUSTOMER_BANK_STATEMENT (state, { statements }) {
      state.customerBankStatements = statements
    },
    SET_CUSTOMER_OUTGOING_PAYMENTS (state, { outgoing }) {
      state.customerOutgoingPayments = outgoing
    },
    SET_CUSTOMER_REPORTS (state, { reports }) {
      state.customerReports = reports
    },
    ADD_CUSTOMER_REPORT (state, { report }) {
      state.customerReports = unionBy([report], state.customerReports, 'timestamp')
    },
    SET_CUSTOMER_FACTORING_CONTRACTS (state, { contracts }) {
      state.customerFactoring = contracts
    },
    SET_CUSTOMER_FACTORING_THIRD_PARTIES (state, { thirdParties }) {
      state.customerThirdParties = thirdParties
    },
    SET_CUSTOMER_ANNUAL_REPORTS (state, { annualReports }) {
      state.customerAnnualReports = annualReports
    },
    SET_CUSTOM_FIELDS (state, { customFields }) {
      state.customFieldsValues = customFields
    },
    SET_PREPAYMENT_ACCOUNTS (state, { accounts }) {
      state.customerPrepaymentAccounts = accounts
    },
    SET_CUSTOMER_ADDRESS (state, { addresses }) {
      state.customerAddress = addresses
    }
  }
}
