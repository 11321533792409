import { connection, downloadFile } from '@/api/connection'
import pickBy from 'lodash/pickBy'
import qs from 'qs'
import pick from 'lodash/pick'
import { createBlobFromObject } from '@/helpers'

export default {
  getFactoringContract: ({ id }) => connection.get(`bo/factoring/contracts/${id}`),
  patchFactoringContract: ({ id, factoringData }) => connection.patch(`bo/factoring/contracts/${id}`, factoringData, {
    headers: {
      'Content-Type': 'application/merge-patch+json'
    }
  }),
  getFactoringContractOverview: ({ id }) => connection.get(`bo/factoring/contracts/${id}/overview`),
  postFactoringContract: ({ factoringData }) => connection.post('bo/factoring/contracts', factoringData),
  getFactoringContractTerms: ({ id, params }) => connection.get(`bo/factoring/contracts/${id}/terms`, {
    params
  }),
  putFactoringStatus: ({ contractId, statusId, statusData }) => connection.put(`bo/factoring/contracts/${contractId}/status/${statusId}`, statusData),
  getFactoringContractClassifiers: () => connection.get('bo/factoring/contracts/classifiers'),
  postFactoringContractTermCopy: ({ contractId }) => connection.post(`bo/factoring/contracts/${contractId}/terms/copy`),
  putFactoringContractActivateTerm: ({ contractId, data }) => connection.put(`bo/factoring/contracts/${contractId}/terms/activation`, data),
  putFactoringTerm: ({ contractId, termId, termData }) => connection.put(`bo/factoring/contracts/${contractId}/terms/${termId}`, termData),
  deleteFactoringContractTerm: ({ contractId, termId }) => connection.delete(`bo/factoring/contracts/${contractId}/terms/${termId}`),
  getFactoringContracts: ({ filters }) => connection.get('bo/factoring/contracts', {
    params: pickBy(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
    }
  }),
  getFactoringFinancial: ({ contractId }) => connection.get(`bo/factoring/contracts/${contractId}/financial`),
  getFactoringContractTransactions: ({ contractId, filters }) => connection.get(`bo/factoring/contracts/${contractId}/transactions`, {
    params: pickBy(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
    }
  }),
  postFactoringTerminate: ({ contractId, data }) => connection.post(`bo/factoring/contracts/${contractId}/terminate`, data),
  getFactoringMessageTemplate: ({ templateId, contractId, thirdPartyId }) => {
    if (thirdPartyId) {
      return connection.get(`bo/factoring/contracts/${contractId}/templates/${templateId}/model?thirdPartyId=${thirdPartyId}`)
    } else {
      return connection.get(`bo/factoring/contracts/${contractId}/templates/${templateId}/model`)
    }
  },
  putFactoringTermReason ({ contractId, termId, changeReasonComment }) {
    const queryString = qs.stringify(pickBy({
      changeReasonComment
    }))
    return connection.put(`bo/factoring/contracts/${contractId}/terms/${termId}/comment?${queryString}`)
  },
  getFactoringMessageTemplatePreview: ({ templateId, contractId, languageId, thirdPartyId }) => {
    const queryString = qs.stringify(pickBy({
      languageId,
      thirdPartyId
    }))
    return downloadFile(`bo/factoring/contracts/${contractId}/templates/${templateId}/preview?${queryString}`)
  },
  postFactoringMessage: ({ contractId, data }) => {
    const formData = new FormData()
    const messageData = pick(data, ['text', 'subject', 'customerEmail', 'customerPhoneNumber'])
    formData.append('templates', JSON.stringify([data.infoSheetTemplateId, data.agreementTemplateId, data.scheduleTemplateId].filter(Number)))
    formData.append('channel', data.channel)
    formData.append('message', createBlobFromObject(messageData))
    formData.append('languageId', data.languageId)
    if (data.files.length) {
      formData.append('documentCategoryId', data.documentCategoryId)
    }
    formData.append('recipients', JSON.stringify(data.recipients))
    if (data.thirdPartyId) {
      formData.append('thirdPartyId', data.thirdPartyId)
    }
    for (const file of data.files) {
      formData.append('files', file)
    }
    return connection.post(`bo/factoring/contracts/${contractId}/message`, formData)
  }
}
