<template lang = "pug">
tr.customer-tasks-list-item
  td
    fi-switch.ml-auto(
      v-model   = 'computedStatus'
      :disabled = 'saving'
      on-text   = 'Active'
      off-text  = 'Inactive'
    )
  td.text-center {{ category.human }}
  td.text-center.text-nowrap
    router-link(:to = '{ name: "Customer", params: { id: comment.createdBy } }') {{ comment.createdByName }}
    br
    small.text-muted.mr-1 {{ $t('common:formatDatetime', { value: comment.createdAt }) }}
  td.text-nowrap.text-center
    router-link(
      v-if = 'comment.assignedTo'
      :to  = '{ name: "Customer", params: { id: comment.assignedTo } }'
    ) {{ managerById(comment.assignedTo).name }}
    button.btn.btn-link(
      v-else
      @click = 'assignToMe'
    ) {{ $t('assignToMe') }}
  td.text-nowrap.text-center {{ $t('common:formatDate', { value: comment.dueDate }) }}
  td.text-nowrap.text-center
    .badge(v-for = '{ type } in comment.actions')
      i(
        :class = 'commentActionIcon(type.code)'
        :title = 'type.code'
      )
  td.text-center.text-nowrap
    template(v-if = 'callAction && callAction.result') {{ classifierByName(callAction.result.classifier, callAction.result.code).human }}
    .input-group.animated.fadeIn(v-else-if = 'editResult')
      fi-select(
        v-model   = 'taskResult'
        :options  = 'optionsFromClassifier("commentActionResult")'
        sm
      )
      .input-group-prepend
        button.btn.btn-sm.btn-link(@click = 'saveResult'): i.fa.fa-check.fa-lg.text-success
        button.btn.btn-sm.btn-link(@click = 'editResult = false'): i.fa.fa-close.fa-lg.text-danger
    button.btn.btn-link.p-0(
      v-else-if = 'callAction'
      @click    = 'editResult = true'
    ) {{ $t('addResult') }}

  td.text-center
    router-link(:to  = '`${$route.matched[1].path}/${comment.commentableId}/overview`') {{ comment.contractAgreementNumber }}
  td.w-auto: .comment-text(v-html = 'sanitizedComment')
  td.text-nowrap.text-right
    button.btn.btn-link.py-0.btn-sm(@click.prevent = 'editing = true'): i.far.fa-edit.fa-lg
    i.fa.fa-spinner.fa-pulse(v-if = 'removing')
    button.btn.btn-link.py-0.btn-sm.text-danger(
      v-else
      :disabled    = 'removing'
      v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }'
    ): i.far.fa-trash-alt.fa-lg
    customer-comment-modal(
      v-model           = 'editing'
      :comment          = 'comment'
      :commentable-type = 'commentableType'
      :store-module     = 'storeModule'
      task
    )
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { Comment } from '@/models'
import { mapActions, mapGetters, mapState } from 'vuex'
import CustomerCommentModal from './CustomerCommentModal'
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'customer-tasks-list-item',

  components: {
    FiSelect,
    FiDataField,
    FiSwitch,
    CustomerCommentModal
  },

  inject: ['storeModule', 'commentableType'],

  props: {
    comment: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      editResult: false,
      taskResult: ''
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'managerById', 'commentActionIcon', 'optionsFromClassifier']),
    ...mapState('auth', ['user']),
    saving () {
      return this.$vueLoading.isLoading(`${this.storeModule}:comment:${this.comment.id}:save`)
    },
    removing () {
      return this.$vueLoading.isLoading(`${this.storeModule}:comment:${this.comment.id}:remove`)
    },
    category () {
      return this.classifierById('commentCategory', this.comment.categoryId)
    },
    callAction () {
      return this.comment.actions.find(({ type }) => type.code === 'CALL')
    },
    computedStatus: {
      get () {
        return this.comment.active
      },
      set (status) {
        if (!this.saving) {
          this.updateComment({
            commentId: this.comment.id,
            commentData: new Comment({ ...this.comment, active: status })
          })
        }
      }
    },
    sanitizedComment () {
      return sanitizeHtml(this.comment.comment)
    }
  },

  methods: {
    ...mapActions({
      updateComment (dispatch, payload) {
        return dispatch(`${this.storeModule}/updateComment`, payload)
      },
      removeComment (dispatch, payload) {
        return dispatch(`${this.storeModule}/removeComment`, payload)
      },
      updateCommentAction (dispatch, payload) {
        return dispatch(`${this.storeModule}/updateCommentAction`, payload)
      }
    }),
    async remove ({ done }) {
      await this.removeComment({ id: this.comment.id })
      done()
    },
    async saveResult () {
      await this.updateCommentAction({
        id: this.comment.id,
        actionId: this.callAction.id,
        actionData: { result: this.taskResult }
      })
      this.editResult = false
    },
    assignToMe () {
      this.updateComment({
        commentId: this.comment.id,
        commentData: new Comment({ ...this.comment, assignedTo: this.user.id })
      })
    }
  }
}
</script>


<style lang = "scss" scoped>
.comment-text {
  white-space: pre-line;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px; /* fallback */
  max-height: 56px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>


<i18n>
en:
  removeConfirm: "Are you sure you want to delete this note?"
  addResult:     "Add result"
  assignToMe:    "Assign to me"
et:
  removeConfirm: "Are you sure you want to delete this note?"
  addResult:     "Add result"
  assignToMe:    "Assign to me"
ru:
  removeConfirm: "Are you sure you want to delete this note?"
  addResult:     "Add result"
  assignToMe:    "Assign to me"
</i18n>
