<template lang="pug">
fi-modal.session-timeout-modal(ref               = 'modal'
                               v-model           = 'showModal'
                               :title            = '$t("title")'
                               header-bg-variant = 'warning'
                               lazy
                               @hide             = 'onContinue')
  | {{ $t('message', { minutes: Math.ceil(remainingSeconds / 60) }) }}
  template(#modal-footer)
    button.btn.btn-primary(@click.prevent = '$refs.modal.hide()') {{ $t('ok') }}
    button.btn.btn-secondary(@click.prevent = 'onLogout') {{ $t('cancel') }}
</template>


<script>
import FiModal from '@/components/FiModal'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'session-timeout-modal',

  components: {
    FiModal
  },

  i18nOptions: {},

  data () {
    return {
      currentTimestamp: new Date().valueOf()
    }
  },

  computed: {
    ...mapState('auth', ['lastRequestTimestamp', 'isIdCardAuth']),
    showModal: {
      get () {
        return Number.isInteger(this.remainingSeconds) && this.remainingSeconds <= 300
      },
      set () {}
    },
    remainingSeconds () {
      if (!this.lastRequestTimestamp) {
        return
      }
      const logoutTimeout = 29.5 * 60
      const elapsedSeconds = (this.currentTimestamp - this.lastRequestTimestamp) / 1000 | 0
      return logoutTimeout - elapsedSeconds
    }
  },

  watch: {
    remainingSeconds (seconds) {
      if (Number.isInteger(seconds) && seconds <= 0) this.onLogout()
    }
  },

  created () {
    this.startIntervalCounter()
  },

  beforeDestroy () {
    this.stopIntervalCounter()
  },

  methods: {
    ...mapActions('auth', ['logout', 'updateSession']),
    startIntervalCounter () {
      this.intervalId = setInterval(() => { this.currentTimestamp = Date.now() }, 3000)
    },
    stopIntervalCounter () {
      clearInterval(this.intervalId)
    },
    async onContinue () {
      this.stopIntervalCounter()
      await this.updateSession()
      this.startIntervalCounter()
    },
    async onLogout () {
      await this.logout()
      if (this.isIdCardAuth) {
        this.$router.push({ name: 'IdLogout' })
      } else {
        this.$router.push({ name: 'Auth' })
      }
    }
  }
}
</script>


<i18n>
en:
  title:   "Session is about to expire"
  message: "Your session will expire in {{ minutes }} minutes. Do you want to continue?"
  ok:      "Continue"
  cancel:  "Log out"
et:
  title:   "Session is about to expire"
  message: "Your session will expire in {{ minutes }} minutes. Do you want to continue?"
  ok:      "Continue"
  cancel:  "Log out"
ru:
  title:   "Session is about to expire"
  message: "Your session will expire in {{ minutes }} minutes. Do you want to continue?"
  ok:      "Continue"
  cancel:  "Log out"
</i18n>
