import { validators } from '@/validation'

export default class Resource {
  constructor (resourceData = {}) {
    this.file = resourceData.file
    this.comment = resourceData.comment
    this.documentCategoryId = resourceData.documentCategoryId
    this.visibleForPortal = Boolean(resourceData.visibleForPortal)
    this.documentRelationCode = resourceData.documentRelationCode
    this.documentRelationEntityId = resourceData.documentRelationEntityId
    this.documentRelationEntityContractNumber = resourceData.documentRelationEntityContractNumber
  }

  static get validation () {
    return {
      documentCategoryId: {
        required: validators.required
      }
    }
  }
}
