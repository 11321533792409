<template lang="pug">
li.dashboard-widget-deposit-notifications-item
  router-link.text-body.d-block(:to = '{ name: "Deposit", params: { id: notification.depositContractId } }')
   .row
      .col
        strong {{ notification.customerName }}
        .small {{ $t(notification.type) }}
      .col-auto.small {{ $t('common:formatDate', { value: notification.createdAt }) }}
  hr.mt-2.mb-2
</template>


<script>
export default {
  name: 'dashboard-widget-deposit-notifications-item',

  props: {
    notification: {
      type: Object,
      required: true
    }
  }
}
</script>

<i18n>
en:
  PENDING:            "Pending agreements"
  POLICY_ENDING:      "Ending insurance policies"

et:
  PENDING:            "Pending agreements"
  POLICY_ENDING:      "Ending insurance policies"

ru:
  PENDING:            "Pending agreements"
  POLICY_ENDING:      "Ending insurance policies"
</i18n>
