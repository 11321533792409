export default [
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Auth'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Login'),
        props: route => ({ redirect: route.query.redirect })
      },
      {
        path: 'card',
        name: 'Card',
        component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/CardAuth'),
        props: route => ({ redirect: route.query.redirect })
      },
      {
        path: 'mobile',
        name: 'Mobile',
        component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Mobile'),
        props: route => ({ redirect: route.query.redirect })
      },
      {
        path: 'smart',
        name: 'Smart',
        component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Smart'),
        props: route => ({ redirect: route.query.redirect })
      }
    ]
  },
  {
    path: '/auth/reset',
    component: () => import(/* webpackChunkName: "Auth" */'@/views/passwordReset/ResetView'),
    redirect: 'ResetRequest',
    children: [
      {
        path: '',
        name: 'ResetRequest',
        component: () => import(/* webpackChunkName: "Auth" */'@/views/passwordReset/ResetRequest')
      },
      {
        path: ':token',
        name: 'NewPassword',
        component: () => import(/* webpackChunkName: "Auth" */'@/views/passwordReset/ResetNewPassword'),
        props: true
      }
    ]
  },
  {
    path: '/id-logout',
    name: 'IdLogout',
    component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/IdLogout')
  }
]
