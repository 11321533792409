import pick from 'lodash/pick'
import findLast from 'lodash/findLast'
import orderBy from 'lodash/orderBy'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import unionBy from 'lodash/unionBy'
import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading, endLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    applications: [],
    applicationsPages: 0,
    application: null,
    applicationStatusHistory: null,
    applicationCustomer: null,
    systemCustomer: null,
    systemCustomers: [],
    applicationMessages: [],
    applicationMessagesPages: 0,
    applicationProduct: {},
    applicationPreScoreRequest: null,
    applicationPersonalScoreRequest: null,
    applicationScoreRequest: null,
    applicationCustomerHistory: null,
    applicationOfferTemplate: null,
    applicationLoanId: null,
    applicationResources: [],
    applicationResourcesPages: 0,
    applicationAssets: [],
    applicationMortgages: [],
    applicationCustomerComments: [],
    applicationCustomerBankStatements: [],
    applicationComments: [],
    commentsPages: 0,
    applicationMemberScoreRequests: {},
    applicationOwnersScoreRequests: {},
    customFieldsValues: [],
    customerFinancialData: [],
    applicationRelatedPersons: [],
    applicationPaidTaxes: []
  },

  getters: {
    sortedComments: state => orderBy(state.applicationComments, ['commentableType.id', 'commentableId', 'id'], ['desc', 'asc', 'desc']),
    applicationStatusColor: (state, getters, rootState, rootGetters) => applicationStatusId => {
      const colorMap = {
        inserted: 'primary',
        inProgress: 'warning',
        offerSent: 'warning',
        closed: 'danger',
        contractPrepared: 'success'
      }
      return colorMap[rootGetters['classifiers/classifierById']('creditApplicationStatuses', applicationStatusId).code] || 'dark'
    },
    customerFromApplication: () => application => {
      const { countryId, countyId, city, fullAddress, postalCode } = application
      const customer = pick(application, ['firstName', 'lastName', 'customerName', 'idCode', 'sectorId', 'segmentId', 'phoneNumber', 'email', 'countryId'])

      customer.addresses = [{
        countryId,
        countyId,
        city,
        fullAddress,
        postalCode,
        isDefault: true
      }]

      return customer
    },
    customerDataForApplication: () => (customer) => {
      const data = pick(customer, [
        'firstName',
        'lastName',
        'customerName',
        'idCode',
        'sectorId',
        'segmentId',
        'phoneNumbers',
        'emails',
        'countryId',
        'creditLimit',
        'externalBankAccounts',
        'emtakId',
        'naceId'
      ])
      const { addresses = [] } = customer || {}
      const address = addresses.find(({ isDefault }) => isDefault) || addresses[0]
      if (address) {
        data.countyId = address.countyId
        data.city = address.city
        data.address = address.fullAddress
        data.postalCode = address.postalCode
      }
      data.accountExternalId = customer?.defaultExternalAccount?.accountExternalNumber
      return data
    },
    historyStatus: (state) => (statusId) => findLast(state.application.creditApplicationStatusHistoryList, { statusId }),
    systemCustomersForSelect: (state, getters, rootState, rootGetters) => state.systemCustomers.map((customer) => ({
      value: customer.id,
      text: `${rootGetters['customers/customerName'](customer)} (${customer.idCode})`
    })),
    applicationProductFees: ({ applicationProduct }) => applicationProduct.fees?.reduce((res, fee) => ({
      ...res,
      [fee.feeType.code]: fee
    }), {})
  },

  actions: {
    async loadApplications ({ commit, dispatch }, { filters } = {}) {
      commit('SET_APPLICATIONS', { applications: [], applicationsPages: 0 })
      const response = await startLoading(dispatch, 'applications:fetch', () => api.getApplications({ filters }))
      commit('SET_APPLICATIONS', { applications: response.data.content, applicationsPages: response.data.totalPages })
      commit('CLEAR_APPLICATION')
    },

    async loadApplication ({ commit, dispatch }, { id }) {
      commit('CLEAR_APPLICATION')

      const response = await startLoading(dispatch, 'application:fetch', () => api.getApplication({ id }))

      commit('SET_APPLICATION', { application: response.data })
    },

    async loadApplicationProduct ({ state, commit, dispatch }, { id = state.application.productId, group } = {}) {
      const getProduct = group === 'FACTORING' ? api.getFactoringProduct : api.getProduct
      const response = await startLoading(dispatch, 'application:product:fetch', () => getProduct({ id }))

      commit('SET_APPLICATION_PRODUCT', { product: response.data })
    },

    async loadApplicationCustomer ({ state, commit, dispatch }, { id = state.application.customerId } = {}) {
      const response = await startLoading(dispatch, 'application:customer:fetch', () => api.getCustomer({ id }))

      commit('SET_APPLICATION_CUSTOMER', { customer: response.data })
    },

    async loadApplicationCustomerComments ({ commit, dispatch }, { id, filters }) {
      commit('CLEAR_APPLICATION_CUSTOMER_COMMENTS')

      const { data: { content: comments, totalPages } } = await startLoading(
        dispatch,
        'application:customer:comments:fetch',
        () => api.getCustomerComments({ customerId: id, filters })
      )

      commit('SET_APPLICATION_CUSTOMER_COMMENTS', { comments, totalPages })
    },

    async findSystemCustomer ({ state, commit, dispatch }) {
      startLoading(dispatch, 'application:systemCustomer:fetch')
      const { data } = await api.getCustomerByIdCode({ idCode: state.application.idCode })
      const foundCustomers = data.content
      if (foundCustomers.length) {
        await dispatch('loadSystemCustomer', { id: foundCustomers[0].id })
      } else {
        commit('SET_SYSTEM_CUSTOMER', { customer: {} })
      }
      endLoading(dispatch, 'application:systemCustomer:fetch')
    },

    async findSystemCustomers ({ commit, dispatch }, { query, sectorId, segmentId }) {
      if (!query) {
        commit('SET_SYSTEM_CUSTOMERS', { customers: [] })
        return Promise.resolve()
      }
      const response = await startLoading(
        dispatch,
        'applications:systemCustomers:fetch',
        () => api.getCustomers({ filters: { criteria: query, sectorId, segmentId } })
      )
      commit('SET_SYSTEM_CUSTOMERS', { customers: response.data.content })
    },

    async loadSystemCustomer ({ commit }, { id }) {
      const response = await api.getCustomer({ id })

      commit('SET_SYSTEM_CUSTOMER', { customer: response.data })
    },

    async loadMessages ({ state, commit, dispatch }, filters) {
      const { data: { content: messages, totalPages } } = await startLoading(
        dispatch,
        'customer:messages:fetch',
        () => api.getCustomerMessages({ customerId: state.application.customerId, filters })
      )
      commit('SET_APPLICATION_MESSAGES', { messages, totalPages })
    },

    async loadCustomerHistory ({ state, commit, dispatch }) {
      const response = await startLoading(
        dispatch,
        'customer:history:fetch',
        () => api.getCustomerHistory({ customerId: state.application.customerId })
      )
      commit('SET_APPLICATION_CUSTOMER_HISTORY', { history: response.data })
    },

    async loadCustomerDebts ({ commit, dispatch, state }) {
      const { data = [] } = await startLoading(
        dispatch,
        'customer:debts:fetch',
        () => api.getCustomerDebts({ customerId: state.application.customerId })
      )

      commit('SET_CUSTOMER_DEBTS', { debts: data })
    },

    async loadApplicationScoreRequest ({ commit }, { requestType, requestId, type }) {
      if (!requestId) {
        commit('CLEAR_APPLICATION_SCORE_REQUEST', { requestType })
        return
      }
      const response = await api.getScoreRequest({ requestId, type })

      commit('SET_APPLICATION_SCORE_REQUEST', { requestType: requestType, request: response.data })
    },

    async loadApplicationMemberScoreRequest ({ commit }, { requestId, type }) {
      const { data: request } = await api.getScoreRequest({ requestId, type })

      commit('SET_APPLICATION_MEMBER_SCORE_REQUEST', { request })
    },
    async loadApplicationOwnerScoreRequest ({ commit }, { requestId, type }) {
      const { data: request } = await api.getScoreRequest({ requestId, type })

      commit('SET_APPLICATION_OWNERS_SCORE_REQUEST', { request })
    },

    async createApplication ({ commit, dispatch }, { applicationData }) {
      const response = await startLoading(dispatch, 'application:save', () => api.postApplication({ applicationData }))

      commit('CLEAR_APPLICATION')
      commit('SET_APPLICATION', { application: response.data })
    },

    async updateApplication ({ commit, dispatch }, { id, applicationData, type }) {
      try {
        const { data: application } = await startLoading(
          dispatch,
          `application:${id}:save`,
          () => api.putApplication({ id, applicationData, type })
        )
        commit('SET_APPLICATION', { application })
      } catch ({ response: { data } }) {
        return Promise.reject(data.message)
      }
    },
    async removeApplicationAnnualReport ({ commit, dispatch }, { reportId, applicationId }) {
      try {
        const { data: application } = await startLoading(
          dispatch,
          `application:${applicationId}:save`,
          () => api.deleteApplicationAnnualReport({ reportId, applicationId })
        )
        commit('SET_APPLICATION', { application })
      } catch ({ response: { data } }) {
        return Promise.reject(data.message)
      }
    },

    async setApplicationAssignee ({ state, commit, dispatch }, { id, customerId }) {
      const { data: application } = await startLoading(
        dispatch,
        `applications:assignee:${id}:save`,
        () => api.putApplicationAssignee({ id, customerId })
      )
      commit('REPLACE_LIST_APPLICATION', { application })
      if (state.application) commit('SET_APPLICATION_ASSIGNEE', { application })
    },

    async loadOfferTemplate ({ commit, dispatch }, { templateId, objectId }) {
      commit('SET_APPLICATION_OFFER_TEMPLATE', { template: null })
      const response = await startLoading(
        dispatch,
        'application:offerTemplate:load',
        () => api.getTemplate({ id: templateId, objectId })
      )
      commit('SET_APPLICATION_OFFER_TEMPLATE', { template: response.data })
    },

    async sendOffer ({ state, commit, dispatch }, { formData }) {
      const response = await startLoading(
        dispatch,
        'application:offer:save',
        () => api.putOffer({ id: state.application.id, data: formData })
      )

      commit('SET_APPLICATION', { application: response.data })
    },

    async close ({ state, commit, dispatch }, { closeReasonId, comment }) {
      const response = await startLoading(
        dispatch,
        'application:close:save',
        () => api.putApplicationClose({ id: state.application.id, closeReasonId, comment })
      )

      commit('SET_APPLICATION', { application: response.data })
    },

    async linkSystemCustomer ({ state, commit, dispatch }) {
      await startLoading(
        dispatch,
        'application:systemCustomer:link:save',
        () => api.putApplicationSystemCustomerLink({ id: state.application.id, customerId: state.systemCustomer.id })
      )

      commit('LINK_APPLICATION_CUSTOMER')
    },

    async updateApplicationCustomer ({ dispatch, commit }, { customerId, customerData, privateCustomerData, addressData }) {
      const { data: customer } = await api.putCustomer({ id: customerId, data: customerData })

      const parallelResponses = await startLoading(dispatch, 'application:customer:save', async () => {
        let privateCustomerResponse
        if (!isEmpty(privateCustomerData)) {
          privateCustomerResponse = api.putCustomerPrivate({ customerId: customer.id, data: privateCustomerData })
        }
        const addressResponse = api.postCustomerAddress({ customerId: customer.id, data: addressData })

        return {
          privateCustomerResponse: await privateCustomerResponse,
          addressResponse: await addressResponse
        }
      })

      if (!isEmpty(privateCustomerData)) {
        customer.privateCustomer = parallelResponses.privateCustomerResponse.data
      }

      customer.addresses = parallelResponses.addressResponse.data

      commit('SET_APPLICATION_CUSTOMER', { customer })
    },

    async createMessage ({ state, commit, dispatch }, { messageData, direction }) {
      const response = await startLoading(
        dispatch,
        'customer:message:save',
        () => api.postCustomerMessage({ customerId: state.application.customerId, data: messageData }))
      commit('ADD_APPLICATION_MESSAGE', { message: response.data, direction })
    },

    async createApplicationScoreRequest ({ state, commit, dispatch }, { scoreType }) {
      const storeKey = {
        personal: 'applicationPersonalScoreRequest',
        application: 'applicationScoreRequest'
      }

      const response = await startLoading(
        dispatch,
        `application:scoreRequest:${scoreType}:save`,
        () => api.postApplicationScoreRequest({ id: state.application.id, scoreType })
      )

      commit('SET_APPLICATION_SCORE_REQUEST', { requestType: storeKey[scoreType], request: response.data })
    },

    async createCorporateScoreRequest ({ state, commit, dispatch }, { scoreType }) {
      const storeKey = {
        application: 'applicationScoreRequest',
        prescore: 'applicationPreScoreRequest'
      }

      const response = await startLoading(
        dispatch,
        `application:scoreRequest:${scoreType}:save`,
        () => api.postCorporateScoreRequest({ id: state.application.id, scoreType })
      )

      commit('SET_APPLICATION_SCORE_REQUEST', { requestType: storeKey[scoreType], request: response.data })
    },

    async approveNegativeScoring ({ dispatch, state, commit }, { approvingData }) {
      const response = await startLoading(
        dispatch,
        'application:negativeScoreNote:save',
        () => api.putApplicationApproveNegativeScore({ id: state.application.id, data: approvingData })
      )
      commit('SET_APPLICATION', { application: response.data })
    },

    async prepareContract ({ dispatch, state, commit }) {
      const response = await startLoading(
        dispatch,
        'application:prepareContract:save',
        () => api.putPrepareContract({ id: state.application.id })
      )
      commit('SET_APPLICATION_LOAN_ID', { loanId: response.data })
    },

    async loadResources ({ state, commit, dispatch }, { filters }) {
      const { data: { content: resources, totalPages } } = await startLoading(
        dispatch,
        'resources:fetch',
        () => api.getApplicationResources({ id: state.application.id, filters })
      )
      commit('SET_APPLICATION_RESOURCES', { resources, totalPages })
    },

    async createApplicationResource ({ state, commit, dispatch }, { resourceData, customerId }) {
      const response = await startLoading(dispatch, 'resource:save', () => api.postApplicationResource({
        applicationId: state.application.id,
        data: resourceData,
        customerId
      }))
      commit('ADD_APPLICATION_RESOURCE', { resource: response.data })
    },
    async removeResource ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `resource:${id}:remove`, () => api.deleteResource({ id: id }))
      commit('REMOVE_APPLICATION_RESOURCE', { id })
    },
    async setApplicationAnalysis ({ commit, dispatch }, { id }) {
      const { data: application } = await startLoading(
        dispatch,
        'application:analysis:save',
        () => api.putApplicationInAnalysis({ id })
      )

      commit('SET_APPLICATION', { application })
    },
    async createInternalComment ({ commit, dispatch }, { id, commentData }) {
      const { data: application } = await startLoading(
        dispatch,
        'application:comment:save',
        () => api.postInternalComment({ id, commentData })
      )

      commit('SET_APPLICATION', { application })
    },
    async createApplicationRelatedPersons ({ commit, dispatch }, { id, relatedPersons }) {
      const { data: relatedPerson } = await startLoading(
        dispatch,
        'application:comment:save',
        () => api.postApplicationRelatedPersons({ id, relatedPersons })
      )

      commit('ADD_APPLICATION_RELATED_PERSON', { relatedPerson })
    },
    async updateApplicationRelatedPersons ({ commit, dispatch }, { id, relatedPersons }) {
      const { data: relatedPerson } = await startLoading(
        dispatch,
        'application:comment:save',
        () => api.putApplicationRelatedPersons({ id, relatedPersons })
      )

      commit('SET_APPLICATION_RELATED_PERSON', { relatedPerson })
    },
    async updateInternalComment ({ commit, dispatch }, { id, commentData }) {
      const { data: comment } = await startLoading(
        dispatch,
        'application:comment:save',
        () => api.putComment({ id, data: commentData })
      )

      commit('SET_APPLICATION_COMMENT', { comment })
    },
    async loadAssets ({ commit, dispatch, state }) {
      const { data: assets } = await startLoading(
        dispatch,
        'applications:assets:fetch',
        () => api.getApplicationAssets({ id: state.application.id })
      )

      commit('SET_APPLICATION_ASSETS', {
        assets: assets.map(asset => ({
          ...asset
        }))
      })
    },
    async createAsset ({ commit, dispatch, state, rootGetters }, { assetData }) {
      const { data: asset } = await startLoading(
        dispatch,
        'applications:asset:save',
        () => api.postApplicationAsset({ id: state.application.id, assetData })
      )

      commit('ADD_APPLICATION_ASSET', { asset })
    },
    async updateAsset ({ commit, dispatch, state, rootGetters }, { id, assetData }) {
      const { data: asset } = await startLoading(
        dispatch,
        `applications:asset:${id}:save`,
        () => api.putApplicationAsset({ id, assetData })
      )

      commit('UPDATE_APPLICATION_ASSET', { asset })
    },
    async removeAsset ({ commit, dispatch, state }, { id }) {
      const { data: assets } = await startLoading(
        dispatch,
        `applications:asset:${id}:remove`,
        () => api.deleteApplicationAsset({ id })
      )

      commit('SET_APPLICATION_ASSETS', { assets })
    },
    async updateResource ({ commit, dispatch }, { resourceId, resourceData }) {
      const { data: resource } = await startLoading(
        dispatch,
        `applications:resource:${resourceId}:save`,
        () => api.putResource({ id: resourceId, resourceData })
      )

      commit('SET_APPLICATION_RESOURCE', { resource })
    },
    async updateApplicationOfferTerms ({ commit, dispatch }, { id, termsData }) {
      const { data: application } = await startLoading(
        dispatch,
        `application:${id}:offerTerms:save`,
        () => api.putApplicationOfferTerms({ id, termsData })
      )

      commit('SET_APPLICATION', { application })
    },
    async removeInternalComment ({ commit, dispatch }, { id, commentId }) {
      const { data: application } = await startLoading(
        dispatch,
        `application:${id}:comment:${commentId}:delete`,
        () => api.deleteInternalComment({ id, commentId })
      )

      commit('SET_APPLICATION', { application })
    },
    async loadApplicationCustomerBankStatement ({ dispatch, commit }, { idCode }) {
      const { data: statements } = await startLoading(
        dispatch,
        'application:customer:bankStatement:fetch',
        () => api.getUserAccountStatement({ idCode })
      )

      commit('SET_APPLICATION_CUSTOMER_BANK_STATEMENT', { statements })
    },
    async loadComments ({ commit, dispatch, state }, { id = state.application.id, filters } = {}) {
      commit('CLEAR_COMMENTS')

      const { data: { content: comments, totalPages } } = await startLoading(
        dispatch,
        'applications:comments:fetch',
        () => api.getApplicationComments({ id, filters })
      )

      commit('SET_APPLICATION_COMMENTS', { comments, totalPages })
    },
    async createComment ({ state, commit, dispatch }, { commentData, commentableId = state.application.id } = {}) {
      const { data: comment } = await startLoading(
        dispatch,
        'applications:comment:save',
        () => api.postComment({ ...commentData, commentableId })
      )
      commit('ADD_APPLICATION_COMMENT', { comment })
    },
    async updateComment ({ commit, dispatch }, { commentId, commentData }) {
      const { data: comment } = await startLoading(
        dispatch,
        `applications:comment:${commentId}:save`,
        () => api.putComment({ id: commentId, data: commentData })
      )
      commit('UPDATE_APPLICATION_COMMENT', { comment })
    },
    async removeComment ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `applications:comment:${id}:remove`, () => api.deleteComment({ id }))
      commit('REMOVE_APPLICATION_COMMENT', { id })
    },
    async scoreCorporateMember ({ commit, dispatch }, { id, memberData }) {
      await startLoading(dispatch, `application:scoring:member:${memberData.code}:save`, () => api.postScoreMember({ id, memberData }))
    },
    async loadApplicationCustomFields ({ dispatch, commit }, { applicationId }) {
      const { data: customFields } = await startLoading(dispatch, 'application:custom-fields:fetch', () => api.getApplicationCustomFieldValues({ applicationId }))

      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async loadApplicationRelatedPersons ({ commit, dispatch }, { applicationId }) {
      const { data: relatedPersons } = await startLoading(
        dispatch,
        'application:relatedPersons:fetch',
        () => api.getApplicationRelatedPersons({ applicationId })
      )

      commit('SET_APPLICATION_RELATED_PERSONS', { relatedPersons })
    },
    async loadApplicationPaidTaxes ({ commit, dispatch }, { idCode, idCodeCountry }) {
      const { data: paidTaxes } = await startLoading(
        dispatch,
        'application:paidTaxes:fetch',
        () => api.getApplicationPaidTaxes({ idCode, idCodeCountry })
      )

      commit('SET_APPLICATION_PAID_TAXES', { paidTaxes })
    },
    async removeApplicationRelatedPerson ({ commit, dispatch }, { relatedPersonId }) {
      await startLoading(
        dispatch,
        `application:relatedPerson:${relatedPersonId}:delete`,
        () => api.deleteApplicationRelatedPerson({ relatedPersonId })
      )

      commit('REMOVE_APPLICATION_RELATED_PERSON', { relatedPersonId })
    },
    async saveApplicationCustomFields ({ dispatch, commit }, { applicationId, customFieldData }) {
      const { data: customFields } = await startLoading(
        dispatch,
        'application:custom-fields:save',
        () => api.postApplicationCustomFieldValues({ applicationId, customFieldValues: customFieldData })
      )
      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async loadCustomerFinancialData ({ commit, dispatch }, { customerId, applicationId }) {
      const { data: { content: financial } } = await startLoading(dispatch, 'application:customerData:fetch', () => api.getCustomerFinancialData({ customerId, applicationId }))

      commit('SET_FINANCIAL_DATA', { financial })
    },
    async createCustomerFinancialData ({ commit, dispatch }, { customerId, ...financialData }) {
      const { data: financial } = await startLoading(dispatch, 'application:customerData:save', () => api.postCustomerFinancialData({ customerId, financialData }))

      commit('ADD_FINANCIAL_DATA', { financial })
    },
    async updateCustomerFinancialData ({ commit, dispatch }, { customerId, financialDataId, ...financialData }) {
      const { data: financial } = await startLoading(
        dispatch,
        `application:customerData:${financialDataId}:save`,
        () => api.putCustomerFinancialData({ customerId, financialData, financialDataId })
      )

      commit('UPDATE_FINANCIAL_DATA', { financial })
    },
    async removeCustomerFinancialData ({ commit, dispatch }, { customerId, applicationId }) {
      await startLoading(
        dispatch,
        `application:customerData:${applicationId}:remove`,
        () => api.deleteCustomerFinancialData({ customerId, applicationId })
      )

      commit('REMOVE_FINANCIAL_DATA', { applicationId })
      return 'Delete completed'
    }
  },

  mutations: {
    SET_APPLICATIONS (state, { applications, applicationsPages }) {
      state.applications = applications
      state.applicationsPages = applicationsPages
    },
    REPLACE_LIST_APPLICATION (state, { application }) {
      state.applications = unionBy([application], state.applications, 'id')
    },
    CLEAR_APPLICATION (state) {
      state.application = null
      state.applicationStatusHistory = null
      state.applicationMessages = []
      state.applicationProduct = {}
      state.applicationPreScoreRequest = null
      state.applicationPersonalScoreRequest = null
      state.applicationScoreRequest = null
      state.applicationCustomerHistory = null
      state.applicationOfferTemplate = null
    },
    SET_APPLICATION (state, { application }) {
      state.application = { ...application }
    },
    REMOVE_APPLICATION_RELATED_PERSON (state, { relatedPersonId }) {
      state.applicationRelatedPersons = state.applicationRelatedPersons.filter(relatedPerson => relatedPerson.id !== relatedPersonId)
    },
    SET_APPLICATION_MESSAGES (state, { messages, totalPages }) {
      state.applicationMessages = messages
      state.applicationMessagesPages = totalPages
    },
    ADD_APPLICATION_MESSAGE (state, { message, direction }) {
      if (message.direction !== direction) {
        return
      }
      state.applicationMessages.push(message)
    },
    SET_APPLICATION_RELATED_PERSONS (state, { relatedPersons }) {
      state.applicationRelatedPersons = relatedPersons
    },
    SET_APPLICATION_PAID_TAXES (state, { paidTaxes }) {
      state.applicationPaidTaxes = paidTaxes
    },
    SET_APPLICATION_PRODUCT (state, { product }) {
      state.applicationProduct = product
    },
    SET_APPLICATION_CUSTOMER (state, { customer }) {
      state.applicationCustomer = customer
    },
    SET_SYSTEM_CUSTOMER (state, { customer }) {
      state.systemCustomer = customer
    },
    SET_SYSTEM_CUSTOMERS (state, { customers }) {
      state.systemCustomers = customers
    },
    SET_APPLICATION_CUSTOMER_HISTORY (state, { history }) {
      state.applicationCustomerHistory = {
        ...state.applicationCustomerHistory,
        contracts: orderBy([...(history.loans || []), ...(history.deposits || [])], ['createdAt', 'id'], ['desc', 'desc']),
        creditApplications: orderBy(history.creditApplications, ['createdAt', 'id'], ['desc', 'desc'])
      }
    },
    LINK_APPLICATION_CUSTOMER (state) {
      state.application.customerId = state.systemCustomer.id
      state.applicationCustomer = cloneDeep(state.systemCustomer)
    },
    SET_APPLICATION_SCORE_REQUEST (state, { requestType, request }) {
      state[requestType] = request
    },
    CLEAR_APPLICATION_SCORE_REQUEST (state, { requestType }) {
      state[requestType] = null
    },
    SET_APPLICATION_OFFER_TEMPLATE (state, { template }) {
      state.applicationOfferTemplate = template
    },
    SET_APPLICATION_LOAN_ID (state, { loanId }) {
      state.applicationLoanId = loanId
    },
    SET_CUSTOMER_DEBTS (state, { debts }) {
      state.applicationCustomerHistory = {
        ...state.applicationCustomerHistory,
        debts: orderBy(debts, ['paymentDate', 'id'], ['desc', 'desc'])
      }
    },
    SET_APPLICATION_RESOURCES (state, { resources, totalPages }) {
      state.applicationResources = resources
      state.applicationResourcesPages = totalPages
    },
    ADD_APPLICATION_RESOURCE (state, { resource }) {
      state.applicationResources.push(resource)
    },
    REMOVE_APPLICATION_RESOURCE (state, { id }) {
      state.applicationResources = state.applicationResources.filter(resource => resource.id !== id)
    },
    SET_APPLICATION_COMMENT (state, { comment }) {
      state.application.internalNotes = unionBy([comment], state.application.internalNotes, 'id')
    },
    ADD_APPLICATION_RELATED_PERSON (state, { relatedPerson }) {
      state.applicationRelatedPersons.push(relatedPerson)
    },
    SET_APPLICATION_RELATED_PERSON (state, { relatedPerson }) {
      state.applicationRelatedPersons = unionBy([relatedPerson], state.applicationRelatedPersons, 'id')
    },
    SET_APPLICATION_ASSETS (state, { assets }) {
      state.applicationAssets = assets
    },
    UPDATE_APPLICATION_ASSET (state, { asset }) {
      state.applicationAssets = unionBy([asset], state.applicationAssets, 'id')
    },
    ADD_APPLICATION_ASSET (state, { asset }) {
      state.applicationAssets.push(asset)
    },
    SET_APPLICATION_RESOURCE (state, { resource }) {
      state.applicationResources = unionBy([resource], state.applicationResources, 'id')
    },
    SET_APPLICATION_CUSTOMER_COMMENTS (state, { comments }) {
      state.applicationCustomerComments = comments
    },
    CLEAR_APPLICATION_CUSTOMER_COMMENTS (state) {
      state.applicationCustomerComments = []
    },
    SET_APPLICATION_CUSTOMER_BANK_STATEMENT (state, { statements }) {
      state.applicationCustomerBankStatements = statements
    },
    SET_APPLICATION_COMMENTS (state, { comments, totalPages }) {
      state.applicationComments = comments
      state.commentsPages = totalPages
    },
    ADD_APPLICATION_COMMENT (state, { comment }) {
      state.applicationComments.push(comment)
    },
    UPDATE_APPLICATION_COMMENT (state, { comment }) {
      state.applicationComments = unionBy([comment], state.applicationComments, 'id')
    },
    REMOVE_APPLICATION_COMMENT (state, { id }) {
      state.applicationComments = state.applicationComments.filter(comment => comment.id !== id)
    },
    CLEAR_COMMENTS (state) {
      state.applicationComments = []
      state.commentsPages = 0
    },
    SET_APPLICATION_MEMBER_SCORE_REQUEST (state, { request }) {
      state.applicationMemberScoreRequests = {
        ...state.applicationMemberScoreRequests,
        [request.idCode]: request
      }
    },
    SET_APPLICATION_OWNERS_SCORE_REQUEST (state, { request }) {
      state.applicationOwnersScoreRequests = {
        ...state.applicationOwnersScoreRequests,
        [request.idCode]: request
      }
    },
    SET_CUSTOM_FIELDS (state, { customFields }) {
      state.customFieldsValues = customFields
    },
    SET_FINANCIAL_DATA (state, { financial }) {
      state.customerFinancialData = financial
    },
    ADD_FINANCIAL_DATA (state, { financial }) {
      state.customerFinancialData.push(financial)
    },
    UPDATE_FINANCIAL_DATA (state, { financial }) {
      state.customerFinancialData = unionBy([financial], state.customerFinancialData, 'id')
    },
    REMOVE_FINANCIAL_DATA (state, { dataId }) {
      state.customerFinancialData = state.customerFinancialData.filter(({ id }) => id !== dataId)
    },
    SET_APPLICATION_ASSIGNEE (state, { application }) {
      const { employeeId, lastAssignDate, statusId } = application
      state.application = {
        ...state.application,
        creditApplicationStatusHistoryList: [...state.application.creditApplicationStatusHistoryList, { statusId }],
        employeeId,
        lastAssignDate,
        statusId
      }
    }
  }
}
