<template lang="pug">
fi-modal.application-notifications-modal(ref               = 'modal'
                                         v-model           = 'showModal'
                                         :title            = '$t("title")'
                                         size              = 'sm'
                                         header-bg-variant = 'warning'
                                         lazy
                                         hide-footer)
  ul.list-unstyled.m-0
    dashboard-widget-application-notifications-item(v-for         = 'notification in dashboardApplicationNotifications'
                                                    :key          = 'notification.id'
                                                    :notification = 'notification')
</template>


<script>
import { mapActions, mapState } from 'vuex'
import DashboardWidgetApplicationNotificationsItem from '../dashboard/DashboardWidgetApplicationNotificationsItem'
import FiModal from '@/components/FiModal'

export default {
  name: 'application-notifications-modal',

  components: {
    DashboardWidgetApplicationNotificationsItem,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('dashboard', ['dashboardApplicationNotifications']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  mounted () {
    this.loadDashboardApplicationNotifications()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardApplicationNotifications'])
  }
}
</script>

<i18n>
en:
  title:    "All application notices"
et:
  title:    "All application notices"
ru:
  title:    "All application notices"
</i18n>
