import { mapGetters, mapState } from 'vuex'

export const isOverdraft = {
  computed: {
    ...mapState('loans', ['loanProduct']),
    ...mapGetters('classifiers', ['classifierById']),
    isOverdraftProduct () {
      return /overdraft|guarantee/i.test(this.loanProduct?.group)
    },
    overdraftScheduleType () {
      return this.classifierById('loanScheduleType', this.loanProduct.scheduleTypeId)
    }
  }
}
