import { connection } from './connection'

export default {
  getManagers () {
    return connection.get('bo/customermanagers')
  },

  getCreditIssuers () {
    return connection.get('bo/credit-issuers')
  },

  postDayChange () {
    return connection.post('bo/daychange')
  }
}
