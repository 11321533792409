<template lang="pug">
tr.payment-matching-loan-list-item
  td: router-link(:to = '{ name: "Loan", params: { id: loan.loanId } }') {{ loan.agreementNumber }}
  td {{ name }} ({{ loan.customer.idCode }})
  td.money {{ loan.amount | money }}
  td.money {{ loan.balance | money }}
  td Not implemented
  td Not implemented
  td {{ loan.referenceNumber }}
  td: .badge(:class = '`badge-${loanStatusColor(status.name)}`') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'payment-matching-loan-list-item',

  props: {
    loan: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('loans', ['loanStatusColor']),
    status () {
      return this.classifierById(this.loan.status.classifier, this.loan.status.id)
    },
    name () {
      return this.customerName(this.loan.customer)
    }
  }
}
</script>
