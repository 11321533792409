export default [
  {
    path: 'scoring',
    name: 'ScoringRequests',
    component: () => import(/* webpackChunkName: "Scoring" */'@/views/scoring/ScoringRequestList'),
    meta: { label: 'menu:scoring' }
  },
  {
    path: 'scoring',
    component: { render (c) { return c('router-view') } },
    meta: { label: 'menu:scoring' },
    children: [
      {
        path: 'new',
        name: 'NewScoringRequest',
        component: () => import(/* webpackChunkName: "Scoring" */'@/views/scoring/NewScoringRequest'),
        props: (route) => ({ modelId: route.query.modelId, customer: route.params.customer }),
        meta: { label: 'menu:newScoringRequest' },
        beforeEnter: (to, from, next) => {
          if (parseInt(to.query.modelId)) {
            next()
          } else {
            next('/admin/scoring-models')
          }
        }
      },
      {
        path: ':type/:id',
        name: 'ScoringRequest',
        component: () => import(/* webpackChunkName: "Scoring" */'@/views/scoring/ScoringRequest'),
        meta: { label: 'menu:scoringRequest' },
        props: true
      }
    ]
  }
]
