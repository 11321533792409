import auth from '@/router/auth'
import deposits from '@/router/deposits'
import scoring from '@/router/scoring'
import applications from '@/router/applications'
import customers from '@/router/customers'
import loans from '@/router/loans'
import admin from './admin'
import Vue from 'vue'
import Router from 'vue-router'

import FullView from '@/views/layout/FullView'

import Dashboard from '@/views/dashboard/Dashboard'

import DebtList from '@/views/debts/DebtList'

import PaymentIncomingList from '@/views/payments/PaymentIncomingList'
import PaymentOutgoingList from '@/views/payments/PaymentOutgoingList'
import PaymentIncoming from '@/views/payments/PaymentIncoming.vue'
import PaymentOutgoing from '@/views/payments/PaymentOutgoing.vue'

import ReportsView from '@/views/reports/ReportsView'
import ReportsFinancial from '@/views/reports/ReportsFinancial'
import ReportsQuicksight from '@/views/reports/ReportsQuicksight'

import NotificationList from '@/views/notifications/NotificationList'

import Store from '@/store'
import factoring from '@/router/factoring'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: FullView,
      meta: { label: 'menu:home' },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: { label: 'menu:dashboard' }
        },
        ...applications,
        ...customers,
        ...loans,
        ...factoring,
        ...deposits,
        {
          path: 'debts',
          name: 'Debts',
          component: DebtList,
          meta: { label: 'menu:debts' }
        },
        ...scoring,
        {
          path: 'payments',
          name: 'Payments',
          component: { render (c) { return c('router-view') } },
          meta: { label: 'menu:payments' },
          redirect: { name: 'incomingPaymentsList' },
          children: [
            {
              path: 'incoming',
              name: 'incomingPaymentsList',
              component: PaymentIncomingList,
              meta: { label: 'menu:paymentsIncoming' },
              props: true
            },
            {
              path: 'outgoing',
              name: 'outgoingPaymentsList',
              component: PaymentOutgoingList,
              meta: { label: 'menu:paymentsOutgoing' },
              props: true
            }
          ]
        },
        {
          path: 'payments',
          component: { render (c) { return c('router-view') } },
          meta: { label: 'menu:payments' },
          children: [
            {
              path: 'incoming/:id',
              name: 'incomingPayment',
              component: PaymentIncoming,
              meta: { label: 'menu:payment' },
              props: true
            },
            {
              path: 'outgoing/:id',
              name: 'outgoingPayment',
              component: PaymentOutgoing,
              meta: { label: 'menu:payment' },
              props: true
            }
          ]
        },
        {
          path: 'reports',
          name: 'ReportsView',
          component: ReportsView,
          meta: { label: 'menu:reports' },
          redirect: { name: 'ReportsFinancial' },
          children: [
            {
              path: '',
              name: 'ReportsFinancial',
              component: ReportsFinancial,
              meta: { label: 'menu:reportsFinancial' }
            },
            {
              path: 'bi-dashboards',
              name: 'ReportsQuicksight',
              component: ReportsQuicksight,
              meta: { label: 'menu:reportsQuicksight' }
            }
          ]
        },
        admin,
        {
          path: 'notifications',
          name: 'Notifications',
          component: NotificationList,
          meta: { label: 'menu:systemNotifications' }
        }
      ]
    },
    ...auth
  ]
})

const { state } = Store

router.beforeEach((to, from, next) => {
  const isLoggedIn = state.auth.isLoggedIn
  const authRegexp = /(^\/auth|^\/id-logout|^\/reset)/

  if (to.path.match(authRegexp)) {
    if (isLoggedIn) {
      next('/')
    } else {
      next()
    }
  } else {
    if (isLoggedIn) {
      next()
    } else {
      next({
        path: '/auth',
        query: { redirect: to.path }
      })
    }
  }
})

export default router
