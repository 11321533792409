<template lang = "pug">
.payment-details
  .card.fadeIn.m-0.border-bottom-0
    .card-header {{ $t('title', { id: payment.id }) }}
    .card-body
      .row
        .col-lg-6
          fi-data-field(:label = '$t("date")') {{ $t('common:formatDate', { value: payment.paymentDate }) }}
          fi-data-field(:label = '$t("payer")') {{ payment.fromName }}
          fi-data-field(
            v-if   = 'payment.fromAccount'
            :label = '$t("account")'
          ) {{ payment.fromAccount }}
          fi-form-field(
            v-else
            :label = '$t("account")'
            :state = '!$v.paymentData.fromAccount.$error'
          )
            template(#error)
              .error-message(v-if = '!$v.paymentData.fromAccount.required') {{ $t('common:required') }}

            input.form-control.form-control-sm(
              v-model = 'paymentData.fromAccount'
              :class  = '{ "is-invalid": $v.paymentData.fromAccount.$error }'
            )
          fi-data-field(:label = '$t("description")') {{ payment.description }}
          fi-data-field(:label = '$t("amount")') {{ payment.amount | money }}
        .col-lg-6
          fi-data-field(:label = '$t("nostro")') {{ payment.nostroAccount }}
          fi-data-field(:label = '$t("bankPaymentId")') {{ payment.inboundId }}
  .card
    .card-body.animated.fadeIn(v-if = 'isEditable')
      form(@submit.prevent = 'savePayment')
        template(v-if = 'splitData.length')
          .row: .col-lg-4
            fi-data-field(:label = '$t("status")') {{ status.human }}
          payment-split-item(
            v-for        = '(split, index) in splitData'
            :key         = 'index'
            :split       = 'split'
            :split-types = 'splitTypeOptions'
          )
        .row(v-else)
          .col-lg-6
            fi-form-field(:label = '$t("status")')
              fi-select(
                v-model.number = 'paymentData.statusId'
                :options       = 'optionsFromClassifier("incomingPaymentStatuses")'
                sm
                required
              )
            fi-form-field(
              :label    = '$t("reference")'
              :disabled = 'isProcessed'
            )
              input.form-control.form-control-sm(v-model = 'paymentData.referenceNumber')
          .col-lg-6(v-if = 'payment.invoiceNumber')
            fi-data-field(
              :label = '$t("invoice")'
              label-cols="3"
            ) {{ payment.invoiceNumber }}
        .form-text.alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}

        .row: .col-lg-6
          button.btn.btn-primary.mr-2(
            type = 'submit'
            :disabled = 'saving'
          ) {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')

          template(v-if = 'splitData.length')
            button.btn.btn-primary.mr-2(
              @click.prevent = 'addSplitPayment'
              :disabled      = 'saving'
            ) {{ $t('addSplit') }}
            button.btn.btn-secondary(
              @click.prevent = 'cancelSplit'
              :disabled      = 'saving'
            ) {{ $t('common:cancel') }}
          button.btn.btn-primary.mr-2(
            v-else
            @click.prevent = 'addSplitPayment'
            :disabled      = 'saving'
          ) {{ $t('split') }}
    .card-body.animated.fadeIn(v-else)
      .row: .col-lg-6
        fi-data-field(:label = '$t("status")') {{ status.human }}
        fi-data-field(:label = '$t("reference")') {{ payment.referenceNumber }}

</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import BigNumber from 'bignumber.js'
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiMultiselect from '@/components/FiMultiselect'
import PaymentSplitItem from '@/views/payments/PaymentSplitItem'
import { validation, validators } from '@/validation'

export default {
  name: 'payment-details',

  components: {
    PaymentSplitItem,
    FiMultiselect,
    FiSelect,
    FiDataField,
    FiFormField,
    FiMoneyInput
  },

  mixins: [validation],

  i18nOptions: {},

  data () {
    return {
      error: '',
      paymentData: {
        referenceNumber: null,
        statusId: null,
        fromAccount: null
      },
      splitData: []
    }
  },

  computed: {
    ...mapState('payments', ['payment']),
    ...mapState('loans', ['systemData']),
    ...mapGetters('classifiers', ['classifierById', 'optionsFromClassifier']),
    type () {
      return this.payment.creditTransaction ? this.$t('typeCredit') : this.$t('typeDebit')
    },
    isEditable () {
      return /pending/i.test(this.status.code) || (this.isProcessed && this.$moment(this.payment.paymentDate).isAfter(this.systemData.lastDayChange))
    },
    isProcessed () {
      return /processed/i.test(this.status.code)
    },
    status () {
      return this.classifierById('incomingPaymentStatuses', this.payment.statusId)
    },
    saving () {
      return this.$vueLoading.isLoading([`payment:${this.payment.id}:save`, `payment:${this.payment.id}:split:save`])
    },
    splitSum () {
      return this.splitData.reduce((sum, { amount }) => sum.plus(amount), new BigNumber(0))
    },
    splitValid () {
      return this.splitSum.eq(this.payment.amount)
    },
    splitTypeOptions () {
      return this.optionsFromClassifier('incomingPaymentSplitType', true)
    }
  },

  validations: {
    paymentData: {
      fromAccount: {
        required: validators.required
      }
    }
  },

  created () {
    this.loadFactoringInvoiceClassifiers()
    this.resetData()
  },

  methods: {
    ...mapActions('payments', ['updatePayment', 'loadCustomerPayments', 'splitPayment']),
    ...mapActions('classifiers', ['loadFactoringInvoiceClassifiers']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.paymentData = pick(this.payment, Object.keys(this.paymentData))
    },
    addSplitPayment () {
      this.splitData.push({
        amount: new BigNumber(this.payment.amount).minus(this.splitSum).toNumber(),
        description: '',
        referenceNumber: this.payment.referenceNumber,
        splitType: 'REFERENCE_NUMBER'
      })
    },
    cancelSplit () {
      this.splitData = []
    },
    async savePayment () {
      if (this.splitData.length) {
        if (!this.splitValid) {
          this.error = this.$t('splitSumError')
          return
        }
        await this.splitPayment({ id: this.payment.id, data: this.splitData })
        this.$router.push({ name: 'Payments' })
      } else {
        if (this.validate()) {
          await this.updatePayment({ id: this.payment.id, data: this.paymentData })

          this.resetData()
          if (this.status.code === 'CANCELLED') {
            this.$router.push({ name: 'Payments' })
          }
        }
      }
    }
  }
}
</script>


<i18n>
en:
  title:            "Payment #{{id}}"
  date:             "Date"
  payer:            "Payer Name"
  account:          "Payer Account"
  description:      "Description"
  amount:           "Amount"
  nostro:           "Nostro Account"
  bankPaymentId:    "Bank payment ID"
  type:             "Debit/Credit"
  typeCredit:       "Credit"
  typeDebit:        "Debit"
  reference:        "Reference"
  status:           "Status"
  prevTransactions: "Previous transactions from the same account"
  split:            "Split payment"
  addSplit:         "Add split"
  splitSumError:    "Sum of split payments amounts should be equal to original payment amount"
  invoice:          "Factoring invoice number"
  invoiceNumber:     "Invoice number"
et:
  title:            "Payment #{{id}}"
  date:             "Date"
  payer:            "Payer Name"
  account:          "Payer Account"
  description:      "Description"
  amount:           "Amount"
  nostro:           "Nostro Account"
  bankPaymentId:    "Bank payment ID"
  type:             "Debit/Credit"
  typeCredit:       "Credit"
  typeDebit:        "Debit"
  reference:        "Reference"
  status:           "Status"
  prevTransactions: "Previous transactions from the same account"
  splitSumError:    "Sum of split payments amounts should be equal to original payment amount"
  invoice:          "Factoring invoice number"
  invoiceNumber:     "Invoice number"
ru:
  title:            "Payment #{{id}}"
  date:             "Date"
  payer:            "Payer Name"
  account:          "Payer Account"
  description:      "Description"
  amount:           "Amount"
  nostro:           "Nostro Account"
  bankPaymentId:    "Bank payment ID"
  type:             "Debit/Credit"
  typeCredit:       "Credit"
  typeDebit:        "Debit"
  reference:        "Reference"
  status:           "Status"
  prevTransactions: "Previous transactions from the same account"
  splitSumError:    "Sum of split payments amounts should be equal to original payment amount"
  invoice:          "Factoring invoice number"
  invoiceNumber:     "Invoice number"
</i18n>
