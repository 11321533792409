<template lang="pug">
.dashboard.animated.fadeIn.card.bg-transparent.border-0
  .card-deck.mb-4
    dashboard-widget-applications-new
    dashboard-widget-application-notifications
    dashboard-widget-loan-notifications
    dashboard-widget-deposit-notifications(v-if = 'isDepositEnabled')
    dashboard-widget-messages-new
    dashboard-widget-payments-pending
  dashboard-tasks
</template>


<script>
import { isDepositEnabled } from '@/mixins'
import DashboardTasks from '@/views/dashboard/DashboardTasks'
import DashboardWidgetDepositNotifications from '@/views/dashboard/DashboardWidgetDepositNotifications'
import { mapActions } from 'vuex'
import DashboardWidgetApplicationsNew from '@/views/dashboard/DashboardWidgetApplicationsNew'
import DashboardWidgetPaymentsPending from '@/views/dashboard/DashboardWidgetPaymentsPending'
import DashboardWidgetApplicationNotifications from '@/views/dashboard/DashboardWidgetApplicationNotifications'
import DashboardWidgetLoanNotifications from '@/views/dashboard/DashboardWidgetLoanNotifications'
import DashboardWidgetMessagesNew from '@/views/dashboard/DashboardWidgetMessagesNew'
import DashboardApplicationList from '@/views/dashboard/DashboardApplicationList'

export default {
  name: 'dashboard',

  components: {
    DashboardWidgetDepositNotifications,
    DashboardTasks,
    DashboardWidgetApplicationsNew,
    DashboardWidgetPaymentsPending,
    DashboardWidgetApplicationNotifications,
    DashboardWidgetLoanNotifications,
    DashboardWidgetMessagesNew,
    DashboardApplicationList
  },

  mixins: [isDepositEnabled],

  mounted () {
    this.loadManagers()
  },

  methods: {
    ...mapActions('classifiers', ['loadManagers'])
  }
}
</script>
