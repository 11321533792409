<template lang="pug">
.card
  .card-header {{ $t('language') }}: {{ language }}
  .card-body
    form.form-horizontal(v-if = 'content')
      fi-form-field(
        :label      = '$t("subject")'
        :label-cols = '3'
      )
        input.form-control(
          v-model      = 'content.subject'
          :placeholder = '$t("subjectFieldPh")'
        )
      fi-form-field(
        :label      = '$t("templateContent")'
        :label-cols = '3'
      )
        .switch-status-wrap
          fi-switch(
            v-model   = 'srcEditor'
            :on-text  = '$t(onLabel)'
            :off-text = '$t(offLabel)'
          )
        fi-codemirror.animated.fadeIn(
          v-if         = 'srcEditor'
          v-model      = 'content.content'
          :placeholder = '$t("templateContentPh")'
        )
        template(v-else)
          .rendered.animated.fadeIn(
            v-if   = 'isPdf'
            v-html = 'sanitizedText'
          )
          fi-rich-text-editor.animated.fadeIn(
            v-else-if    = 'content'
            v-model      = 'content.content'
            :placeholder = '$t("templateContentPh")'
          )
</template>

<script>
import FiCodemirror from '@/components/FiCodemirror'
import FiFormField from '@/components/FiFormField'
import FiSwitch from '@/components/FiSwitch'
import sanitizeHtml from 'sanitize-html'
import FiRichTextEditor from '@/components/FiRichTextEditor'

export default {
  name: 'template-content',

  components: {
    FiCodemirror,
    FiSwitch,
    FiFormField,
    FiRichTextEditor
  },

  props: {
    language: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      default: () => ({})
    },
    isPdf: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data: () => ({
    srcEditor: false
  }),

  computed: {
    sanitizedText () {
      return sanitizeHtml(this.content.content || '', {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['data', 'http'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript']
      })
    },
    onLabel () {
      return this.isPdf ? 'edit' : 'srcEditor'
    },
    offLabel () {
      return this.isPdf ? 'view' : 'htmlEditor'
    }
  }
}
</script>


<i18n>
en:
  edit:              "Edit"
  view:              "View"
  srcEditor:         "Edit source"
  htmlEditor:        "Edit html"
  templateContent:   "Template content"
  templateContentPh: "Template content..."
  subjectFieldPh:    "Enter template subject"
et:
  edit:              "Edit"
  view:              "View"
  srcEditor:         "Edit source"
  htmlEditor:        "Edit html"
  templateContent:   "Template content"
  templateContentPh: "Template content..."
  subjectFieldPh:    "Enter template subject"
ru:
  edit:              "Edit"
  view:              "View"
  srcEditor:         "Edit source"
  htmlEditor:        "Edit html"
  templateContent:   "Template content"
  templateContentPh: "Template content..."
  subjectFieldPh:    "Enter template subject"
</i18n>

<style scoped lang="scss">
.switch-status-wrap {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
}

.rendered {
  max-height: 300px;
  overflow: auto;
}
</style>
