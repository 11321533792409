import { connection } from '@/api/connection'
import pickBy from 'lodash/pickBy'
import qs from 'qs'
import isNil from 'lodash/isNil'
import { createBlobFromObject } from '@/helpers'

const { get, post } = connection

export default {
  getDocumentsByContract: ({ contractId, filters }) => get(`bo/contracts/${contractId}/documents`, {
    params: pickBy(filters),
    paramsSerializer: (params) => qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
  }),
  getCommentsByContract: ({ contractId, filters }) => get(`bo/contracts/${contractId}/related-comments`, {
    params: pickBy(filters, filter => !isNil(filter)),
    paramsSerializer: (params) => qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
  }),
  postResource: ({ contractId, customerId, resourceData: { file, ...resource } }) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('resource', createBlobFromObject(resource))
    formData.append('customerId', customerId)
    return post(`bo/contracts/${contractId}/documents`, formData)
  }
}
