import { createActionHelpers } from 'vuex-loading'
import api from '@/api'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    currentVariableInterest: {},
    variableInterests: [],

    currentInterestRate: {},
    interestRates: []
  },

  actions: {
    async loadVariableInterestsRate ({ commit, dispatch }) {
      commit('SET_VARIABLE_INTERESTS_RATE', [])

      const { data } = await startLoading(
        dispatch,
        'interests:variable:fetch',
        () => api.getInterestRates()
      )

      commit('SET_VARIABLE_INTERESTS_RATE', data)
    },
    async loadVariableInterests ({ commit, dispatch }) {
      commit('SET_VARIABLE_INTERESTS', [])

      const { data } = await startLoading(
        dispatch,
        'interests:variable:fetch',
        () => api.getVariableInterests()
      )

      commit('SET_VARIABLE_INTERESTS', data)
    }
  },

  mutations: {
    SET_VARIABLE_INTERESTS (state, variableInterests) {
      state.variableInterests = variableInterests
    },
    SET_VARIABLE_INTERESTS_RATE (state, interestRates) {
      state.interestRates = interestRates
    }
  }
}
