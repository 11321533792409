<template lang = 'pug'>
.card.dashboard-tasks
  .card-header {{ $t('title') }}
  .card-body
    form.form-horizontal
      .form-row
        .form-group.col
          button.btn.btn-link(
            :class = '{ "text-secondary": searchParams.active === "true" }'
            @click.prevent = 'searchParams.active = "true"'
          ) {{ $t('active') }}
          | /
          button.btn.btn-link(
            :class = '{ "text-secondary": searchParams.active === "false" }'
            @click.prevent = 'searchParams.active = "false"'
          ) {{ $t('inactive') }}
          | /
          button.btn.btn-link(
            :class = '{ "text-secondary": searchParams.active === null }'
            @click.prevent = 'searchParams.active = null'
          ) {{ $t('all') }}
      .form-row
        .form-group.col-lg-6
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-search
            input.form-control(
              v-model.trim = 'searchParams.criteria'
              :placeholder = '$t("searchPlaceholder")'
              type         = 'text'
            )
        .form-group.col-lg-6
          range-input-date(
            :options     = 'rangeInputDateOptions'
            :start.sync  = 'searchParams.dateMin'
            :end.sync    = 'searchParams.dateMax'
            :column.sync = 'searchParams.dateColumn'
          )
      .form-row
        .form-group.col-lg-3
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-users
            fi-select(
              v-model.number = 'searchParams.roleTypeId'
              :options       = 'optionsFromClassifier("roles")'
              :placeholder   = '$t("rolesPlaceholder")'
            )
        .form-group.col-lg-3
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-layer-group
            fi-select(
              v-model.number = 'searchParams.commentCategoryId'
              :options       = 'optionsFromClassifier("commentCategory")'
              :placeholder   = '$t("categoriesPlaceholder")'
            )
        .form-group.col-lg-3
          .input-group
            .input-group-prepend: span.input-group-text
              i.fa.fa-user
            fi-select(
              v-model.number = 'searchParams.assignedTo'
              :options       = 'optionsFromManagers'
              :placeholder   = '$t("managersPlaceholder")'
            )
        .form-group.col-lg-3
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-tag
            fi-select(
              v-model.number = 'searchParams.segmentId'
              :options       = 'optionsFromClassifier("segments")'
              :placeholder   = '$t("segmentsPlaceholder")'
            )
      .form-row
        .form-group.col-lg-4
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-flag
            fi-select(
              v-model.number = 'searchParams.contractStatusId'
              :options       = 'optionsFromClassifier("loanStatuses")'
              :placeholder   = '$t("statusesPlaceholder")'
            )
        .form-group.col-lg-4
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-tag
            fi-select(
              v-model.number = 'searchParams.actionType'
              :options       = 'optionsFromClassifier("commentActionType", true)'
              :placeholder   = '$t("actionsPlaceholder")'
            )
        .form-group.col-lg-4
          .input-group
            .input-group-prepend: span.input-group-text: i.far.fa-check-square
            fi-select(
              v-model.number = 'searchParams.result'
              :options       = 'optionsFromClassifier("commentActionResult", true)'
              :placeholder   = '$t("resultsPlaceholder")'
            )
    fi-table(
      :fields     = 'fields'
      :items      = 'dashboardCustomerTasks'
      :emptyLabel = '$t("notFound")'
      loader      = 'dashboard:tasks:fetch'
      :sort.sync  = 'searchParams.sort'
      responsive
    )
      template(#default = '{ items }')
        dashboard-task-item(
          v-for       = 'task in items'
          v-if        = 'searchParams.active === "true" ? task.active === true : searchParams.active === "false" ? task.active === false : task'
          :key        = 'task.id'
          :task       = 'task'
          @change     = 'updateComment'
          @result     = 'updateCommentAction'
          @edit       = 'editTask'
          @remove     = 'removeDashboardCustomerTask'
        )
  fi-pagination(
    v-if    = 'dashboardCustomerTasksPages > 1'
    :pages  = 'dashboardCustomerTasksPages'
    v-model = 'pagination.page'
  )
  customer-comment-modal(
    v-if              = 'editingTask'
    v-model           = 'editing'
    :comment          = 'editingTask'
    :commentable-type = 'editingTask.commentableType.id'
    store-module      = 'dashboard'
    task
  )
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import RangeInputDate from '@/components/RangeInputDate'
import Template from '@/views/admin/templates/Template'
import CustomerCommentModal from '@/views/customers/CustomerCommentModal'
import DashboardTaskItem from '@/views/dashboard/DashboardTaskItem'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'
import { pagination } from '@/const'
import pickBy from 'lodash/pickBy'

const searchParams = {
  active: 'true',
  criteria: null,
  roleTypeId: '',
  commentCategoryId: '',
  assignedTo: '',
  segmentId: '',
  actionType: '',
  contractStatusId: '',
  dateColumn: 'createdAt',
  dateMin: '',
  dateMax: '',
  sort: 'createdAt,desc'
}

export default {
  name: 'dashboard-tasks',

  components: {
    Template,
    CustomerCommentModal,
    FiPagination,
    RangeInputDate,
    DashboardTaskItem,
    FiSelect,
    FiTable
  },

  data: (vm) => ({
    editing: false,
    editingTask: null,
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)), criteria: vm.criteria },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)), size: 10 }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromManagers', 'classifierById']),
    ...mapState('dashboard', ['dashboardCustomerTasks', 'dashboardCustomerTasksPages']),
    fields () {
      return [
        {
          key: 'customer',
          label: this.$t('customer')
        },
        {
          key: 'contract',
          label: this.$t('contract')
        },
        {
          key: 'application',
          label: this.$t('application')
        },
        {
          key: 'contractStatus',
          label: this.$t('status'),
          class: 'text-center'
        },
        {
          key: 'taskStatus',
          label: this.$t('taskStatus'),
          class: 'text-center'
        },
        {
          key: 'category',
          label: this.$t('category')
        },
        {
          key: 'createdBy',
          label: this.$t('createdBy'),
          class: 'text-center'
        },
        {
          key: 'assignedTo',
          label: this.$t('assignedTo'),
          class: 'text-center'
        },
        {
          key: 'dueDate',
          label: this.$t('dueDate'),
          class: 'text-center',
          sort: 'dueDate'
        },
        {
          key: 'actions',
          label: this.$t('actions'),
          class: 'text-center'
        },
        {
          key: 'result',
          label: this.$t('result'),
          class: 'text-center'
        },
        {
          key: 'note',
          label: this.$t('note')
        },
        {
          key: 'updatedBy',
          label: this.$t('updatedBy'),
          class: 'text-center'
        },
        {}
      ]
    },
    rangeInputDateOptions () {
      return {
        createdAt: this.$t('createdAt'),
        updatedAt: this.$t('updatedAt'),
        dueDate: this.$t('dueDate')
      }
    },
    filters () {
      return pickBy({ ...this.searchParams, ...this.pagination }, (value) => value !== '')
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    },
    '$route.query': {
      handler (query) {
        this.loadDashboardTasks({ filters: { ...query, hasAction: true } })
      },
      deep: true
    },
    '$route.params': {
      handler (params) {
        if (params.statusUserNotification) {
          this.searchParams.active = params.statusUserNotification
        }
      },
      deep: true
    }
  },

  created () {
    this.loadDashboardTasks({ filters: { ...this.filters, hasAction: true } })
  },

  methods: {
    ...mapActions('dashboard', ['updateComment', 'updateCommentAction', 'loadDashboardTasks', 'removeDashboardCustomerTask']),
    search: debounce(
      function () {
        this.$router.push({ query: this.filters })
      }, 500, {
        leading: true
      }
    ),
    editTask (task) {
      this.editingTask = task
      this.editing = true
    }
  }
}
</script>


<i18n>
en:
  title:               "Tasks"
  active:              "Active"
  inactive:            "Inactive"
  all:                 "All"
  notFound:            "Tasks not found"
  customer:            "Customer"
  contractStatus:      "Contract status"
  status:              "Status"
  taskStatus:          "Task status"
  category:            "Category"
  createdBy:           "Created by"
  assignedTo:          "Assigned to"
  actions:             "Actions"
  result:              "Result"
  contract:            "Contract"
  application:         "Application"
  note:                "Note"
  updatedBy:           "Updated by"
  searchPlaceholder:   "Search by name, id code, contract number"
  createdAt:           "Created at"
  updatedAt:           "Updated at"
  dueDate:             "Due date"
  rolesPlaceholder:    "Assigned to: all roles"
  categoriesPlaceholder:  "All categories"
  segmentsPlaceholder: "All customer segments"
  managersPlaceholder: "Assigned to: all managers"
  actionsPlaceholder:  "All actions"
  statusesPlaceholder: "All contract statuses"
  resultsPlaceholder:  "All results"
et:
  title:               "Tasks"
  active:              "Active"
  inactive:            "Inactive"
  all:                 "All"
  notFound:            "Tasks not found"
  customer:            "Customer"
  contractStatus:      "Contract status"
  status:              "Status"
  taskStatus:          "Task status"
  category:            "Category"
  createdBy:           "Created by"
  assignedTo:          "Assigned to"
  actions:             "Actions"
  result:              "Result"
  contract:            "Contract"
  application:         "Application"
  note:                "Note"
  updatedBy:           "Updated by"
  searchPlaceholder:   "Search by name, id code, contract number"
  createdAt:           "Created at"
  updatedAt:           "Updated at"
  dueDate:             "Due date"
  rolesPlaceholder:    "Assigned to: all roles"
  categoriesPlaceholder:  "All categories"
  segmentsPlaceholder: "All customer segments"
  managersPlaceholder: "Assigned to: all managers"
  actionsPlaceholder:  "All actions"
  statusesPlaceholder: "All contract statuses"
  resultsPlaceholder:  "All results"
ru:
  title:               "Tasks"
  active:              "Active"
  inactive:            "Inactive"
  all:                 "All"
  notFound:            "Tasks not found"
  customer:            "Customer"
  contractStatus:      "Contract status"
  status:              "Status"
  taskStatus:          "Task status"
  category:            "Category"
  createdBy:           "Created by"
  assignedTo:          "Assigned to"
  actions:             "Actions"
  result:              "Result"
  contract:            "Contract"
  application:         "Application"
  note:                "Note"
  updatedBy:           "Updated by"
  searchPlaceholder:   "Search by name, id code, contract number"
  createdAt:           "Created at"
  updatedAt:           "Updated at"
  dueDate:             "Due date"
  rolesPlaceholder:    "Assigned to: all roles"
  categoriesPlaceholder:  "All categories"
  segmentsPlaceholder: "All customer segments"
  managersPlaceholder: "Assigned to: all managers"
  actionsPlaceholder:  "All actions"
  statusesPlaceholder: "All contract statuses"
  resultsPlaceholder:  "All results"
</i18n>
