<template lang="pug">
multiselect.fi-multiselect(
  :value           = 'value'
  :searchable      = 'true'
  :internal-search = '!searchMethod'
  :track-by        = 'trackBy'
  :label           = 'label'
  v-bind           = '$attrs'
  :selectLabel     = '$t("selectLabel")'
  :selectedLabel   = '$t("selectedLabel")'
  :deselectLabel   = '$t("deselectLabel")'
  :tag-placeholder = '$t("tagPh")'
  :max-height      = '211'
  @input           = 'onInput'
  @search-change   = 'onSearch'
  @tag             = 'onTag'
)
  template(v-for='(_, slot) in $scopedSlots' v-slot:[slot] = 'props')
    slot(
      :name  = 'slot'
      v-bind = 'props'
    )
</template>

<script>
import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'

export default {
  name: 'fi-multiselect',

  components: { Multiselect },

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Array, Number, Object],
      default: null
    },
    searchCallback: {
      type: Function,
      default: null
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: 'text'
    }
  },

  computed: {
    searchMethod () {
      const { search = null } = this.$listeners
      return this.searchCallback ?? search
    }
  },

  async created () {
    if (this.$route.params.customer && this.$route.params.customer.customerName) {
      this.onInput(await this.searchCallback({ query: `${this.$route.params.customer.customerName}` }))
    }
  },

  methods: {
    onInput (e) {
      this.$emit('input', e)
    },
    onSearch: debounce(function (query) {
      if (typeof this.searchMethod === 'function') {
        this.searchMethod({ query })
      }
    }, 300),
    onTag (e) {
      this.$emit('tag', e)
    }
  }
}
</script>


<i18n>
en:
  selectLabel:   "Select"
  selectedLabel: "Selected"
  deselectLabel: "Remove"
  tagPh:         "Add new item"
et:
  selectLabel:   "Select"
  selectedLabel: "Selected"
  deselectLabel: "Remove"
  tagPh:         "Add new item"
ru:
  selectLabel:   "Select"
  selectedLabel: "Selected"
  deselectLabel: "Remove"
  tagPh:         "Add new item"
</i18n>


<style lang="scss">
  @import "scss/variables";
  @import "~bootstrap/scss/mixins";

  $vue-multiselect-padding-x : $custom-select-padding-x !default;
  $vue-multiselect-padding-y : $custom-select-padding-y !default;
  $vue-multiselect-min-height : $custom-select-height !default;
  $vue-multiselect-border-radius : $border-radius !default;
  $vue-multiselect-border-color : $custom-select-border-color !default;
  $vue-multiselect-border-width : 1px !default;
  $vue-multiselect-disabled-opacity : $btn-disabled-opacity !default;
  $vue-multiselect-placeholder-color : $text-muted !default;
  $vue-multiselect-bg : $custom-select-bg !default;
  $vue-multiselect-color : $custom-select-color !default;
  $vue-multiselect-disabled-bg : $custom-select-disabled-bg !default;
  $vue-multiselect-disabled-color : $custom-select-disabled-color !default;

  $vue-multiselect-option-selected-bg : #f3f3f3 !default;
  $vue-multiselect-option-selected-color : $body-color !default;
  $vue-multiselect-option-highlight-color : #fff !default;
  $vue-multiselect-option-highlight-bg : theme-color("primary") !default;
  $vue-multiselect-option-selected-highlight-color : #fff !default;
  $vue-multiselect-option-selected-highlight-bg : theme-color("secondary") !default;

  $vue-multiselect-tag-color : $dark !default;
  $vue-multiselect-tag-bg : $light !default;
  $vue-multiselect-tag-font-size : $badge-font-size !default;
  $vue-multiselect-tag-font-weight : normal !default;
  $vue-multiselect-tag-icon-size : 1.4rem !default;
  $vue-multiselect-tag-icon-color : $vue-multiselect-tag-color !default;
  $vue-multiselect-tag-icon-color-hover : white !default;
  $vue-multiselect-tag-icon-bg-hover : $danger !default;
  $vue-multiselect-tag-padding-x : .4rem !default;
  $vue-multiselect-tag-padding-y : .25rem !default;
  $vue-multiselect-tag-border-radius : $badge-border-radius !default;

  $vue-multiselect-arrow-color : $custom-select-color !default;
  $vue-multiselect-arrow-size : 5px !default;
  $vue-multiselect-arrow-padding : 8px !default;

  $vue-multiselect-spinner-size : 16px !default;
  $vue-multiselect-spinner-thickness : 2px !default;
  $vue-multiselect-spinner-margin : -$vue-multiselect-spinner-size/2 !default;
  $vue-multiselect-spinner-color : $dark !default;

  .multiselect {
    box-sizing : content-box;
    display    : block;
    position   : relative;
    width      : 100%;
    min-height : $vue-multiselect-min-height;
    text-align : left;
    color      : $vue-multiselect-color;

    &,
    &__input,
    &__single {
      font-family  : inherit;
      font-size    : inherit;
      touch-action : manipulation;
    }

    & * {
      box-sizing : border-box;
    }

    &:focus {
      outline : none;
    }

    fieldset[disabled] &,
    &--disabled &{
      background     : $vue-multiselect-disabled-bg;
      pointer-events : none;

      &__current,
      &__select,
      &__tags,
      &__single {
        background : $vue-multiselect-disabled-bg;
        color      : $vue-multiselect-disabled-color;
      }
    }

    &--active {
      z-index : 50;
      &:not(.multiselect--above) {
        .multiselect__current,
        .multiselect__input,
        .multiselect__tags {
          border-bottom-left-radius  : 0;
          border-bottom-right-radius : 0;
        }
      }

      .multiselect--above &{
        .multiselect__current,
        .multiselect__input,
        .multiselect__tags {
          border-top-left-radius  : 0;
          border-top-right-radius : 0;
        }
      }

      & .multiselect__select {
        transform : rotateZ(180deg);
      }
    }

    &__input,
    &__single {
      position       : relative;
      display        : inline-block;
      border         : none;
      border-radius  : $vue-multiselect-border-radius;
      background     : $vue-multiselect-bg;
      width          : 100%;
      transition     : border 0.1s ease;
      box-sizing     : border-box;
      vertical-align : middle;

      .multiselect__tag ~ & {
        width : auto;
      }

      &:hover {
        border-color : #cfcfcf;
      }

      &:focus {
        border-color : #a8a8a8;
        outline      : none;
      }
    }

    &__input::placeholder {
      color : $vue-multiselect-placeholder-color;
    }

    &__tags {
      @include box-shadow($input-box-shadow);
      min-height    : $vue-multiselect-min-height;
      display       : flex;
      padding-left  : $vue-multiselect-padding-x;
      padding-right: $vue-multiselect-min-height;
      @include border-radius($custom-select-border-radius, 0);
      border        : $vue-multiselect-border-width solid $vue-multiselect-border-color;
      background    : $vue-multiselect-bg;
      font-family   : inherit;
      font-size     : inherit;
      flex-direction: column;
      justify-content: center;

      &-wrap {
        display : inline-flex;
        flex-wrap: wrap;
      }

      .is-invalid & {
        border-color: red;
      }
    }

    &__tag {
      position      : relative;
      display       : inline-block;
      padding       : $vue-multiselect-tag-padding-y ($vue-multiselect-tag-icon-size + $vue-multiselect-tag-padding-x) $vue-multiselect-tag-padding-y $vue-multiselect-tag-padding-x;
      border-radius : $vue-multiselect-tag-border-radius;
      margin-right  : $vue-multiselect-tag-padding-x;
      color         : $vue-multiselect-tag-color;
      background    : $vue-multiselect-tag-bg;
      white-space   : nowrap;
      overflow      : hidden;
      max-width     : 100%;
      text-overflow : ellipsis;
      font-size     : $vue-multiselect-tag-font-size;
      font-weight   : $vue-multiselect-tag-font-weight;
      margin-top    : $vue-multiselect-tag-padding-y;
      margin-bottom : $vue-multiselect-tag-padding-y;

      &-icon {
        cursor      : pointer;
        margin-left : $vue-multiselect-tag-padding-y;
        position    : absolute;
        right       : 0;
        top         : 0;
        bottom      : 0;
        font-style  : initial;
        width       : $vue-multiselect-tag-icon-size;
        text-align  : center;
        line-height : $vue-multiselect-tag-icon-size;
        transition  : all 0.2s ease;
        font-size   : $vue-multiselect-tag-font-size;
        font-weight : $vue-multiselect-tag-font-weight;

        &:after {
          content   : "×";
          color     : $vue-multiselect-tag-icon-color;
          font-size : 220%;
        }

        &:focus,
        &:hover {
          background : $vue-multiselect-tag-icon-bg-hover;

          &:after {
            color : $vue-multiselect-tag-icon-color-hover;
          }
        }
      }

      &-checkbox {
        margin-right: 0.2rem;
        vertical-align: middle;
        position: relative;
        top: -1px;
      }
    }

    &__current {
      line-height     : $vue-multiselect-min-height / 2;
      min-height      : $vue-multiselect-min-height;
      box-sizing      : border-box;
      display         : block;
      overflow        : hidden;
      padding         : 8px 30px 0 12px;
      white-space     : nowrap;
      margin          : 0;
      text-decoration : none;
      border-radius   : $vue-multiselect-border-radius;
      border          : 1px solid $vue-multiselect-border-color;
      cursor          : pointer;
    }

    &__select {
      display         : block;
      position        : absolute;
      box-sizing      : border-box;
      width           : $vue-multiselect-min-height;
      bottom          : 0;
      right           : 0;
      top             : 0;
      margin          : $vue-multiselect-border-width;
      text-decoration : none;
      text-align      : center;
      cursor          : pointer;
      transition      : transform 0.2s ease;

      &:before {
        position     : relative;
        right        : 0;
        top          : 50%;
        color        : $vue-multiselect-arrow-color;
        border-style : solid;
        border-width : $vue-multiselect-arrow-size $vue-multiselect-arrow-size 0 $vue-multiselect-arrow-size;
        border-color : $vue-multiselect-arrow-color transparent transparent transparent;
        content      : "";
      }
    }

    &__placeholder {
      color         : $vue-multiselect-placeholder-color;
      display       : inline-block;
    }

    &__content {
      list-style     : none;
      display        : inline-block;
      padding        : 0;
      margin         : 0;
      min-width      : 100%;
      vertical-align : top;

      &-wrapper {
        position                   : absolute;
        display                    : block;
        background                 : $vue-multiselect-bg;
        width                      : 100%;
        overflow                   : auto;
        border                     : $vue-multiselect-border-width solid $vue-multiselect-border-color;
        border-top                 : none;
        border-bottom-left-radius  : $vue-multiselect-border-radius;
        border-bottom-right-radius : $vue-multiselect-border-radius;
        z-index                    : 1000;
        -webkit-overflow-scrolling : touch;

        .multiselect--above & {
          bottom        : 100%;
          border-radius : $vue-multiselect-border-radius $vue-multiselect-border-radius 0 0;
          border-bottom : none;
          border-top    : $vue-multiselect-border-width solid $vue-multiselect-border-color;
        }
      }

      &::-webkit-scrollbar {
        display : none;
      }
    }

    &__element {
      display : block;
    }

    &__option {
      display         : block;
      padding         : $vue-multiselect-padding-y $vue-multiselect-padding-x;
      min-height      : $vue-multiselect-min-height;
      line-height     : $vue-multiselect-min-height / 2;
      text-decoration : none;
      text-transform  : none;
      vertical-align  : middle;
      position        : relative;
      cursor          : pointer;
      white-space     : nowrap;

      &:after {
        top           : 0;
        right         : 0;
        position      : absolute;
        line-height   : $vue-multiselect-min-height;
        padding-right : 12px;
        padding-left  : 20px;
        font-family   : inherit;
        font-size     : inherit;
      }

      &--highlight {
        background : $vue-multiselect-option-highlight-bg;
        outline    : none;
        color      : $vue-multiselect-option-highlight-color;

        &:after {
          content    : attr(data-select);
          background : $vue-multiselect-option-highlight-bg;
          color      : $vue-multiselect-option-highlight-color;
        }
      }

      &--selected {
        background  : $vue-multiselect-option-selected-bg;
        color       : $vue-multiselect-option-selected-color;
        font-weight : bold;

        &:after {
          content : attr(data-selected);
          color   : silver;
        }
      }

      &--disabled {
        background     : $vue-multiselect-disabled-bg;
        color          : $vue-multiselect-disabled-color;
        cursor         : text;
        pointer-events : none;

        &.multiselect__option--highlight {
          background : $vue-multiselect-disabled-bg;
        }
      }

      &--group {
        background : $vue-multiselect-disabled-bg;
        color      : $vue-multiselect-disabled-color;

        &.multiselect__option--highlight {
          background : $vue-multiselect-disabled-color;
          color      : $vue-multiselect-disabled-bg;
          &:after {
            background : $vue-multiselect-disabled-color;
          }
        }
      }
    }

    &__option--group-selected.multiselect__option--highlight {
      background : $vue-multiselect-option-selected-highlight-bg;
      color      : $vue-multiselect-option-selected-highlight-color;

      &:after {
        background : $vue-multiselect-option-selected-highlight-bg;
        content    : attr(data-deselect);
        color      : $vue-multiselect-option-selected-highlight-color;
      }
    }

    &__option--selected.multiselect__option--highlight {
      background : $vue-multiselect-option-selected-highlight-bg;
      color      : $vue-multiselect-option-selected-highlight-color;
      &:after {
        background : $vue-multiselect-option-selected-highlight-bg;
        content    : attr(data-deselect);
        color      : $vue-multiselect-option-selected-highlight-color;
      }
    }

    &-enter-active,
    &-leave-active {
      transition : all 0.15s ease;
    }

    &-enter,
    &-leave-active {
      opacity : 0;
    }

    &__strong {
      margin-bottom  : $vue-multiselect-padding-y;
      line-height    : $vue-multiselect-min-height/2;
      display        : inline-block;
      vertical-align : top;
    }

    // --------------------------------
    // Spinner
    //--

    &__spinner {
      position   : absolute;
      right      : 2px;
      top        : 1px;
      width      : calc(#{$vue-multiselect-min-height} - 2px);
      height     : calc(#{$vue-multiselect-min-height} - 2px);
      background : $vue-multiselect-bg;
      display    : block;

      &:before,
      &:after {
        position         : absolute;
        content          : "";
        top              : 50%;
        left             : 50%;
        margin           : $vue-multiselect-spinner-margin 0 0 $vue-multiselect-spinner-margin;
        width            : $vue-multiselect-spinner-size;
        height           : $vue-multiselect-spinner-size;
        border-radius    : 100%;
        border           : $vue-multiselect-spinner-thickness solid transparent;
        border-top-color : $vue-multiselect-spinner-color;
        box-shadow       : 0 0 0 1px transparent;
        animation-iteration-count : infinite;
      }

      &:before {
        animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
      }

      &:after {
        animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
      }
    }

    &__loading-enter-active,
    &__loading-leave-active {
      transition : opacity 0.4s ease-in-out;
      opacity    : 1;
    }

    &__loading-enter,
    &__loading-leave-active {
      opacity : 0;
    }

    // --------------------------------
    // RTL adjustments
    //--

    *[dir="rtl"] & {
      text-align : right;
      &__select {
        right : auto;
        left  : 1px;
      }
      &__tags {
        padding : $vue-multiselect-padding-y $vue-multiselect-padding-y 0 $vue-multiselect-min-height;
      }
      &__content {
        text-align : right;
      }
      &__option:after {
        right : auto;
        left  : 0;
      }
      &__clear {
        right : auto;
        left  : 12px;
      }
      &__spinner {
        right : auto;
        left  : 1px;
      }
    }

    .input-group > & {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;

      &:not(:last-child) .multiselect__tags {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

  }

  @keyframes spinning {
    from {
      transform : rotate(0)
    }
    to {
      transform : rotate(2turn)
    }
  }
</style>
