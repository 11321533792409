<template lang="pug">
.card.payment-matching-loan-list
  .card-header {{ $t(`${status.toLowerCase()}Title`) }}
  .card-body
    .table-responsive
      table.table.table-hover
        thead
          tr
            th {{ $t('agreementNum') }}
            th {{ $t('customer') }}
            th.money {{ $t('amount') }}
            th.money {{ $t('balance') }}
            th {{ $t('paymentDate') }}
            th.money {{ $t('paymentAmount') }}
            th {{ $t('reference') }}
            th {{ $t('status') }}
        tbody
          tr(v-if = '$vueLoading.isLoading("payment:loans:fetch")'): td.text-center(colspan = 9): i.fa.fa-spinner.fa-pulse.fa-lg
          tr(v-else-if = 'matchingLoans.length === 0'): td.text-center(colspan = 9) {{ $t('notFound') }}
          template(v-else)
            payment-matching-loan-list-item(v-for = 'loan in matchingLoans'
                                            :key  = 'loan.id'
                                            :loan = 'loan')
</template>


<script>
import PaymentMatchingLoanListItem from './PaymentMatchingLoanListItem.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'payment-matching-loan-list',

  components: {
    PaymentMatchingLoanListItem
  },

  props: {
    status: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('payments', ['matchingLoans'])
  },

  created () {
    this.loadMatchingLoans()
  },

  methods: {
    ...mapActions('payments', ['loadMatchingLoans'])
  }
}
</script>


<i18n>
en:
  pendingTitle:   "Matching customer loans (by name or account number)"
  processedTitle: "Matching customer loans (by reference)"
  agreementNum:   "Agreement No"
  customer:       "Customer"
  amount:         "Amount"
  balance:        "Balance"
  paymentDate:    "Payment date"
  paymentAmount:  "Payment amount"
  reference:      "Reference"
  status:         "Status"
  notFound:       "Loans not found"
et:
  pendingTitle:   "Matching customer loans (by name or account number)"
  processedTitle: "Matching customer loans (by reference)"
  agreementNum:   "Agreement No"
  customer:       "Customer"
  amount:         "Amount"
  balance:        "Balance"
  paymentDate:    "Payment date"
  paymentAmount:  "Payment amount"
  reference:      "Reference"
  status:         "Status"
  notFound:       "Loans not found"
ru:
  pendingTitle:   "Matching customer loans (by name or account number)"
  processedTitle: "Matching customer loans (by reference)"
  agreementNum:   "Agreement No"
  customer:       "Customer"
  amount:         "Amount"
  balance:        "Balance"
  paymentDate:    "Payment date"
  paymentAmount:  "Payment amount"
  reference:      "Reference"
  status:         "Status"
  notFound:       "Loans not found"
</i18n>
