import api from '@/api'
import { createActionHelpers } from 'vuex-loading'
const { startLoading, endLoading } = createActionHelpers({ moduleName: 'loading' })

const state = {
  status: null
}

const mutations = {
  SET_MIGRATION_STATUS (state, { status }) {
    state.status = status
  }
}

const actions = {
  async uploadDataFile ({ state, commit, dispatch }, { file }) {
    try {
      startLoading(dispatch, 'migration:process')
      await api.postDataFile({ file })
      dispatch('loadMigrationStatus')
    } catch (e) {
      endLoading(dispatch, 'migration:process')
    }
  },
  async loadMigrationStatus ({ commit, dispatch }) {
    const response = await startLoading(dispatch, 'migration:status:fetch', () => api.getMigrationStatus())
    const { data: status } = response
    commit('SET_MIGRATION_STATUS', { status })

    if (response.status === 202) {
      setTimeout(() => dispatch('loadMigrationStatus'), 500)
    } else {
      endLoading(dispatch, 'migration:process')
    }
  },
  async startDocumentsMigration ({ dispatch }) {
    try {
      startLoading(dispatch, 'migration:process')
      await api.postDocumentMigration()
      dispatch('loadMigrationStatus')
    } catch (e) {
      endLoading(dispatch, 'migration:process')
    }
  }
}

export default {
  state,
  mutations,
  actions
}
