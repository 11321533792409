import { connection } from './connection'
import qs from 'qs'

export default {
  getProductTypes () {
    return connection.get('bo/products/types')
  },

  getProductsByGroup: ({ groups }) => connection.get('bo/products/groups', {
    params: {
      groups
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
  }),
  getFeeConfigByGroup: ({ group }) => connection.get(`bo/products/groups/${group}/fee-configs`),
  getProductsSettlementOrders: () => connection.get('bo/products/settlement/orders'),
  getTransactionMethodByGroup: ({ group }) => connection.get(`bo/products/groups/${group}/transactions-methods`)
}
