export default [
  {
    path: 'deposits',
    name: 'Deposits',
    component: () => import(/* webpackChunkName: "Deposits" */'@/views/deposits/DepositList'),
    meta: { label: 'menu:deposits' }
  },
  {
    path: 'deposits',
    component: { render (c) { return c('router-view') } },
    meta: { label: 'menu:deposits' },
    children: [
      {
        path: 'new',
        name: 'DepositNew',
        component: () => import(/* webpackChunkName: "Deposits" */'@/views/deposits/DepositNew'),
        meta: { label: 'menu:depositNew' },
        props: route => ({
          customer: route.params.customer
        })
      },
      {
        path: ':id',
        name: 'Deposit',
        component: () => import(/* webpackChunkName: "Deposits" */'@/views/deposits/DepositView'),
        meta: { label: 'menu:deposit' },
        props: true,
        redirect: { name: 'DepositOverview' },
        children: [
          {
            path: 'overview',
            name: 'DepositOverview',
            component: () => import(/* webpackChunkName: "Deposits" */'@/views/deposits/DepositOverview'),
            meta: { skip: true }
          },
          {
            path: 'terms',
            name: 'DepositTerms',
            component: () => import(/* webpackChunkName: "Deposits" */'@/views/deposits/DepositTermsView'),
            meta: { skip: true }
          },
          {
            path: 'financials',
            name: 'DepositFinancials',
            component: () => import(/* webpackChunkName: "Deposits" */'@/views/deposits/DepositFinancials'),
            meta: { skip: true }
          },
          {
            path: 'communication',
            name: 'DepositCommunication',
            component: () => import(/* webpackChunkName: "Deposits" */'@/views/deposits/DepositCommunication'),
            meta: { skip: true }
          }
        ]
      }
    ]
  }
]
