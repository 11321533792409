import api from '@/api'
import store from '@/store'
import { createActionHelpers } from 'vuex-loading'
const { startLoading } = createActionHelpers({ moduleName: 'loading' })

window.addEventListener('storage', (e) => {
  if (e.key === 'lastRequestTimestamp') {
    store.commit('auth/SET_LAST_REQUEST_TIME', { time: parseInt(e.newValue) })
  }
})

export default {
  state: {
    lastRequestTimestamp: null,
    isIdCardAuth: false,
    isLoggedIn: !!sessionStorage.getItem('token'),
    error: null,
    user: JSON.parse(sessionStorage.getItem('user')) || {},
    mobidData: null,
    smartData: null,
    permissions: JSON.parse(atob(sessionStorage.getItem('permissions') || '') || '[]')
  },

  actions: {
    updateLastRequestTime ({ commit }, { time = Date.now() } = {}) {
      sessionStorage.setItem('lastRequestTimestamp', time)
      commit('SET_LAST_REQUEST_TIME', { time })
    },

    updateSessionIfNeeded ({ commit, dispatch }, { time = Date.now() } = {}) {
      const lastSessionRenewal = sessionStorage.getItem('lastSessionRenewal', time)
      if (lastSessionRenewal) {
        const elapsedSeconds = (time - lastSessionRenewal) / 1000 | 0
        if (elapsedSeconds > 300) {
          dispatch('updateSession')
          sessionStorage.setItem('lastSessionRenewal', time)
        }
      }
    },

    async expireSession () {
      await api.postExpireSession()
    },

    async updateSession ({ commit }) {
      const { data: { token = null, permissions } } = await api.postRefreshSession()

      commit('SET_SESSION', { token, permissions })
    },

    async login ({ commit, dispatch }, data) {
      commit('CLEAR_ERROR')
      try {
        const result = await startLoading(dispatch, 'auth:login', () => api.postLogin({ data }))
        commit('LOGIN_SUCCESS', result.data)
        commit('SET_SESSION', result.data)
      } catch (e) {
        commit('LOGIN_ERROR', { message: e.response.data })
      }
    },

    async mobidLogin ({ dispatch, commit }, { phone, idCode }) {
      commit('CLEAR_ERROR')
      commit('CLEAR_MOBID_DATA')
      try {
        const result = await startLoading(dispatch, 'auth:login', () => api.getMobIdAuth({ phone, idCode }))
        commit('SET_MOBID_DATA', result)
        setTimeout(() => { dispatch('mobidPoll') }, 2000)
      } catch (e) {
        commit('LOGIN_ERROR', { message: e.response.data })
      }
    },

    async mobidPoll ({ state, dispatch, commit }) {
      try {
        const result = await startLoading(dispatch, 'auth:login', () => {
          return api.getMobIdPoll({ sessCode: state.mobidData.sessCode })
        })
        if (result.status === 202) {
          setTimeout(() => { dispatch('mobidPoll') }, 2000)
        } else {
          commit('LOGIN_SUCCESS', result.data)
          commit('SET_SESSION', result.data)
          commit('CLEAR_MOBID_DATA')
        }
      } catch (e) {
        commit('LOGIN_ERROR', { message: e.response.data })
        commit('CLEAR_MOBID_DATA')
      }
    },

    async smartLogin ({ dispatch, commit }, { idCode, countryCode }) {
      commit('CLEAR_ERROR')
      commit('CLEAR_SMART_DATA')
      try {
        const result = await startLoading(dispatch, 'auth:login', () => api.getSmartIdauth({ idCode, countryCode }))
        commit('SET_SMART_DATA', result)
        setTimeout(() => { dispatch('smartPoll') }, 2000)
      } catch (e) {
        commit('LOGIN_ERROR', { message: e.response.data })
      }
    },

    async smartPoll ({ state, dispatch, commit }) {
      try {
        const result = await startLoading(dispatch, 'auth:login', () => api.getSmartIdPoll({ sessionId: state.smartData.sessionID }))
        if (result.status === 202) {
          setTimeout(() => { dispatch('smartPoll') }, 2000)
        } else {
          commit('LOGIN_SUCCESS', result.data)
          commit('SET_SESSION', result.data)
          commit('CLEAR_SMART_DATA')
        }
      } catch (e) {
        commit('LOGIN_ERROR', { message: e.response.data })
        commit('CLEAR_SMART_DATA')
      }
    },

    async idCardLogin ({ commit, dispatch }) {
      commit('SET_ID_CARD_AUTH', true)
      try {
        const result = await startLoading(dispatch, 'auth:login', () => api.getIdCardLogin())
        commit('LOGIN_SUCCESS', result.data)
        commit('SET_SESSION', result.data)
      } catch (e) {
        commit('LOGIN_ERROR', { message: e.response.data })
        commit('SET_ID_CARD_AUTH', false)
      }
    },

    async logout ({ commit, dispatch }) {
      try {
        commit('CLEAR_MOBID_DATA')
        commit('CLEAR_SMART_DATA')
        await dispatch('expireSession')
        commit('LOGOUT')
      } catch (e) {
      }
    },

    clearError ({ commit }) {
      commit('CLEAR_ERROR')
    }
  },

  mutations: {
    SET_LAST_REQUEST_TIME (state, { time }) {
      state.lastRequestTimestamp = time
    },

    CLEAR_ERROR (state) {
      state.isIdCardAuth = false
      state.error = null
    },

    SET_SESSION (state, { token, permissions }) {
      state.permissions = permissions
      sessionStorage.setItem('token', token)
      sessionStorage.setItem('permissions', btoa(JSON.stringify(permissions)))
    },

    LOGIN_SUCCESS (state, { id, firstName, lastName, refreshToken, login }) {
      state.isLoggedIn = true
      state.user = { id, firstName, lastName, login }
      sessionStorage.setItem('refreshToken', refreshToken)
      sessionStorage.setItem('user', JSON.stringify(state.user))
      sessionStorage.setItem('lastSessionRenewal', Date.now())
    },

    LOGIN_ERROR (state, { message }) {
      state.error = message
    },

    LOGOUT (state) {
      state.isLoggedIn = false
      state.user = {}
      state.lastRequestTimestamp = null
      state.permissions = []
      sessionStorage.clear()
    },

    SET_MOBID_DATA (state, { data }) {
      state.mobidData = data
    },

    CLEAR_MOBID_DATA (state) {
      state.mobidData = null
    },

    SET_SMART_DATA (state, { data }) {
      state.smartData = data
    },

    CLEAR_SMART_DATA (state) {
      state.smartData = null
    },

    SET_ID_CARD_AUTH (state, value = false) {
      state.isIdCardAuth = value
    }
  }
}
