import { connection } from './connection'

export default {
  /** Variable interests */
  getVariableInterests: (
  ) => connection.get('bo/variable-interest/all'),
  getVariableInterest: (
    id
  ) => connection.get(`bo/variable-interest/${id}`),
  createVariableInterest: (
    variableInterest
  ) => connection.post('bo/variable-interest', { ...variableInterest }),
  updateVariableInterest: (
    id,
    variableInterest
  ) => connection.put(`bo/variable-interest/${id}`, { ...variableInterest }),
  deleteVariableInterest: (
    id
  ) => connection.delete(`bo/variable-interest/${id}`),
  /** Interest rates */
  getInterestRates: (
  ) => connection.get('bo/variable-interest-rate/all'),
  getInterestRate: (
    id
  ) => connection.get(`bo/variable-interest-rate/${id}`),
  createInterestRate: (
    interestRate
  ) => connection.post('bo/variable-interest-rate', { ...interestRate }),
  updateInterestRate: (
    id,
    interestRate
  ) => connection.put(`bo/variable-interest-rate/${id}`, { ...interestRate }),
  deleteInterestRate: (
    id
  ) => connection.delete(`bo/variable-interest-rate/${id}`)
}
