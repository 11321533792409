<template lang = "pug">
fi-modal.customer-comment-modal(
  ref     = 'modal'
  v-model = 'showModal'
  size    = 'md'
  :title  = 'title'
  lazy
  @hide   = 'onHide'
  @shown  = 'resetData'
)
  form.form(
    novalidate
    @submit.prevent = 'onSubmit'
  )
    fi-form-field(
      :label     = '$t("status")'
      label-cols = 3
    )
      fi-switch(
        v-model   = 'commentData.active'
        :disabled = 'saving'
        :on-text  = '$t("common:enabled")'
        :off-text = '$t("common:disabled")'
      )
    fi-form-field(
      :label     = '$t("category")'
      label-cols = 3
    )
      fi-select(
        :options = 'optionsFromClassifier("commentCategory")'
        v-model = 'commentData.categoryId'
        required
        sm
      )
    fi-form-field(
      :label     = '$t("message")'
      label-cols = 3
    )
      textarea.form-control(v-model.trim = 'commentData.comment')
    template(v-if='localTask')
      fi-form-field(
        :label     = '$t("assignTo")'
        label-cols = 3
        :state     = '!$v.commentData.assignedTo.$error'
      )
        template(#error)
          .error-message(v-if = '!$v.commentData.assignedTo.required') {{ $t('common:required') }}
        fi-select(
          v-model = 'commentData.assignedTo'
          :options = 'optionsFromManagers'
          :class   = '{ "is-invalid": $v.commentData.assignedTo.$error }'
          sm
        )
      fi-form-field(
        :label     = '$t("dueDate")'
        label-cols = 3
      )
        fi-datepicker(v-model = 'commentData.dueDate')
      fi-form-field(
        :label     = '$t("actions")'
        label-cols = 3
        :state     = '!$v.commentData.actions.$error'
      )
        template(#error)
          .error-message(v-if = '!$v.commentData.actions.required') {{ $t('common:required') }}
        fi-multiselect(
          v-model  = 'selectedActions'
          :options = 'optionsFromClassifier("commentActionType")'
          :class   = '{ "is-invalid": $v.commentData.actions.$error }'
          multiple
        )
      fi-form-field(
        v-if       = 'callAction'
        :label     = '$t("result")'
        label-cols = 3
      )
        fi-select(
          v-model  = 'callAction.result'
          :options = 'optionsFromClassifier("commentActionResult")'
          sm
        )
    button.btn.btn-link(
      v-else
      @click.prevent = 'localTask = true'
    ) {{ $t('setTask') }}
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse(v-if = 'saving')
    | &nbsp;
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { Comment } from '@/models'
import { validation, validators } from '@/validation'
import defaults from 'lodash/defaults'
import { mapActions, mapGetters } from 'vuex'
import FiDatepicker from '@/components/FiDatepicker'

export default {
  name: 'customer-comment-modal',

  components: { FiDatepicker, FiMultiselect, FiSelect, FiFormField, FiSwitch, FiModal },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    comment: {
      type: Object,
      default: null
    },
    task: {
      type: Boolean,
      default: false
    },
    commentableType: {
      type: String,
      required: true
    },
    storeModule: {
      type: String,
      required: true
    },
    commentableId: {
      type: [Number, String],
      default: undefined
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    localTask: vm.task,
    commentData: new Comment()
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromManagers', 'classifierByName']),
    selectedActions: {
      get () {
        return this.commentData.actions?.map(({ type: value, result }) => ({
          value,
          text: this.classifierByName('commentActionType', value)?.human,
          result
        }))
      },
      set (value = []) {
        this.commentData.actions = value.map(({ result, value: type }) => ({ result, type }))
      }
    },
    showModal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    saving () {
      if (this.comment) {
        return this.$vueLoading.isLoading(`${this.storeModule}:comment:${this.comment.id}:save`)
      } else {
        return this.$vueLoading.isLoading(`${this.storeModule}:comment:save`)
      }
    },
    callAction () {
      return (this.comment && this.commentData.actions.find(({ type }) => type === 'CALL')) || null
    },
    title () {
      if (this.comment?.id) {
        if (this.task) {
          return this.$t('taskEdit')
        } else {
          return this.$t('noteEdit')
        }
      } else {
        if (this.task) {
          return this.$t('taskNew')
        } else {
          return this.$t('noteNew')
        }
      }
    }
  },

  validations () {
    return {
      commentData: {
        assignedTo: {
          required: validators.requiredIf(function () {
            return this.task
          })
        },
        actions: {
          required: validators.requiredIf(function () {
            return this.task
          })
        }
      }
    }
  },

  methods: {
    ...mapActions({
      createComment (dispatch, payload) {
        return dispatch(`${this.storeModule}/createComment`, payload)
      },
      updateComment (dispatch, payload) {
        return dispatch(`${this.storeModule}/updateComment`, payload)
      }
    }),
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.commentData = new Comment(defaults(
        this.comment,
        { commentableType: this.commentableType }
      ))
      if (this.comment && ['actions', 'assignedTo'].some(property => Object.hasOwnProperty.call(this.comment, property))) {
        this.localTask = true
      }
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.comment) {
          await this.updateComment({ commentId: this.comment.id, commentData: this.commentData })
        } else {
          await this.createComment({ commentData: this.commentData, commentableId: this.commentableId })
        }
        this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  noteNew:  "New note"
  taskNew:  "New task"
  noteEdit: "Edit note"
  taskEdit: "Edit task"
  status:   "Status"
  category: "Category"
  setTask:  "Add task"
  assignTo: "Assign to"
  dueDate:  "Due date"
  actions:  "Actions"
  message:  "Message"
  result:   "Result"
et:
  noteNew:  "New note"
  taskNew:  "New task"
  noteEdit: "Edit note"
  taskEdit: "Edit task"
  status:   "Status"
  category: "Category"
  setTask:  "Add task"
  assignTo: "Assign to"
  dueDate:  "Due date"
  actions:  "Actions"
  message:  "Message"
  result:   "Result"
ru:
  noteNew:  "New note"
  taskNew:  "New task"
  noteEdit: "Edit note"
  taskEdit: "Edit task"
  status:   "Status"
  category: "Category"
  setTask:  "Add task"
  assignTo: "Assign to"
  dueDate:  "Due date"
  actions:  "Actions"
  message:  "Message"
  result:   "Result"
</i18n>
