<template lang="pug">
.card.dashboard-widget-loan-notifications
  .card-header.text-white.bg-success.border-bottom-0
    i.far.fa-2x.fa-star.text-muted.fa-pull-right.m-0
    h4.mb-0 {{dashboardLoanNotifications.length}}
    small.text-muted.font-weight-bold
      router-link.text-muted.font-weight-bold(:to = '{ name: "Loans", query: { statuses: "INSERTED", sort: "periodStart,desc", size: 25 } }') {{ $t('title') }}
  .card-body
    ul.list-unstyled.m-0
      dashboard-widget-loan-notifications-item(v-for = '(notification, index) in dashboardLoanNotifications.slice(0, 3)'
                                              :key = 'index'
                                              :notification = 'notification')

      li.text-center.nav-link(@click.prevent = 'showLoanNotificationsModal = true'): i.fa.fa-ellipsis-h.fa-lg

      loan-notifications-modal(
        v-if    = 'showLoanNotificationsModal'
        v-model = 'showLoanNotificationsModal'
      )
</template>


<script>
import { mapState, mapActions } from 'vuex'
import DashboardWidgetLoanNotificationsItem from './DashboardWidgetLoanNotificationsItem'
import LoanNotificationsModal from '../loans/LoanNotificationsModal'

export default {
  name: 'dashboard-widget-loan-notifications',

  components: {
    DashboardWidgetLoanNotificationsItem,
    LoanNotificationsModal
  },

  i18nOptions: {},

  data () {
    return {
      showLoanNotificationsModal: false
    }
  },

  computed: {
    ...mapState('dashboard', ['dashboardLoanNotifications'])
  },

  mounted () {
    this.loadDashboardLoanNotifications()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardLoanNotifications'])
  }

}
</script>


<i18n>
en:
  title:    "LOAN NOTICES"

et:
  title:    "LOAN NOTICES"

ru:
  title:    "LOAN NOTICES"

</i18n>

<style scoped lang="scss">
  .nav-link { cursor:pointer; }
</style>
