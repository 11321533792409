<template lang='pug'>
b-modal.payment-outgoing-sheet-list-modal(
  ref       = 'modal'
  v-model   = 'showModal'
  :title    = '$t("title")'
  size      = 'xl'
  :ok-title = '$t("common:close")'
  ok-only
  lazy
  @shown    = 'modalShown'
)
  template(#modal-header = '{ close }')
    .col
      h5 {{ $t('title') }}
    .col-4
      fi-form-field.m-0(
        :label = '$t("generateFor")'
        label-cols = 4
      )
        .input-group.input-group-sm
          fi-select(
            v-model = 'handlerName'
            :options = 'optionsFromHandlers'
            required
            sm
          )
          .input-group-append
            button.btn.btn-primary(
              :disabled = 'saving'
              @click        = 'createSheet'
            ) {{ $t('common:apply') }}
              i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.close(@click = 'close') ×
  fi-table(
    :fields      = 'fields'
    :items       = 'exportSheets'
    loader       = 'payments:outgoing:sheets:fetch'
    :empty-label = '$t("notFound")'
  )
    template(v-slot = '{ items }')
      payment-outgoing-sheet-list-item(
        v-for = 'item in items'
        :key  = 'item.id'
        :sheet = 'item'
      )
  fi-pagination(
    v-if    = 'exportSheetsPages > 1'
    :pages  = 'exportSheetsPages'
    v-model = 'pagination.page'
  )

</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import PaymentOutgoingSheetListItem from '@/views/payments/PaymentOutgoingSheetListItem'
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'

export default {
  name: 'payment-outgoing-sheet-list-modal',

  components: { PaymentOutgoingSheetListItem, FiFormField, FiSelect, FiPagination, FiTable, FiModal },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data: () => ({
    handlerName: '',
    pagination: {
      page: 0
    }
  }),

  computed: {
    ...mapState('payments', ['exportSheets', 'exportSheetsPages']),
    ...mapGetters('classifiers', ['optionsFromHandlers']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    fields () {
      return [
        {
          key: 'createdAt',
          label: this.$t('createdAt')
        },
        {
          key: 'numberOfPayments',
          label: this.$t('numberOfPayments')
        },
        {
          key: 'total',
          label: this.$t('total'),
          class: 'money'
        },
        {
          key: 'handler',
          label: this.$t('handler')
        },
        {}
      ]
    },
    saving () {
      return this.$vueLoading.isLoading('payments:outgoing:sheet:save')
    },
    searchQuery () {
      return pickBy({ ...this.pagination })
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page': {
      handler () {
        this.search()
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('payments', ['loadExportSheets', 'createExportSheet']),
    modalShown () {
      this.loadExportSheets({ filters: this.searchQuery })
      this.handlerName = this.optionsFromHandlers[0].value
    },
    createSheet () {
      this.createExportSheet({ handler: this.handlerName, filters: this.searchQuery })
    },
    search: debounce(
      function () {
        this.loadExportSheets({ filters: this.searchQuery })
      }, 500
    )
  }
}
</script>


<i18n>
en:
  title:               "Export sheets"
  generateFor:         "Generate for"
  createdAt:           "Created at"
  numberOfPayments:    "Payments"
  total:               "Total"
  handler:             "Handler"
  notFound:            "Sheets not found"
et:
  title:               "Export sheets"
  generateFor:         "Generate for"
  createdAt:           "Created at"
  numberOfPayments:    "Payments"
  total:               "Total"
  handler:             "Handler"
  notFound:            "Sheets not found"
ru:
  title:               "Export sheets"
  generateFor:         "Generate for"
  createdAt:           "Created at"
  numberOfPayments:    "Payments"
  total:               "Total"
  handler:             "Handler"
  notFound:            "Sheets not found"
</i18n>
