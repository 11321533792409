<template lang="pug">
.form-row.fi-data-field.form-group-sm
  label.col-form-label-sm.fi-data-field-label(
    v-if   = 'labelCols'
    :class = 'labelColClass'
  ) {{ !required ? label : `${ label } *` }}
  .col-form-label-sm.fi-data-field-value.animated.fadeIn(:class = 'valueColClass'): slot
</template>

<script>
export default {
  name: 'fi-data-field',

  props: {
    label: {
      type: String,
      default: ''
    },
    labelCols: {
      type: [Number, String],
      default: 6
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    labelColClass () {
      return `col-sm-${this.labelCols}`
    },
    valueColClass () {
      return this.labelCols === '12' ? 'col' : `col-sm-${12 - this.labelCols}`
    }
  }

}
</script>

<style scoped lang="scss">
  @import '~bootstrap/scss/mixins';
  @import 'scss/variables';

.fi-data-field-label {
  font-weight: 500;
  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
    margin-bottom: 0;
  }

}

.fi-data-field-value {
  color: #3e515b;
  @include media-breakpoint-down(xs) {
    padding-top: 0;
  }

  & /deep/ table { border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden; }
  & /deep/ td, & /deep/ th {border: 1px solid; padding: 4px}
}
</style>
