import { connection, downloadFile } from '@/api/connection'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import qs from 'qs'
import isNil from 'lodash/isNil'
import { createBlobFromObject } from '@/helpers'

export default {
  getDepositContracts ({ filters }) {
    return connection.get('bo/deposit/contracts', {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  getDepositContract ({ contractId }) {
    return connection.get(`bo/deposit/contracts/${contractId}`)
  },
  postDepositContract ({ contractData, files, documentCategoryId }) {
    const formData = new FormData()
    formData.append('contractData', createBlobFromObject(pickBy(contractData)))
    if (files.length) {
      formData.append('documentCategoryId', documentCategoryId)
    }
    Array.from(files).forEach(file => {
      formData.append('files', file)
    })
    return connection.post('bo/deposit/contracts', formData)
  },
  getDepositContractClassifiers () {
    return connection.get('bo/deposit/contracts/classifiers')
  },
  patchDepositContract ({ contractId, operation, path, value }) {
    return connection.patch(`bo/deposit/contracts/${contractId}/patch`, [{
      op: operation,
      path,
      value
    }], {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    })
  },
  patchDepositContractTerms ({ contractId, contractData }) {
    return connection.patch(`bo/deposit/contracts/${contractId}`, {
      terms: contractData
    }, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },
  putDepositContract ({ contractId, contractData }) {
    return connection.put(`bo/deposit/contracts/${contractId}`, contractData)
  },
  getDepositContractAudit ({ contractId }) {
    return connection.get(`bo/deposit/contracts/${contractId}/audit`)
  },
  getDepositContractDocuments ({ contractId }) {
    return connection.get(`bo/deposit/contracts/${contractId}/documents`)
  },
  getDepositContractFinancials ({ contractId }) {
    return connection.get(`bo/deposit/contracts/${contractId}/financials`)
  },
  getDepositContractFinancialTransactions ({ contractId, filters }) {
    return connection.get(`bo/deposit/contracts/${contractId}/transactions`, {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  putDepositContractProvision ({ contractId, provisionData }) {
    return connection.put(`bo/deposit/contracts/${contractId}/provision`, provisionData)
  },
  putDepositContractCorrection ({ contractId, correctionData }) {
    return connection.put(`bo/deposit/contracts/${contractId}/correction`, correctionData)
  },
  getDepositContractNotifications () {
    return connection.get('bo/deposit/contracts/notifications')
  },
  getDepositContractTemplate ({ templateId, contractId, languageId }) {
    const queryString = qs.stringify(pickBy({
      languageId
    }))
    return downloadFile(`bo/deposit/contracts/${contractId}/templates/${templateId}/preview?${queryString}`)
  },
  getDepositContractMessageTemplate ({ templateId, contractId }) {
    return connection.get(`bo/deposit/contracts/${contractId}/templates/${templateId}/model`)
  },
  postDepositContractMessage ({ id, data }) {
    const formData = new FormData()
    const messageData = pick(data, ['text', 'subject', 'customerEmail', 'customerPhoneNumber'])
    formData.append('templates', JSON.stringify([data.infoSheetTemplateId, data.agreementTemplateId, data.scheduleTemplateId].filter(Number)))
    formData.append('channel', data.channel)
    formData.append('message', createBlobFromObject(messageData))
    formData.append('languageId', data.languageId)
    if (data.files.length) {
      formData.append('documentCategoryId', data.documentCategoryId)
    }
    formData.append('recipients', JSON.stringify(data.recipients))
    Array.from(data.files).forEach(file => {
      formData.append('files', file)
    })
    return connection.post(`bo/deposit/contracts/${id}/message`, formData)
  },
  getDepositComments ({ contractId, filters }) {
    return connection.get(`bo/deposit/contracts/${contractId}/related-comments`, {
      params: pickBy(filters, filter => !isNil(filter))
    })
  },
  getDepositTermsRevisions ({ contractId }) {
    return connection.get(`bo/deposit/contracts/${contractId}/revisions`)
  },
  postDepositTermsRevision ({ contractId, revisionData }) {
    return connection.post(`bo/deposit/contracts/${contractId}/revisions`, revisionData)
  },
  putDepositTermsRevision ({ contractId, revisionId, revisionData }) {
    return connection.put(`bo/deposit/contracts/${contractId}/revisions/${revisionId}`, revisionData)
  },
  deleteDepositTermsRevision ({ contractId, revisionId }) {
    return connection.delete(`bo/deposit/contracts/${contractId}/revisions/${revisionId}`)
  },
  putDepositTermsRevisionAccept ({ contractId }) {
    return connection.put(`bo/deposit/contracts/${contractId}/revisions/acceptance`)
  },
  putDepositTermsRevisionComment ({ contractId, revisionId, commentData }) {
    return connection.put(`bo/deposit/contracts/${contractId}/revisions/${revisionId}/comment`, {
      comment: commentData
    })
  },
  getDepositTermsRevisionsClassifiers () {
    return connection.get('bo/deposit/contracts/revisions/classifiers')
  },
  getDepositContractByRevision ({ contractId, revisionId }) {
    return connection.get(`bo/deposit/contracts/${contractId}/revisions/${revisionId}`)
  },
  putDepositContractTerminate ({ contractId, terminateData }) {
    return connection.put(`bo/deposit/contracts/${contractId}/termination`, terminateData)
  },
  putDepositContractWithdrawal: ({ contractId, withdrawalData }) =>
    connection.put(`bo/deposit/contracts/${contractId}/withdrawal`, withdrawalData),
  putDepositContractSyncProduct: ({ contractId }) => connection.put(`bo/deposit/contracts/${contractId}/synchronization-against-product`)
}
