import { render, staticRenderFns } from "./FiSwitch.vue?vue&type=template&id=13245788&scoped=true&lang=pug&"
import script from "./FiSwitch.vue?vue&type=script&lang=js&"
export * from "./FiSwitch.vue?vue&type=script&lang=js&"
import style0 from "./FiSwitch.vue?vue&type=style&index=0&id=13245788&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13245788",
  null
  
)

export default component.exports