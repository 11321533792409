export default {
  vehicleBrand: null,
  vehicleModel: null,
  vehicleProductionYear: null,
  vehiclePower: null,
  vehicleMileage: null,
  vehicleSerialNumber: null,
  vehicleRegistrationNumber: null,
  sellerId: null,
  sellerContactName: null,
  sellerPhone: '',
  sellerEmail: null,
  assetsOwners: [],
  assetReportingType: '',
  assetSoldValue: null,
  assetStatus: '',
  collateralLocationCode: '',
  soldDate: null,
  valuationType: '',
  valueType: '',
  warrantyPostalCode: '',
  // appraisalPartnerName: '',
  warrantyArea: null,
  warrantyOwnerName: null,
  appraisalPartnerId: '',
  areaSizeTypeId: null
}
