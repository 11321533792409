<template lang="pug">
.input-group.range-input-text
  .input-group-prepend: span.input-group-text: i.fa.fa-chart-area
  .input-group-prepend(v-if = 'title'): span.input-group-text {{ title }}
  .input-group-prepend(v-else-if = 'options')
    fi-select.bg-light(
      v-model  = 'computedColumn'
      :options = 'options'
      required
    )
  input.form-control(v-model      = 'computedStart'
                     :placeholder = '$t("startPlaceholder")'
                     type         = 'text')
  input.form-control(v-model      = 'computedEnd'
                     :placeholder = '$t("endPlaceholder")'
                     type         = 'text')
</template>


<script>
import FiSelect from '@/components/FiSelect'
import RangeInputBase from './RangeInputBase'

export default {
  name: 'range-input-text',
  components: { FiSelect },
  extends: RangeInputBase,

  i18nOptions: {}
}
</script>


<i18n>
en:
  startPlaceholder: "min"
  endPlaceholder: "max"

et:
  startPlaceholder: "min"
  endPlaceholder: "max"

ru:
  startPlaceholder: "min"
  endPlaceholder: "max"
</i18n>


<style scoped lang="scss">
.range-input-text input[type=text]:last-child { margin-left: -1px; }
</style>
