<template lang='pug'>
.card.reports-view.animated.fadeIn
  nav.card-header
    b-nav(card-header tabs)
      b-nav-item(:to = '{ name: "ReportsFinancial" }' exact) {{ $t('menu:reportsFinancial') }}
      b-nav-item(:to = '{ name: "ReportsQuicksight" }') {{ $t('menu:reportsQuicksight') }}
  .card-body
    router-view
</template>

<script>
export default {
  name: 'reports-view',

  i18nOptions: {}
}
</script>

<style lang='scss' scoped>
  .reports-view {
    flex-grow: 1;
  }
</style>
