<template lang="pug">
nav.fi-pagination
  b-pagination(align       = 'center'
               :total-rows = 'pages'
               :per-page   = 1
               :limit      = 'limit'
               v-model     = 'page')
</template>


<script>
export default {
  name: 'fi-pagination',

  props: {
    pages: {
      type: [Number, String],
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    },
    limit: {
      type: [Number, String],
      default: 9
    }
  },

  computed: {
    page: {
      get () {
        return +this.value + 1
      },
      set (value) {
        this.$emit('input', value - 1)
      }
    }
  }
}
</script>


<style scoped lang="scss">
.fi-pagination /deep/ li.page-item:focus { outline: none; }
.fi-pagination /deep/ a.page-link:focus {
  box-shadow: none;
  outline: none;
}
</style>
