import api from '@/api'
import { createActionHelpers } from 'vuex-loading'
const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  actions: {
    markMessageAsRead ({ commit, dispatch }, { messageIds }) {
      return api.markAsRead({ messageIds })
    },
    markMessageAsUnread ({ commit, dispatch }, { messageIds }) {
      return api.markAsUnread({ messageIds })
    },
    async messageReassign ({ commit, dispatch }, { messageId, customerId }) {
      await startLoading(dispatch, 'customer:message:reassign:save', () => api.postMessageNewOwner({ messageId, customerId }))
    }
  }
}
