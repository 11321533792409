import automaticNotificationRules from './automaticNotificationRules'
import customerRules from './customerRules'
import applicationRules from './applicationRules'
import loanRules from './loanRules'
import productRules from './productRules'
import overdraftRules from './overdraftRules'
import templateRules from './templateRules'
import { validationMixin } from 'vuelidate'
import * as Validators from 'vuelidate/lib/validators'
import moment from 'moment'

export const validators = {
  ...Validators,
  datesBetween: (min, max) => Validators.helpers.withParams(
    { type: 'between', min, max },
    (value) => !Validators.helpers.req(value) || moment(value).isBetween(min, max, 'day', '[]')
  ),
  gte: (param) => Validators.helpers.withParams(
    { value: param },
    (value) => !Validators.helpers.req(value) || !param || +param <= +value),
  lte: (param) => Validators.helpers.withParams(
    { value: param },
    (value) => !Validators.helpers.req(value) || !param || +param >= +value),
  minValueRef (param) {
    return Validators.helpers.withParams(
      { type: 'min', param },
      function (value, parentVm) {
        const paramValue = Validators.helpers.ref(param, this, parentVm)
        return !Validators.helpers.req(value) || !paramValue || Math.max(paramValue, value) === value
      }
    )
  },
  maxValueRef (param) {
    return Validators.helpers.withParams(
      { type: 'max', param },
      function (value, parentVm) {
        const paramValue = Validators.helpers.ref(param, this, parentVm)
        return !Validators.helpers.req(value) || !paramValue || Math.min(paramValue, value) === value
      }
    )
  },
  maxValueRefWithZero (param) {
    return Validators.helpers.withParams(
      { type: 'max', param },
      function (value, parentVm) {
        const paramValue = Validators.helpers.ref(param, this, parentVm)
        return !Validators.helpers.req(value) || Math.min(paramValue, value) === value
      }
    )
  }
}

export const rules = {
  automaticNotificationRules,
  customerRules,
  applicationRules,
  loanRules,
  productRules,
  overdraftRules,
  templateRules
}

export const validation = {
  mixins: [validationMixin],
  methods: {
    validate () {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.$nextTick(() => {
          this.focusFirstInvalid()
        })
        return false
      }
      return true
    },
    focusFirstInvalid () {
      const invalidElm = this.$el.ownerDocument.querySelector('.is-invalid:not(fieldset):not(div)')
      invalidElm && invalidElm.focus()
    },
    formatRangeError (string, value, isPercentage) {
      const { money, percent } = this.$options.filters
      return this.$t(string, { value: isPercentage ? percent(value) : money(value) })
    }
  }
}
