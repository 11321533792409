export default class Comment {
  constructor (commentData = { actions: [] }) {
    this.commentableType = commentData.commentableType?.id || commentData.commentableType
    this.commentableId = commentData.commentableId
    this.assignedTo = commentData.assignedTo
    this.categoryId = commentData.categoryId
    this.active = Boolean(commentData.active)
    this.comment = commentData.comment
    this.actions = commentData.actions?.map(({ type, result = {} } = {}) => ({ type: type.code, result: result.code })) || []
    this.dueDate = commentData.dueDate
  }
}
