<template lang="pug">
.customer-tasks-list(v-if = 'tasks')
  button.btn.btn-link.pb-2.fa-pull-right(@click.prevent = 'newForm = true') {{ $t('newTask') }}
  template(v-if='!noFilter')
    | {{ $t("filterBy") }}
    button.btn.btn-link(:class = '{ "text-secondary": filter.active === true }'
                        @click = 'filter.active = true') {{ $t('active') }}
    | /
    button.btn.btn-link(:class = '{ "text-secondary": filter.active === false }'
                        @click = 'filter.active = false') {{ $t('inactive') }}
    | /
    button.btn.btn-link(:class = '{ "text-secondary": filter.active === null }'
                        @click = 'filter.active = null') {{ $t('all') }}
  fi-table(v-slot      = '{ items }'
           :fields     = 'tasksFields'
           :items      = 'tasks'
           :loader     = 'loader'
           :emptyLabel = '$t("notFound")'
           responsive)
    customer-tasks-list-item(v-for    = 'comment in items'
                             :key     = 'comment.id'
                             :comment = 'comment')
  fi-pagination(v-if    = 'tasksPages > 1'
                :pages  = 'tasksPages'
                v-model = 'pagination.page')
  customer-comment-modal(v-model           = 'newForm'
                         :commentable-type = 'commentableType'
                         :commentable-id   = 'commentableId'
                         :store-module     = 'storeModule'
                         task)
</template>


<script>
import FiPagination from '@/components/FiPagination'
import CustomerCommentModal from '@/views/customers/CustomerCommentModal'
import CustomerTasksListItem from '@/views/customers/CustomerTasksListItem'
import FiTable from '@/components/FiTable'

export default {
  name: 'customer-tasks-list',

  components: {
    FiPagination,
    CustomerCommentModal,
    CustomerTasksListItem,
    FiTable
  },

  inject: ['storeModule', 'commentableType'],

  props: {
    tasks: {
      type: Array,
      required: true
    },
    tasksPages: {
      type: Number,
      default: 0
    },
    loadTasks: {
      type: Function,
      required: true
    },
    loader: {
      type: String,
      default: ''
    },
    noFilter: {
      type: Boolean,
      default: false
    },
    commentableId: {
      type: [Number, String],
      default: undefined
    }
  },

  i18nOptions: {},

  data: () => ({
    newForm: false,
    filter: {
      active: true
    },
    pagination: {
      page: 0,
      size: 10
    }
  }),

  computed: {
    tasksFields () {
      return [
        {
          key: 'status',
          label: this.$t('status')
        },
        {
          key: 'category',
          label: this.$t('category'),
          class: 'text-center'
        },
        {
          key: 'createdBy',
          label: this.$t('createdBy'),
          class: 'text-center'
        },
        {
          key: 'assignedTo',
          label: this.$t('assignedTo'),
          class: 'text-center'
        },
        {
          key: 'dueDate',
          label: this.$t('dueDate'),
          class: 'text-center'
        },
        {
          key: 'actions',
          label: this.$t('actions'),
          class: 'text-center'
        },
        {
          key: 'result',
          label: this.$t('result'),
          class: 'text-center'
        },
        {
          key: 'agreementNumber',
          label: this.$t('contract'),
          class: 'text-center'
        },
        {
          key: 'comment',
          label: this.$t('note')
        },
        {}
      ]
    }
  },

  watch: {
    'pagination.page' () {
      this.search()
    },
    'filter.active' () {
      this.search()
    }
  },

  created () {
    this.search()
  },

  methods: {
    search () {
      this.loadTasks({ filters: { ...this.pagination, hasAction: true, ...this.filter } })
    }
  }
}
</script>


<i18n>
en:
  title:      "Tasks"
  filterBy:   "Filter by"
  active:     "Active"
  inactive:   "Inactive"
  all:        "All"
  newTask:    "+ New task"
  notFound:   "Tasks not found"
  status:     "Status"
  category:   "Category"
  createdBy:  "Created by"
  assignedTo: "Assigned to"
  dueDate:    "Due date"
  actions:    "Actions"
  result:     "Result"
  contract:   "Contract"
  note:       "Note"
et:
  title:      "Tasks"
  filterBy:   "Filter by"
  active:     "Active"
  inactive:   "Inactive"
  all:        "All"
  newTask:    "+ New task"
  notFound:   "Tasks not found"
  status:     "Status"
  category:   "Category"
  createdBy:  "Created by"
  assignedTo: "Assigned to"
  dueDate:    "Due date"
  actions:    "Actions"
  result:     "Result"
  contract:   "Contract"
  note:       "Note"
ru:
  title:      "Tasks"
  filterBy:   "Filter by"
  active:     "Active"
  inactive:   "Inactive"
  all:        "All"
  newTask:    "+ New task"
  notFound:   "Tasks not found"
  status:     "Status"
  category:   "Category"
  createdBy:  "Created by"
  assignedTo: "Assigned to"
  dueDate:    "Due date"
  actions:    "Actions"
  result:     "Result"
  contract:   "Contract"
  note:       "Note"
</i18n>
