<template lang="pug">
tr.debt-list-item
  td: router-link(:to = '{ name: "Customer", params: { id: debt.customer.id } }') {{ customerName(debt.customer) }} ({{ debt.customer.idCode }})
  td: router-link(:to = 'link') {{ debt.agreementNumber }}
  td {{ productById(debt.productId).name }}
  td.money {{ debt.principalBalance | money }}
  td.money
    | {{ debt.debts | money }}
    .small.font-italic
      .justify-content-end {{ $t('principal') }}: {{ debt.principalDebt | money }}
      .justify-content-end {{ $t('interest') }}: {{ debt.interestDebt | money }}
      .justify-content-end {{ $t('penalty') }}: {{ debt.penaltyDebt | money }}
      .justify-content-end {{ $t('fee') }}: {{ debt.feeDebt | money }}
      .justify-content-end(v-if="debt.vatDebt") {{ $t('vat') }}: {{ debt.vatDebt | money }}
  td.money {{ debt.interestAccruedBalance | money }}
  td.money {{ outstandingBalance | money }}
  td.text-nowrap(v-if = 'debt.inDebtSince') {{ $t('common:formatDate', { value: debt.inDebtSince }) }} ({{ debt.daysInDebt }})
  td(v-else)
  td {{ $t('common:formatDate', { value: debt.nextPaymentDate }) }}
  td: .badge(:class = '`badge-${loanStatusColor(status.name)}`') {{ status.human }}
  td.text-nowrap
    button.btn.btn-link.notify(@click='isShowTaskModal=true')
      i.fa.fa-bars.fa-lg
      span.badge.badge-warning.badge-pill {{ debt.activeActionsCount }}
  debts-list-item-task-modal(
    v-model = 'isShowTaskModal'
    :debt   = 'debt'
  )
</template>


<script>
import DebtsListItemTaskModal from '@/views/debts/DebtsListItemTaskModal'
import { mapGetters, mapState } from 'vuex'

const contractTypeUrl = {
  loan: 'Loan',
  factoring: 'FactoringContract',
  overdraft: 'Loan'
}

export default {
  name: 'debt-list-item',
  components: { DebtsListItemTaskModal },
  props: {
    debt: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    isShowTaskModal: false
  }),

  computed: {
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('products', ['productById']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('classifiers', ['classifierById']),
    ...mapState('loans', ['systemData']),
    status () {
      return this.classifierById(this.debt.status.classifier, this.debt.status.id)
    },
    outstandingBalance () {
      return this.debt.principalBalance + this.debt.debts + this.debt.interestAccruedBalance
    },
    link () {
      return { name: contractTypeUrl[this.debt.contractType], params: { id: this.debt.loanId } }
    }
  }
}
</script>


<style lang='scss' scoped>
  .notify {
    position: relative;
    & .badge {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -16px;
      margin-left: 0;
    }
  }
</style>


<i18n>
en:
  principal: "Principal"
  interest:  "Interest"
  penalty:   "Penalty"
  fee:       "Fee"
  vat:       "VAT"
et:
  principal: "Principal"
  interest:  "Interest"
  penalty:   "Penalty"
  fee:       "Fee"
  vat:       "VAT"
ru:
  principal: "Principal"
  interest:  "Interest"
  penalty:   "Penalty"
  fee:       "Fee"
  vat:       "VAT"
</i18n>
