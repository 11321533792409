<template lang='pug'>
tr.notification-list-item
  td {{ $t('common:formatDate', { value: notification.day  }) }}
  td {{ notification.status }}
  td.w-25 {{ notification.message }}
  td {{ $t('common:formatDatetime', { value: notification.startTime }) }}
  td {{ $t('common:formatDatetime', { value: notification.endTime }) }}
  td {{ notification.duration }}
  td: .badge(:class = 'badgeClass') {{ notification.severity }}
</template>


<script>
export default {
  name: 'notification-list-item',

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  computed: {
    badgeClass () {
      return {
        'badge-danger': this.notification.severity === 'CRITICAL'
      }
    }
  }
}
</script>
