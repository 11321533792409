import api from '@/api'
import { createActionHelpers } from 'vuex-loading'
import unionBy from 'lodash/unionBy'
import orderBy from 'lodash/orderBy'
import { factoringInvoiceStatuses } from '@/const'
import { customerName } from '@/helpers'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

const createFactoringInvoice = (invoice) => ({
  availableLimit: invoice?.availableLimit,
  factoringInvoiceId: invoice?.id,
  invoiceNumber: invoice?.invoiceNumber,
  invoiceAmount: invoice?.amount,
  advanceAmount: invoice?.advanceAmount,
  paidOutAmount: invoice.paidAmount,
  reserveAmount: invoice?.reserveAmount,
  receivedAmount: invoice?.receivedAmount,
  paidOutDate: invoice?.paidDate,
  dueDate: invoice?.dueDate,
  deadline: invoice?.paymentDate,
  factoringInvoiceStatus: invoice?.status,
  availableStatuses: invoice?.availableStatuses,
  thirdPartyId: invoice?.thirdPartyId,
  thirdPartyName: invoice?.thirdPartyName,
  thirdPartyCustomer: { fullName: invoice?.thirdPartyName },
  thirdPartyCustomerId: invoice?.thirdPartyCustomerId
})

export default {
  state: {
    systemCustomers: [],
    systemCustomer: null,
    factoringProduct: {},
    factoringContract: null,
    factoringContractOverview: null,
    factoringCustomer: null,
    factoringCustomerRelations: null,
    factoringManager: null,
    factoringAccount: {},
    factoringContractTerms: [],
    importedInvoiceList: [],
    importedInvoiceListPages: 0,
    importedInvoiceListTotal: 0,
    scheduleInvoiceList: [],
    scheduleInvoiceListPages: 0,
    factoringRelations: [],
    factoringPaymentRelations: [],
    factoringThirdParties: [],
    factoringContracts: [],
    factoringContractsPages: 0,
    factoringContractsTotal: 0,
    factoringResources: [],
    factoringResourcesPages: 0,
    factoringComments: [],
    commentsPages: 0,
    factoringMessages: [],
    factoringMessagesPages: 0,
    factoringCustomerHistory: null,
    factoringImportedInvoice: {},
    factoringFinancial: null,
    factoringTransactions: [],
    factoringTransactionPages: 0,
    applicationThirdParties: [],
    messageTemplate: null,
    customFieldsValues: []
  },

  getters: {
    sortedComments: ({ factoringComments }) => orderBy(factoringComments, ['commentableType.id', 'commentableId', 'id'], ['desc', 'asc', 'desc']),
    factoringProductAttributes: ({ factoringProduct }) => factoringProduct.attributes?.reduce((res, attribute) => ({
      ...res,
      [attribute.type.code]: attribute
    }), {}),
    factoringProductFees: ({ factoringProduct }) => factoringProduct.fees?.reduce((res, fee) => ({
      ...res,
      [fee.feeType.code]: fee
    }), {}),
    factoringInvoiceStatusColor: () => invoiceStatus => {
      const colorMap = {
        [factoringInvoiceStatuses.approved]: 'success',
        [factoringInvoiceStatuses.unapproved]: 'primary',
        [factoringInvoiceStatuses.pending]: 'pending',
        [factoringInvoiceStatuses.cancel]: 'danger',
        [factoringInvoiceStatuses.partlyPaid]: 'warning',
        [factoringInvoiceStatuses.advancePaid]: 'success'
      }
      return colorMap[invoiceStatus] || 'dark'
    },
    systemCustomersForSelect: (state, getters, rootState, rootGetters) => state.systemCustomers
      .map(customer => ({
        value: customer.id,
        text: `${rootGetters['customers/customerName'](customer)} (${customer.idCode})`,
        sectorId: customer.sectorId,
        segmentId: customer.segmentId,
        email: customer.email,
        phone: customer.phoneNumber
      })),
    optionsFromThirdParties: ({ factoringThirdParties }) => factoringThirdParties?.map(thirdParty => ({
      value: thirdParty.id,
      text: `${customerName(thirdParty.customer)} (${thirdParty.customer.idCode})`
    }))
  },

  actions: {
    async loadCustomerRelations ({ commit, dispatch, state }, { customerId = state.factoringCustomer.id } = {}) {
      const { data: relations } = await startLoading(dispatch, 'customer:relations:fetch', () => api.getCustomerRelations({ customerId }))

      commit('SET_CUSTOMER_RELATIONS', { relations })
    },
    async findSystemCustomers ({ commit, dispatch }, { query, sectorId }) {
      if (!query) {
        commit('SET_SYSTEM_CUSTOMERS', { customers: [] })
        return Promise.resolve()
      }
      const response = await startLoading(dispatch, 'loans:systemCustomers:fetch', () => api.getCustomers({ filters: { criteria: query, sectorId } }))
      commit('SET_SYSTEM_CUSTOMERS', { customers: response.data.content })
    },
    async loadProduct ({ commit, dispatch, state }, { id } = { id: state.factoringProduct?.productId }) {
      const response = await startLoading(dispatch, 'factoring:product:fetch', () => api.getFactoringProduct({ id }))
      commit('SET_FACTORING_PRODUCT', { product: response.data })
    },
    async createFactoringContract ({ dispatch, commit }, { factoringData }) {
      const { data: contract } = await startLoading(dispatch, 'factoring:contract:save', () => api.postFactoringContract({ factoringData }))

      commit('SET_FACTORING_CONTRACT', { contract })
    },
    async loadFactoringContract ({ commit, dispatch }, { id }) {
      const { data: contract } = await startLoading(dispatch, 'factoring:contract:fetch', () => api.getFactoringContract({ id }))

      commit('SET_FACTORING_CONTRACT', { contract })
    },
    async loadFactoringContractOverview ({ commit, dispatch }, { id }) {
      const { data: contract } = await startLoading(dispatch, 'factoring:contract:overview:fetch', () => api.getFactoringContractOverview({ id }))

      commit('SET_FACTORING_CONTRACT_OVERVIEW', { contract })
    },
    async loadFactoringCustomer ({ commit, dispatch, state }, { id } = { id: state.factoringContract.customerBorrowerId }) {
      const { data: customer } = await startLoading(dispatch, 'factoring:contract:customer:fetch', () => api.getCustomer({ id }))

      commit('SET_FACTORING_CUSTOMER', { customer })
    },
    async loadFactoringManager ({ commit, dispatch, state }, { id } = { id: state.factoringContract.managerId }) {
      commit('SET_FACTORING_MANAGER', { manager: null })
      if (!id) return

      const { data: manager } = await startLoading(dispatch, 'factoring:contract:manager:fetch', () => api.getCustomer({ id }))

      commit('SET_FACTORING_MANAGER', { manager })
    },
    async loadFactoringAccount ({ commit, dispatch }, { accountId }) {
      const { data: account } = await startLoading(dispatch, 'factoring:account:fetch', () => api.getAccount({ accountId }))

      commit('SET_FACTORING_ACCOUNT', { account })
    },
    async loadFactoringContractTerms ({ commit, dispatch }, { id }) {
      const { data: { content, pageTotal } } = await startLoading(dispatch, 'factoring:contract:terms:fetch', () => api.getFactoringContractTerms({ id }))

      commit('SET_FACTORING_TERMS', { terms: content, pageTotal })
    },
    async createFactoringContractPendingTerm ({ commit, dispatch }, { contractId }) {
      await startLoading(dispatch, 'factoring:contract:term:copy:save', () => api.postFactoringContractTermCopy({ contractId }))

      dispatch('loadFactoringContractTerms', { id: contractId })
    },
    async activateFactoringContractPendingTerm ({ commit, dispatch }, { contractId, data }) {
      await startLoading(dispatch, 'factoring:contract:term:activate:save', () => api.putFactoringContractActivateTerm({ contractId, data }))

      dispatch('loadFactoringContractTerms', { id: contractId })
      dispatch('loadFactoringContract', { id: contractId })
    },
    async removeFactoringContractTerm ({ commit, dispatch }, { contractId, termId }) {
      await startLoading(dispatch, 'factoring:contract:term:remove:save', () => api.deleteFactoringContractTerm({ contractId, termId }))

      dispatch('loadFactoringContractTerms', { id: contractId })
    },
    async saveFactoringContract ({ commit, dispatch }, { id, factoringData }) {
      const { data: contract } = await startLoading(dispatch, 'factoring:contract:save', () => api.patchFactoringContract({ id, factoringData }))

      commit('SET_FACTORING_CONTRACT', { contract })
    },
    async saveFactoringContractTerm ({ commit, dispatch }, { contractId, termId, termData }) {
      const invoiceLengthInterestRates = termData.invoiceLengthInterestRates
      const { data: term } = await startLoading(dispatch, `factoring:contract:term:${termId}:save`, () => api.putFactoringTerm({ contractId, termId, termData }))
      term.invoiceLengthInterestRates = invoiceLengthInterestRates
      commit('UPDATE_FACTORING_TERM', { term })
    },
    async saveFactoringChangeReason ({ commit, dispatch }, { contractId, termId, changeReasonComment }) {
      const { data: term } = await startLoading(dispatch, 'loan:schedule:reason:save', () => api.putFactoringTermReason({ contractId, termId, changeReasonComment }))

      commit('UPDATE_FACTORING_TERM', { term })
    },
    async saveFactoringStatus ({ commit, dispatch }, { contractId, statusId, statusData }) {
      const { data: contract } = await startLoading(dispatch, 'factoring:contract:status:save', () => api.putFactoringStatus({ contractId, statusId, statusData }))

      commit('SET_FACTORING_CONTRACT', { contract })
    },
    async loadFactoringContractScheduleInvoices ({ commit, dispatch }, { contractId, thirdPartyId, ...filters }) {
      const { data: { content: invoices, totalPages, totalElements } } = await startLoading(
        dispatch,
        'factoring:contract:invoices:schedule:fetch',
        () => api.getFactoringScheduleInvoicesByFilters({ ...filters, contractId, thirdPartyId })
      )

      commit('SET_FACTORING_CONTRACT_INVOICES_SCHEDULE', { invoices, totalPages, totalElements })
    },
    async loadFactoringImportedInvoices ({ commit, dispatch }, filters) {
      const { data: { content: invoices, totalPages, totalElements } } = await startLoading(
        dispatch,
        'factoring:contract:invoices:imported:fetch',
        () => api.getFactoringInvoicesByFilters(filters)
      )

      commit('SET_FACTORING_CONTRACT_INVOICES_IMPORTED', { invoices, totalPages, totalElements })
    },
    async loadFactoringRelations ({ commit, dispatch }, { contractId }) {
      const { data: relations } = await startLoading(
        dispatch,
        'contract:relations:fetch',
        () => api.getContractRelations({ id: contractId })
      )

      commit('SET_FACTORING_RELATIONS', { relations })
    },
    async createFactoringRelation ({ commit, dispatch }, { contractId, relationData }) {
      const { data: relations } = await startLoading(
        dispatch,
        'contract:relation:save',
        () => api.postContractRelation({ id: contractId, relationData })
      )

      commit('SET_FACTORING_RELATIONS', { relations })
    },
    async updateFactoringRelation ({ commit, dispatch }, { contractId, relationId, relationData }) {
      const { data: relation } = await startLoading(
        dispatch,
        'contract:relation:save',
        () => api.putContractRelation({ id: contractId, relationData, relationId })
      )

      commit('UPDATE_FACTORING_RELATION', { relation })
    },
    async removeFactoringRelation ({ commit, dispatch }, { contractId, relationId }) {
      await startLoading(
        dispatch,
        `contract:relation:${relationId}:delete`,
        () => api.deleteContractRelation({ id: contractId, relationId })
      )

      commit('REMOVE_FACTORING_RELATION', { relationId })
    },
    async loadFactoringPaymentRelations ({ commit, dispatch }, { contractId }) {
      const { data: relations } = await startLoading(
        dispatch,
        'factoring:contract:relations:payment:fetch',
        () => api.getFactoringPaymentRelations({ contractId })
      )

      commit('SET_FACTORING_PAYMENT_RELATIONS', { relations })
    },
    async createFactoringPaymentRelation ({ commit, dispatch }, { contractId, relationData }) {
      const { data: paymentRelation } = await startLoading(
        dispatch,
        'paymentRelation:create:save',
        () => api.postFactoringPaymentRelation({ contractId, relationData })
      )

      commit('ADD_FACTORING_PAYMENT_RELATION', { paymentRelation })
    },
    async removeFactoringPaymentRelation ({ commit, dispatch, state }, { contractId, relationId }) {
      await startLoading(
        dispatch,
        `paymentRelation:${relationId}:delete`,
        () => api.deleteFactoringPaymentRelation({ contractId, relationId })
      )

      commit('REMOVE_FACTORING_PAYMENT_RELATION', { relationId })
    },
    async updateFactoringPaymentRelation ({ commit, dispatch, state }, { contractId, relationId, relationData }) {
      const { data: paymentRelation } = await startLoading(
        dispatch,
        `paymentRelation:${relationId}:update:save`,
        () => api.putFactoringPaymentRelation({ contractId, relationId, relationData })
      )

      commit('UPDATE_FACTORING_PAYMENT_RELATION', { paymentRelation })
    },
    async loadFactoringThirdParties ({ commit, dispatch }, { contractId }) {
      const { data: thirdParties } = await startLoading(
        dispatch,
        'factoring:contract:thirdParties:fetch',
        () => api.getFactoringThirdParties({ contractId })
      )

      commit('SET_FACTORING_THIRD_PARTIES', { thirdParties })
    },
    async updateFactoringThirdParty ({ commit, dispatch }, { contractId, thirdPartyId, thirdPartyData }) {
      const invoiceLengthInterestRates = thirdPartyData.invoiceLengthInterestRates
      const { data: thirdParty } = await startLoading(
        dispatch,
        `factoring:contract:thirdParty:${thirdPartyId}:save`,
        () => api.putFactoringThirdParty({ contractId, thirdPartyId, thirdPartyData })
      )
      thirdParty.invoiceLengthInterestRates = invoiceLengthInterestRates
      commit('UPDATE_FACTORING_THIRD_PARTY', { thirdParty })
    },
    async createFactoringThirdParty ({ commit, dispatch }, { contractId, thirdPartyData }) {
      const { data: thirdParty } = await startLoading(
        dispatch,
        'factoring:contract:thirdParty:save',
        () => api.postFactoringThirdParty({ contractId, thirdPartyData })
      )
      commit('ADD_FACTORING_THIRD_PARTY', { thirdParty })
    },
    async loadFactoringContracts ({ commit, dispatch }, { filters }) {
      const { data: { content: contracts, totalPages, totalElements } } = await startLoading(dispatch, 'factoring:contracts:fetch', () => api.getFactoringContracts({ filters }))

      commit('SET_FACTORING_CONTRACTS', { contracts, totalPages, totalElements })
    },
    async createFactoringInvoice ({ commit, dispatch }, { invoiceData, resourceData, files }) {
      const { data: invoice } = await startLoading(
        dispatch,
        'factoring:contract:invoice:create',
        () => api.postFactoringInvoice({ invoiceData, resourceData, files })
      )

      commit('ADD_FACTORING_INVOICE_IMPORTED', { invoice })
    },
    async loadFactoringImportedInvoice ({ dispatch, commit }, { invoiceId }) {
      const { data: invoice } = await startLoading(dispatch, `factoring:invoice:imported:${invoiceId}:fetch`, () => api.getFactoringInvoice({ invoiceId }))

      commit('SET_FACTORING_INVOICE_IMPORTED', { invoice })
    },
    async updateFactoringImportedInvoice ({ commit, dispatch }, { invoiceId, invoiceData }) {
      const { data: invoice } = await startLoading(
        dispatch,
        `factoring:invoice:imported:${invoiceId}:save`,
        () => api.putFactoringInvoice({ invoiceId, invoiceData })
      )

      commit('UPDATE_FACTORING_INVOICE_IMPORTED', { invoice })
    },
    async createFactoringResource ({ commit, dispatch }, { resourceData, customerId, contractId }) {
      const { data: resource } = await startLoading(dispatch, 'resource:save', () => api.postResource({
        contractId,
        resourceData,
        customerId
      }))
      commit('ADD_FACTORING_RESOURCE', { resource })
    },
    async loadResources ({ state, commit, dispatch }, { filters }) {
      const { data: { content: resources, totalPages } } = await startLoading(dispatch, 'resources:fetch', () => {
        return api.getDocumentsByContract({ contractId: state.factoringContract.id, filters })
      })
      commit('SET_FACTORING_RESOURCES', { resources, totalPages })
    },
    async removeResource ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `resource:${id}:remove`, () => {
        return api.deleteResource({ id: id })
      })
      commit('REMOVE_FACTORING_RESOURCE', { id })
    },
    async updateResource ({ commit, dispatch }, { resourceId, resourceData }) {
      const { data: resource } = await startLoading(
        dispatch,
        `resource:${resourceId}:save`,
        () => api.putResource({ id: resourceId, resourceData })
      )

      commit('SET_FACTORING_RESOURCE', { resource })
    },
    async loadComments ({ commit, dispatch, state }, { id = state.factoringContract.id, filters } = {}) {
      commit('CLEAR_COMMENTS')

      const { data: { content: comments, totalPages } } = await startLoading(
        dispatch,
        'factoring:comments:fetch',
        () => api.getCommentsByContract({ contractId: id, filters })
      )

      commit('SET_FACTORING_COMMENTS', { comments, totalPages })
    },
    async createComment ({ state, commit, dispatch }, { commentData, commentableId = state.factoringContract.id } = {}) {
      const { data: comment } = await startLoading(dispatch, 'factoring:comment:save', () => {
        return api.postComment({ ...commentData, commentableId })
      })
      commit('ADD_FACTORING_COMMENT', { comment })
    },
    async updateComment ({ commit, dispatch }, { commentId, commentData }) {
      const { data: comment } = await startLoading(dispatch, `factoring:comment:${commentId}:save`, () => {
        return api.putComment({ id: commentId, data: commentData })
      })
      commit('UPDATE_FACTORING_COMMENT', { comment })
    },
    async removeComment ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `factoring:comment:${id}:remove`, () => {
        return api.deleteComment({ id })
      })
      commit('REMOVE_FACTORING_COMMENT', { id })
    },
    async loadMessages ({ state, commit, dispatch }, filters) {
      const { data: { content: messages, totalPages } } = await startLoading(
        dispatch,
        'customer:messages:fetch', () => api.getCustomerMessages({ customerId: state.factoringContract.customerBorrowerId, filters })
      )
      commit('SET_FACTORING_MESSAGES', { messages, totalPages })
    },
    async loadCustomerHistory ({ state, commit, dispatch }, { customerId = state.factoringCustomer?.id } = {}) {
      const response = await startLoading(dispatch, 'customer:history:fetch', () => {
        return api.getCustomerHistory({ customerId })
      })
      commit('SET_CUSTOMER_HISTORY', { history: response.data })
    },
    async createMessage ({ state, commit, dispatch }, { messageData, direction, customerId }) {
      const response = await startLoading(
        dispatch,
        'customer:message:save',
        () => api.postCustomerMessage({ customerId: state.factoringCustomer?.id, data: messageData })
      )
      commit('ADD_FACTORING_MESSAGE', { message: response.data, direction })
    },
    async loadFactoringFinancial ({ commit, dispatch, state }, { contractId } = {}) {
      const { data: financial } = await startLoading(dispatch, 'factoring:financial:fetch', () => api.getFactoringFinancial({ contractId }))
      commit('SET_FACTORING_FINANCIAL', { financial })
    },
    async loadFactoringContractTransactions ({ commit, dispatch, state }, { contractId, filters }) {
      const { data: { content: transactions, totalPages } } = await startLoading(
        dispatch,
        'factoring:contract:transactions:fetch',
        () => api.getFactoringContractTransactions({ contractId, filters }))

      commit('SET_FACTORING_CONTRACT_TRANSACTIONS', { transactions, totalPages })
    },
    async updateFactoringImportedInvoicesStatus ({ commit, dispatch }, { invoices, status } = {}) {
      const { data: changedInvoices } = await startLoading(dispatch, 'factoring:invoices:imported:status:save', () => api.putFactoringInvoicesStatus({ invoices, status }))

      commit('SET_FACTORING_CONTRACT_UPDATED_INVOICES', { changedInvoices })
    },
    async updateFactoringScheduleInvoiceDeadline ({ commit, dispatch }, { invoiceNumber, ...payload }) {
      const { data: changedInvoice } = await startLoading(dispatch, `factoring:invoices:schedule:${invoiceNumber}:deadline:save`, () => api.putFactoringScheduleInvoiceDeadline({ ...payload, invoiceNumber }))

      commit('SET_FACTORING_CONTRACT_UPDATED_SCHEDULE_INVOICES', { changedInvoice })
    },
    async createFactoringContractInvoicesPayout ({ commit, dispatch }, { contractId, payoutData }) {
      const { data: changedInvoices } = await startLoading(dispatch, 'factoring:invoices:contract:payout:save', () => api.postFactoringPayout({ contractId, payoutData }))

      commit('SET_FACTORING_CONTRACT_UPDATED_INVOICES', { changedInvoices })
    },
    async terminateFactoringContract ({ state, dispatch, commit }, { contractId, data }) {
      await startLoading(dispatch, 'factoring:contract:term:copy:save', () => api.postFactoringTerminate({ contractId, data }))

      dispatch('loadFactoringContractTerms', { id: contractId })
    },
    async loadFactoringApplicationThirdParties ({ commit, dispatch }, { applicationId }) {
      const { data: thirdParties } = await startLoading(
        dispatch,
        'factoring:application:thirdParties:fetch',
        () => api.getFactoringApplicationThirdParties({ applicationId })
      )

      commit('SET_FACTORING_APPLICATION_THIRD_PARTIES', { thirdParties })
    },
    async updateFactoringApplicationThirdParty ({ commit, dispatch }, { applicationId, thirdPartyId, thirdPartyData }) {
      const { data: thirdParty } = await startLoading(
        dispatch,
        `factoring:application:thirdParty:${thirdPartyId}:save`,
        () => api.putFactoringApplicationThirdParty({ applicationId, thirdPartyId, thirdPartyData })
      )

      commit('UPDATE_FACTORING_APPLICATION_THIRD_PARTY', { thirdParty })
    },
    async createFactoringApplicationThirdParty ({ commit, dispatch }, { applicationId, thirdPartyData }) {
      const { data: thirdParty } = await startLoading(
        dispatch,
        'factoring:application:thirdParty:save',
        () => api.postFactoringApplicationThirdParty({ applicationId, thirdPartyData })
      )
      commit('ADD_FACTORING_APPLICATION_THIRD_PARTY', { thirdParty })
    },
    async removeFactoringApplicationThirdParty ({ commit, dispatch }, { applicationId, thirdPartyId }) {
      await startLoading(
        dispatch,
        `factoring:application:thirdParty:${thirdPartyId}:delete`,
        () => api.deleteFactoringApplicationThirdParty({ applicationId, thirdPartyId })
      )

      commit('REMOVE_FACTORING_APPLICATION_THIRD_PARTY', { thirdPartyId })
    },
    createFactoringImportedInvoiceDocument ({ dispatch }, { invoiceId, customerId, files, resourceData }) {
      return startLoading(dispatch, 'factoring:invoices:imported:documents:save', () => api.postFactoringInvoiceDocument({ invoiceId, customerId, files, resourceData }))
    },
    async loadMessageTemplate ({ commit, dispatch }, { templateId, contractId, thirdPartyId }) {
      commit('SET_TEMPLATE', { template: null })

      if (!templateId) return

      const { data: template } = await startLoading(dispatch, 'contract:message:template:fetch', () => api.getFactoringMessageTemplate({ templateId, contractId, thirdPartyId }))
      commit('SET_TEMPLATE', { template })
    },
    async loadContractTemplate ({ commit, dispatch }, { templateId, contractId, languageId, thirdPartyId }) {
      await startLoading(dispatch, `contract:template:${templateId}:fetch`, () => api.getFactoringMessageTemplatePreview({ templateId, contractId, languageId, thirdPartyId }))
    },
    async sendContractMessage ({ commit, dispatch }, { id, data }) {
      await startLoading(dispatch, 'contract:message:save', () => api.postFactoringMessage({ contractId: id, data }))
    },
    async saveLoanProvision ({ commit, dispatch }, { id, provisionData }) {
      const { data: { financialDetail, accountStatement } } = await startLoading(dispatch, `contract:${id}:provision:save`, () => api.postLoanProvision({ loanId: id, provisionData }))

      commit('SET_FACTORING_FINANCIAL', { financial: financialDetail })
      commit('ADD_FACTORING_CONTRACT_TRANSACTION', { transaction: accountStatement })
    },
    async saveLoanCorrection ({ commit, dispatch }, { id, correctionData }) {
      const { data: { financialDetail, accountStatement } } = await startLoading(dispatch, `contract:${id}:correction:save`, () => api.putLoanCorrection({ loanId: id, correctionData }))

      commit('SET_FACTORING_FINANCIAL', { financial: financialDetail })
      commit('ADD_FACTORING_CONTRACT_TRANSACTION', { transaction: accountStatement })
    },
    async loadFactoringCustomFields ({ dispatch, commit }, { contractId, productGroup }) {
      const { data: customFields } = await startLoading(
        dispatch,
        'factoring:custom-fields:fetch',
        () => api.getContractCustomFieldValues({ contractId, productGroup })
      )
      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async saveFactoringCustomFields ({ dispatch, commit }, { contractId, productGroup, customFieldData }) {
      const { data: customFields } = await startLoading(
        dispatch,
        'factoring:custom-fields:save',
        () => api.postContractCustomFieldValues({ contractId, customFieldValues: customFieldData, productGroup })
      )
      commit('SET_CUSTOM_FIELDS', { customFields })
    }
  },

  mutations: {
    SET_TEMPLATE (state, { template }) {
      state.messageTemplate = template
    },
    SET_SYSTEM_CUSTOMERS (state, { customers }) {
      state.systemCustomers = customers
    },
    SET_SYSTEM_CUSTOMER (state, { customer }) {
      state.systemCustomer = customer
    },
    SET_FACTORING_PRODUCT (state, { product }) {
      state.factoringProduct = product
    },
    SET_FACTORING_CONTRACT (state, { contract }) {
      state.factoringContract = contract
    },
    SET_FACTORING_CONTRACT_OVERVIEW (state, { contract }) {
      state.factoringContractOverview = contract
    },
    SET_FACTORING_CUSTOMER (state, { customer }) {
      state.factoringCustomer = customer
    },
    SET_FACTORING_MANAGER (state, { manager }) {
      state.factoringManager = manager
    },
    SET_FACTORING_ACCOUNT (state, { account }) {
      state.factoringAccount = account
    },
    SET_FACTORING_TERMS (state, { terms, pageTotal }) {
      state.factoringContractTerms = terms
    },
    UPDATE_FACTORING_TERM (state, { term }) {
      state.factoringContractTerms = unionBy([term], state.factoringContractTerms, 'id')
      state.factoringContract.term = term
    },
    SET_FACTORING_CONTRACT_INVOICES_SCHEDULE (state, { invoices, totalPages }) {
      state.scheduleInvoiceList = invoices
      state.scheduleInvoiceListPages = totalPages
    },
    SET_FACTORING_CONTRACT_INVOICES_IMPORTED (state, { invoices, totalPages, totalElements }) {
      state.importedInvoiceList = invoices
      state.importedInvoiceListPages = totalPages
      state.importedInvoiceListTotal = totalElements
    },
    SET_FACTORING_RELATIONS (state, { relations }) {
      state.factoringRelations = relations
    },
    REMOVE_FACTORING_RELATION (state, { relationId }) {
      state.factoringRelations = state.factoringRelations.filter(relation => relation.relationId !== relationId)
    },
    UPDATE_FACTORING_RELATION (state, { relation }) {
      state.factoringRelations = unionBy([relation], state.factoringRelations, 'relationId')
    },
    SET_FACTORING_PAYMENT_RELATIONS (state, { relations }) {
      state.factoringPaymentRelations = relations
    },
    REMOVE_FACTORING_PAYMENT_RELATION (state, { relationId }) {
      state.factoringPaymentRelations = state.factoringPaymentRelations.filter(relation => relation.relationId !== relationId)
    },
    UPDATE_FACTORING_PAYMENT_RELATION (state, { paymentRelation }) {
      state.factoringPaymentRelations = unionBy([paymentRelation], state.factoringPaymentRelations, 'relationId')
    },
    ADD_FACTORING_PAYMENT_RELATION (state, { paymentRelation }) {
      state.factoringPaymentRelations.push(paymentRelation)
    },
    SET_FACTORING_THIRD_PARTIES (state, { thirdParties }) {
      state.factoringThirdParties = thirdParties
    },
    SET_FACTORING_CONTRACTS (state, { contracts, totalPages, totalElements }) {
      state.factoringContracts = contracts
      state.factoringContractsPages = totalPages
      state.factoringContractsTotal = totalElements
    },
    UPDATE_FACTORING_THIRD_PARTY (state, { thirdParty }) {
      state.factoringThirdParties = unionBy([thirdParty], state.factoringThirdParties, 'id')
    },
    ADD_FACTORING_THIRD_PARTY (state, { thirdParty }) {
      state.factoringThirdParties.push(thirdParty)
    },
    ADD_FACTORING_INVOICE_IMPORTED (state, { invoice }) {
      state.importedInvoiceList.push(createFactoringInvoice(invoice))
    },
    UPDATE_FACTORING_INVOICE_IMPORTED (state, { invoice }) {
      state.importedInvoiceList = unionBy([{
        ...createFactoringInvoice(invoice)
      }], state.importedInvoiceList, 'factoringInvoiceId')
    },
    SET_FACTORING_INVOICE_IMPORTED (state, { invoice }) {
      state.factoringImportedInvoice = invoice
    },
    SET_FACTORING_RESOURCES (state, { resources, totalPages }) {
      state.factoringResources = resources
      state.factoringResourcesPages = totalPages
    },
    ADD_FACTORING_RESOURCE (state, { resource }) {
      state.factoringResources.push(resource)
    },
    REMOVE_FACTORING_RESOURCE (state, { id }) {
      state.factoringResources = state.factoringResources.filter(resource => resource.id !== id)
    },
    SET_FACTORING_RESOURCE (state, { resource }) {
      state.factoringResources = unionBy([resource], state.factoringResources, 'id')
    },
    SET_FACTORING_COMMENTS (state, { comments, totalPages }) {
      state.factoringComments = comments
      state.commentsPages = totalPages
    },
    ADD_FACTORING_COMMENT (state, { comment }) {
      state.factoringComments.push(comment)
    },
    UPDATE_FACTORING_COMMENT (state, { comment }) {
      state.factoringComments = unionBy([comment], state.factoringComments, 'id')
    },
    REMOVE_FACTORING_COMMENT (state, { id }) {
      state.factoringComments = state.factoringComments.filter(comment => comment.id !== id)
    },
    CLEAR_COMMENTS (state) {
      state.factoringComments = []
      state.commentsPages = 0
    },
    SET_FACTORING_MESSAGES (state, { messages, totalPages }) {
      state.factoringMessages = messages
      state.factoringMessagesPages = totalPages
    },
    ADD_FACTORING_MESSAGE (state, { message, direction }) {
      if (message.direction !== direction) {
        return
      }
      state.factoringMessages.push(message)
    },
    SET_CUSTOMER_HISTORY (state, { history }) {
      state.factoringCustomerHistory = {
        ...state.factoringCustomerHistory,
        contracts: orderBy([...(history.loans || []), ...(history.deposits || []), ...(history.factorings ?? [])], ['createdAt', 'id'], ['desc', 'desc']),
        creditApplications: orderBy(history.creditApplications, ['createdAt', 'id'], ['desc', 'desc'])
      }
    },
    SET_FACTORING_FINANCIAL (state, { financial }) {
      state.factoringFinancial = financial
    },
    SET_FACTORING_CONTRACT_TRANSACTIONS (state, { transactions, totalPages }) {
      state.factoringTransactions = transactions
      state.factoringTransactionPages = totalPages
    },
    ADD_FACTORING_CONTRACT_TRANSACTION (state, { transaction }) {
      state.factoringTransactions = [transaction, ...state.factoringTransactions]
    },
    SET_FACTORING_APPLICATION_THIRD_PARTIES (state, { thirdParties }) {
      state.applicationThirdParties = thirdParties
    },
    UPDATE_FACTORING_APPLICATION_THIRD_PARTY (state, { thirdParty }) {
      state.applicationThirdParties = unionBy([thirdParty], state.applicationThirdParties, 'id')
    },
    ADD_FACTORING_APPLICATION_THIRD_PARTY (state, { thirdParty }) {
      state.applicationThirdParties.push(thirdParty)
    },
    REMOVE_FACTORING_APPLICATION_THIRD_PARTY (state, { thirdPartyId }) {
      state.applicationThirdParties = state.applicationThirdParties.filter(thirdParty => thirdParty.id !== thirdPartyId)
    },
    SET_CUSTOMER_RELATIONS (state, { relations }) {
      state.factoringCustomerRelations = relations
    },
    SET_FACTORING_CONTRACT_UPDATED_INVOICES (state, { changedInvoices }) {
      const updatedInvoices = changedInvoices.map(invoice => ({
        ...state.importedInvoiceList.find(({ factoringInvoiceId }) => factoringInvoiceId === invoice.factoringInvoiceId),
        ...invoice
      }))
      state.importedInvoiceList = unionBy(updatedInvoices, state.importedInvoiceList, 'factoringInvoiceId')
    },
    SET_FACTORING_CONTRACT_UPDATED_SCHEDULE_INVOICES (state, { changedInvoice }) {
      state.scheduleInvoiceList = unionBy([{
        ...state.scheduleInvoiceList.find(({ invoiceNumber }) => invoiceNumber === changedInvoice.invoiceNumber),
        ...changedInvoice
      }], state.scheduleInvoiceList, 'invoiceNumber')
    },
    SET_CUSTOM_FIELDS (state, { customFields }) {
      state.customFieldsValues = customFields
    }
  }
}
