import { connection } from './connection'

export default {
  deleteAddress ({ id }) {
    return connection.delete(`bo/addresses/${id}`)
  },

  putAddress ({ id, addressData }) {
    return connection.put(`bo/addresses/${id}`, addressData)
  }
}
