import api from '@/api'
import unionBy from 'lodash/unionBy'
import orderBy from 'lodash/orderBy'
import { createActionHelpers } from 'vuex-loading'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    deposits: [],
    depositPages: 0,
    depositTotal: 0,
    depositContract: null,
    depositProduct: {},
    systemCustomers: [],
    systemCustomer: null,
    depositCustomerAccounts: [],
    depositAudit: [],
    depositResources: [],
    depositResourcesPages: 0,
    depositFinancials: {},
    depositComments: [],
    depositCommentsPages: 0,
    commentsPages: 0,
    depositTransactions: [],
    depositTransactionPages: 0,
    messageTemplate: null,
    depositTermsHistory: [],
    depositTermsHistoryPages: 0,
    depositMessages: [],
    depositMessagesPages: 0,
    depositCustomerHistory: null,
    depositPaymentRelations: [],
    depositCustomer: null,
    customFieldsValues: []
  },

  getters: {
    sortedComments: state => orderBy(state.depositComments, ['commentableType.id', 'commentableId', 'id'], ['desc', 'asc', 'desc']),
    systemCustomersForSelect: (state, getters, rootState, rootGetters) => state.systemCustomers
      .map(customer => ({
        value: customer.id,
        text: `${rootGetters['customers/customerName'](customer)} (${customer.idCode})`,
        sectorId: customer.sectorId,
        segmentId: customer.segmentId
      })),
    optionsFromCustomerAccounts: (state, getters, rootState, rootGetters) => {
      const prepaymentTypeId = rootGetters['classifiers/classifierByName']('accountTypes', 'PREPAYMENT').id
      return state.depositCustomerAccounts
        .filter(account => account.accountTypeId === prepaymentTypeId)
        .map(account => {
          // const accountEntry = account.accountEntries.find(entry => entry.currencyId === rootGetters['classifiers/classifierByName']('currencies', rootState.settings.settings.currency.toUpperCase()).id) || {}
          return {
            value: account.id,
            text: `${account.referenceNumber} (${account.accountEntries[0].availableBalance} ${rootState.settings.settings.currencySymbol})`
          }
        })
    },
    depositProductFees: ({ depositProduct }) => depositProduct.fees?.reduce((res, fee) => ({
      ...res,
      [fee.feeType.code]: fee
    }), {}) ?? {}
  },

  actions: {
    async findSystemCustomers ({ commit, dispatch }, { query, sectorId }) {
      if (!query) {
        commit('SET_SYSTEM_CUSTOMERS', { customers: [] })
        return Promise.resolve()
      }
      const { data: { content: customers } } = await startLoading(dispatch, 'deposits:systemCustomers:fetch', () => api.getCustomers({ filters: { criteria: query, sectorId } }))
      commit('SET_SYSTEM_CUSTOMERS', { customers })
    },
    async loadSystemCustomer ({ commit, dispatch }, { id }) {
      const { data: customer } = await startLoading(dispatch, 'deposits:systemCustomer:fetch', () => api.getCustomer({ id }))

      commit('SET_SYSTEM_CUSTOMER', { customer })
    },
    async loadDepositCustomerAccounts ({ state, dispatch, commit }, { customerId = state.depositContract.customer.id } = {}) {
      const response = await startLoading(dispatch, 'deposits:customer:accounts:fetch', () => {
        return api.getCustomerAccounts({ customerId, productGroup: 'DEPOSIT' })
      })
      commit('SET_DEPOSIT_CUSTOMER_ACCOUNTS', { accounts: response.data })
    },
    async loadDepositContracts ({ commit, dispatch }, { filters } = {}) {
      const { data: { content: deposits, totalPages, totalElements } } = await startLoading(dispatch, 'deposits:fetch', () => api.getDepositContracts({ filters }))

      commit('SET_DEPOSIT_CONTRACTS', { deposits, totalPages, totalElements })
    },
    async loadDepositProduct ({ commit, dispatch, state }, { id = state.depositContract.terms.productId } = {}) {
      const { data: product } = await startLoading(dispatch, 'deposits:product:fetch', () => api.getDepositProduct({ id }))
      commit('SET_DEPOSIT_PRODUCT', { product })
    },
    async createDepositContract ({ commit, dispatch }, { contractData, files, documentCategoryId }) {
      const { data: deposit } = await startLoading(dispatch, 'deposits:contract:save', () => api.postDepositContract({ contractData, files, documentCategoryId }))

      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async setDepositContractManager ({ commit, dispatch }, { contractId, managerId }) {
      const { data: deposit } = await startLoading(
        dispatch,
        `deposits:contract:${contractId}:manager:save`,
        () => api.patchDepositContract({ contractId, operation: 'add', path: '/manager', value: managerId })
      )

      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async setDepositContractStatus ({ commit, dispatch, state }, { contractId = state.depositContract.id, statusId } = {}) {
      const { data: deposit } = await startLoading(
        dispatch,
        `deposits:contract:${contractId}:status:save`,
        () => api.patchDepositContract({ contractId, operation: 'replace', path: '/status', value: statusId })
      )

      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async loadDepositContract ({ commit, dispatch }, { contractId }) {
      const { data: deposit } = await startLoading(dispatch, 'deposits:contract:fetch', () => api.getDepositContract({ contractId }))

      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async updateDepositContract ({ commit, dispatch, state }, { contractId = state.depositContract.id, contractData } = {}) {
      const { data: deposit } = await startLoading(dispatch, 'deposits:contract:save', () => api.patchDepositContractTerms({ contractId, contractData }))

      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async loadDepositContractAudit ({ commit, dispatch, state }, { contractId = state.depositContract.id } = {}) {
      const { data: audit } = await startLoading(dispatch, 'deposits:contract:audit:fetch', () => api.getDepositContractAudit({ contractId }))

      commit('SET_DEPOSIT_CONTRACT_AUDIT', { audit })
    },
    async loadResources ({ commit, dispatch, state }, { contractId = state.depositContract.id } = {}) {
      const { data: { content: resources, totalPages } } = await startLoading(dispatch, 'resources:fetch', () => api.getDepositContractDocuments({ contractId }))

      commit('SET_DEPOSIT_CONTRACT_RESOURCES', { resources, totalPages })
    },
    async saveDepositContractWithdrawal ({ commit, dispatch }, { contractId, withdrawalData }) {
      const { data: deposit } = await startLoading(
        dispatch,
        'deposits:contract:withdrawal:save',
        () => api.putDepositContractWithdrawal({ contractId, withdrawalData })
      )

      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async saveDepositContractTerminate ({ commit, dispatch, state }, { contractId = state.depositContract.id, terminateData }) {
      const { data: deposit } = await startLoading(dispatch, 'deposits:contract:terminate:save', () => api.putDepositContractTerminate({ contractId, terminateData }))
      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async loadDepositContractFinancials ({ commit, dispatch, state }, { contractId = state.depositContract.id } = {}) {
      const { data: financials } = await startLoading(dispatch, 'deposits:contract:financials:fetch', () => api.getDepositContractFinancials({ contractId }))

      commit('SET_DEPOSIT_CONTRACT_FINANCIALS', { financials })
    },
    async loadDepositContractTransactions ({ commit, dispatch, state }, { contractId = state.depositContract.id, filters } = {}) {
      const { data: { content: transactions, totalPages } } = await startLoading(
        dispatch,
        'deposits:contract:transactions:fetch',
        () => api.getDepositContractFinancialTransactions({ contractId, filters }))

      commit('SET_DEPOSIT_CONTRACT_TRANSACTIONS', { transactions, totalPages })
    },
    async saveDepositProvision ({ commit, dispatch, state }, { contractId = state.depositContract.id, provisionData }) {
      const { data: { content: transactions, totalPages } } = await startLoading(dispatch, 'deposits:contract:provision:save', () => api.putDepositContractProvision({ contractId, provisionData }))

      commit('SET_DEPOSIT_CONTRACT_TRANSACTIONS', { transactions, totalPages })
    },
    async saveDepositCorrection ({ commit, dispatch, state }, { contractId = state.depositContract.id, correctionData }) {
      const { data: { content: transactions, totalPages } } = await startLoading(dispatch, 'deposits:contract:correction:save', () => api.putDepositContractCorrection({ contractId, correctionData }))

      commit('SET_DEPOSIT_CONTRACT_TRANSACTIONS', { transactions, totalPages })
    },
    async loadContractTemplate ({ commit, dispatch }, { templateId, contractId, languageId }) {
      await startLoading(dispatch, `contract:template:${templateId}:fetch`, () => api.getDepositContractTemplate({ templateId, contractId, languageId }))
    },
    async loadMessageTemplate ({ commit, dispatch }, { templateId, contractId }) {
      commit('SET_TEMPLATE', { template: null })

      if (!templateId) return

      const { data: template } = await startLoading(dispatch, 'contract:message:template:fetch', () => api.getDepositContractMessageTemplate({ templateId, contractId }))
      commit('SET_TEMPLATE', { template })
    },
    async sendContractMessage ({ commit, dispatch }, { id, data }) {
      await startLoading(dispatch, 'contract:message:save', () => api.postDepositContractMessage({ id, data }))
    },
    async updateResource ({ commit, dispatch }, { resourceId, resourceData }) {
      const { data: resource } = await startLoading(
        dispatch,
        `resource:${resourceId}:save`,
        () => api.putResource({ id: resourceId, resourceData })
      )

      commit('SET_DEPOSIT_RESOURCE', { resource })
    },
    async createDepositResource ({ state, commit, dispatch }, { resourceData, contractId = state.depositContract.id, customerId }) {
      const { data: resource } = await startLoading(dispatch, 'resource:save', () => {
        return api.postResource({
          contractId,
          resourceData,
          customerId
        })
      })
      commit('ADD_DEPOSIT_RESOURCE', { resource })
    },
    async removeResource ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `resource:${id}:remove`, () => {
        return api.deleteResource({ id: id })
      })
      commit('REMOVE_DEPOSIT_RESOURCE', { id })
    },
    async loadComments ({ commit, dispatch, state }, { id = state.depositContract.id, filters } = {}) {
      commit('CLEAR_COMMENTS')

      const { data: { content: comments, totalPages } } = await startLoading(
        dispatch,
        'deposits:comments:fetch',
        () => api.getDepositComments({ contractId: id, filters })
      )

      commit('SET_DEPOSIT_COMMENTS', { comments, totalPages })
    },
    async createComment ({ state, commit, dispatch }, { commentData, commentableId = state.depositContract.id } = {}) {
      const { data: comment } = await startLoading(dispatch, 'deposits:comment:save', () => {
        return api.postComment({ ...commentData, commentableId })
      })
      commit('ADD_DEPOSIT_COMMENT', { comment })
    },
    async updateComment ({ commit, dispatch }, { commentId, commentData }) {
      const { data: comment } = await startLoading(dispatch, `deposits:comment:${commentId}:save`, () => {
        return api.putComment({ id: commentId, data: commentData })
      })
      commit('UPDATE_DEPOSIT_COMMENT', { comment })
    },
    async removeComment ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `deposits:comment:${id}:remove`, () => {
        return api.deleteComment({ id })
      })
      commit('REMOVE_DEPOSIT_COMMENT', { id })
    },
    async updateCommentAction ({ commit, dispatch }, { id, actionId, actionData }) {
      const { data: comment } = await startLoading(dispatch, 'deposits:comment:action:save', () => api.putCommentAction({ id, actionData, actionId }))

      commit('UPDATE_DEPOSIT_COMMENT', { comment })
    },
    async loadDepositHistory ({ commit, dispatch }, { contractId }) {
      commit('SET_DEPOSIT_TERMS_REVISIONS', { depositTermsHistory: [], totalPages: 0 })
      const { data: { content: depositTermsHistory, totalPages } } = await startLoading(dispatch, 'deposits:terms:history:fetch', () => api.getDepositTermsRevisions({ contractId }))

      commit('SET_DEPOSIT_TERMS_REVISIONS', { depositTermsHistory, totalPages })
    },
    async createDepositTermsRevision ({ commit, dispatch }, { contractId, revisionData }) {
      const { data: termsRevision } = await startLoading(dispatch, 'deposits:terms:revision:save', () => api.postDepositTermsRevision({ contractId, revisionData }))

      commit('ADD_DEPOSIT_TERMS_REVISION', { termsRevision })
      return termsRevision
    },
    async removeDepositTermsRevision ({ commit, dispatch }, { contractId, revisionId }) {
      await startLoading(dispatch, `deposits:terms:revision:${revisionId}:remove`, () => api.deleteDepositTermsRevision({ contractId, revisionId }))

      commit('REMOVE_DEPOSIT_TERMS_REVISION', { revisionId })
    },
    async saveDepositTermsRevisionAccept ({ dispatch }, { contractId, revisionId }) {
      await startLoading(dispatch, `deposits:terms:revision:${revisionId}:accept:save`, () => api.putDepositTermsRevisionAccept({ contractId, revisionId }))
    },
    async saveDepositTermsRevisionReasonComment ({ dispatch, commit }, { contractId, revisionId, commentData }) {
      const { data: revision } = await startLoading(dispatch, 'deposits:terms:revision:reason:save', () => api.putDepositTermsRevisionComment({ contractId, revisionId, commentData }))

      commit('UPDATE_DEPOSIT_TERMS_REVISION', { revision })
    },
    async loadMessages ({ state, commit, dispatch }, filters) {
      const { data: { content: messages, totalPages } } = await startLoading(dispatch, 'customer:messages:fetch', () => {
        return api.getCustomerMessages({ customerId: state.depositContract.customer.id, filters })
      })
      commit('SET_DEPOSIT_MESSAGES', { messages, totalPages })
    },
    async createMessage ({ state, commit, dispatch }, { messageData, direction }) {
      const response = await startLoading(
        dispatch,
        'customer:message:save',
        () => api.postCustomerMessage({ customerId: state.depositContract.customer?.id, data: messageData })
      )
      commit('ADD_DEPOSIT_MESSAGE', { message: response.data, direction })
    },
    async loadDepositContractByRevision ({ commit, dispatch }, { contractId, revisionId }) {
      const { data: deposit } = await startLoading(
        dispatch,
        `deposits:contract:revision:${revisionId}:fetch`,
        () => api.getDepositContractByRevision({ contractId, revisionId })
      )
      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async saveDepositTermsRevision ({ dispatch, commit }, { contractId, revisionId, revisionData }) {
      const { data: revision } = await startLoading(
        dispatch,
        `deposits:contract:revision:${revisionId}:save`,
        () => api.putDepositTermsRevision({ contractId, revisionId, revisionData })
      )

      commit('UPDATE_DEPOSIT_TERMS_REVISION', { revision })
    },
    async loadCustomerHistory ({ state, commit, dispatch }, { customerId = state.depositContract?.customer?.id } = {}) {
      const response = await startLoading(dispatch, 'customer:history:fetch', () => {
        return api.getCustomerHistory({ customerId })
      })
      commit('SET_CUSTOMER_HISTORY', { history: response.data })
    },
    async loadDepositPaymentRelations ({ commit, dispatch, state }, { contractId = state.depositContract.id } = {}) {
      const { data: paymentRelations } = await startLoading(
        dispatch,
        'deposit:paymentRelations:fetch',
        () => api.getDepositPaymentRelations({ contractId })
      )

      commit('SET_DEPOSIT_PAYMENT_RELATIONS', { paymentRelations })
    },
    async saveDepositPaymentRelation ({ commit, dispatch, state }, { paymentRelationData, paymentRelationId, contractId = state.depositContract.id }) {
      const { data: relation } = await startLoading(
        dispatch,
        'deposit:paymentRelation:save',
        () => api.putDepositPaymentRelation({ contractId, paymentRelationData, paymentRelationId })
      )

      commit('SET_DEPOSIT_PAYMENT_RELATION', { relation })
    },
    async createDepositPaymentRelation ({ commit, dispatch, state }, { paymentRelationData, contractId = state.depositContract.id }) {
      const { data: relation } = await startLoading(
        dispatch,
        'deposit:paymentRelation:save',
        () => api.postDepositPaymentRelation({ contractId, paymentRelationData })
      )

      commit('SET_DEPOSIT_PAYMENT_RELATION', { relation })
    },
    async loadDepositCustomer ({ state, commit, dispatch }, { id = state.depositContract.customer.id } = {}) {
      const { data: customer } = await startLoading(dispatch, 'deposit:customer:fetch', () => api.getCustomer({ id }))

      commit('SET_DEPOSIT_CUSTOMER', { customer })
    },
    async syncDepositContractWithProduct ({ state, commit, dispatch }, { contractId = state.depositContract.id } = {}) {
      const { data: deposit } = await startLoading(dispatch, 'deposit:product:sync', () => api.putDepositContractSyncProduct({ contractId }))

      commit('SET_DEPOSIT_CONTRACT', { deposit })
    },
    async loadDepositCustomFields ({ dispatch, commit }, { contractId, productGroup }) {
      const { data: customFields } = await startLoading(
        dispatch,
        'deposit:custom-fields:fetch',
        () => api.getContractCustomFieldValues({ contractId, productGroup })
      )
      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async saveDepositCustomFields ({ dispatch, commit }, { contractId, productGroup, customFieldData }) {
      const { data: customFields } = await startLoading(
        dispatch,
        'deposit:custom-fields:save',
        () => api.postContractCustomFieldValues({ contractId, customFieldValues: customFieldData, productGroup })
      )
      commit('SET_CUSTOM_FIELDS', { customFields })
    }
  },

  mutations: {
    SET_SYSTEM_CUSTOMERS (state, { customers }) {
      state.systemCustomers = customers
    },
    SET_SYSTEM_CUSTOMER (state, { customer }) {
      state.systemCustomer = customer
    },
    SET_DEPOSIT_CONTRACTS (state, { deposits, totalPages, totalElements }) {
      state.deposits = deposits
      state.depositPages = totalPages
      state.depositTotal = totalElements
    },
    SET_DEPOSIT_PRODUCT (state, { product }) {
      state.depositProduct = product
    },
    SET_DEPOSIT_CUSTOMER_ACCOUNTS (state, { accounts }) {
      state.depositCustomerAccounts = accounts
    },
    SET_DEPOSIT_CONTRACT (state, { deposit }) {
      state.depositContract = deposit
    },
    SET_DEPOSIT_CONTRACT_AUDIT (state, { audit }) {
      state.depositAudit = audit
    },
    SET_DEPOSIT_CONTRACT_RESOURCES (state, { resources, totalPages }) {
      state.depositResources = resources
      state.depositResourcesPages = totalPages
    },
    SET_DEPOSIT_CONTRACT_FINANCIALS (state, { financials }) {
      state.depositFinancials = financials
    },
    SET_DEPOSIT_CONTRACT_TRANSACTIONS (state, { transactions, totalPages }) {
      state.depositTransactions = transactions
      state.depositTransactionPages = totalPages
    },
    SET_TEMPLATE (state, { template }) {
      state.messageTemplate = template
    },
    SET_DEPOSIT_RESOURCE (state, { resource }) {
      state.depositResources = unionBy([resource], state.depositResources, 'id')
    },
    ADD_DEPOSIT_RESOURCE (state, { resource }) {
      state.depositResources.push(resource)
    },
    REMOVE_DEPOSIT_RESOURCE (state, { id }) {
      state.depositResources = state.depositResources.filter(resource => resource.id !== id)
    },
    SET_DEPOSIT_COMMENTS (state, { comments, totalPages }) {
      state.depositComments = comments
      state.depositCommentsPages = totalPages
    },
    ADD_DEPOSIT_COMMENT (state, { comment }) {
      state.depositComments.push(comment)
    },
    UPDATE_DEPOSIT_COMMENT (state, { comment }) {
      state.depositComments = unionBy([comment], state.depositComments, 'id')
    },
    REMOVE_DEPOSIT_COMMENT (state, { id }) {
      state.depositComments = state.depositComments.filter(comment => comment.id !== id)
    },
    CLEAR_COMMENTS (state) {
      state.depositComments = []
      state.depositCommentsPages = 0
    },
    SET_DEPOSIT_TERMS_REVISIONS (state, { depositTermsHistory, totalPages }) {
      state.depositTermsHistory = depositTermsHistory
      state.depositTermsHistoryPages = totalPages
    },
    ADD_DEPOSIT_TERMS_REVISION (state, { termsRevision }) {
      state.depositTermsHistory.push(termsRevision)
    },
    REMOVE_DEPOSIT_TERMS_REVISION (state, { revisionId }) {
      state.depositTermsHistory = state.depositTermsHistory.filter(({ id }) => revisionId !== id)
    },
    UPDATE_DEPOSIT_TERMS_REVISION (state, { revision }) {
      state.depositTermsHistory = unionBy([revision], state.depositTermsHistory, 'id')
    },
    SET_DEPOSIT_MESSAGES (state, { messages, totalPages }) {
      state.depositMessages = messages
      state.depositMessagesPages = totalPages
    },
    SET_CUSTOMER_HISTORY (state, { history }) {
      state.depositCustomerHistory = {
        ...state.depositCustomerHistory,
        contracts: orderBy([...(history.loans || []), ...(history.deposits || []), ...(history.factorings ?? [])], ['createdAt', 'id'], ['desc', 'desc']),
        creditApplications: orderBy(history.creditApplications, ['createdAt', 'id'], ['desc', 'desc'])
      }
    },
    SET_DEPOSIT_PAYMENT_RELATIONS (state, { paymentRelations }) {
      state.depositPaymentRelations = paymentRelations
    },
    SET_DEPOSIT_PAYMENT_RELATION (state, { relation }) {
      state.depositPaymentRelations = unionBy([relation], state.depositPaymentRelations, 'id')
    },
    SET_DEPOSIT_CUSTOMER (state, { customer }) {
      state.depositCustomer = customer
    },
    ADD_DEPOSIT_MESSAGE (state, { message, direction }) {
      if (message.direction !== direction) {
        return
      }
      state.depositMessages.push(message)
    },
    SET_CUSTOM_FIELDS (state, { customFields }) {
      state.customFieldsValues = customFields
    }
  }
}
