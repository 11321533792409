<template lang="pug">
.card.payment-account-prev-transactions
  .card-header {{ $t('title') }}
  .card-body
    payment-list(
      :payments = 'accountPayments'
      loader    = 'payment:accountPayments:fetch'
    )
</template>


<script>
import PaymentList from '@/views/payments/PaymentList.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'payment-account-prev-transactions',

  components: { PaymentList },

  i18nOptions: {},

  computed: {
    ...mapState('payments', ['accountPayments'])
  },

  mounted () {
    this.loadAccountPayments()
  },

  methods: {
    ...mapActions('payments', ['loadAccountPayments'])
  }
}
</script>


<i18n>
en:
  title: "Previous transactions from the same account"
et:
  title: "Previous transactions from the same account"
ru:
  title: "Previous transactions from the same account"
</i18n>
