<template lang='pug'>
.reports-calculate-history
  button.fa-pull-right.btn.btn-outline-primary.mb-2(
    :disabled      = 'loading'
    @click.prevent = 'loadCalculateHistory'
  )
    i.mr-2.fa.fa-refresh(:class = '{ "fa-pulse": loading }')
    | {{ $t('refresh') }}
  table.table
    tr
      th {{ $t('day') }}
      th {{ $t('status') }}
      th {{ $t('started') }}
      th {{ $t('finished') }}
    tr(v-if = 'loading')
      td(colspan = 3)
        .text-center: i.fa.fa-spinner.fa-pulse.ml-1
    template(v-else)
      tr(v-for = 'history in calculateHistory')
        td {{ $t('common:formatDate', { value: history.day }) }}
        td {{ history.status }}
        td {{ $t('common:formatDatetime', { value: history.startedAt }) }}
        td {{ $t('common:formatDatetime', { value: history.finishedAt }) }}
</template>

<script>
import Template from '@/views/admin/templates/Template'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'reports-calculate-history',
  components: { Template },
  i18nOptions: {},

  computed: {
    ...mapState('reports', ['calculateHistory']),
    loading () {
      return this.$vueLoading.isLoading('reports:calculate:history:fetch')
    }
  },

  mounted () {
    this.loadCalculateHistory()
  },

  methods: mapActions('reports', ['loadCalculateHistory'])
}
</script>


<i18n>
en:
  day:     "Day"
  status:  "Status"
  started: "Started"
  refresh: "Refresh"
  finished: "Finished"
et:
  day:     "Day"
  status:  "Status"
  started: "Started"
  refresh: "Refresh"
  finished: "Finished"
ru:
  day:     "Day"
  status:  "Status"
  started: "Started"
  refresh: "Refresh"
  finished: "Finished"
</i18n>
