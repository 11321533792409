import { connection } from '@/api/connection'
import pickBy from 'lodash/pickBy'
import qs from 'qs'

export default {
  getTaxationClassifiers () {
    return connection.get('bo/taxes/classifiers')
  },
  getDepositTaxationList ({ filters }) {
    return connection.get('bo/taxes', {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  getTaxRate ({ taxRateId }) {
    return connection.get(`bo/taxes/${taxRateId}`)
  },
  postTaxRate ({ taxRateData }) {
    return connection.post('bo/taxes', taxRateData)
  },
  putTaxRate ({ taxRateData, taxRateId }) {
    return connection.put(`bo/taxes/${taxRateId}`, taxRateData)
  },
  deleteTaxRate ({ taxRateId }) {
    return connection.delete(`bo/taxes/${taxRateId}`)
  },
  getTaxRateVat ({ countryId }) {
    return connection.get(`bo/taxes/VAT/rates/${countryId}`)
  },
  getTaxationCountries ({ taxType }) {
    return connection.get('bo/taxes/countries', {
      params: {
        taxType
      }
    })
  },
  convertAmount ({ amountData }) {
    return connection.post('bo/taxes/VAT/conversion', amountData)
  },
  getTaxationTransactions (filters) {
    return connection.get('bo/taxes/transactions', {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  }
}
