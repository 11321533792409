import api from '@/api'
import { createActionHelpers } from 'vuex-loading'
const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    systemNotifications: [],
    automaticNotifications: [],
    automaticNotification: null
  },

  actions: {
    async loadSystemNotifications ({ commit, dispatch }) {
      const { data: notifications } = await startLoading(dispatch, 'notifications:fetch', () => api.getSystemNotifications())

      commit('SET_SYSTEM_NOTIFICATIONS', { notifications })
    },
    async loadAutomaticNotifications ({ commit, dispatch }) {
      const { data: notifications } = await startLoading(dispatch, 'notifications:automatic:fetch', () => api.getAutomaticNotifications())

      commit('SET_AUTOMATIC_NOTIFICATIONS', { notifications })
    },
    async loadAutomaticNotification ({ commit, dispatch }, { notificationId }) {
      const { data: notification } = await startLoading(dispatch, `notification:automatic:${notificationId}:fetch`, () => api.getAutomaticNotification({ notificationId }))

      commit('SET_AUTOMATIC_NOTIFICATION', { notification })
    },
    async createAutomaticNotification ({ commit, dispatch }, { notificationData }) {
      const { data: notification } = await startLoading(dispatch, 'notification:automatic:save', () => api.postAutomaticNotification({ notificationData }))

      commit('SET_AUTOMATIC_NOTIFICATION', { notification })
    },
    async updateAutomaticNotification ({ commit, dispatch }, { notificationId, notificationData }) {
      const { data: notification } = await startLoading(dispatch, `notification:automatic:${notificationId}:save`, () => api.putAutomaticNotification({ notificationId, notificationData }))

      commit('SET_AUTOMATIC_NOTIFICATION', { notification })
    },
    async deleteAutomaticNotification ({ commit, dispatch }, { notificationId }) {
      await startLoading(dispatch, `notification:automatic:${notificationId}:delete`, () => api.deleteAutomaticNotification({ notificationId }))
      commit('DELETE_AUTOMATIC_NOTIFICATIONS', { notificationId })
    }
  },

  mutations: {
    SET_SYSTEM_NOTIFICATIONS (state, { notifications }) {
      state.systemNotifications = notifications
    },
    SET_AUTOMATIC_NOTIFICATIONS (state, { notifications }) {
      state.automaticNotifications = notifications
    },
    CREATE_AUTOMATIC_NOTIFICATION (state, { notification }) {
      state.automaticNotifications.push(notification)
    },
    SET_AUTOMATIC_NOTIFICATION (state, { notification }) {
      state.automaticNotification = notification
    },
    DELETE_AUTOMATIC_NOTIFICATIONS (state, { notificationId }) {
      state.automaticNotifications = state.automaticNotifications.filter(notification => notification.id !== notificationId)
    }
  }
}
