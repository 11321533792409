import { required } from 'vuelidate/lib/validators'

export default {
  currencyId: {
    required
  },
  dayCountConventionId: {
    required
  },
  firstInterestPayment: {
    required
  },
  firstPrincipalPayment: {
    required
  },
  interestPaymentPeriodId: {
    required
  },
  penaltyDayCountConventionId: {
    required
  },
  principalPaymentPeriodId: {
    required
  },
  scheduleTypeId: {
    required
  },
  invoicePaymentTerms: {
    required
  },
  invoicingDay: {
    required
  },
  dailyBalanceCalcType: {
    required
  },
  periodUnitType: {
    required
  },
  period: {
    required
  },
  periodMin: {
    required
  },
  periodMax: {
    required
  },
  periodUnitTypeId: {
    required
  }
}
