<template lang='pug'>
.payment-outgoing-fields
  template(v-if   = 'Object.hasOwnProperty.call(validationState, "paymentWayId")')
    fi-form-field(
      :label = '$t("paymentWay")'
      :state = '!validationState.paymentWayId.$error'
    )
      template(#error)
        .error-message(v-if = '!validationState.paymentWayId.required') {{ $t('common:required') }}
      fi-select(
        v-model  = 'validationState.paymentWayId.$model'
        :class   = '{ "is-invalid": validationState.paymentWayId.$error }'
        :options = 'optionsFromClassifier("paymentWays")'
        sm
        required
      )
  template(v-if = '!isNew')
    fi-form-field(:label = '$t("status")')
      fi-select(
        v-model   = 'validationState.status.$model'
        :options  = 'paymentStatuses'
        required
        sm
      )
    fi-form-field(:label = '$t("handler")')
      fi-select(
        v-model   = 'validationState.paymentHandler.$model'
        :options  = 'optionsFromHandlers'
        required
        sm
      )
  fi-form-field(
    :label    = '$t("customerName")'
    :disabled = '!isNew'
  )
    fi-multiselect(
      v-if             = 'isNew'
      v-model          = 'selectedCustomerModel'
      :options         = 'customers'
      :search-callback = 'findCustomers'
      :loading         = '$vueLoading.isLoading("loans:systemCustomers:fetch")'
    )
    input.form-control-sm.form-control(
      v-if    = '!isInternalPayment'
      v-model = 'validationState.customerFullName.$model'
    )
  fi-form-field(
    :label    = '$t("bankAccount")'
    :state    = '!validationState.externalAccount.$error'
    :disabled = '!isNew'
  )
    template(#error)
      .error-message(v-if = '!validationState.externalAccount.required') {{ $t('common:required') }}
    fi-select(
      v-if         = 'accounts.length'
      v-model.trim = 'validationState.externalAccount.$model'
      :options     = 'accounts'
      sm
      required
    )
    input.form-control-sm.form-control(
      v-else
      v-model.trim = 'validationState.externalAccount.$model'
      :class       = '{ "is-invalid": validationState.externalAccount.$error }'
    )
  fi-form-field(:label = '$t("amount")')
    fi-money-input.form-control-sm.form-control(
      v-model   = 'validationState.amount.$model'
      :disabled = '!isNew'
    )

  fi-form-field(
    v-if   = '!isNew'
    :label = '$t("payableAfterTime")'
    :state = '!validationState.payableAfterTime.$error'
  )
    template(#error)
      .error-message(v-if = '!validationState.payableAfterTime.positive') {{ $t('positiveError') }}
    input.form-control-sm.form-control(
      v-model   = 'validationState.payableAfterTime.$model'
      :disabled = '!editable'
      :class    = '{ "is-invalid": validationState.payableAfterTime.$error }'
    )

  fi-form-field(
    :label = '$t("description")'
    :state = '!validationState.description.$error'
  )
    template(#error)
      .error-message(v-if = '!validationState.description.required') {{ $t('common:required') }}
    input.form-control-sm.form-control(
      v-model   = 'validationState.description.$model'
      :disabled = '!editable'
      :class    = '{ "is-invalid": validationState.description.$error }'
    )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import { mapGetters, mapState } from 'vuex'
import FiMultiselect from '@/components/FiMultiselect'
import api from '@/api'

export default {
  name: 'payment-outgoing-fields',

  components: { FiMultiselect, FiMoneyInput, FiSelect, FiFormField },

  props: {
    isNew: {
      type: Boolean,
      default: false
    },
    statuses: {
      type: Array,
      default: () => []
    },
    validationState: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    customers: {
      type: Array,
      default: () => []
    },
    accounts: {
      type: Array,
      default: () => []
    },
    selectedCustomer: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('customers', ['customer']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'optionsFromHandlers']),
    paymentStatuses () {
      const statuses = [
        ...this.statuses,
        ...[this.validationState.status.$model]
      ]
      return this.optionsFromClassifier('outgoingPaymentStatuses', true).filter(({ value }) => !statuses.length || statuses.includes(value))
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.$emit('select-customer', selected)
      }
    },
    isInternalPayment () {
      return this.classifierById('paymentWays', this.validationState.paymentWayId?.$model)?.code === 'internal'
    }
  },

  async mounted () {
    const { data: customer } = await api.getCustomer({ id: this.customer.id })
    this.$emit('select-customer', { ...customer, value: customer.id })
  },

  methods: {
    findCustomers (query) {
      this.$emit('find-customers', query)
    }
  }
}
</script>


<i18n>
en:
  customerName:     "Customer name"
  bankAccount:      "Customer bank account"
  amount:           "Amount"
  status:           "Status"
  handler:          "Handler"
  description:      "Description"
  payableAfterTime: "Payable after hours"
  positiveError:    "Field value should be positive"
  paymentWay:       "Payment destination"
et:
  customerName:     "Customer name"
  bankAccount:      "Customer bank account"
  amount:           "Amount"
  status:           "Status"
  handler:          "Handler"
  description:      "Description"
  payableAfterTime: "Payable after hours"
  positiveError:    "Field value should be positive"
  paymentWay:       "Payment destination"
ru:
  customerName:     "Customer name"
  bankAccount:      "Customer bank account"
  amount:           "Amount"
  status:           "Status"
  handler:          "Handler"
  description:      "Description"
  payableAfterTime: "Payable after hours"
  positiveError:    "Field value should be positive"
  paymentWay:       "Payment destination"
</i18n>
