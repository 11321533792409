<template lang="pug">
.card.dashboard-widget-applications-new
  .card-header.text-white.bg-primary.border-bottom-0
    i.fa.fa-2x.fa-inbox.text-muted.fa-pull-right.m-0
    h4.mb-0 {{ dashboardApplicationsNewCount }}
    small.text-muted.font-weight-bold
      router-link.text-muted.font-weight-bold(:to = '{ name: "Applications", query: { statusId: "1" } }') {{ $t('title') }}
  .card-body
    v-loading(loader = 'dashboard:applicationsNew:fetch')
      .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
      .text-center(v-if = 'dashboardApplicationsNewCount === 0') {{ $t('notFound') }}
      ul.list-unstyled.m-0(v-else)
        dashboard-widget-applications-new-item(v-for        = 'application in dashboardApplicationsNew'
                                               :application = 'application'
                                               :key         = 'application.id')
        li.text-center
          router-link.text-body.d-block(:to = '{ name: "Applications", query: { statusId: statusInsertedId } }'): i.fa.fa-ellipsis-h.fa-lg
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DashboardWidgetApplicationsNewItem from './DashboardWidgetApplicationsNewItem'

export default {
  name: 'dashboard-widget-applications-new',

  components: {
    DashboardWidgetApplicationsNewItem
  },

  i18nOptions: {},

  computed: {
    ...mapState('dashboard', ['dashboardApplicationsNew', 'dashboardApplicationsNewCount']),
    ...mapGetters('classifiers', ['classifierByName']),
    statusInsertedId () {
      return this.classifierByName('creditApplicationStatuses', 'inserted')?.id
    }
  },

  mounted () {
    this.loadDashboardApplicationsNew()
    this.loadProducts()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardApplicationsNew']),
    ...mapActions('products', ['loadProducts'])
  }
}
</script>


<i18n>
en:
  title:    "NEW APPLICATIONS"
  notFound: "Nothing found"

et:
  title:    "NEW APPLICATIONS"
  notFound: "Nothing found"

ru:
  title:    "NEW APPLICATIONS"
  notFound: "Nothing found"

</i18n>
