const ReportsRangeDate = () => import('@/views/reports/ReportsRangeDate')
const ReportsSingleDate = () => import('@/views/reports/ReportsSingleDate')
const InspectionReportsSingleDate = () => import('@/views/reports/InspectionReportsSingleDate')


export default [
  {
    category: 'Accounting reports',
    reports: [
      {
        name: 'Accounts',
        code: 'accountsSheet',
        actionCSV: 'loadAccountsSheetReport',
        component: ReportsRangeDate,
        actionJSON: 'loadAccountsSheetReportJSON'
      },
      // {
      //   name: 'Balance',
      //   code: 'balanceSheet',
      //   actionCSV: 'loadBalanceSheetReport',
      //   component: ReportsRangeDate,
      // },
      // {
      //   name: 'Income',
      //   code: 'income',
      //   actionCSV: 'loadIncomeSheetReport',
      //   component: ReportsRangeDate,
      // },
      {
        name: 'Periodization',
        actionCSV: 'loadPeriodisingReport',
        component: ReportsSingleDate
      }
      // {
      //   name: 'Current and non-current loans',
      //   actionCSV: 'loadCurrentSheetReport',
      //   component: ReportsRangeDate
      // },
      // {
      //   name: 'Incoming payments',
      //   actionCSV: 'loadPrepaymentIncomingTransactionsSheet',
      //   component: ReportsRangeDate
      // }
    ]
  },
  {
    category: 'Behaviour',
    reports: [
      {
        name: 'Behaviour of mortgage loan customers',
        code: 'behaviorMortgage',
        description: 'Mortgage loan contracts with correct behaviour and debtors by days in debt',
        actionCSV: 'loadMortgageStatementReport',
        actionJSON: 'loadMortgageStatementReportJSON',
        component: ReportsSingleDate
      },
      // {
      //   name: 'Loans in debt',
      //   code: 'behaviorDebts',
      //   description: 'The profile of loan in debt contracts',
      //   actionCSV: 'loadInDebtStatementReport',
      //   component: 'ReportsSingleDate'
      // },
      {
        name: 'Pledgee report',
        code: 'behaviorPledgee',
        description: 'Total outstanding by pledgee',
        actionCSV: 'loadPledgeeStatementReport',
        component: ReportsSingleDate
      }
      // {
      //   name: 'Prepayments',
      //   description: '',
      //   actionCSV: 'loadPrepaymentTransactionsSheet',
      //   component: 'ReportsRangeDate'
      // }
    ]
  },
  {
    category: 'Financial inspection',
    reports: [
      {
        name: 'Appendix 3 - Loans Turnover',
        actionCSV: 'loadReport',
        actionXML: 'loadReportXML',
        reportName: 'REPORT_6107',
        component: ReportsRangeDate
      },
      {
        name: 'Appendix 4 - Loan Debts',
        actionCSV: 'loadReport',
        actionXML: 'loadReportXML',
        reportName: 'REPORT_6108',
        component: InspectionReportsSingleDate
      },
      {
        name: 'Appendix 5 - Loan Portfolio',
        actionCSV: 'loadReport',
        actionXML: 'loadReportXML',
        reportName: 'REPORT_6105',
        component: InspectionReportsSingleDate
      },
      {
        name: '6111 report',
        actionCSV: 'loadReport',
        actionXML: 'loadReportXML',
        reportName: 'REPORT_6111',
        component: ReportsRangeDate
      },
      {
        name: '6112 report',
        actionCSV: 'loadReport',
        actionXML: 'loadReportXML',
        reportName: 'REPORT_6112',
        component: InspectionReportsSingleDate
      },
      {
        name: '212 report',
        actionCSV: 'loadReport',
        actionXML: 'loadReportXML',
        reportName: 'REPORT_212',
        component: InspectionReportsSingleDate
      }
    ]
  },
  {
    category: 'Loan overview',
    reports: [
      {
        name: 'Loan overview',
        code: 'loansSheet',
        actionCSV: 'loadLoansSheetReport',
        actionJSON: 'loadLoansSheetReportJSON',
        component: ReportsSingleDate
      }
    ]
  },
  {
    category: 'Tax - MMR report',
    reports: [
      {
        name: 'Tax - MMR report',
        code: 'taxMmr',
        actionXML: 'loadMMRReportXML',
        isMMR: true,
        component: ReportsSingleDate
      }
    ]
  }
]
