import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import { parseStringBoolean } from '@/helpers'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    settings: null,
    files: {},
    logo: null
  },

  getters: {
    fileByKey: state => key => state.files[key],
    settingByKey: state => key => state.settings?.[key],
    isLimitEnabled: state => Boolean(state.settings?.creditLimitInitial),
    isDepositEnabled: state => parseStringBoolean(state.settings?.depositMode),
    isFactoringEnabled: state => parseStringBoolean(state.settings?.factoringMode),
    isScoringEnabled: state => parseStringBoolean(state.settings?.scoringMode),
    creditIssuerAccountOptions: state => state.settings?.creditIssuerBankAccount.split(',')
      .filter(acc => acc)
      .map(account => ({
        value: account,
        text: account
      }))
  },

  actions: {
    async loadSettings ({ commit, dispatch, state }, { force } = { force: false }) {
      if (force || state.settings === null) {
        commit('SET_SETTINGS', { settings: null })
        const { data: settings } = await startLoading(dispatch, 'settings:fetch', () => {
          return api.getSettings()
        })
        commit('SET_SETTINGS', { settings })
      }
    },
    async saveSettings ({ commit, dispatch }, { data }) {
      const { data: settings } = await startLoading(dispatch, 'settings:save', () => {
        return api.putSettings({ data: data })
      })
      commit('SET_SETTINGS', { settings })
    },
    async loadLogo ({ dispatch, commit }) {
      try {
        const { data, headers } = await startLoading(dispatch, 'system:logo:fetch', () => api.getLogo())
        const filename = headers['content-disposition']?.match('filename="(.*)"')[1] || 'file'
        const file = new File([data], filename, { type: headers['content-type'] })
        const fileReader = new FileReader()
        fileReader.onload = (e) => commit('SET_LOGO', { logo: e.target.result })
        fileReader.readAsDataURL(file)
      } catch (e) {
        Promise.reject(e)
      }
    },
    async saveSettingFile ({ commit, dispatch }, { key, file }) {
      const { data } = await startLoading(dispatch, `setting:${key}:file:save`, () => api.postSettingFile({ key, file }))

      commit('SET_SETTINGS_KEY', data)
      return data.value
    }
  },

  mutations: {
    SET_SETTINGS (state, { settings }) {
      state.settings = settings
    },
    GET_SETTING_FILE (state, { key, file }) {
      state.files = { ...state.files, [key]: file }
    },
    SET_SETTINGS_KEY (state, setting) {
      state.settings = { ...state.settings, [setting.keyAsLowerCamelCase]: setting.value }
    },
    SET_LOGO (state, { logo }) {
      state.logo = logo
    }
  }
}
