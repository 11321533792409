import { connection } from '@/api/connection'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export default {
  getDepositClassifiers () {
    return connection.get('bo/products/deposit/classifiers')
  },
  getDepositProduct ({ id }) {
    return connection.get(`bo/products/deposit/${id}`)
  },
  postDepositProduct ({ productData }) {
    return connection.post('bo/products/deposit', omitBy(productData, isNil))
  },
  putDepositProduct ({ id, productData }) {
    return connection.put(`bo/products/deposit/${id}`, omitBy(productData, isNil))
  }
}
