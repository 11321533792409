export default [
  {
    path: 'applications',
    name: 'Applications',
    component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/ApplicationList'),
    meta: { label: 'menu:applications' }
  },
  {
    path: 'applications',
    component: { render (c) { return c('router-view') } },
    meta: { label: 'menu:applications' },
    children: [
      {
        path: 'new',
        name: 'ApplicationNew',
        component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/ApplicationNewPage'),
        meta: { label: 'menu:applicationNew' },
        props: route => (
          {
            productType: route.query.productType,
            copy: route.params.copy,
            customerId: route.params.customerId
          })
      },
      {
        path: ':id',
        name: 'Application',
        component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/Application'),
        meta: { label: 'menu:application' },
        props: true,
        redirect: { name: 'ApplicationOverview' },
        children: [
          {
            path: 'overview',
            name: 'ApplicationOverview',
            component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/ApplicationOverview'),
            meta: { skip: true }
          },
          {
            path: 'scoring',
            name: 'ApplicationScoring',
            component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/ApplicationScoring'),
            meta: { skip: true }
          },
          {
            path: 'communication',
            name: 'ApplicationCommunication',
            component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/ApplicationCommunication'),
            meta: { skip: true }
          },
          {
            path: 'collateral',
            name: 'ApplicationCollateral',
            component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/ApplicationCollateral'),
            meta: { skip: true }
          },
          {
            path: 'customerHistory',
            name: 'ApplicationCustomerHistory',
            component: () => import(/* webpackChunkName: "Applications" */'@/views/applications/ApplicationCustomerHistory'),
            meta: { skip: true }
          }
        ]
      }
    ]
  }
]
