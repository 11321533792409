<template lang="pug">
tr.payment-outgoing-list-item
  td(v-if = 'isSelectable')
    .custom-control.custom-checkbox
      input.custom-control-input(
        v-model = 'selected'
        :id     = '`payment-item-${payment.id}`'
        type    = 'checkbox'
        :value  = 'payment.id'
      )
      label.custom-control-label(:for = '`payment-item-${payment.id}`')
  td: router-link(:to = '{ name: "outgoingPayment", params: { id: payment.id.toString() } }') {{ payment.id }}
  td {{ $t('common:formatDate', { value: payment.paymentDate }) }}
  td.text-nowrap
    router-link(
      v-if = 'metadata.createdById'
      :to  = '{ name: "Customer", params: { id: metadata.createdById.toString() }}'
    ) {{ metadata.createdByFullName }}
  td {{ metadata.triggeredBy }}
  td.text-nowrap
    router-link(
      v-if = 'metadata.confirmedBy'
      :to  = '{ name: "Customer", params: { id: metadata.confirmedBy.toString() }}'
    ) {{ metadata.confirmedByName }}
  td.text-nowrap {{ payment.toAccount }}
  td {{ payment.description }}
  td.money {{ payment.amount | money }}
  td {{ payment.paymentHandler }}
  td {{ $t('common:formatDatetime', { value: payment.payableAfterDateTime }) }}
  td
    router-link(
      v-if = 'metadata.idCode'
      :to  = '{ name: "Customer", params: { id: metadata.customerId.toString() }}'
    ) {{ payment.toName }} ({{ metadata.idCode }})
    template(v-else) {{ payment.toName }}
  td: .badge(:class = 'statusClass') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

const STATUS_COLOR_MAP = {
  WAITING_APPROVAL: 'primary',
  CREATED: 'primary',
  COMPLETED: 'success',
  PENDING: 'warning',
  CANCELED: 'secondary',
  FAILED: 'danger'
}

export default {
  ename: 'payment-outgoing-list-item',

  props: {
    payment: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    isSelectable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'managerById']),
    ...mapGetters('customers', ['customerName']),
    status () {
      return this.classifierById('outgoingPaymentStatuses', this.payment.status)
    },
    statusClass () {
      return `badge-${STATUS_COLOR_MAP[this.payment.status] ?? 'dark'}`
    },
    selected: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    metadata () {
      return this.payment.metadata || {}
    }
  }
}
</script>
