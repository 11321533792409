<template lang="pug">
navbar-view.header.header-view
  button.navbar-toggler.mobile-sidebar-toggler.d-xl-none(
    type           = 'button'
    @click.prevent = 'mobileSidebarToggle'
  )
    i.fa.fa-bars
  a.navbar-brand.animated.fadeIn(
    href   = '/'
    :style = 'logoStyle'
  )
    i.fa.fa-spinner.fa-pulse.fa-lg(v-if = 'logoLoader && !logo')
  ul.nav.navbar-nav.d-lg-down-none
    form(@submit.prevent = 'doSearch')
      .form-group.mb-0.pr-2
        input.form-control(
          v-model      = 'searchCriteria'
          type         = 'text'
          :placeholder = '$t("search")'
        )
    li.nav-item: router-link.nav-link.px-2(:to = '{ name: "Dashboard", params: { statusUserNotification: "true" }}') {{ $t('dashboard') }}
    li.nav-item: router-link.nav-link.px-2(:to = '{ name: "Applications" }') {{ $t('applications') }}
    li.nav-item: router-link.nav-link.px-2(:to = '{ name: "Customers" }') {{ $t('customers') }}
    li.nav-item: router-link.nav-link.px-2(:to = '{ name: "Loans" }') {{ $t('loans') }}
    li.nav-item(v-if = 'isFactoringEnabled')
      router-link.nav-link.px-2(:to = '{ name: "FactoringList" }') {{ $t('factoring') }}
    li.nav-item(v-if = 'isDepositEnabled')
      router-link.nav-link.px-2(:to  = '{ name: "Deposits" }') {{ $t('deposits') }}
    li.nav-item: router-link.nav-link.px-2(:to = '{ name: "Debts" }') {{ $t('debts') }}
    li.nav-item(v-if = 'isScoringEnabled'): router-link.nav-link.px-2(:to = '{ name: "ScoringRequests" }') {{ $t('scoring') }}
    li.nav-item: router-link.nav-link.px-2(:to = '{ name: "Payments" }') {{ $t('payments') }}
    li.nav-item: router-link.nav-link.px-2(:to = '{ name: "ReportsView" }') {{ $t('reports') }}
    b-nav-item-dropdown.px-2(:text = '$t("admin")')
      b-dropdown-item(:to = '{ name: "ProductList" }') {{ $t('products') }}
      b-dropdown-item(:to = '{ name: "Classifiers" }') {{ $t('classifiers') }}
      b-dropdown-item(:to = '{ name: "Settings" }') {{ $t('settings') }}
      b-dropdown-item(:to = '{ name: "TemplateList" }') {{ $t('templates') }}
      b-dropdown-item(
        v-if = 'isScoringEnabled'
        :to = '{ name: "ScoringModelList" }'
      ) {{ $t('scoringModels') }}
      b-dropdown-item(:to = '{ name: "LogsView" }') {{ $t('logs') }}
      b-dropdown-item(:to = '{ name: "RoleList" }') {{ $t('roleList') }}
      b-dropdown-item(:to = '{ name: "AutomaticNotificationList" }') {{ $t('automaticNotifications') }}
      b-dropdown-item(:to = '{ name: "DataMigration" }') {{ $t('migration') }}
      b-dropdown-item(:to = '{ name: "TaxationList" }') {{ $t('taxation') }}
      b-dropdown-item(:to = '{ name: "CustomFieldList" }') {{ $t('customFields') }}
      b-dropdown-item(:to = '{ name: "CustomerFieldList" }') {{ $t('customerFields') }}
      b-dropdown-item(:to = '{ name: "KYCCustomFieldList" }') {{ $t('KYCCustomFields') }}
      b-dropdown-item(:to = '{ name: "VariableInterests" }') {{ $t('variableInterest') }}
  hr.mr-lg-auto
  ul.nav.navbar-nav
    li.nav-item
      router-link.nav-link(:to  = '{ name: "Notifications" }')
        i.text-danger.fa.fa-exclamation-circle.fa-lg(v-if = 'hasSystemErrors')
        i.text-success.fa.fa-check-circle.fa-lg(v-else)
    li.nav-item
      router-link.nav-link(
        v-b-tooltip.hover
        :to    = '{ name: "Dashboard", query: { assignedTo: user.id }, params: { statusUserNotification: "true" } }'
        :title = '$t("tasksNotification", { tasksCount: customerAssignedComments.length })'
      )
        i.fa.fa-bars.fa-lg
        span.badge.badge-warning.badge-pill(v-if = 'customerAssignedComments.length') {{ customerAssignedComments.length }}
    b-nav-item-dropdown.px-2.b-l-1.ml-2(right)
      span(slot = 'button-content')
        .float-left.text-left {{ user.firstName }} {{ user.lastName }}
      b-dd-header
        .text-center Bankish {{ version }}
      b-dd-group(:header = '$t("language")')
        b-dropdown-item(
          v-for          = '(localeTitle, locale) in availableLocales'
          :key           = 'locale'
          @click.prevent = 'setLocale(locale)'
        )
          i.fa(:class = '{ "fa-check": currentLocale === locale }')
          | {{ localeTitle }}
      b-dd-divider
      b-dropdown-item(@click.prevent = 'onLogout')
        i.fa.fa-sign-out
        | {{ $t('logout') }}
</template>


<script>
import { isDepositEnabled, isFactoringEnabled, isScoringEnabled } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import NavbarView from './NavbarView'

export default {
  name: 'header-view',

  inject: ['version'],

  components: { NavbarView },

  mixins: [isDepositEnabled, isFactoringEnabled, isScoringEnabled],

  i18nOptions: { namespaces: 'menu' },

  data () {
    return {
      searchCriteria: ''
    }
  },

  computed: {
    ...mapState('auth', ['user', 'isIdCardLogin']),
    ...mapState('i18N', ['availableLocales', 'currentLocale']),
    ...mapState('notifications', ['systemNotifications']),
    ...mapState('settings', ['logo']),
    ...mapState('customers', ['customerAssignedComments']),
    hasSystemErrors () {
      return this.systemNotifications.some(notification => notification.severity === 'CRITICAL')
    },
    logoLoader () {
      return this.$vueLoading.isLoading('system:logo:fetch')
    },
    logoStyle () {
      return { 'background-image': `url(${this.logo})` }
    }
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('i18N', ['setLocale']),
    mobileSidebarToggle () {
      document.body.classList.toggle('sidebar-mobile-show')
    },
    async onLogout () {
      await this.logout()
      if (this.isIdCardLogin) {
        this.$router.push({ name: 'IdLogout' })
      } else {
        this.$router.push({ name: 'Auth' })
      }
    },
    doSearch () {
      this.$router.push({ name: 'Customers', query: { criteria: this.searchCriteria } })
    }
  }
}
</script>


<style scoped lang="scss">
@import 'scss/variables';

.app-header.navbar {
  .navbar-brand {
    background-color: transparent;
    background-size: 100px 50px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    border-bottom: none;
    width: 138px;
  }
  .nav.navbar-nav {
    margin-right: 15px;
    right: 0;
  }
}

.dropdown-item {
  color: $gray-600;
  &.active-dropdown-item { color: $gray-800; }
}


ul.nav li.nav-item a.nav-link span.badge {
  top: 0.5rem;
  left: auto;
  right: -0.1rem;
}

span.company-name { font-size: 0.75rem; }
</style>


<i18n>
en:
  tasksNotification: "You have {{ tasksCount }} tasks assigned"
  language:          "Language"
et:
  tasksNotification: "You have {{ tasksCount }} tasks assigned"
  language:          "Language"
ru:
  tasksNotification: "You have {{ tasksCount }} tasks assigned"
  language:          "Language"
</i18n>
