<template lang="pug">
.card.dashboard-widget-messages-new
  .card-header.text-white.bg-info.border-bottom-0
    i.far.fa-2x.fa-envelope.text-muted.fa-pull-right.m-0
    h4.mb-0 {{dashboardMessagesNew.length}}
    small.text-muted.font-weight-bold
      router-link.text-muted.font-weight-bold(:to = '{ path: `/customers/${settings.defaultMailSenderUser}/communication`}') {{ $t('title') }}
  .card-body
    ul.list-unstyled.m-0
      dashboard-widget-messages-new-item(v-for = '(message, index) in dashboardMessagesNew.slice(0, 3)'
                                         :key = 'index'
                                         :message = 'message')

      li.text-center.nav-link(@click.prevent = 'showMessagesNewModal = true'): i.fa.fa-ellipsis-h.fa-lg

      messages-new-modal(v-model  = 'showMessagesNewModal')
</template>


<script>
import { mapState, mapActions } from 'vuex'
import DashboardWidgetMessagesNewItem from './DashboardWidgetMessagesNewItem'
import MessagesNewModal from '../messages/MessagesNewModal'

export default {
  name: 'dashboard-widget-messages-new',

  components: {
    DashboardWidgetMessagesNewItem,
    MessagesNewModal
  },

  i18nOptions: {},

  data: () => ({
    showMessagesNewModal: false
  }),

  computed: {
    ...mapState('settings', ['settings']),
    ...mapState('dashboard', ['dashboardMessagesNew'])
  },

  created () {
    this.loadDashboardMessagesNew()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardMessagesNew'])
  }

}
</script>


<i18n>
en:
  title:    "NEW MESSAGES"
et:
  title:    "NEW MESSAGES"
ru:
  title:    "NEW MESSAGES"
</i18n>

<style scoped lang="scss">
  .nav-link { cursor:pointer; }
</style>
