import { validators } from '@/validation'

export default (data) => ({
  attributesData: {
    residualAmount: data.residualAmount,
    residualAmountMax: data.residualAmountMax,
    residualAmountMin: data.residualAmountMin,
    residualAmountCalculationType: data.residualAmountCalculationType ?? {
      code: '',
      classifier: 'FeeCalculationType'
    },
    residualAmountOverride: data.residualAmountOverride,
    residualAmountCalculationTypeIdOverride: data.residualAmountCalculationTypeIdOverride,
    upfrontAmount: data.upfrontAmount,
    upfrontAmountMax: data.upfrontAmountMax,
    upfrontAmountMin: data.upfrontAmountMin,
    upfrontAmountCalculationType: data.upfrontAmountCalculationType ?? {
      code: '',
      classifier: 'FeeCalculationType'
    },
    upfrontAmountOverride: data.upfrontAmountOverride,
    upfrontAmountCalculationTypeIdOverride: data.upfrontAmountCalculationTypeIdOverride,
    principalWithVat: Boolean(data.principalWithVat),
    interestWithVat: Boolean(data.interestWithVat),
    fees: data.fees?.map(({ withVat, ...fee }) => ({ ...fee, withVat: Boolean(withVat) }))
  },
  validations: {
    attributesData: {
      upfrontAmountGroup: ['attributesData.upfrontAmount', 'attributesData.upfrontAmountMin', 'attributesData.upfrontAmountMax'],
      upfrontAmount: {
        required: validators.required
      },
      upfrontAmountMin: {
        max: validators.maxValueRef(function () {
          return this.attributesData.upfrontAmountMax
        })
      },
      upfrontAmountMax: {
        min: validators.minValueRef(function () {
          return this.attributesData.upfrontAmountMin
        })
      },
      upfrontAmountCalculationType: {
        required: validators.required,
        classifier: {},
        code: {
          required: validators.required
        }
      },
      residualAmountGroup: ['attributesData.residualAmount', 'attributesData.residualAmountMin', 'attributesData.residualAmountMax'],
      residualAmount: {
        required: validators.required
      },
      residualAmountMin: {
        max: validators.maxValueRef(function () {
          return this.attributesData.residualAmountMax
        })
      },
      residualAmountMax: {
        min: validators.minValueRef(function () {
          return this.attributesData.residualAmountMin
        })
      },
      residualAmountCalculationType: {
        required: validators.required,
        classifier: {},
        code: {
          required: validators.required
        }
      },
      fees: {
        $each: {
          withVat: {}
        }
      }
    }
  },
  dump () {
    const { generalData, attributesData, templatesData, scoringData, provision, paymentsData, customFields } = this
    const { fees, residualAmountCalculationType, upfrontAmountCalculationType } = this.attributesData
    return {
      ...generalData,
      ...attributesData,
      ...scoringData,
      ...templatesData,
      ...paymentsData,
      provision,
      customFields,
      residualAmountCalculationType: residualAmountCalculationType.code,
      upfrontAmountCalculationType: upfrontAmountCalculationType.code,
      fees: [
        ...fees.filter(({ feeType }) => feeType.code)
          .map(fee => ({
            ...fee,
            feeType: fee.feeType.code,
            feeCalculationType: fee.feeCalculationType.code
          }))
      ],
      financialData: this.financialData
    }
  }
})
