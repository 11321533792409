import { required } from 'vuelidate/lib/validators'

export default {
  creditAmount: {
    required
  },
  loanAmountWithVat: {},
  loanLength: {
    required
  },
  creditIssuerId: {
    required
  },
  productId: {
    required
  },
  purchasedGood: {
    required
  },
  purchasedGoodCost: {
    required
  },
  subject: {
    required
  },
  text: {
    required
  },
  warrantyTypeId: {
    required
  },
  warrantyCountryId: {
    required
  },
  warrantyCountyId: {
    required
  },
  warrantyCity: {
    required
  },
  warrantyAddress: {
    required
  },
  vehicleBrand: {
    required
  },
  vehiclePrice: {
    required
  },
  vehicleModel: {
    required
  },
  vehicleProductionYear: {
    required
  },
  vehicleRegistrationNumber: {
    required
  },
  sellerCompanyName: {
    required
  },
  residualAmount: {
    required
  },
  residualAmountCalculationTypeId: {
    required
  },
  upfrontAmount: {
    required
  },
  upfrontAmountCalculationTypeId: {
    required
  },
  contractLimit: {},
  advanceRate: {},
  invoiceLengthMin: {},
  invoiceLengthMax: {},
  rightOfRecourse: {},
  invoiceLengthInterestRates: {}
}
