import { connection, idAuthConnection } from './connection'

export default {
  postRefreshSession () {
    return connection.post('refreshSession', sessionStorage.getItem('refreshToken'))
  },
  postLogin ({ data }) {
    return connection.post('auth', data)
  },
  getMobIdAuth ({ phone, idCode }) {
    return connection.get(`mobidauth/${phone}/${idCode}`)
  },
  getMobIdPoll ({ sessCode }) {
    return connection.get(`mobidpoll/${sessCode}`)
  },
  getSmartIdauth ({ idCode, countryCode }) {
    return connection.get(`smartidauth/${countryCode}/${idCode}`)
  },
  getSmartIdPoll ({ sessionId }) {
    return connection.get(`smartidpoll/${sessionId}`)
  },
  getIdCardLogin () {
    return idAuthConnection.get('idauth')
  },
  postExpireSession () {
    return connection.post('expireSession')
  }
}
