import { validators } from '@/validation'

export default data => ({
  attributesData: {
    amountRangePeriodInterests: data.amountRangePeriodInterests || [],
    autoExtendable: data.autoExtendable || { extendable: false },
    currencyId: data.currencyId,
    dailyBalanceCalcType: data.dailyBalanceCalcType,
    dayCountConventionType: data.dayCountConventionType,
    interestPaymentMethods: data.interestPaymentMethods || [{
      deduction: 0,
      type: {
        code: 'END_OF_PERIOD'
      }
    }],
    interestRateMin: data.interestRateMin,
    interestRateMax: data.interestRateMax,
    hoursForActivation: data.hoursForActivation,
    maxWithdrawalFromCurrentAmount: data.maxWithdrawalFromCurrentAmount,
    periodUnitType: data.periodUnitType,
    fees: data.fees,
    withholdTaxEnabled: Boolean(data.withholdTaxEnabled),
    originOfMoneyLimit: data.originOfMoneyLimit,
    compoundInterest: Boolean(data.compoundInterest)
  },
  templatesData: {
    agreementTemplateId: data.agreementTemplateId,
    agreementTemplateIdOverride: Boolean(data.agreementTemplateIdOverride),
    informationSheetTemplateId: data.informationSheetTemplateId,
    informationSheetTemplateIdOverride: Boolean(data.informationSheetTemplateIdOverride),
    offerTemplateId: data.offerTemplateId,
    offerTemplateIdOverride: Boolean(data.offerTemplateIdOverride)
  },
  validations: {
    generalData: {
      sectorId: {
        required: validators.required
      }
    },
    attributesData: {
      currencyId: {
        required: validators.required
      },
      dayCountConventionType: {
        required: validators.required
      },
      dailyBalanceCalcType: {
        required: validators.required
      },
      periodUnitType: {
        required: validators.required
      },
      interestRateConstraintsGroup: ['attributesData.interestRateMin', 'attributesData.interestRateMax'],
      interestRateMin: {
        required: validators.required
      },
      interestRateMax: {
        required: validators.required
      },
      maxWithdrawalFromCurrentAmount: {
        required: validators.requiredIf(function () {
          return this.isFlexible
        })
      },
      hoursForActivation: {
        required: validators.required
      },
      autoExtendable: {
        extendable: {},
        additionalInterest: {
          required: validators.requiredIf(parent => parent.extendable)
        }
      },
      withholdTaxEnabled: {},
      amountRangePeriodInterests: {
        required: validators.required,
        minLength: validators.minLength(1),
        $each: {
          amountRange: {
            from: {
              min: validators.minValue(1),
              required: validators.required
            },
            to: {
              required: validators.required
            }
          },
          sliderStep: {
            required: validators.required,
            steps: (value, parentVm) => {
              const { from, to } = parentVm.amountRange
              return !validators.helpers.req(value) || Number.isInteger((from - to) / value)
            }
          },
          periodRangeInterests: {
            required: validators.required,
            minLength: validators.minLength(1),
            $each: {
              periodRange: {
                from: {
                  required: validators.required,
                  min: validators.minValue(1)
                },
                to: {
                  required: validators.required,
                  min: validators.minValue(1)
                }
              },
              interest: {
                required: validators.required,
                min: validators.minValueRef(function () {
                  return this.attributesData.interestRateMin
                }),
                max: validators.maxValueRef(function () {
                  return this.attributesData.interestRateMax
                })
              }
            }
          }
        }
      },
      interestPaymentMethods: {
        required: validators.required,
        minLength: validators.minLength(1),
        $each: {
          type: {
            code: {
              required: validators.required
            }
          },
          deduction: {
            required: validators.required
          }
        }
      },
      fees: {
        required: validators.required,
        minLength: validators.minLength(2),
        $each: {
          feeType: {
            classifier: {},
            code: {
              required: validators.required
            }
          },
          feeAmount: {
            required: validators.required
          },
          feeMax: {
            required: validators.required
          },
          feeMin: {
            required: validators.required
          },
          feeCalculationType: {
            classifier: {},
            code: {
              required: validators.required
            }
          },
          feeOverride: {}
        }
      },
      originOfMoneyLimit: {
        required: validators.required
      },
      compoundInterest: {}
    }
  },
  dump () {
    const { generalData, attributesData, templatesData, provision, paymentsData, customFields } = this
    const { fees, interestPaymentMethods } = this.attributesData
    return {
      ...generalData,
      ...attributesData,
      ...templatesData,
      ...paymentsData,
      provision,
      customFields,
      fees: [
        ...fees.filter(({ feeType }) => feeType.code)
          .map(fee => ({
            ...fee,
            feeType: fee.feeType.code,
            feeCalculationType: fee.feeCalculationType.code
          }))
      ],
      interestPaymentMethods: interestPaymentMethods.map(val => ({ ...val, type: val.type.code }))
    }
  }
})
