import api from '@/api'
import { createActionHelpers } from 'vuex-loading'
const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  actions: {
    async sendResetRequest ({ dispatch }, login) {
      try {
        await startLoading(dispatch, 'reset:request', () => api.postResetRequest(login))
        return true
      } catch (e) {
        return false
      }
    },
    async saveNewPassword ({ dispatch, commit }, { token, newPassword }) {
      try {
        await startLoading(dispatch, 'reset:password:save', () => api.postReset({ token, newPassword }))
      } catch (e) {
        return Promise.reject(e.response.data)
      }
    }
  }
}
