<template lang="pug">
date-picker.fi-datepicker(
  v-model          = 'selectedDate'
  v-bind           = '$attrs'
  :input-props     = '{ placeholder }'
  :locale          = 'currentLocale'
  :masks           = 'masks'
  :class           = '[{ "hide-input": inline }]'
  :popover         = '{ visibility: "click" }'
  :is-inline       = 'inline'
  :available-dates = 'enabledDates'
  :attributes      = 'attributes'
)
  template(#default = '{ inputProps, inputEvents }')
    input.form-control(
      v-if         = '!inline'
      v-bind       = 'inputProps'
      v-on         = 'inputEvents'
      :class       = '{ "form-control-sm": sm }'
      :disabled    = 'disabled'
      :placeholder = 'placeholder'
    )
</template>


<script>
import { mapState } from 'vuex'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

const formats = {
  en: 'DD-MMM-YYYY',
  et: 'DD.MM.YYYY',
  ru: 'DD.MM.YYYY'
}

export default {
  name: 'fi-datepicker',

  components: {
    DatePicker
  },

  inheritAttrs: false,

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Date, Array],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    enabledDates: {
      type: [Array, Object],
      default: () => []
    },
    time: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    allowInput: {
      type: Boolean,
      default: true
    },
    defaultDates: {
      type: [Array, String, Date],
      default: ''
    },
    sm: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('i18N', ['currentLocale']),
    selectedDate: {
      get () {
        if (!this.value) {
          return this.value
        }
        if (Array.isArray(this.value)) {
          return this.value.map(date => this.$moment(date).toDate())
        } else {
          return this.$moment(this.value).toDate()
        }
      },
      set (value) {
        if (!value) {
          this.$emit('input', value)
          return
        }
        if (Array.isArray(value)) {
          this.$emit('input', value.map(date => this.$moment(date).format('YYYY-MM-DD')))
        } else {
          this.$emit('input', this.$moment(value).format('YYYY-MM-DD'))
        }
      }
    },
    masks () {
      return {
        input: [
          formats[this.currentLocale] || formats.en
        ],
        navMonths: 'MMM'
      }
    },
    attributes () {
      return [
        {
          key: 'today',
          highlight: 'green',
          dates: new Date()
        }
      ]
    }
  }
}
</script>


<style lang="scss" scoped>
.is-invalid > input { border-color: red!important; }
.hide-input input { display: none }
.fi-datepicker {
  flex: 1;
  & /deep/ .vc-popover-content-wrapper {
    z-index: 1100;
  }
  .input-group & {
    &:not(:first-child) {
      & .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:not(:last-child) {
      & .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  & + & {
    & .form-control {
      margin-left: -1px;
    }
  }
}
</style>
