<template lang="pug">
fi-modal.loan-notifications-modal(ref               = 'modal'
                                  v-model           = 'showModal'
                                  :title            = '$t("title")'
                                  size              = 'sm'
                                  header-bg-variant = 'success'
                                  lazy
                                  hide-footer)
  ul.list-unstyled.m-0
    dashboard-widget-loan-notifications-item(v-for         = '(notification) in dashboardLoanNotifications'
                                             :key          = 'notification.id'
                                             :notification = 'notification')
</template>


<script>
import { mapActions, mapState } from 'vuex'
import DashboardWidgetLoanNotificationsItem from '../dashboard/DashboardWidgetLoanNotificationsItem'
import FiModal from '@/components/FiModal'

export default {
  name: 'loan-notifications-modal',

  components: {
    DashboardWidgetLoanNotificationsItem,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('dashboard', ['dashboardLoanNotifications']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  mounted () {
    this.loadDashboardLoanNotifications()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardLoanNotifications'])
  }
}
</script>

<i18n>
en:
  title:    "All loan notices"
et:
  title:    "All loan notices"
ru:
  title:    "All loan notices"
</i18n>
