<template lang="pug">
header.app-header.navbar.navbar-view
  slot
</template>

<script>
export default {
  name: 'navbar-view',

  created () {
    this._navbar = true
  }
}
</script>
