<template lang="pug">
b-tabs(
  content-class = 'border-0'
  pills
  lazy
)
  b-tab(
    v-for  = '(reportCategory, index) in reports'
    :key   = 'index'
    :title = '$t(reportCategory.category)'
  )
    report-tab(:report-category = 'reportCategory')
  b-tab(:title = '$t("dataSet")')
    b-tabs(pills lazy)
      b-tab(:title = '$t("dataSet")')
        .row
          fi-form-field.col-lg-3
            fi-datepicker(
              v-model         = 'entryDate'
              :disabled-dates = 'successDates'
              :max-date       = 'systemData.lastDayChange'
            )
          .col-lg-3
            button.btn.btn-outline-primary.fa-pull-right(@click.prevent = 'calculate')
              i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("reports:calculate:save")')
              | {{ $t('calculate') }}
      b-tab(:title = '$t("status")')
        reports-calculate-history
  </template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import RangeInputDate from '@/components/RangeInputDate'
import FiFormField from '@/components/FiFormField'
import ReportsCalculateHistory from '@/views/reports/ReportsCalculateHistory'
import ReportTab from '@/views/reports/ReportTab'
import { mapActions, mapState } from 'vuex'
import reportsMock from './reportsData'

export default {
  name: 'reports-view',
  components: {
    ReportsCalculateHistory,
    ReportTab,
    FiDatepicker,
    FiFormField,
    RangeInputDate
  },

  data () {
    return {
      entryDate: '',
      reports: reportsMock
    }
  },

  computed: {
    ...mapState('reports', ['calculateHistory', 'successDates']),
    ...mapState('loans', ['systemData'])
  },

  created () {
    this.loadCreditIssuers()
    this.loadSuccessDates()
    this.loadCalculateHistory()
  },

  methods: {
    ...mapActions('classifiers', ['loadCreditIssuers']),
    ...mapActions('reports', ['postCalculateHistory', 'loadSuccessDates', 'loadCalculateHistory']),
    async calculate () {
      await this.postCalculateHistory({ entryDate: this.entryDate })
      this.loadSuccessDates()
    }
  }
}

</script>


<i18n>
en:
  title:          "Reports"
  period:         "Report period"
  date:           "Report date"
  creditIssuer:   "Credit issuer"
  defaultPeriods: "Default periods"
  lastWeek:       "Last week"
  lastMonth:      "Last month"
  lastQuarter:    "Last quarter"
  downloadCSV:    "Download as CSV"
  downloadXML:    "Download as XML"
  apply:          "Apply"
  calculate:      "Calculate reports data"
  dataSet:        "Set of data"
  status:         "Status"
et:
  title:          "Reports"
  period:         "Report period"
  date:           "Report date"
  creditIssuer:   "Credit issuer"
  defaultPeriods: "Default periods"
  lastWeek:       "Last week"
  lastMonth:      "Last month"
  lastQuarter:    "Last quarter"
  downloadCSV:    "Download as CSV"
  downloadXML:    "Download as XML"
  apply:          "Apply"
  calculate:      "Calculate reports data"
  dataSet:        "Set of data"
  status:         "Status"
ru:
  title:          "Reports"
  period:         "Report period"
  date:           "Report date"
  creditIssuer:   "Credit issuer"
  defaultPeriods: "Default periods"
  lastWeek:       "Last week"
  lastMonth:      "Last month"
  lastQuarter:    "Last quarter"
  downloadCSV:    "Download as CSV"
  downloadXML:    "Download as XML"
  apply:          "Apply"
  calculate:      "Calculate reports data"
  dataSet:        "Set of data"
  status:         "Status"
</i18n>
