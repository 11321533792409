import { connection, downloadFile } from '@/api/connection'
import pickBy from 'lodash/pickBy'
import qs from 'qs'

export default {
  getOutgoingPayments ({ filters }) {
    return connection.get('bo/payments/outgoing', {
      params: pickBy(filters)
    })
  },
  getOutgoingPayment ({ id }) {
    return connection.get(`bo/payments/outgoing/${id}`)
  },
  putOutgoingPayment ({ paymentData }) {
    return connection.put('bo/payments/outgoing', paymentData)
  },
  postFromPrepayment ({ paymentData }) {
    return connection.post('bo/payments/outgoing/prepayment', paymentData)
  },
  putPaymentsBulk ({ paymentIds, status, metadata } = {}) {
    return connection.put('bo/payments/outgoing/batch', {
      paymentIds,
      status,
      metadata
    })
  },
  getSheets ({ filters }) {
    return connection.get('bo/payments/outgoing/export', {
      params: pickBy(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, arrayFormat: 'repeat' })
      }
    })
  },
  postCreateSheet ({ handler }) {
    return connection.post(`bo/payments/outgoing/export/${handler}`)
  },
  getExportSheetXML ({ sheetId }) {
    return downloadFile(`bo/payments/outgoing/export/${sheetId}/xml`)
  },
  getManualUpdate: () => connection.get('bo/payments/outgoing/synchronize')
}
