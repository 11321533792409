<template lang="pug">
.payment-incoming.animated.fadeIn
  v-loading(loader = 'payment:fetch')
    template(#spinner)
      .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
    template(v-if = 'payment')
      payment-details
      payment-matching-loan-list(
        v-if    = 'showMatchingContracts'
        :status = 'status.name'
      )
      payment-account-prev-transactions
</template>


<script>
import PaymentDetails from '@/views/payments/PaymentDetails'
import { mapActions, mapGetters, mapState } from 'vuex'
import PaymentAccountPrevTransactions from './PaymentAccountPrevTransactions'
import PaymentMatchingLoanList from './PaymentMatchingLoanList'

export default {
  name: 'payment-incoming',

  components: {
    PaymentDetails,
    PaymentAccountPrevTransactions,
    PaymentMatchingLoanList
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  computed: {
    ...mapState('payments', ['payment']),
    ...mapGetters('classifiers', ['classifierById']),
    showMatchingContracts () {
      return /processed|pending/i.test(this.status.code)
    },
    status () {
      return this.classifierById('incomingPaymentStatuses', this.payment.statusId)
    }
  },

  watch: {
    id: {
      handler (newVal, oldVal) {
        if (newVal === oldVal) return
        this.loadIncomingPayment({ id: this.id, type: 'incoming' })
      },
      immediate: true
    }
  },

  methods: mapActions('payments', ['loadIncomingPayment'])
}
</script>
