<template lang='pug'>
.fi-rich-text-editor.form-control(v-if   = 'editor'
                                  @click = 'editor.focus()')
  editor-menu-bar.fi-rich-text-editor__menubar(:editor = 'editor'
                                               v-slot  = '{ commands, isActive }')
    .btn-toolbar
      .btn-group-sm
        button.btn.btn-sm(:class="{ 'btn-primary': isActive.bold() }" @click.prevent="commands.bold")
          fi-icon(name="bold")
        button.btn.btn-sm(:class="{ 'btn-primary': isActive.italic() }" @click.prevent="commands.italic")
          fi-icon(name="italic")
        button.btn.btn-sm(:class="{ 'btn-primary': isActive.underline() }" @click.prevent="commands.underline")
          fi-icon(name="underline")
      .btn-group-sm
        button.btn.btn-sm(v-for = 'i in Array.from({length: 6}, (v, k) => k+1)'
                          :class="{ 'btn-primary': isActive.heading({ level: i }) }" @click.prevent="commands.heading({ level: i })") H{{ i }}
      .btn-group-sm
        button.btn.btn-sm(:class="{ 'btn-primary': isActive.bullet_list() }" @click.prevent="commands.bullet_list")
          fi-icon(name="ul")
        button.btn.btn-sm(:class="{ 'btn-primary': isActive.ordered_list() }" @click.prevent="commands.ordered_list")
          fi-icon(name="ol")
      .btn-group-sm
        button.btn.btn-sm(@click.prevent="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })")
          fi-icon(name="table")
        span(v-if="isActive.table()")
          button.btn.btn-sm(@click.prevent="commands.deleteTable")
            fi-icon(name="delete_table")
          button.btn.btn-sm(@click.prevent="commands.addColumnBefore")
            fi-icon(name="add_col_before")
          button.btn.btn-sm(@click.prevent="commands.addColumnAfter")
            fi-icon(name="add_col_after")
          button.btn.btn-sm(@click.prevent="commands.deleteColumn")
            fi-icon(name="delete_col")
          button.btn.btn-sm(@click.prevent="commands.addRowBefore")
            fi-icon(name="add_row_before")
          button.btn.btn-sm(@click.prevent="commands.addRowAfter")
            fi-icon(name="add_row_after")
          button.btn.btn-sm(@click.prevent="commands.deleteRow")
            fi-icon(name="delete_row")
          button.btn.btn-sm(@click.prevent="commands.toggleCellMerge")
            fi-icon(name="combine_cells")
  editor-content.fi-rich-text-editor__content(:editor = 'editor')
</template>


<script>
import FiIcon from '@/components/FiIcon'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
  Placeholder
} from 'tiptap-extensions'

export default {
  name: 'fi-rich-text-editor',

  components: {
    FiIcon,
    EditorContent,
    EditorMenuBar
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  data: (vm) => ({
    editor: null,
    state: vm.value
  }),

  watch: {
    value: {
      handler (value) {
        this.state = value
        this.editor.setContent(value)
      }
    }
  },

  created () {
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        new Table({
          resizable: true
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Placeholder({
          emptyNodeText: this.placeholder,
          showOnlyWhenEditable: true
        })
      ],
      onUpdate: ({ getHTML }) => {
        this.state = getHTML()
      },
      onBlur: () => {
        this.$emit('input', this.state)
      }
    })
    this.editor.setContent(this.value)
  },

  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style lang='scss'>
@import "scss/variables";
@import "~bootstrap/scss/variables";

.fi-rich-text-editor {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  position: relative;
  border: 1px solid #e4e4e4;
  width: 100%;

  &.form-control {
    padding: 0;
    margin: 0;
    height: auto;
  }

  &:focus-within {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }

  &.is-invalid {
    border-color: #ff5454;
  }

  &.is-invalid:focus-within {
    border-color: #ff5454;
    box-shadow: 0 0 0 0.2rem rgba(255, 84, 84, 0.25)
  }

  &__menubar {
    display: flex;
    background-color: #f6f6f6;
  }

  &__content {
    line-height: 1.33;
    font-family: inherit;
    color: inherit;
    overflow-y: auto;
    padding: 0.25rem 0.5rem;
    max-height: 300px;
    min-height: 250px;
    outline: none!important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
      caret-color: currentColor;
    }

    p.is-empty:first-child::before {
      content: attr(data-empty-text);
      float: left;
      color: #aaa;
      pointer-events: none;
      height: 0;
      font-style: italic;
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: #000;
      color: #fff;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      display: inline-block;
      padding: 0 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba(#000, 0.1);
      color: rgba(#000, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba(#000, 0.1);
      color: rgba(#000, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td, th {
        min-width: 1em;
        border: 2px solid #cfcfcf;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0; right: 0; top: 0; bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px; top: 0; bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
}

</style>
