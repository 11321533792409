export default [
  {
    path: 'loans',
    name: 'Loans',
    component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanList'),
    meta: { label: 'menu:loans' },
    props: route => ({ criteria: route.query.criteria })
  },
  {
    path: 'loans',
    component: { render (c) { return c('router-view') } },
    meta: { label: 'menu:loans' },
    children: [
      {
        path: 'new',
        name: 'LoanNew',
        component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanNew'),
        meta: { label: 'menu:loanNew' },
        props: route => ({
          customer: route.params.customer
        })
      },
      {
        path: ':id',
        name: 'Loan',
        component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanView'),
        meta: { label: 'menu:loan' },
        props: true,
        redirect: { name: 'LoanOverview' },
        children: [
          {
            path: 'overview',
            name: 'LoanOverview',
            component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanOverview'),
            meta: { skip: true }
          },
          {
            path: 'financials',
            name: 'LoanFinancials',
            component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanFinancials'),
            meta: { skip: true }
          },
          {
            path: 'schedule',
            name: 'LoanSchedule',
            component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanScheduleView'),
            meta: { skip: true }
          },
          {
            path: 'terms',
            name: 'LoanTerms',
            props: true,
            component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanTerms'),
            meta: { skip: true }
          },
          {
            path: 'collateral',
            name: 'LoanCollateral',
            component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanCollateralView'),
            meta: { skip: true }
          },
          {
            path: 'communication',
            name: 'LoanCommunication',
            component: () => import(/* webpackChunkName: "Loans" */'@/views/loans/LoanCommunication'),
            meta: { skip: true }
          }
        ]
      }
    ]
  }
]
