<template lang = "pug">
tr.dashboard-task-item
  td: router-link(:to = '{ name: "CustomerCommunication", params: { id: task.customerId } }') {{ task.customerName }}
  td: router-link(:to = 'contractLink') {{ task.contractAgreementNumber }}
  td
    router-link(
      v-if = 'task.commentableType.code === "APPLICATION"'
      :to = '{ name: "ApplicationCommunication", params: { id: task.commentableId } }'
    ) {{ task.commentableId }}
  td.text-center: .badge(:class = '`badge-${loanStatusColor(status.name)}`') {{ status.human }}
  td.text-center
    fi-switch.ml-auto(
      v-model   = 'computedStatus'
      :disabled = 'saving'
      on-text   = 'Active'
      off-text  = 'Inactive'
    )
  td {{ classifierById('commentCategory', task.categoryId).human }}
  td.text-center.text-nowrap
    router-link(:to = '{ name: "Customer", params: { id: task.createdBy } }') {{ task.createdByName }}
    br
    small.text-muted.mr-1 {{ $t('common:formatDatetime', { value: task.createdAt }) }}
  td.text-center.text-nowrap
    router-link(
      v-if = 'task.assignedTo'
      :to = '{ name: "Customer", params: { id: task.assignedTo } }'
    ) {{ task.assignedToName }}
    button.btn.btn-link(
      v-else
      @click = 'assignToMe'
    ) {{ $t('assignToMe') }}
  td.text-nowrap.text-center {{ $t('common:formatDate', { value: task.dueDate }) }}
  td.text-nowrap.text-center
    .badge(v-for = '{ type = {} } in task.actions')
      i(
        :class = 'commentActionIcon(type.code)'
        :title = 'type.code'
      )
  td.text-center.text-nowrap
    template(v-if = 'callAction && callAction.result') {{ classifierByName(callAction.result.classifier, callAction.result.code).human }}
    template(v-else-if = 'editResult')
      .input-group.animated.fadeIn.flex-nowrap
        fi-select(
          v-model   = 'taskResult'
          :options  = 'optionsFromClassifier("commentActionResult")'
          sm
        )
        .input-group-prepend
          button.btn.btn-sm.btn-link(@click = 'saveResult'): i.fa.fa-check.fa-lg.text-success
          button.btn.btn-sm.btn-link(@click = 'editResult = false'): i.fa.fa-close.fa-lg.text-danger
    button.btn.btn-link(
      v-else-if = 'callAction'
      @click    = 'editResult = true'
    ) {{ $t('addResult') }}
  td.w-20: .comment-text(v-html = 'sanitizedComment')
  td.text-center.text-nowrap
    router-link(:to = '{ name: "Customer", params: { id: task.updatedBy } }') {{ task.updatedByName }}
    br
    small.text-muted.mr-1 {{ $t('common:formatDatetime', { value: task.updatedAt }) }}
  td.text-center.text-nowrap
    button.btn.btn-link.px-0.btn-sm(@click.prevent = '$emit("edit", task)')
      i.far.fa-edit.fa-lg
    br
    i.fa.fa-spinner.fa-pulse(v-if = 'removing')
    button.btn.btn-link.text-danger.px-0.btn-sm(
      v-else
      :disabled    = 'removing'
      v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }'
    )
      i.far.fa-trash-alt.fa-lg
</template>


<script>
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { Comment } from '@/models'
import sanitizeHtml from 'sanitize-html'
import { mapGetters, mapState } from 'vuex'

const STATUS_CLASSIFIER_BY_COMMENTABLE = {
  DEPOSIT_CONTRACT: 'DepositContractStatusType',
  APPLICATION: 'CreditApplicationStatuses',
  LOAN_CONTRACT: 'CreditContractStatus',
  FACTORING_CONTRACT: 'CreditContractStatus'
}

const ROUTE_BY_COMMENTABLE = {
  DEPOSIT_CONTRACT: 'DepositCommunication',
  LOAN_CONTRACT: 'LoanCommunication',
  FACTORING_CONTRACT: 'FactoringCommunication'
}

export default {
  name: 'dashboard-task-item',

  components: { FiSelect, FiSwitch },

  props: {
    task: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editing: false,
    editResult: false,
    taskResult: ''
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'managerById', 'commentActionIcon', 'optionsFromClassifier']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapState('auth', ['user']),
    contractLink () {
      return { name: ROUTE_BY_COMMENTABLE[this.task.commentableType.code], params: { id: this.task.commentableId } }
    },
    status () {
      if (!this.task.contractStatus) return ''
      return this.classifierByName(STATUS_CLASSIFIER_BY_COMMENTABLE[this.task.commentableType.code], this.task.contractStatus) ?? ''
    },
    computedStatus: {
      get () {
        return this.task.active
      },
      set (status) {
        if (!this.saving) this.saveTask({ active: status })
      }
    },
    callAction () {
      return this.task.actions?.find(({ type }) => type?.code === 'CALL')
    },
    saving () {
      return this.$vueLoading.isLoading(`dashboard:customer:task:${this.task.id}:save`)
    },
    removing () {
      return this.$vueLoading.isLoading(`dashboard:customer:task:${this.task.id}:remove`)
    },
    sanitizedComment () {
      return sanitizeHtml(this.task.comment)
    }
  },

  methods: {
    assignToMe () {
      this.saveTask({ assignedTo: this.user.id })
    },
    saveTask (data) {
      this.$emit('change', { commentId: this.task.id, commentData: new Comment({ ...this.task, ...data }) })
    },
    async saveResult () {
      await this.$emit('result', {
        id: this.task.id,
        actionId: this.callAction.id,
        actionData: { result: this.taskResult }
      })
      this.editResult = false
    },
    remove ({ done }) {
      this.$emit('remove', { id: this.task.id })
      done()
    }
  }
}
</script>


<style lang = 'scss' scoped>
.w-20 {
  width: 20% !important;
}

.comment-text {
  white-space: pre-line;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px; /* fallback */
  max-height: 56px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>


<i18n>
en:
  addResult:     "Add result"
  assignToMe:    "Assign to me"
  removeConfirm: "Are you sure you want to delete this task?"
et:
  addResult:     "Add result"
  assignToMe:    "Assign to me"
  removeConfirm: "Are you sure you want to delete this task?"
ru:
  addResult:     "Add result"
  assignToMe:    "Assign to me"
  removeConfirm: "Are you sure you want to delete this task?"
</i18n>
