import { connection } from '@/api/connection'
import pickBy from 'lodash/pickBy'
import { createBlobFromObject } from '@/helpers'

const { get, post, put } = connection

export default {
  getFactoringInvoicesByFilters: (filters) => get('bo/factoring/imported/invoices', {
    params: pickBy(filters)
  }),
  postFactoringInvoice: ({ resourceData, files, invoiceData }) => {
    const formData = new FormData()
    if (files.length) {
      files.forEach(file => formData.append('files', file))
      formData.append('resource', createBlobFromObject(resourceData))
    }
    formData.append('json', createBlobFromObject(invoiceData))

    return post('bo/factoring/imported/invoices', formData)
  },
  getFactoringInvoice: ({ invoiceId }) => get(`bo/factoring/imported/invoices/${invoiceId}`),
  getFactoringInvoiceClassifiers: () => get('bo/factoring/imported/invoices/classifiers'),
  putFactoringInvoice: ({ invoiceId, invoiceData }) => put(`bo/factoring/imported/invoices/${invoiceId}`, invoiceData),
  putFactoringInvoicesStatus: ({ status, invoices }) => put(`bo/factoring/imported/invoices/status/${status}`, invoices),
  postFactoringInvoiceDocument: ({ invoiceId, customerId, resourceData, files }) => {
    const formData = new FormData()
    files.forEach(file => formData.append('files', file))
    formData.append('resource', createBlobFromObject(resourceData))
    formData.append('customerId', customerId)
    return post(`bo/factoring/imported/invoices/${invoiceId}/documents`, formData)
  }
}
