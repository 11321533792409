import { feeDisabled, feeEditable, feePercentage } from '@/helpers'

export const isFeePercentage = {
  methods: {
    feePercentage
  }
}

export const feeDisabledMixin = {
  methods: {
    feeDisabled
  }
}

export const feeEditableMixin = {
  methods: {
    feeEditable
  }
}
