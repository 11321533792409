<template lang="pug">
.card.dashboard-widget-application-notifications
  .card-header.text-white.bg-warning.border-bottom-0
    i.far.fa-2x.fa-bell.text-muted.fa-pull-right.m-0
    h4.mb-0 {{dashboardApplicationNotifications.length}}
    small.text-muted.font-weight-bold
      router-link.text-muted.font-weight-bold(:to = '{ name: "Applications", query: { statusId: "2, 3, 6", sort: "createdAt,desc", size: 25 } }') {{ $t('title') | upperCase}}
  .card-body
    ul.list-unstyled.m-0
      dashboard-widget-application-notifications-item(v-for = '(notification, index) in dashboardApplicationNotifications.slice(0, 3)'
                                                      :key = 'index'
                                                      :notification = 'notification')

      li.text-center.nav-link(@click.prevent = 'showApplicationNotificationsModal = true'): i.fa.fa-ellipsis-h.fa-lg

      application-notifications-modal(
        v-if    = 'showApplicationNotificationsModal'
        v-model = 'showApplicationNotificationsModal'
      )
</template>


<script>
import { mapState, mapActions } from 'vuex'
import DashboardWidgetApplicationNotificationsItem from './DashboardWidgetApplicationNotificationsItem'
import ApplicationNotificationsModal from '../applications/ApplicationNotificationsModal'

export default {
  name: 'dashboard-widget-application-notifications',

  components: {
    DashboardWidgetApplicationNotificationsItem,
    ApplicationNotificationsModal
  },

  i18nOptions: {},

  data () {
    return {
      showApplicationNotificationsModal: false,
      statusIdArray: [
        { text: 'inProgress', value: 2 },
        { text: 'offerSent', value: 3 },
        { text: 'inAnalysis', value: 6 }
      ]
    }
  },

  computed: {
    ...mapState('dashboard', ['dashboardApplicationNotifications'])
  },

  mounted () {
    this.loadDashboardApplicationNotifications()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardApplicationNotifications'])
  }

}
</script>


<i18n>
en:
  title:    "Application notices"
et:
  title:    "Application notices"
ru:
  title:    "Application notices"

</i18n>

<style scoped lang="scss">
  .nav-link { cursor:pointer; }
</style>
