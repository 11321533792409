<template lang="pug">
.sidebar.sidebar-view
  nav.sidebar-nav
    form(@submit.prevent = 'doSearch')
      .form-group.mb-0.pr-2
        input.form-control(type         = 'text'
                           :placeholder = '$t("search")'
                           v-model      = 'searchCriteria')
    ul.nav
      li.nav-item: router-link.nav-link(:to = '{ name: "Dashboard" }') {{ $t('dashboard') }}
      li.nav-item: router-link.nav-link(:to = '{ name: "Applications" }') {{ $t('applications') }}
      li.nav-item: router-link.nav-link(:to = '{ name: "Customers" }') {{ $t('customers') }}
      li.nav-item: router-link.nav-link(:to = '{ name: "Loans" }') {{ $t('menu:loans') }}
      li.nav-item(v-if = 'isFactoringEnabled')
        router-link.nav-link(:to = '{ name: "FactoringList" }') {{ $t('menu:factoring') }}
      li.nav-item(v-if = 'isDepositEnabled')
        router-link.nav-link(:to = '{ name: "Deposits" }') {{ $t('deposits') }}
      li.nav-item: router-link.nav-link(:to = '{ name: "Debts" }') {{ $t('debts') }}
      li.nav-item: router-link.nav-link(:to = '{ name: "ScoringRequests" }') {{ $t('scoring') }}
      li.nav-item: router-link.nav-link(:to = '{ name: "Payments" }') {{ $t('payments') }}
      li.nav-item: router-link.nav-link(:to = '{ name: "ReportsView" }') {{ $t('reports') }}
      router-link.nav-item.nav-dropdown(tag = 'li'
                                        :to = '{ name: "Admin" }'
                                        disabled)
        .nav-link.nav-dropdown-toggle(href           = '#'
                                      @click.prevent = 'handleClick') {{ $t('admin') }}
        ul.nav-dropdown-items
          li.nav-item: router-link.nav-link(:to = '{ name: "ProductList" }' exact) {{ $t('products') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "Classifiers" }' exact) {{ $t('classifiers') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "Settings" }' exact) {{ $t('settings') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "TemplateList" }' exact) {{ $t('templates') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "ScoringModelList" }' exact) {{ $t('scoringModels') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "LogsView" }' exact) {{ $t('logs') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "RoleList" }' exact) {{ $t('roleList') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "AutomaticNotificationList" }' exact) {{ $t('automaticNotifications') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "DataMigration" }' exact) {{ $t('migration') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "TaxationList" }' exact) {{ $t('taxation') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "CustomFieldList" }' exact) {{ $t('customFields') }}
          li.nav-item: router-link.nav-link(:to = '{ name: "CustomerFieldList" }' exact) {{ $t('customerFields') }}

</template>


<script>
import { isDepositEnabled, isFactoringEnabled } from '@/mixins'

export default {
  name: 'sidebar-view',

  mixins: [isDepositEnabled, isFactoringEnabled],

  i18nOptions: { namespaces: 'menu' },

  data () {
    return {
      searchCriteria: ''
    }
  },

  methods: {
    handleClick (e) {
      e.target.parentElement.classList.toggle('open')
    },
    doSearch () {
      this.$router.push({ name: 'Customers', query: { criteria: this.searchCriteria } })
    }
  }
}
</script>


<style scoped lang="scss">
.nav-link { cursor:pointer; }
</style>
