<template lang="pug">
.card.dashboard-application-list
  .card-header {{ $t('title') }}
  .card-body
    .table-responsive
      table.table.table-hover
        thead: tr
          th ID
          th {{ $t('customer') }}
          th {{ $t('product') }}
          th.money {{ $t('amount') }}
          th {{ $t('date') }}
          th {{ $t('manager') }}
          th {{ $t('status') }}
        tbody
          tr(v-if = '$vueLoading.isLoading("dashboard:applicationsRecent:fetch")')
            td.text-center(colspan = 8)
              i.fa.fa-spinner.fa-pulse.fa-lg
          tr(v-else-if = 'dashboardApplicationsRecent.length === 0'): td.text-center(colspan = 8) {{ $t('notFound') }}
          template(v-else)
            dashboard-application-list-item(v-for        = 'application in dashboardApplicationsRecent'
                                            :key         = 'application.id'
                                            :application = 'application')
    router-link.btn.btn-outline-primary(:to = '{ name: "Applications" }') {{ $t('viewAll') }}
</template>


<script>
import { mapState, mapActions } from 'vuex'
import DashboardApplicationListItem from './DashboardApplicationListItem'

export default {
  name: 'dashboard-application-list',

  components: {
    DashboardApplicationListItem
  },

  i18nOptions: {},

  computed: {
    ...mapState('dashboard', ['dashboardApplicationsRecent'])
  },

  created () {
    this.loadDashboardApplicationsRecent()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardApplicationsRecent'])
  }
}
</script>


<i18n>
en:
  title:       "Recent applications"
  application: "Application"
  date:        "Date"
  customer:    "Customer"
  product:     "Loan product"
  amount:      "Amount"
  manager:     "Manager"
  status:      "Status"
  viewAll:     "View all"
  notFound:    "No applications found"
et:
  title:       "Recent applications"
  application: "Application"
  date:        "Date"
  customer:    "Customer"
  product:     "Loan product"
  amount:      "Amount"
  manager:     "Manager"
  status:      "Status"
  viewAll:     "View all"
  notFound:    "No applications found"
ru:
  title:       "Recent applications"
  application: "Application"
  date:        "Date"
  customer:    "Customer"
  product:     "Loan product"
  amount:      "Amount"
  manager:     "Manager"
  status:      "Status"
  viewAll:     "View all"
  notFound:    "No applications found"
</i18n>
