<template lang="pug">
fi-modal.deposit-notifications-modal(ref               = 'modal'
                                    v-model           = 'showModal'
                                    :title            = '$t("title")'
                                    header-bg-variant = 'secondary'
                                    size              = 'sm'
                                    lazy
                                    hide-footer)
  ul.list-unstyled.m-0
    dashboard-widget-deposit-notifications-item(v-for         = '(notification, index) in dashboardDepositsNotificationsInsertedAndSigned'
                                                :key          = 'index'
                                                :notification = 'notification')
</template>


<script>
import FiModal from '@/components/FiModal'
import DashboardWidgetDepositNotificationsItem from '@/views/dashboard/DashboardWidgetDepositNotificationsItem'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'deposit-notifications-modal',

  components: { FiModal, DashboardWidgetDepositNotificationsItem },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('dashboard', ['dashboardDepositsNotifications']),
    dashboardDepositsNotificationsInsertedAndSigned () {
      const dashboardDepositsNotificationsInsertedAndSigned = []
      this.dashboardDepositsNotifications.forEach((element) => {
        if (element.type === 'INSERTED' || element.type === 'SIGNED') {
          dashboardDepositsNotificationsInsertedAndSigned.push(element)
        }
      })
      return dashboardDepositsNotificationsInsertedAndSigned
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  mounted () {
    this.loadDashboardDepositNotifications()
  },

  methods: {
    ...mapActions('dashboard', ['loadDashboardDepositNotifications'])
  }
}
</script>


<i18n>
en:
  title: "All deposit notices"
et:
  title: "All deposit notices"
ru:
  title: "All deposit notices"
</i18n>
