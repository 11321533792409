import { mapGetters, mapState } from 'vuex'

export const isLoanLeasing = {
  computed: {
    ...mapState('loans', ['loanProduct']),
    isLeasingGroup () {
      return /leasing/i.test(this.loanProduct?.group)
    }
  }
}

export const isCapitalLeasing = {
  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapState('loans', ['loanProduct']),
    isCapitalCompanyLeasing () {
      return /CAPITAL_LEASING_FOR_COMPANY/i.test(this.loanProduct?.productType)
    }
  }
}
