export default [
  {
    path: 'factoring',
    name: 'FactoringList',
    component: { render (c) { return c('router-view') } },
    meta: { label: 'menu:factoring' },
    redirect: { name: 'FactoringContractList' },
    children: [
      {
        path: 'contracts',
        name: 'FactoringContractList',
        component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringContractList'),
        meta: { label: 'menu:factoringContracts' }
      },
      {
        path: 'invoices',
        name: 'FactoringInvoiceList',
        component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringInvoiceList'),
        meta: { label: 'menu:factoringInvoices' }
      },
      {
        path: 'new',
        name: 'FactoringNew',
        component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringContractNew'),
        meta: { label: 'menu:factoringNew' },
        props: route => ({
          customer: route.params.customer
        })
      },
      {
        path: ':id',
        name: 'FactoringContract',
        component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringView'),
        meta: { label: 'menu:factoring' },
        props: true,
        redirect: { name: 'FactoringOverview' },
        children: [
          {
            path: 'overview',
            name: 'FactoringOverview',
            component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringOverview'),
            meta: { skip: true }
          },
          {
            path: 'terms',
            name: 'FactoringTerms',
            component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringContractTermsView'),
            meta: { skip: true }
          },
          {
            path: 'invoices',
            name: 'FactoringInvoices',
            component: { render (c) { return c('router-view') } },
            meta: { skip: true },
            redirect: { name: 'FactoringContractInvoiceList' },
            children: [
              {
                path: 'contract',
                name: 'FactoringContractInvoiceList',
                component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringContractInvoiceList'),
                meta: { skip: true },
                props: true
              },
              {
                path: 'schedule',
                name: 'FactoringContractScheduleInvoiceList',
                component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringContractScheduleInvoiceList'),
                meta: { skip: true },
                props: true
              }
            ]
          },
          {
            path: 'financials',
            name: 'FactoringFinancials',
            component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringFinancials'),
            meta: { skip: true }
          },
          {
            path: 'communication',
            name: 'FactoringCommunication',
            component: () => import(/* webpackChunkName: "Factoring" */'@/views/factoring/FactoringCommunication'),
            meta: { skip: true }
          }
        ]
      }
    ]
  }
]
